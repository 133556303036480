import { Injectable } from '@angular/core';
import { ItemController__factory } from '@data/abi';
import { TransactionDataModel } from '@models/transaction-data.model';
import { ErrorService } from '@services/error.service';
import { FeesExtension } from '@services/onchain/FeesExtension';
import { RelayService } from '@services/onchain/relay.service';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { adjustGasLimit } from '@shared/utils';
import { NGXLogger } from 'ngx-logger';
import { catchError, concatMap, from, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ItemControllerService extends FeesExtension {
  constructor(
    private providerService: ProviderService,
    private errorService: ErrorService,
    private logger: NGXLogger,
    private relayService: RelayService,
  ) {
    super();
  }

  // --- FACTORIES ---

  private createItemController(chainId: number) {
    return ItemController__factory.connect(
      GET_CORE_ADDRESSES(chainId).itemController,
      this.providerService.getProviderForRead(),
    );
  }

  // --- CALLS ---

  use$(account: string, chainId: number, token: string, tokenId: number, hero: string, heroId: number) {
    this.logger.trace(`Use item`, token, tokenId, hero, heroId);
    return from(this.createItemController(chainId).use.estimateGas(token, tokenId, hero, heroId)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Use item',
            isNeedUpdateHero: true,
            isItemUsed: true,
            txPopulated: this.createItemController(chainId).use.populateTransaction(token, tokenId, hero, heroId),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  augment$(account: string, chainId: number, token: string, id: number, destroy: number) {
    return from(this.createItemController(chainId).augment.estimateGas(token, id, destroy)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Augment',
            subgraphWaitUserData: false,
            isNeedUpdateBalances: true,
            txPopulated: this.createItemController(chainId).augment.populateTransaction(token, id, destroy),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  equip$(
    account: string,
    chainId: number,
    token: string[],
    tokenId: number[],
    hero: string,
    heroId: number,
    slot: number[],
  ) {
    this.logger.trace(`Equip item
    token: ${token}
    tokenId: ${tokenId}
    hero: ${hero}
    heroId: ${heroId}
    slot: ${slot}
    `);
    return from(this.createItemController(chainId).equip.estimateGas(hero, heroId, token, tokenId, slot)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Equip',
            isNeedUpdateHero: true,
            txPopulated: this.createItemController(chainId).equip.populateTransaction(
              hero,
              heroId,
              token,
              tokenId,
              slot,
            ),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            isItemUsed: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  repairDurability$(account: string, chainId: number, token: string, id: number, destroy: number) {
    return from(this.createItemController(chainId).repairDurability.estimateGas(token, id, destroy)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Repair',
            isNeedUpdateBalances: true,
            txPopulated: this.createItemController(chainId).repairDurability.populateTransaction(token, id, destroy),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  takeOff$(
    account: string,
    chainId: number,
    item: string[],
    itemId: number[],
    heroAdr: string,
    heroId: number,
    slot: number[],
  ) {
    return from(this.createItemController(chainId).takeOff.estimateGas(heroAdr, heroId, item, itemId, slot)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Take off',
            isNeedUpdateHero: true,
            txPopulated: this.createItemController(chainId).takeOff.populateTransaction(
              heroAdr,
              heroId,
              item,
              itemId,
              slot,
            ),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            isItemUsed: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  combineItems$(account: string, chainId: number, configId: number, items: string[], itemIds: number[][]) {
    this.logger.trace('combineItems$', account, chainId, configId, items, itemIds);
    return from(this.createItemController(chainId).combineItems.estimateGas(configId, items, itemIds)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Combine items',
            isNeedUpdateHero: true,
            txPopulated: this.createItemController(chainId).combineItems.populateTransaction(configId, items, itemIds),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }
}
