<app-dialog-title
  class="g-flex-column__item-fixed"
  [title]="'bottles-dialog.title' | translate"
  (closeDialog)="close()">
</app-dialog-title>

<div
  class="content g-flex-column__item g-flex g-flex--justify-center g-flex--wrap g-scroll-y"
  [class.g-flex--align-start]="data.items.length"
  [class.g-flex--align-center]="!data.items.length">
  @if (data.items.length) {
    @for (item of data.items; track item) {
      <app-item-slot
        class="inventory__all_slot-item"
        [slotItem]="item"
        [clickOnItemImg]="true"
        slotSize="huge"
        (clickEmit)="openItemDescriptionDialog(item)">
      </app-item-slot>
    }
  } @else {
    <div>
      {{ 'bottles-dialog.no-items' | translate }}
    </div>
  }
</div>
