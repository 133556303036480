<div
  class="g-flex g-flex--align-center g-flex--justify-end"
  [class.g-flex--reverse]="isInputToRight"
  (clickOutside)="toggleVisibility(false)">
  @if (isContentOpen || isAlwaysOpen) {
    <input
      #searchInput
      class="input app-input g-flex__item app-btn_font-size"
      [class.app-input--huge]="size === 'huge'"
      [class.app-input--nickname]="size === 'large'"
      [class.large]="size === 'large'"
      [formControl]="control" />
  }

  <button
    class="search-btn app-btn-square-search app-btn-square-search--small"
    [class.pressed]="isContentOpen"
    (click)="toggleInput()"></button>
</div>
