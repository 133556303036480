import { Injectable } from '@angular/core';
import { HeaderStateModel } from '@services/app-state-service/models/header-state.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private zoom = 1;
  private headerState = new BehaviorSubject<HeaderStateModel>(new HeaderStateModel());

  setHeaderState(newState: HeaderStateModel = {}): void {
    this.headerState.next({
      ...new HeaderStateModel(),
      ...newState,
    });
  }

  getHeaderStateObservable(): Observable<HeaderStateModel> {
    return this.headerState.asObservable();
  }

  setZoom(zoom: number) {
    this.zoom = zoom;
  }

  getZoom() {
    return this.zoom;
  }
}
