import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ItemMetaEntity, StatsMetaEntity } from '@generated/gql';
import { getActualMetaUri } from '@helpers/data-helper';
import { AttributesMetaModel } from '@models/attributes-meta.model';
import { AttributesModel } from '@models/attributes.model';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { HeroStatItemComponent } from '@shared/components/hero-stat-item/hero-stat-item.component';
import { FilterNullPipe } from '@shared/components/pipes/filter-null/filter-null.pipe';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { SkillAreaComponent } from '@shared/components/skill-area/skill-area.component';
import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTES,
  MAGIC_ATTACK_ICON,
  MAGIC_ATTACK_NAME,
} from '@shared/constants/attributes.constant';
import { ITEM_DESCRIPTIONS } from '@shared/constants/descriptions/item-descriptions.constant';
import { getItemNameBySymbol } from '@shared/constants/game.constant';
import {
  getBackgroundColorName,
  getBackgroundColorNameFromMeta,
  getTextColorNameMeta,
  ITEM_CLASS,
  ITEM_SLOT_BACKGROUND,
  ITEM_TYPE,
  ITEM_TYPE_ICONS,
  ITEM_TYPE_NAMES,
} from '@shared/constants/items.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { ItemSlotBgName } from '@shared/types/item-slot-bg-name.type';
import { ItemSlotSize } from '@shared/types/item-slot-size.type';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-item-meta-description',
  standalone: true,
  templateUrl: './item-meta-description.component.html',
  styleUrls: ['./item-meta-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column g-scroll-y',
  },
  imports: [
    NgClass,
    HeroStatItemComponent,
    SkillAreaComponent,
    ScratchComponent,
    TranslateModule,
    FilterNullPipe,
    BalanceComponent,
  ],
})
export class ItemMetaDescriptionComponent implements OnInit {
  @Input() item: ItemMetaEntity;
  @Input() isShowClose: boolean = true;
  @Input() isShowMarketButton: boolean;

  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  imageUrl = '';
  name = '';
  description = '';
  headerBackground = '';
  itemsTypeName: string | undefined = '';
  itemsTypeIcon: string | undefined = '';
  lastPrices: number[] = [];
  avgPrice = 0;
  consumableStats: StatsMetaEntity = { id: '' };
  consumableAttributes?: AttributesModel;
  backgroundClass? = '';
  slotSize: ItemSlotSize = 'huge';
  itemMetaAttributes: AttributesMetaModel;
  skillBuffCasterAttributes?: AttributesMetaModel;
  skillBuffTargetAttributes?: AttributesMetaModel;
  skillManaConsumption: number = 0;
  isMagicAttack: boolean = false;

  protected readonly getActualMetaUri = getActualMetaUri;
  protected readonly ATTRIBUTE_TO_ICON = ATTRIBUTE_TO_ICON;
  protected readonly ATTRIBUTES = ATTRIBUTES;
  protected readonly ATTRIBUTE_TO_DESCRIPTION = ATTRIBUTE_TO_DESCRIPTION;
  protected readonly MAGIC_ATTACK_ICON = MAGIC_ATTACK_ICON;
  protected readonly MAGIC_ATTACK_NAME = MAGIC_ATTACK_NAME;

  constructor(
    private subgraphService: SubgraphService,
    private destroy$: DestroyService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.item) {
      return;
    }
    this.name = getItemNameBySymbol(this.item.name);
    this.description =
      (this.item.symbol && ITEM_DESCRIPTIONS.get(this.item.symbol.replace('SACRA_', '').replace('_ITEM', ''))) ??
      'Nobody knows what is it';

    this.imageUrl = getActualMetaUri(this.item);
    this.backgroundClass = this.getBackgroundClass();

    this.lastPrices =
      this.item.pawnshopItemStat.length > 0
        ? this.item.pawnshopItemStat[0].prices
            .map(i => +i)
            .reverse()
            .slice(0, 3)
        : [];
    if (this.lastPrices.length > 0) {
      this.avgPrice = Math.ceil(this.lastPrices.reduce((a, b) => a + b, 0) / this.lastPrices.length);
    }

    this.itemMetaAttributes = new AttributesMetaModel(
      (this.item.params?.commonAttributes?.ids || []).slice(),
      (this.item.params?.commonAttributes?.mins || []).slice(),
      (this.item.params?.commonAttributes?.maxs || []).slice(),
      (this.item.params?.commonAttributes?.chances || []).slice(),
      this.item.params?.itemMeta?.minRandomAttributes || 0,
      this.item.params?.itemMeta?.maxRandomAttributes || 0,
    );
    if (this.item.params) {
      if (this.item.params.consumableAttributes?.ids.length > 0) {
        this.consumableAttributes = AttributesModel.fromMeta(
          this.item.params.consumableAttributes.ids,
          this.item.params.consumableAttributes.values,
        );
      }
      if (this.item.params.casterAttributes?.ids.length > 0) {
        this.skillBuffCasterAttributes = new AttributesMetaModel(
          this.item.params.casterAttributes.ids.slice(),
          this.item.params.casterAttributes.mins.slice(),
          this.item.params.casterAttributes.maxs.slice(),
          this.item.params.casterAttributes.chances.slice(),
        );
      }
      if (this.item.params.targetAttributes?.ids.length > 0) {
        this.skillBuffTargetAttributes = new AttributesMetaModel(
          this.item.params.targetAttributes.ids.slice(),
          this.item.params.targetAttributes.mins.slice(),
          this.item.params.targetAttributes.maxs.slice(),
          this.item.params.targetAttributes.chances.slice(),
        );
      }

      if (this.item.params.genAttackInfo?.aType > 0) {
        this.isMagicAttack = true;
      }
    }

    this.skillManaConsumption = this.item.manaCost ?? 0;
    this.consumableStats = this.item.params?.consumableStats ?? { id: '' };
    this.itemsTypeName = ITEM_TYPE_NAMES.get(this.item.itemType);
    this.itemsTypeIcon = ITEM_TYPE_ICONS.get(this.item.itemType);
    this.headerBackground = `url(assets/images/ui/paint/for-item-tittles/${getBackgroundColorName(null)}.png)`;

    this.getFloorPrice();
  }

  getFloorPrice() {
    this.avgPrice = 0;
    this.subgraphService
      .pawnshopPositionFloor$(this.item.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(floor => {
        this.avgPrice = +floor;
        this.changeDetectorRef.detectChanges();
      });
  }

  titleColor(): string {
    return getTextColorNameMeta();
  }

  onClose(): void {
    this.closeDialog.emit(true);
  }

  onBuyOnMarket() {
    this.closeDialog.emit();

    this.router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.PAWNSHOP], {
      queryParams: {
        search: getItemNameBySymbol(this.item.name),
      },
    });
  }

  private getBackgroundClass(): string | undefined {
    if (this.item.itemType === ITEM_TYPE.SKILL) {
      return `app-item-slots-${this.slotSize}_aquamarine-skill${this.item.isAttackItem ? '-attack' : ''}`;
    }

    if (this.item.itemType === ITEM_CLASS.ATTACK) {
      return `app-item-slots-${this.slotSize}_aquamarine`;
    }

    const itemSlotBgName: ItemSlotBgName = getBackgroundColorNameFromMeta(this.item);

    if (this.item.itemType === ITEM_TYPE.SKILL) {
      return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}-skill${
        this.item.isAttackItem ? '-attack' : ''
      }`;
    }

    return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}`;
  }
}
