<div class="container g-flex g-flex--align-center g-flex--justify-center">
  @for (item of items; track item; let isLast = $last) {
    <div
      class="item g-flex-column g-flex--align-center"
      [class.item--style2]="isUseStyle2"
      [class.pointer]="!isDisabled && isChangeManually"
      [class.app-btn-small_font-size]="size === 'small'"
      [class.app-btn-medium_font-size]="size === 'medium'"
      [class.app-btn-medium-small_font-size]="size === 'medium-small'"
      [class.app-btn_font-size]="size === 'default'"
      [class.app-btn-huge_font-size]="size === 'big'"
      [class.item--disabled]="isDisabled"
      [class.item--selected]="item.id === selectedItemId">
      <div class="g-flex g-flex--align-center">
        <div
          class="g-flex-column g-flex--align-center"
          (click)="onSelect(item)">
          @if (item.icon) {
            <img
              [class.hidden]="!(!item.iconDisabled || (item.iconDisabled && item.id === selectedItemId))"
              [src]="item.icon"
              alt="" />

            <img
              [class.hidden]="!(item.iconDisabled && item.id !== selectedItemId)"
              [src]="item.iconDisabled"
              alt="" />
          }

          <div
            class="label text-shadow"
            [class.label--wrap]="isWrapLabel">
            {{ item.label | translate }}
          </div>

          @if (selectedType === 'big') {
            <img
              class="item__divider"
              [class.item__divider--big]="size === 'big'"
              [class.item__divider--wrap]="isWrapLabel"
              [class.item__divider--visible]="item.id === selectedItemId"
              src="assets/images/ui/dividers/borders_v/border_v_silver_up.png"
              alt="" />
          }

          @if (selectedType === 'small') {
            <img
              class="item__divider item__divider-small"
              [class.item__divider--visible]="item.id === selectedItemId"
              src="assets/images/ui/dividers/bolt/bolt.png"
              alt="" />
          }
        </div>

        @if (!isLast) {
          @switch (dividerType) {
            @case ('big') {
              <img
                class="divider"
                src="assets/images/ui/dividers/bolt/group-of-bolts.png"
                alt="" />
            }
            @case ('small') {
              <img
                class="divider"
                src="assets/images/ui/dividers/bolt/bolt.png"
                alt="" />
            }
          }
        }
      </div>
    </div>
  }
</div>

@if (isShowDivider) {
  <app-scratch
    class="scratch"
    backgroundType="small">
  </app-scratch>
}
