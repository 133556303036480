import { Injectable } from '@angular/core';

const STORAGE_KEY_SKILLS = 'SKILLS';
const STORAGE_KEY_ATTACK = 'ATTACK_ITEMS';
export const STORAGE_CUSTOM_SUBGRAPH_URL = 'SACRA_CUSTOM_SUBGRAPH_URL';
export const STORAGE_CUSTOM_RPC_URL = 'SACRA_CUSTOM_RPC_URL';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public set(name: string, value: string | number | boolean) {
    window.localStorage.setItem(name, `${value}`);
  }

  public get(name: string): string {
    return window.localStorage.getItem(name) || '';
  }

  public remove(name: string): string {
    return (window as any).localStorage.removeItem(name);
  }

  public getFightItems(hero: string, heroId: number) {
    return {
      attackItem: this.get(hero + '_' + heroId + '_' + STORAGE_KEY_ATTACK),
      skills: this.get(hero + '_' + heroId + '_' + STORAGE_KEY_SKILLS),
    };
  }

  public setAttackItem(hero: string, heroId: number, info: string) {
    this.set(hero + '_' + heroId + '_' + STORAGE_KEY_ATTACK, info);
  }

  public setBuffItems(hero: string, heroId: number, info: string) {
    this.set(hero + '_' + heroId + '_' + STORAGE_KEY_SKILLS, info);
  }

  public removeFightItems(hero: string, heroId: number) {
    this.remove(hero + '_' + heroId + '_' + STORAGE_KEY_SKILLS);
    this.remove(hero + '_' + heroId + '_' + STORAGE_KEY_ATTACK);
  }

  public clearWalletConnect() {
    const toRemove: string[] = [];
    for (let i = 0; i < window.localStorage.length; ++i) {
      const key = window.localStorage.key(i);
      if (key && key.startsWith('wc@2')) {
        toRemove.push(key);
      }
    }
    for (const key of toRemove) {
      this.remove(key);
    }
  }

  public clearOauth() {
    this.remove('corekit_store');
  }
}
