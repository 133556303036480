import { Formatter } from '@helpers/formatter';
import { TokenMetadataModel } from '@models/token-metadata.model';
import { formatUnits } from 'ethers';

export class FeeTokenDataModel {
  readonly meta: TokenMetadataModel;
  readonly amount: bigint;

  allowance = 0n;
  accountBalance = 0n;

  constructor(meta: TokenMetadataModel, amount: bigint) {
    this.meta = meta;
    this.amount = amount;
  }

  getAmountN() {
    return formatUnits(this.amount, this.meta.decimals);
  }

  getAccountBalanceN() {
    return formatUnits(this.accountBalance, this.meta.decimals);
  }

  getAccountBalanceFormatted() {
    return Formatter.formatCurrency(+formatUnits(this.accountBalance, this.meta.decimals));
  }
}
