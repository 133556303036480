@if (innerHero) {
  <div class="inventory__equipped_body app-bg-level-up-front g-flex-column g-flex--align-stretch">
    <div class="items__top g-flex g-flex--align-center g-flex--space-between">
      <div class="lvl app-lvl-default g-flex-column g-flex--align-center g-flex--justify-center">
        <div class="lvl__value">
          {{ innerHero.stats.level }}
        </div>
      </div>

      <app-item-slot
        class="item__head g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.HEAD)"
        [slotItem]="equippedItemOn(ITEM_SLOT.HEAD)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.HEAD)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.HEAD)">
      </app-item-slot>

      <div class="btn-remove-container g-flex g-flex--justify-center">
        @if (innerHero.items.length) {
          <button
            class="btn-remove app-btn-blue-1"
            appButtonClick
            [isNeedConfirm]="true"
            [infoDesc]="'inventory.disabled.desc' | translate"
            [isDisabled]="!!innerHero.dungeon || isStaked"
            (hostClick)="takeOffAll()">
            {{ 'inventory.buttons.get-naked' | translate }}
          </button>
        }
      </div>
    </div>

    <div class="g-flex g-flex--align-center g-flex--space-between">
      <app-item-slot
        class="item__amulet g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.AMULET)"
        [slotItem]="equippedItemOn(ITEM_SLOT.AMULET)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.AMULET)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.AMULET)">
      </app-item-slot>

      <app-item-slot
        class="item__body g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.BODY)"
        [slotItem]="equippedItemOn(ITEM_SLOT.BODY)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.BODY)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.BODY)">
      </app-item-slot>

      <app-item-slot
        class="item__gloves g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.GLOVES)"
        [slotItem]="equippedItemOn(ITEM_SLOT.GLOVES)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.GLOVES)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.GLOVES)">
      </app-item-slot>
    </div>

    <div class="g-flex g-flex--align-center g-flex--space-between">
      <app-item-slot
        class="item__right-ring g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.RIGHT_RING)"
        [slotItem]="equippedItemOn(ITEM_SLOT.RIGHT_RING)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.RIGHT_RING)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.RIGHT_RING)">
      </app-item-slot>

      <app-item-slot
        class="item__belt g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.BELT)"
        [slotItem]="equippedItemOn(ITEM_SLOT.BELT)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.BELT)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.BELT)">
      </app-item-slot>

      <app-item-slot
        class="item__left-ring g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.LEFT_RING)"
        [slotItem]="equippedItemOn(ITEM_SLOT.LEFT_RING)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.LEFT_RING)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.LEFT_RING)">
      </app-item-slot>
    </div>

    <div class="g-flex g-flex--align-center g-flex--space-between">
      <app-item-slot
        class="item__right-hand g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.RIGHT_HAND)"
        [slotItem]="equippedItemOn(ITEM_SLOT.RIGHT_HAND)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.RIGHT_HAND)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.RIGHT_HAND)">
      </app-item-slot>

      <app-item-slot
        class="item__boots g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.BOOTS)"
        [slotItem]="equippedItemOn(ITEM_SLOT.BOOTS)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.BOOTS)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.BOOTS)">
      </app-item-slot>

      <app-item-slot
        class="item__left-hand g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotIcon]="getSlotIcon(ITEM_SLOT.LEFT_HAND)"
        [slotItem]="equippedItemOn(ITEM_SLOT.LEFT_HAND)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.LEFT_HAND)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.LEFT_HAND)">
      </app-item-slot>
    </div>

    <div class="inventory__equipped_divider app-divider-scratches-border-v-medium-down g-flex g-flex--space-between">
      <div class="app-divider-bolt"></div>

      <div class="app-divider-bolt"></div>
    </div>

    <div class="g-flex g-flex--align-center g-flex--space-between">
      <app-item-slot
        class="item__skill1 g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotItem]="equippedItemOn(ITEM_SLOT.SKILL_1)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.SKILL_1)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.SKILL_1)">
      </app-item-slot>

      <app-item-slot
        class="item__skill2 g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotItem]="equippedItemOn(ITEM_SLOT.SKILL_2)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.SKILL_2)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.SKILL_2)">
      </app-item-slot>

      <app-item-slot
        class="item__skill3 g-flex g-flex--align-center g-flex--justify-center"
        slotSize="huge"
        [slotItem]="equippedItemOn(ITEM_SLOT.SKILL_3)"
        [isDisabled]="isSlotDisabled(ITEM_SLOT.SKILL_3)"
        (clickEmit)="onItemSlotClick($event, ITEM_SLOT.SKILL_3)">
      </app-item-slot>
    </div>
  </div>
}
