import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { TranslateModule } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { HeroDataService } from '@services/data/hero-data.service';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { MetaMaskService } from '@services/meta-mask.service';
import { ProviderService } from '@services/provider.service';
import { Web3Service } from '@services/web3.service';
import { AchievementsComponent } from '@shared/components/achievements/achievements.component';
import { HeroAllStatsComponent } from '@shared/components/hero-all-stats/hero-all-stats.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemSlotComponent } from '@shared/components/item-slot/item-slot.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { HERO_ADR, HERO_ID, MAIN_ROUTES } from '@shared/constants/routes.constant';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-hero-stat',
  standalone: true,
  templateUrl: './hero-stat.component.html',
  styleUrls: ['./hero-stat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'g-flex-column',
  },
  imports: [AchievementsComponent, ItemSlotComponent, LoadingSmallComponent, HeroAllStatsComponent, TranslateModule],
})
export class HeroStatComponent implements OnInit {
  @Input() heroAdr: string;
  @Input() heroId: number;

  account: string;
  chainId: number;
  hero: HeroEntity | null;
  heroLoading = true;
  MAIN_ROUTES = MAIN_ROUTES;
  heroItems: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private destroy$: DestroyService,
    private heroDataService: HeroDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private metaMaskService: MetaMaskService,
    private web3: Web3Service,
    private providerService: ProviderService,
    private appStateService: AppStateService,
    private subgraphService: SubgraphService,
    private logger: NGXLogger,
    private dialog: Dialog,
  ) {}

  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.has(HERO_ADR)) {
      this.heroAdr = this.activatedRoute.snapshot.paramMap.get(HERO_ADR) ?? '';
    }

    if (this.activatedRoute.snapshot.paramMap.has(HERO_ID)) {
      this.heroId = Number(this.activatedRoute.snapshot.paramMap.get(HERO_ID) ?? 0);
    }

    // this.appStateService.setHeaderState({
    //   backUrl: [MAIN_ROUTES.EMPTY, MAIN_ROUTES.HERO, this.heroAdr, this.heroId.toString()],
    // });

    this.loadHero();
  }

  loadHero() {
    this.subgraphService
      .hero$(this.heroAdr, this.heroId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hero => {
        this.logger.trace('Hero model', hero);
        this.heroLoading = false;
        if (hero) {
          this.hero = hero;
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  openItemDescriptionDialog(item: ItemEntity): void {
    this.dialog.open(ItemDescriptionDialogComponent, {
      panelClass: 'app-overlay-pane-full-width',
      data: { item, hero: this.hero, isShowButtons: false },
    });
  }
}
