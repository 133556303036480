import { ITutorialConfig } from '@shared/components/modal-tutorial/tutorial-config.interface';

export const STORAGE_KEY_TUTORIAL = 'TETU_GAME_TUTORIAL';

export const TUTORIALS: { [key: string]: ITutorialConfig[] } = {
  fight: [
    {
      position: 'cb',
      text:
        '<b>Battle!</b>' +
        '<br>You meet an enemy. The battle has rounds, witch are displayed here. You have 100 rounds to win.',
      pointer: {
        elementsId: ['div-round'],
        direction: 'rb',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Call reinforcement</b>' +
        '<br>Do not shy to call for help! Summoned hero will increase your chances to win in exchange for part of your loot.',
      pointer: {
        elementsId: ['btn-reinforcement', 'div-reinforcement-called'],
        direction: 'lt',
      },
    },
    {
      position: 'cb',
      text:
        '<b>Check opponent attributes</b>' +
        "<br>Different opponents have different strengths and weaknesses. Check and analyze their attributes before the battle, or you're gonna have some unpleasant surprises.",
      pointer: {
        elementsId: ['div-opponent'],
        direction: 'rb',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Attack item</b>' +
        '<br>You can choose different attack items(such as magic skills or wands) if you have them in your inventory.',
      pointer: {
        elementsId: ['attack-item', 'no-attack-item'],
        direction: 'rt',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Skill items</b>' +
        '<br>You can equip up to 3 skills with different mechanics. Their durability will be decreased only if you use them at least once per battle.' +
        '<br>Make sure you have enough mana, use it wisly.',
      pointer: {
        elementsId: ['skill-items'],
        direction: 'rt',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Battle history</b>' + '<br>You can see details about the battle. Click on this button to see the history.',
      pointer: {
        elementsId: ['btn-history'],
        direction: 'lt',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Key stats</b>' +
        '<br>Compare your stats with the opponent.' +
        '<br>If you have higher Attack Rating than your opponent Defence Rating, you will hit him successfully with higher chance.' +
        '<br>Magic attack always have 100% chance to hit but can be reduced by elemental resistance.',
      pointer: {
        elementsId: ['div-stats'],
        direction: 'rt',
      },
    },
    {
      position: 'ct',
      text: '<b>Attack!</b>' + '<br>When you will be ready, click on the attack button to start the battle. Good luck!',
      pointer: {
        elementsId: ['btn-attack'],
        direction: 'lt',
      },
    },
  ],
  shrine: [
    {
      position: 'ct',
      text: '<b>Shrine!</b><br>In this places you can restore your health and mana. But be careful, it can be dangerous.',
      pointer: {
        elementsId: ['div-shrine'],
        direction: 'rb',
      },
    },
    {
      position: 'ct',
      text: '<b>Accept</b>' + '<br>Click Explore to try your luck to get a buff or heal your hero.',
      pointer: {
        elementsId: ['btn-explore'],
        direction: 'rt',
      },
    },
    {
      position: 'ct',
      text: '<b>Refuse</b>' + '<br>Or click Skip to leave the Shrine.',
      pointer: {
        elementsId: ['btn-skip'],
        direction: 'lt',
      },
    },
  ],
  story: [
    {
      position: 'ct',
      text:
        '<b>Story!</b>' +
        '<br>In your adventures, you will meet a wide variety of characters and circumstances. ' +
        '<br>Make decisions wisly, you can die in some cases.',
      pointer: {
        elementsId: ['story-name'],
        direction: 'rt',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Choose an answer</b>' +
        '<br>You must choose one of answer choices to move on. Your decision will change the outcome of the story.',
      pointer: {
        elementsId: ['btn-answer'],
        direction: 'rt',
      },
    },
  ],
  hero: [
    {
      position: 'cb',
      text:
        '<b>Level</b>' +
        "<br> A hero's level, which increases by accumulating experience points (EXP), boosts attributes such as Strength, Dexterity, Vitality, and Energy." +
        '<br> Higher-level heroes are more challenging to hit in battles.',
      pointer: {
        elementsId: ['div-level'],
        direction: 'rt',
      },
    },
    {
      position: 'cb',
      text: '<b>Experience</b><br>Here you can see your progress to the next level. Even with 100% you will still continue to gain experience.',
      pointer: {
        elementsId: ['experience-bar'],
        direction: 'rb',
      },
    },
    {
      position: 'cb',
      text:
        '<b>Hero status</b>' +
        '<br>This icon indicates the hero’s status. The hero can be in one of the following states: ' +
        '<br> <b>Ready</b> - ready for adventires.' +
        '<br> <b>In adventure</b> - already in an adventure' +
        '<br> <b>In reinforcment</b> - stacked in reinforcement',
      pointer: {
        elementsId: ['img-state'],
        direction: 'rb',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Lifechances</b>' +
        "<br>Lifechances determine the hero's ability to avoid permanent death. When the life chance counter reaches zero, the hero will experience permanent death. In case of permanent death, the hero with all items and achievements ceases to exist.",
      pointer: {
        elementsId: ['life-chances'],
        direction: 'rb',
      },
    },
    {
      position: 'cb',
      text:
        '<b>HP (Hit Points)</b>' +
        '<br>HP represents how many life points the hero has. HP does not regenerate over time. If you lose all your HP, you lose the battle. HP can be restored by drinking the corresponding consumable, exploring health Shrines, getting buffs in quests, and leveling up. Some stories can do HP damage. Ensure that the HP level is always high.',
      pointer: {
        elementsId: ['bar-hp'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text:
        '<b>MP (Magical Points)</b>' +
        '<br>MP is the magical energy required to activate skills during battles and does not regenerate with time. MP can be restored by drinking the corresponding consumable, exploring Shrines, getting buffs in quests, and leveling up.',
      pointer: {
        elementsId: ['bar-mp'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text:
        '<b>Core attributes</b>' +
        '<br>Core attributes are the primary characteristics of a hero that determine their performance in battles. The core attributes are Strength, Dexterity, Vitality, and Energy. You can see details by clicking on them.',
      pointer: {
        elementsId: ['core-stats'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text: 'Click on this button for more details about your hero.',
      pointer: {
        elementsId: ['show-all-stats'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text:
        '<b>Item slots</b>' +
        '<br>Here will be shown the items that your hero has equipped. You can equip items in the inventory.',
      pointer: {
        elementsId: ['item-slots'],
        direction: 'lb',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Go to adventures</b>' +
        '<br>When you will be ready, you can go to adventures. Click on this button to start your journey.',
      pointer: {
        elementsId: ['go-to-map-button', 'continue-button'],
        direction: 'lb',
      },
    },
    {
      position: 'ct',
      text:
        '<b>Reinforcment</b>' +
        '<br>If your hero reached 5th level and has full lifechances, the hero can go to help other heroes in battles and be rewarded for it. Click on the button to send your hero to reinforcement.',
      pointer: {
        elementsId: ['reinforcement-button'],
        direction: 'lt',
      },
    },
  ],
  'modal-item': [
    {
      position: 'cb',
      text:
        'Items rarity represent how good the item randomly generated. The higher the rarity, the better the item.' +
        '<br>Unique items are the best items in the game. In most cases their attributes are constant.',
      pointer: {
        elementsId: ['rarity-name'],
        direction: 'rb',
      },
    },
    {
      position: 'cb',
      text:
        'Augmentation level show how many times you made a successful forge for this item.' +
        '<br>Every forge increases the item attributes on 20% from the previous values.',
      pointer: {
        elementsId: ['augmentation-level'],
        direction: 'rb',
      },
    },
    {
      position: 'cb',
      text: 'Item ID is the item NFT token ID. You can use it to find the item in the blockchain explorer.',
      pointer: {
        elementsId: ['item-id'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text:
        'Gear score is the item power level. ' +
        '<br>Sum of all item scores affect hero score.' +
        '<br> The more points a hero has, the higher the chance to be called in reinforcements.',
      pointer: {
        elementsId: ['gear-score'],
        direction: 'rb',
      },
    },
    {
      position: 'cb',
      text:
        'Item attributes can have positive and negative effects.' +
        '<br> Click on attribute icon to see the description.',
      pointer: {
        elementsId: ['attributes-area'],
        direction: 'rb',
      },
    },
    {
      position: 'cb',
      text:
        'You can use consumable items at any time.' +
        '<br>But only one kind of item can be used at one time until finish any battle.',
      pointer: {
        elementsId: ['btn-use', 'btn-use2'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text: 'Take off equipment are available when you are not in an adventure.',
      pointer: {
        elementsId: ['btn-take-off'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text: 'Repair item are available when the item is broken.',
      pointer: {
        elementsId: ['btn-repair'],
        direction: 'lb',
      },
    },
    {
      position: 'cb',
      text: "You can augment your items to increase it's attributes.",
      pointer: {
        elementsId: ['btn-forge'],
        direction: 'lb',
      },
    },
    {
      position: 'ct',
      text: 'Item durability decrease in every battle. When it reach zero the item will be taken off but will be not destroyed. You can find a broken item in your inventory and use it to repair or augment the same items.',
      pointer: {
        elementsId: ['durability'],
        direction: 'rb',
      },
    },
    {
      position: 'ct',
      text:
        'Item level affect how quickly will be the item broken in a battle.' +
        '<br>You can get items with higher levels on higher bioms.',
      pointer: {
        elementsId: ['item-level'],
        direction: 'rb',
      },
    },
    {
      position: 'ct',
      text: 'Item requirements show which parameter values you need to equip the item. Only base attributes are considered (without buffs/other items bonuses).',
      pointer: {
        elementsId: ['requirements'],
        direction: 'rb',
      },
    },
  ],
  // 'inventory-all': [],
  // 'inventory-equipped': [],
};
