import { Injectable } from '@angular/core';
import { CHAIN_IDS } from '@shared/constants/chain-ids.constant';
import { NGXLogger } from 'ngx-logger';
import { catchError, from, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class MetaMaskService {
  constructor(private logger: NGXLogger) {}

  changeNetwork$(chainId: string) {
    return this.request$('wallet_switchEthereumChain', [{ chainId }]);
  }

  getEncryptionPublicKey$(address: string) {
    return this.request$('eth_getEncryptionPublicKey', [address]);
  }

  decrypt$(data: string, address: string) {
    return this.request$('eth_decrypt', [data, address]);
  }

  addEthereumChain$(chainId: string): Observable<{ code: number }> {
    const rpcUrls: string[] = [];
    let chainName: string | undefined;
    let nativeCurrency: {} | undefined;
    let blockExplorerUrls: string[] = [];
    if (chainId === '0x' + CHAIN_IDS.SKALE_TEST.toString(16)) {
      rpcUrls.push('https://staging-v3.skalenodes.com/v1/staging-fast-active-bellatrix/');
      chainName = 'SKALE test';
      blockExplorerUrls.push('https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com/');
      nativeCurrency = {
        name: 'sFUEL',
        symbol: 'sFUEL',
        decimals: 18,
      };
    }
    if (chainId === '0x' + CHAIN_IDS.MUMBAI.toString(16)) {
      rpcUrls.push('https://rpc-mumbai.maticvigil.com/');
      chainName = 'Mumbai';
      blockExplorerUrls.push('https://mumbai.polygonscan.com/');
      nativeCurrency = {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      };
    }
    if (chainId === '0x' + CHAIN_IDS.SEPOLIA.toString(16)) {
      rpcUrls.push('https://mainnet.infura.io/v3/');
      chainName = 'Sepolia';
      blockExplorerUrls.push('https://sepolia.etherscan.io/');
      nativeCurrency = {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      };
    }
    if (chainId === '0x' + CHAIN_IDS.OP_SEPOLIA.toString(16)) {
      rpcUrls.push('https://sepolia.optimism.io/');
      chainName = 'OP Sepolia';
      blockExplorerUrls.push('https://sepolia-optimism.etherscan.io/');
      nativeCurrency = {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      };
    }
    if (chainId === '0x' + CHAIN_IDS.IMMT.toString(16)) {
      rpcUrls.push('https://rpc.testnet.immutable.com/');
      chainName = 'Immutable Testnet';
      blockExplorerUrls.push('https://explorer.testnet.immutable.com/');
      nativeCurrency = {
        name: 'Test-IMX',
        symbol: 'Test-IMX',
        decimals: 18,
      };
    }

    if (chainId === '0x' + CHAIN_IDS.SONIC.toString(16)) {
      rpcUrls.push('https://rpc.sonic.fantom.network/');
      chainName = 'Sonic';
      blockExplorerUrls.push('https://sonicscan.io');
      nativeCurrency = {
        name: 'FTM',
        symbol: 'FTM',
        decimals: 18,
      };
    }

    if (chainId === '0x' + CHAIN_IDS.FANTOM.toString(16)) {
      rpcUrls.push('https://rpc.ftm.tools/');
      chainName = 'Fantom Opera';
      blockExplorerUrls.push('https://ftmscan.com');
      nativeCurrency = {
        name: 'FTM',
        symbol: 'FTM',
        decimals: 18,
      };
    }

    if (chainId === '0x' + CHAIN_IDS.REAL.toString(16)) {
      rpcUrls.push('https://tangible-real.gateway.tenderly.co/');
      chainName = 're.al';
      blockExplorerUrls.push('https://explorer.re.al');
      nativeCurrency = {
        name: 'reETH',
        symbol: 'reETH',
        decimals: 18,
      };
    }

    if (chainId === '0x' + CHAIN_IDS.NEBULA_TESTNET.toString(16)) {
      rpcUrls.push('https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet/');
      chainName = 'NebulaT';
      blockExplorerUrls.push('https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet');
      nativeCurrency = {
        name: 'sFUEL',
        symbol: 'sFUEL',
        decimals: 18,
      };
    }

    const args = {
      chainId,
      rpcUrls,
      chainName,
      nativeCurrency,
      blockExplorerUrls,
    };

    return this.request$('wallet_addEthereumChain', [args]);
  }

  request$(method: string, params: any[] = [], id: string = uuidv4()): Observable<any> {
    return from(
      window.ethereum.request({
        method,
        params,
        id,
      }),
    ).pipe(
      catchError(error => {
        return of(error);
      }),
    );
  }
}
