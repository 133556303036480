import { Injectable } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { StorageService } from '@services/storage.service';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import {
  STORAGE_KEY_EFFECTS_VOLUME,
  STORAGE_KEY_MUSIC_VOLUME,
  STORAGE_KEY_PLAY_EFFECTS,
  STORAGE_KEY_PLAY_MUSIC,
} from '@shared/constants/settings.constants';
import { ACTION_KIND, SOUNDS_MONSTER } from '@shared/constants/sounds/sounds-monster';
import { SOUNDS, SOUNDS_CONFIG } from '@shared/constants/sounds/sounds.constant';
import { ISound, SOUND_TYPES } from '@shared/types/sound-types';
import { NGXLogger } from 'ngx-logger';
import { from, takeUntil } from 'rxjs';

interface SoundsContextInterface {
  audioContext: AudioContext;
  source: AudioBufferSourceNode;
  gainNode: GainNode;
  audioBuffer: ArrayBuffer;
  key: string;
  soundObj: ISound;
}

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  isPlayMusic = true;
  isPlayEffects = true;
  bgSoundIndex = -1;

  private playedBgMusic: ISound;
  private musicVolume = 0.7;
  private effectsVolume = 1;
  private activeAudioContext = new Map<string, SoundsContextInterface>();

  constructor(
    private storageService: StorageService,
    private logger: NGXLogger,
  ) {
    if (this.storageService.get(STORAGE_KEY_MUSIC_VOLUME)) {
      this.musicVolume = +this.storageService.get(STORAGE_KEY_MUSIC_VOLUME) / 100;
    }

    if (this.storageService.get(STORAGE_KEY_EFFECTS_VOLUME)) {
      this.effectsVolume = +this.storageService.get(STORAGE_KEY_EFFECTS_VOLUME) / 100;
    }

    if (this.storageService.get(STORAGE_KEY_PLAY_MUSIC)) {
      this.isPlayMusic = this.storageService.get(STORAGE_KEY_PLAY_MUSIC) === CHECKBOX_STATE.CHECKED;
    }

    if (this.storageService.get(STORAGE_KEY_PLAY_EFFECTS)) {
      this.isPlayEffects = this.storageService.get(STORAGE_KEY_PLAY_EFFECTS) === CHECKBOX_STATE.CHECKED;
    }
  }

  async play({ key, isBg, isMonster }: { key: string; isBg?: boolean; isMonster?: boolean }) {
    // make sure that multiple bg sounds are not played at the same time
    const { soundPath, soundObj } = this.getSoundPath({ key, isBg, isMonster });

    if (!soundPath || !soundObj) {
      return;
    }

    if (soundObj.type === SOUND_TYPES.MUSIC) {
      await this.stopAll(false);
    }

    this.logger.trace('Play', key, isBg, isMonster, soundPath);

    let audioContext: AudioContext;

    if (this.activeAudioContext.has(soundObj.id)) {
      const { audioContext, audioBuffer } = this.activeAudioContext.get(soundObj.id) as SoundsContextInterface;

      this.playAudioContext({ soundPath, audioContext, audioBuffer, key, soundObj });
    } else {
      audioContext = new AudioContext();

      const response = await fetch(soundPath);

      if (response.status === 200) {
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        this.playAudioContext({ soundPath, audioContext, audioBuffer, key, soundObj });
      } else {
        this.logger.error('Sound not found! Check path', key, soundPath);
      }
    }
  }

  // stop({ key, isBg }: { key: string; isBg?: boolean }) {
  //   const { soundObj } = this.getSoundPath({ key, isBg });
  //   if (!soundObj) {
  //     return;
  //   }
  //   if (this.activeAudioContext.has(soundObj.id)) {
  //     this.activeAudioContext.get(soundObj.id)?.source.stop();
  //   }
  // }

  setMusicVolume(volume: number, isSave = true) {
    this.musicVolume = volume;

    if (isSave) {
      this.storageService.set(STORAGE_KEY_MUSIC_VOLUME, this.musicVolume);
    }

    if (this.activeAudioContext && this.activeAudioContext.size) {
      Array.from(this.activeAudioContext.values())
        .filter(({ soundObj }) => soundObj.type === SOUND_TYPES.MUSIC)
        .forEach(({ audioContext, gainNode }) => {
          gainNode.gain.setValueAtTime(volume, audioContext.currentTime);
        });
    }
  }

  setEffectsVolume(volume: number, isSave = true) {
    this.effectsVolume = volume;

    if (isSave) {
      this.storageService.set(STORAGE_KEY_EFFECTS_VOLUME, this.musicVolume);
    }

    Array.from(this.activeAudioContext.values())
      .filter(({ soundObj }) => soundObj.type === SOUND_TYPES.EFFECT)
      .forEach(({ audioContext, gainNode }) => {
        gainNode.gain.setValueAtTime(volume, audioContext.currentTime);
      });
  }

  async resumeAll() {
    for (const { soundObj, audioContext } of Array.from(this.activeAudioContext.values())) {
      if (soundObj.type === SOUND_TYPES.MUSIC && audioContext.state === 'suspended') {
        await audioContext.resume();
      }
    }
  }

  async stopAll(suspend = false) {
    const keyForRemove: string[] = [];
    for (const { soundObj, audioContext } of Array.from(this.activeAudioContext.values())) {
      // no need to stop little sounds
      if (soundObj.type !== SOUND_TYPES.MUSIC) {
        continue;
      }
      // closed audio context can't be operated
      if (audioContext.state === 'closed') {
        continue;
      }
      if (suspend) {
        await audioContext.suspend();
      } else {
        keyForRemove.push(soundObj.id);
        await audioContext.close();
      }
    }
    keyForRemove.forEach(key => this.activeAudioContext.delete(key));
  }

  stopAllAndPlayMainBg(destroy$: DestroyService, playMainBg = true) {
    from(this.stopAll())
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        if (playMainBg) {
          this.playMainBgSound();
        }
      });
  }

  getPlayed(): ISound {
    return this.playedBgMusic;
  }

  /// @dev Use it with stopAllAndPlayMainBg for avoid race conditions
  async playMainBgSound() {
    // we will play main theme only if no others music is playing
    if (
      Array.from(this.activeAudioContext.values()).filter(({ soundObj }) => soundObj.type === SOUND_TYPES.MUSIC)
        .length === 0
    ) {
      await this.play({ key: 'main', isBg: true });
    }
  }

  private playAudioContext({
    audioContext,
    audioBuffer,
    key,
    soundObj,
  }: {
    soundPath: string;
    audioContext: AudioContext;
    audioBuffer;
    key: string;
    soundObj: ISound;
  }) {
    const gainNode = audioContext.createGain();
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;

    if (soundObj.type === SOUND_TYPES.MUSIC) {
      source.loop = true;
    }

    source.connect(gainNode);

    gainNode.connect(audioContext.destination);

    let volume = 1;

    if (soundObj.type === SOUND_TYPES.MUSIC) {
      volume = this.isPlayMusic ? this.musicVolume : 0;
    }

    if (soundObj.type === SOUND_TYPES.EFFECT) {
      volume = this.isPlayEffects ? this.effectsVolume : 0;
    }

    gainNode.gain.setValueAtTime(volume, audioContext.currentTime);

    source.start();

    if (soundObj.type === SOUND_TYPES.MUSIC) {
      this.playedBgMusic = soundObj;
    }

    if (soundObj.id) {
      this.activeAudioContext.set(soundObj.id, { audioContext, source, gainNode, audioBuffer, key, soundObj });
    }
  }

  private getSoundPath({ key, isBg, isMonster }): { soundPath?: string; soundObj?: ISound } {
    let s: ISound[] = [];
    if (isMonster) {
      const soundType = key.split('_')[0];
      const monsterId = key.split('_')[1];
      const node = SOUNDS_MONSTER.get(Number(monsterId));
      if (!node) {
        console.error('Monster sound not found', key, monsterId, soundType);
        return {
          soundPath: undefined,
          soundObj: undefined,
        };
      }
      if (soundType === ACTION_KIND.PHRASES) {
        if (!node.phrases) {
          console.error('Monster phrase not found', key);
          return {
            soundPath: undefined,
            soundObj: undefined,
          };
        }
        s = node.phrases;
      }
      if (soundType === ACTION_KIND.ATTACK) {
        s = node.attack;
      }
      if (soundType === ACTION_KIND.DAMAGE) {
        s = node.damage;
      }
      if (soundType === ACTION_KIND.MISS) {
        s = node.miss;
      }
    } else {
      s = SOUNDS[key];
    }

    if (!s || s.length === 0) {
      return {
        soundPath: undefined,
        soundObj: undefined,
      };
    }
    let index = s.length === 1 ? 0 : Math.floor(Math.random() * s.length);

    if (isBg) {
      if (this.bgSoundIndex < 0) {
        this.bgSoundIndex = index;
      } else {
        index = this.bgSoundIndex;
      }
    }

    const soundObj = s[index];
    const soundPath = `${SOUNDS_CONFIG.PATH}${soundObj.file}`;

    return { soundPath, soundObj };
  }
}
