import { TransactionDataModel } from '@models/transaction-data.model';
import { MediatorAction } from '@shared/types/action.interface';

export namespace LoadingActions {
  export class Toggle implements MediatorAction {
    constructor(
      public isShow: boolean,
      public transactionData: TransactionDataModel,
    ) {}
  }

  export class PageLoaded implements MediatorAction {
    constructor(public page: string) {}
  }

  export class ChainTransaction implements MediatorAction {
    constructor(public isRunning: boolean) {}
  }
}
