export class StatsModel {
  readonly level: number;
  readonly experience: number;
  readonly life: number;
  readonly mana: number;
  readonly lifeChances: number;

  constructor(level: number, experience: number, life: number, mana: number, lifeChances: number) {
    this.level = level;
    this.experience = experience;
    this.life = life;
    this.mana = mana;
    this.lifeChances = lifeChances;
  }
}

export type StatsType = {
  readonly level: number;
  readonly experience: number;
  readonly life: number;
  readonly mana: number;
  readonly lifeChances: number;
};
