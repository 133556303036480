import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-check-password',
  standalone: true,
  templateUrl: './check-password.component.html',
  styleUrls: ['./check-password.component.scss'],
})
export class CheckPasswordComponent {
  @Input() passwordControl: FormControl;
  @Input() newPasswordControl: FormControl | null;
}
