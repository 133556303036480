import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, HostBinding, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CHECKBOX_STATE } from '../../constants/checkbox-states.constant';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  host: {
    class: 'g-flex g-flex--align-center pointer',
    role: 'checkbox',
    tabindex: '0',
  },
  imports: [NgClass],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string | null = null;
  @Input() isRadioItem: boolean = false;
  @Input() customOnChange: boolean = false;
  @Input() mode: 'checkbox' | 'toggle' = 'checkbox';
  @Input() fontSizeLabelClass: string = 'app-btn-small_font-size';

  @Input()
  @HostBinding('class.disabled')
  isDisabled: boolean = false;

  @HostBinding('class.checkbox--checked')
  get isChecked(): boolean {
    return this.value === CHECKBOX_STATE.CHECKED;
  }

  onChange?: (value: string) => void;
  onTouched?: () => void;

  @HostBinding('class.checkbox--with-title')
  get hasTitle(): string | null {
    return this.label;
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.spacebar', ['$event'])
  onHostClick(): void {
    if (!this.isDisabled && !this.customOnChange) {
      this.value = this.value && this.value !== CHECKBOX_STATE.NONE ? CHECKBOX_STATE.NONE : CHECKBOX_STATE.CHECKED;
    }

    this._onChange();
  }

  value: string = CHECKBOX_STATE.NONE;
  CHECKBOX_STATE: typeof CHECKBOX_STATE = CHECKBOX_STATE;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  registerOnChange(fn: (value: string) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (value !== this.value) {
      this.value = value;

      this.changeDetectorRef.markForCheck();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  private _onChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
}
