import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';

import { DROPDOWN_ITEM_HEIGHT, DROPDOWN_SIZE, DROPDOWN_WIDTHS } from './constants/dropdown-sizes.constant';
import { DropdownItemModel } from './model/dropdown-item.model';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex',
  },
  imports: [NgClass, ClickOutsideModule, NgTemplateOutlet],
})
export class DropdownComponent implements OnChanges {
  @Input() size: DROPDOWN_SIZE = DROPDOWN_SIZE.SMALL;
  @Input() items: DropdownItemModel[] = [];
  @Input() selectedItem: DropdownItemModel | null;
  @Input() isOpen = false;
  @Input() isShowArrow = true;
  @Input() bgCustomLength = 0;
  @Input() iconOnly: string | null = null;
  @Input() selectedItemBgHeight = 0;
  @Input() id: string = '';
  @Input() placeholder: string | null = null;
  @Input() sortDirection: number | string;
  @Input() isShowSortDirection = false;
  @Input() minForShow = 1;
  @Input() hasCustomContent = false;
  @Input() isRearrangeItems = true;

  @Output() selectItem: EventEmitter<DropdownItemModel> = new EventEmitter<DropdownItemModel>();

  DROPDOWN_WIDTHS = DROPDOWN_WIDTHS;
  DROPDOWN_ITEM_HEIGHT = DROPDOWN_ITEM_HEIGHT;
  DROPDOWN_SIZE = DROPDOWN_SIZE;

  itemsBgLength = 2;

  ngOnChanges() {
    if (this.items.length) {
      if (this.bgCustomLength === 0) {
        this.itemsBgLength = this.items.length > 5 ? 5 : this.items.length;
      } else {
        this.itemsBgLength = this.bgCustomLength;
      }
    }
  }

  onToggle() {
    this.isOpen = !this.isOpen;

    setTimeout(() => {
      if (
        !this.isRearrangeItems &&
        this.selectedItem &&
        document.querySelector(`#dropdown-item-${this.selectedItem.id}`)
      ) {
        document.querySelector(`#dropdown-item-${this.selectedItem.id}`)!.scrollIntoView();
      }
    });
  }

  onSelectItem(selectedItem: DropdownItemModel) {
    this.selectedItem = selectedItem;

    this.isOpen = false;

    if (this.isRearrangeItems) {
      this.items = [selectedItem, ...this.items.filter(it => it.id !== selectedItem.id)];
    }

    this.selectItem.emit(selectedItem);
  }
}
