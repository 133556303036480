@if (isWrongDomain) {
  <div
    class="wrong-domain g-flex-column g-flex--justify-center g-flex--align-center gap-80"
    [style.zoom]="appZoom">
    <div class="app-btn-huge_font-size text-shadow">We migrated to a new domain!</div>

    <button
      class="app-btn-red-3"
      (click)="playOnAllowDomain()">
      Play on {{ ALLOW_DOMAIN }}
    </button>

    <button
      class="app-btn-blue-3"
      (click)="playOnForbiddenDomain()">
      Continue on {{ FORBIDDEN_DOMAIN }}
    </button>
  </div>
} @else {
  @if (subgraphDelay >= maxSubgraphDelay && !isLowActivityNetwork()) {
    <div
      class="g-flex-column g-flex--align-center"
      [style.zoom]="appZoom">
      <div class="subgraph-app-error app-btn_font-size">
        {{ 'subgraph.info' | translate: { subgraphDelay: subgraphDelayFormatted } }}<br />
        The game data is inconsistent.<br />
        Please wait until the data is updated and reload this page.<br />
        Or you can try to set custom subgraph URL.
      </div>
      <div class="g-flex-column gap-20">
        <div class="g-flex-column gap-12">
          <app-checkbox
            class="checkbox__url"
            [formControl]="subgraphUrlControl"
            [label]="'settings-dialog.graph-url' | translate">
          </app-checkbox>

          <input
            class="app-input app-input--nickname app-btn-small_font-size"
            [formControl]="subgraphUrlValueControl" />
        </div>
        <div class="g-flex-column g-flex--justify-center g-flex--align-center">
          <button
            class="btn-apply app-btn-blue-3"
            (click)="saveUrl()">
            {{ 'settings-dialog.buttons.apply-url' | translate }}
          </button>
        </div>
      </div>
    </div>
  } @else {
    @if (timeError && !isLowActivityNetwork()) {
      <div
        class="subgraph-app-error"
        [style.zoom]="appZoom">
        System time discrepancy detected: Your device's clock is more than {{ differentErrorTime }} minutes different
        from time. <br />
        Please adjust your system clock to ensure correct functionality and synchronization of applications.
      </div>
    } @else {
      @if (!isFirefox) {
        <app-loading [appZoom]="appZoom"></app-loading>

        @if (isLoading) {
          <app-loading-small [style.zoom]="appZoom"></app-loading-small>
        } @else {
          <div
            class="app-borders"
            [style.max-width.px]="1080 * appZoom">
            <img
              class="shadow shadow__left"
              src="assets/images/ui/bg_art/shadow_left.avif"
              loading="lazy"
              alt="" />

            <img
              class="shadow shadow__right"
              src="assets/images/ui/bg_art/shadow_right.avif"
              loading="lazy"
              alt="" />

            <div
              class="shadow-stone shadow-stone__left"
              [style.width]="'calc(50% - ' + 540 * appZoom + 'px)'"></div>

            <div
              class="shadow-stone shadow-stone__right"
              [style.width]="'calc(50% - ' + 540 * appZoom + 'px)'"></div>

            <div class="stone stone__left"></div>

            <div class="stone stone__right"></div>

            <img
              class="fresco fresco__left"
              [style.left]="'calc((50% - ' + 540 * appZoom + 'px)/2 - 456px/2)'"
              src="assets/images/ui/bg_art/left.avif"
              loading="lazy"
              alt="" />

            <img
              class="fresco fresco__right"
              [style.right]="'calc((50% - ' + 540 * appZoom + 'px)/2 - 456px/2)'"
              src="assets/images/ui/bg_art/right.avif"
              loading="lazy"
              alt="" />
          </div>

          <div
            class="app g-flex-column"
            [class.app--desktop]="!isMobile"
            [style.zoom]="appZoom">
            @if (isShowDisclaimer) {
              <app-modal
                class="disclaimer"
                [class.g-scroll-no]="isMobile"
                [class.g-scroll-y]="!isMobile"
                [open]="isShowDisclaimer"
                [modalTitle]="'disclaimer-screen.title' | translate"
                [modalSubTitle]="'disclaimer-screen.sub-title' | translate"
                modalBg="app-window-responsive-background g-flex-column app-window-responsive-background--full app-window-responsive-background--full-height"
                [allowClose]="false"
                (closed)="closeErrorModal()">
                <div class="g-flex-column g-scroll-no">
                  <div
                    class="disclaimer__desc2 app-btn-small_font-size color-caption"
                    [innerHTML]="'disclaimer-screen.description2' | translate"></div>

                  <div class="wrong-network__divider app-divider-scratches-border-v-down"></div>

                  <button
                    class="btn-disclaimer app-btn-red-4 pointer"
                    [class.app-btn-medium-small_font-size]="!isMobile"
                    [class.app-btn-tiny_font-size]="isMobile"
                    [innerHTML]="'disclaimer-screen.buttons.agree' | translate"
                    (click)="onHideDisclaimer()"></button>

                  <button
                    class="btn-learn-more app-btn-silver-3 pointer"
                    [innerHTML]="'disclaimer-screen.buttons.learn-more' | translate"
                    (click)="onLearnMore()"></button>
                </div>
              </app-modal>
            }

            <ng-container>
              @if (isShowHeader) {
                <app-header
                  [heroToken]="heroToken"
                  [heroTokenId]="heroTokenId"
                  [providerInited]="providerInited"
                  [menuState]="menuState"
                  (clickOutside)="closeMenu()">
                </app-header>
              }

              <main
                class="main g-flex-column__item g-flex-column g-scroll-y"
                [class.main--no-header]="isNoHeader || !isShowHeader"
                [class.main--blured]="menuState.isOpen"
                [class.main--has-hero]="!!heroToken">
                @if (isShowAchievement && achievement) {
                  <div
                    @enterAnimation
                    class="achievement">
                    <app-achievement
                      class="achievement__item"
                      [item]="achievement">
                    </app-achievement>

                    <div class="achievement__bg"></div>
                  </div>
                }

                <router-outlet #outlet="outlet"></router-outlet>

                @if (!isLoading) {
                  <div
                    #subgraphError
                    id="subgraph-error"
                    class="subgraph-error"
                    [class.subgraph-error--closed]="isSubgraphClosed"
                    (click)="onOpenSubgraph()">
                    <div
                      class="subgraph-error__text g-flex g-flex--align-center app-btn-tiny_font-size"
                      [class.g-flex--justify-end]="isSubgraphClosed"
                      [class.subgraph-error__text--red]="subgraphDelay > 60 || indexingError">
                      <div class="app-ver">v{{ window['sacraGameVersion'] }}</div>

                      @if (isSubgraphClosed) {
                        <div class="subgraph-error__text-label no-action">
                          &nbsp;|&nbsp;{{ !indexingError ? subgraphDelayFormatted : '!' }}
                        </div>
                      } @else {
                        <div class="subgraph-error__text-label-open no-action">
                          @if (!indexingError) {
                            &nbsp;|&nbsp;{{ 'subgraph.info' | translate: { subgraphDelay: subgraphDelayFormatted } }}
                          } @else {
                            &nbsp;|&nbsp;{{ 'subgraph.error' | translate }}
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
              </main>
            </ng-container>

            @if (isShowLogger) {
              <div class="logger g-flex-column">
                <div class="logger__btns g-flex g-flex--align-center">
                  <button
                    class="logger__btn app-btn-square-minus"
                    (click)="clearLogs()"></button>

                  <button
                    class="logger__btn app-btn-square-cross"
                    (click)="isShowLogger = false"></button>
                </div>

                <div class="g-scroll-y">
                  @for (log of logs; track log) {
                    <div class="logger__item app-btn-small_font-size">
                      <div class="logger__item-text">
                        <span class="logger__item-label app-btn-tiny_font-size">timestamp:</span> {{ log.timestamp }}
                      </div>

                      <div class="logger__item-text">
                        <span class="logger__item-label app-btn-tiny_font-size">file:</span> {{ log.fileName }}
                      </div>

                      <div class="logger__item-text">
                        <span class="logger__item-label app-btn-tiny_font-size">message:</span> {{ log.message }}
                      </div>

                      @if (log.additional && log.additional.length) {
                        <div class="logger__item-text">
                          <span class="logger__item-label app-btn-tiny_font-size">additional:</span>
                          {{ log.additional.join(', ') }}
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }

        <app-modal-tutorial
          class="tutorial"
          [class.tutorial--show]="isShowTutorial"
          [style.zoom]="appZoom"
          [isShowTutorial]="isShowTutorial">
        </app-modal-tutorial>

        @if (!isLoading && !isShowLogger && !isProductionBuild && isMobile) {
          <div
            class="btn-logger pointer"
            [style.zoom]="appZoom"
            (click)="isShowLogger = true">
            🖥
          </div>
        }

        @if (!isLoading && storageService.get(STORAGE_KEY_PLAY_MUSIC) === CHECKBOX_STATE.CHECKED) {
          <div
            #playedMusic
            class="played-music"
            [style.transform]="'translateX(calc(50% + 540px - ' + playedMusic.offsetWidth / 2 + 'px))'"
            [class.played-music--closed]="isPlayedMusicClosed"
            [style.zoom]="appZoom"
            (click)="onOpenPlayedMusic()">
            <div
              class="played-music__text g-flex g-flex--align-center app-btn-small_font-size"
              [class.g-flex--justify-start]="isPlayedMusicClosed">
              @if (isPlayedMusicClosed) {
                <div class="played-music__text-label color-positive-value no-action">♫</div>
              } @else {
                <div class="played-music__text-label-open no-action">
                  {{ playedMusicName }}
                </div>
              }
            </div>
          </div>
        }
      } @else {
        <div class="ff-error">Mozilla Firefox does not supported.</div>
      }
    }
  }
}
