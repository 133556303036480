<app-dialog-title
  title="Filter"
  (closeDialog)="close()">
</app-dialog-title>

@if (selectedTemplateName) {
  <app-item-id
    class="template"
    [hasIdText]="false"
    [itemId]="selectedTemplateName"
    bgSize="long-1">
  </app-item-id>
}

<div class="counts g-flex g-flex--align-center">
  <app-filter-count
    class="color-caption"
    [count]="generalFilterCount.toString()">
  </app-filter-count>

  <app-filter-count
    class="color-caption"
    [count]="filters.length.toString()">
  </app-filter-count>
</div>

<app-radio-group
  class="action-switch"
  [items]="actionTypes"
  [isShowDivider]="false"
  [selectedItemId]="selectedActionId"
  size="medium"
  dividerType="none"
  (choose)="onSelectAction($event)">
</app-radio-group>

<app-scratch backgroundType="middle"></app-scratch>

<div class="g-flex-column g-flex--align-center g-flex-column__item g-scroll-y">
  @switch (selectedActionId) {
    @case ('general') {
      <form
        class="general-form g-flex g-flex--justify-center g-flex--wrap"
        [formGroup]="generalFormGroup">
        @for (generalControl of generalControls; track generalControl; let isLast = $last) {
          <div [formGroupName]="generalControl.controlName">
            <div class="item g-flex-column g-flex--align-center">
              <div class="item__title">
                {{ generalControl.label }}
              </div>

              <div class="item__inputs g-flex g-flex--align-center">
                <input
                  class="item__input app-input app-input--medium app-btn_font-size"
                  placeholder="min"
                  formControlName="min"
                  tetuDigitOnly />

                <div class="app-divider-bolt"></div>

                <input
                  class="item__input app-input app-input--medium app-btn_font-size"
                  placeholder="max"
                  formControlName="max"
                  tetuDigitOnly />
              </div>
            </div>

            @if (!isLast) {
              <app-scratch
                class="scratch"
                backgroundType="middle">
              </app-scratch>
            }
          </div>
        }
      </form>
    }
    @case ('stats') {
      <div class="stats">
        <div
          class="stat-header g-flex g-flex--align-center g-flex--space-between app-btn-small_font-size color-caption">
          <div class="stat-header__stat">Attribute</div>
          <div class="stat-header__toggle">Or/And</div>
          <div class="stat-header__value">Value</div>
        </div>

        @if (filters.length) {
          <form
            class="filters"
            [formGroup]="filtersFormGroup">
            @for (filter of filters; track filter) {
              <div
                class="filter-item g-flex g-flex--align-center g-flex--space-between"
                [class.filter-item--disabled]="
                  filtersFormGroup.get(filter.id)?.value['enabled'] === CHECKBOX_STATE.NONE
                "
                [formGroupName]="filter.id">
                <div class="filter-item__stat g-flex g-flex--align-center">
                  <app-checkbox
                    class="filter-item__enabled"
                    formControlName="enabled">
                  </app-checkbox>

                  @if (filter.filterTypeIcon) {
                    <img
                      class="filters__img"
                      [src]="filter.filterTypeIcon"
                      alt="img" />
                  }

                  <img
                    class="filters__img"
                    [src]="filter.attribute?.prefixIconPath"
                    alt="img" />

                  <div>
                    {{ filter.filterType === FILTER_TYPE.REQUIRED ? 'Required ' : '' }}{{ filter.attribute?.label }}
                  </div>
                </div>

                <app-checkbox
                  class="stat-item__toggle"
                  formControlName="and"
                  mode="toggle">
                </app-checkbox>

                <input
                  class="stat-item__value app-input app-input--medium app-btn_font-size"
                  placeholder="min"
                  formControlName="value"
                  tetuDigitOnly />

                <button
                  class="app-btn-square-minus app-btn-square-minus--minimal pointer"
                  (click)="onRemoveStat(filter)"></button>
              </div>
            }
          </form>

          <app-scratch
            class="filters__scratch"
            backgroundType="huge"></app-scratch>
        }

        <form [formGroup]="statsFormGroup">
          @for (item of chooseAttributes; track item; let index = $index) {
            <div
              class="stat-item g-flex g-flex--align-center g-flex--space-between"
              [style.z-index]="chooseAttributes.length - index"
              [formGroupName]="item.formName">
              @if (item.icon) {
                <img
                  class="stat-item__icon"
                  [src]="'/assets/images/ui/icons/item-props/' + item.icon + '.png'"
                  alt="item image" />
              }

              <app-dropdown
                class="stat-item__stat"
                [size]="DROPDOWN_SIZE.LARGE"
                [items]="item.attributes"
                [bgCustomLength]="8"
                [placeholder]="item.placeholder"
                [selectedItem]="item.selectedAttribute"
                (selectItem)="item.selectedAttribute = $event">
              </app-dropdown>

              <app-checkbox
                class="stat-item__toggle"
                formControlName="and"
                mode="toggle">
              </app-checkbox>

              <input
                class="stat-item__value app-input app-input--medium app-btn_font-size"
                placeholder="min"
                formControlName="value"
                tetuDigitOnly />

              <button
                class="app-btn-square-plus app-btn-square-plus--minimal pointer"
                [disabled]="!item.selectedAttribute"
                (click)="
                  onAddStat(item.formName, statsFormGroup.get(item.formName)?.getRawValue(), item.selectedAttribute)
                "></button>
            </div>
          }
        </form>
      </div>
    }
  }
</div>

<div class="footer g-flex-column__item-fixed">
  <app-scratch backgroundType="huge"></app-scratch>

  <div class="footer__btns g-flex g-flex--align-center g-flex--space-between">
    <button
      class="btn-reset app-btn-blue-1"
      (click)="onTemplate()">
      Template
    </button>

    <button
      class="btn-reset app-btn-blue-3"
      (click)="onApply()">
      Apply
    </button>

    <button
      class="btn-reset app-btn-blue-1"
      (click)="onReset()">
      Reset
    </button>
  </div>
</div>
