import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LeftRightFrameComponent } from '@shared/components/left-right-frame/left-right-frame.component';

@Component({
  selector: 'app-buttons-level-up',
  standalone: true,
  templateUrl: './buttons-level-up.component.html',
  styleUrls: ['./buttons-level-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center g-flex--space-between',
  },
  imports: [LeftRightFrameComponent],
})
export class ButtonsLevelUpComponent {
  @Input() value: number;
  @Input() valueLevel: number;
  @Input() points: number;
  @Input() valueName: string;

  @Output() down: EventEmitter<string> = new EventEmitter<string>();
  @Output() up: EventEmitter<string> = new EventEmitter<string>();
}
