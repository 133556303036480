import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent],
})
export class ConfirmDialogComponent {
  canClose: boolean;

  constructor(
    @Inject(DIALOG_DATA)
    public data: { title: string; text: string; confirmBtnText: string; cancelBtnText: string; canClose: boolean } = {
      title: 'Confirm',
      text: '',
      confirmBtnText: 'Yes',
      cancelBtnText: 'No',
      canClose: true,
    },
    private dialogRef: DialogRef<boolean, ConfirmDialogComponent>,
  ) {
    this.canClose = data.canClose !== undefined && data.canClose !== null ? data.canClose : true;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
