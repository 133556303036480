import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { adjustAttribute } from '@helpers/data-helper';
import { TranslateModule } from '@ngx-translate/core';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';
import { HeroStatItemDialogComponent } from '@shared/components/hero-stat-item-dialog/hero-stat-item-dialog.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ATTRIBUTES } from '@shared/constants/attributes.constant';

@Component({
  selector: 'app-hero-stat-item',
  standalone: true,
  templateUrl: './hero-stat-item.component.html',
  styleUrls: ['./hero-stat-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex--align-center',
  },
  imports: [TranslateModule],
})
export class HeroStatItemComponent {
  @Input() isRowLayout = false;
  @Input() isCompact = false;
  @Input() isSmallFont = false;
  @Input() isMediumFont = false;
  @Input() item: HeroStatItemInputInterface;
  @Input() isShowSign = false;
  @Input() isDisabled = false;
  @Input() isShowItem = false;
  @Input() isShowDescription = true;
  @Input() useColors = true;
  @Input() isInline = false;
  @Input() isVertical = false;
  @Input() iconCustomWidth = 0;
  @Input() isShowAdjusted = false;
  @Input() isMetaItem = false;

  @HostBinding('class.g-flex')
  private get isFlex() {
    return this.isRowLayout;
  }

  @HostBinding('class.g-flex--space-between')
  private get isSpaceBetween() {
    return !this.isInline;
  }

  @HostBinding('class.g-flex-column')
  private get isFlexColumn() {
    return !this.isRowLayout;
  }

  constructor(private dialog: Dialog) {}

  needShowSign() {
    let need = false;
    switch (this.item.attributeIndex) {
      case ATTRIBUTES.DMG_AGAINST_HUMAN:
        need = true;
        break;
      case ATTRIBUTES.DMG_AGAINST_UNDEAD:
        need = true;
        break;
      case ATTRIBUTES.DMG_AGAINST_DAEMON:
        need = true;
        break;
      case ATTRIBUTES.DMG_AGAINST_BEAST:
        need = true;
        break;
      case ATTRIBUTES.DEF_AGAINST_HUMAN:
        need = true;
        break;
      case ATTRIBUTES.DEF_AGAINST_UNDEAD:
        need = true;
        break;
      case ATTRIBUTES.DEF_AGAINST_DAEMON:
        need = true;
        break;
      case ATTRIBUTES.DEF_AGAINST_BEAST:
        need = true;
        break;
      case ATTRIBUTES.MELEE_DMG_FACTOR:
        need = true;
        break;
      case ATTRIBUTES.FIRE_DMG_FACTOR:
        need = true;
        break;
      case ATTRIBUTES.COLD_DMG_FACTOR:
        need = true;
        break;
      case ATTRIBUTES.LIGHTNING_DMG_FACTOR:
        need = true;
        break;
      case ATTRIBUTES.AR_FACTOR:
        need = true;
        break;
    }
    return this.isShowSign || need;
  }

  isShowPercent() {
    switch (this.item.attributeIndex) {
      case ATTRIBUTES.DMG_AGAINST_HUMAN:
        return true;
      case ATTRIBUTES.DMG_AGAINST_UNDEAD:
        return true;
      case ATTRIBUTES.DMG_AGAINST_DAEMON:
        return true;
      case ATTRIBUTES.DMG_AGAINST_BEAST:
        return true;
      case ATTRIBUTES.MAGIC_FIND:
        return true;
      case ATTRIBUTES.DESTROY_ITEMS:
        return true;
      case ATTRIBUTES.MELEE_DMG_FACTOR:
        return true;
      case ATTRIBUTES.FIRE_DMG_FACTOR:
        return true;
      case ATTRIBUTES.COLD_DMG_FACTOR:
        return true;
      case ATTRIBUTES.LIGHTNING_DMG_FACTOR:
        return true;
      case ATTRIBUTES.AR_FACTOR:
        return true;
      case ATTRIBUTES.LIFE_STOLEN_PER_HIT:
        return true;
      case ATTRIBUTES.REFLECT_DAMAGE_MELEE:
        return true;
      case ATTRIBUTES.REFLECT_DAMAGE_MAGIC:
        return true;
    }
    return false;
  }

  isShowAdjustAttack() {
    switch (this.item.attributeIndex) {
      case ATTRIBUTES.CRITICAL_HIT:
        return true;
      case ATTRIBUTES.STUN:
        return true;
      case ATTRIBUTES.BURN:
        return true;
      case ATTRIBUTES.FREEZE:
        return true;
      case ATTRIBUTES.CONFUSE:
        return true;
      case ATTRIBUTES.CURSE:
        return true;
      case ATTRIBUTES.POISON:
        return true;
    }
    return false;
  }

  isShowAdjustDef() {
    switch (this.item.attributeIndex) {
      case ATTRIBUTES.BLOCK_RATING:
        return true;
      case ATTRIBUTES.FIRE_RESISTANCE:
        return true;
      case ATTRIBUTES.COLD_RESISTANCE:
        return true;
      case ATTRIBUTES.LIGHTNING_RESISTANCE:
        return true;
      case ATTRIBUTES.DEF_AGAINST_HUMAN:
        return true;
      case ATTRIBUTES.DEF_AGAINST_UNDEAD:
        return true;
      case ATTRIBUTES.DEF_AGAINST_DAEMON:
        return true;
      case ATTRIBUTES.DEF_AGAINST_BEAST:
        return true;
      case ATTRIBUTES.DAMAGE_REDUCTION:
        return true;
      case ATTRIBUTES.RESIST_TO_STATUSES:
        return true;
    }
    return false;
  }

  openDialog() {
    if (this.isShowDescription) {
      this.dialog.open(HeroStatItemDialogComponent, {
        panelClass: 'app-overlay-pane',
        data: this.item,
      });
    } else if (this.isShowItem) {
      this.dialog.open(ItemDescriptionDialogComponent, {
        panelClass: 'app-overlay-pane',
        data: this.item,
      });
    }
  }

  adjustAttrDef(x: number) {
    return adjustAttribute(90, x, 100);
  }

  adjustAttrAttack(x: number) {
    return adjustAttribute(100, x, 100);
  }

  valueText() {
    // if(+this.item.text + +(this.item.additionalText ?? '0') < 0) {
    //   return '0';
    // }
    const text1 = +this.item.text > 0 && this.needShowSign() ? '+' : '';
    const text2 = this.item.text;
    const text3 = this.isShowPercent() ? '%' : '';
    const text4 = this.item.additionalText && +this.item.additionalText > 0 ? '+' : '';
    const text5 = this.item.additionalText && +this.item.additionalText < 0 ? '-' : '';
    const text6 = this.item.additionalText && +this.item.additionalText > 0 ? +this.item.additionalText : '';
    const text7 = this.item.additionalText && +this.item.additionalText < 0 ? -this.item.additionalText : '';
    const text7_1 = this.item.additionalText && this.isShowPercent() ? '%' : '';

    let text8 = '';
    let text9 = '';
    if (this.isShowAdjusted && +this.item.text + +(this.item.additionalText ?? '0') > 0) {
      text8 = this.isShowAdjustAttack()
        ? '(' + this.adjustAttrAttack(+this.item.text + +(this.item.additionalText ?? '0')) + '%)'
        : '';
      text9 = this.isShowAdjustDef()
        ? '(' + this.adjustAttrDef(+this.item.text + +(this.item.additionalText ?? '0')) + '%)'
        : '';
    }

    if (this.isMetaItem) {
      if (this.item.text === this.item.additionalText) {
        return text1 + text2 + text3;
      }
      return text1 + text2 + text3 + ' → ' + text4 + text5 + text6 + text7 + text7_1 + text8 + text9;
    }

    return text1 + text2 + text3 + text4 + text5 + text6 + text7 + text8 + text9;
  }

  protected readonly Number = Number;
}
