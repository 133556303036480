import { TransactionStatus } from '@models/transaction-data.model';
import { MediatorAction } from '@shared/types/action.interface';

export namespace ActionStatusActions {
  export class ShowAction implements MediatorAction {
    constructor(
      public status: TransactionStatus = TransactionStatus.PENDING,
      public label: string = '',
      public tooltip: string = '',
      public txLink: string = '',
      public isLoading: boolean = false,
    ) {}
  }

  export class HideAction implements MediatorAction {}
}
