export class DropdownItemModel {
  id: string;
  label: string;
  address?: string;
  prefixIconPath?: string;
  suffixIconPath?: string;
  iconHeight?: string;
  selectedItemPrefixIconPath?: string;
  suffixText?: string;
  valueFormatted?: string;
  valueN?: number;
  valueBN?: bigint;
  data?: any;

  constructor(data: Partial<DropdownItemModel> = {}) {
    Object.assign(this, data);
  }
}
