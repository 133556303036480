<app-dialog-title
  title="Wrong blockchain network"
  [canClose]="canClose"
  (closeDialog)="close()">
</app-dialog-title>

<div class="content g-flex-column g-flex--align-center g-flex--justify-center">
  <div class="disclaimer__desc2 app-btn-small_font-size color-caption info-network">
    The network in your wallet does not match the network of the application.<br />
    Click on the button below to add {{ networksOptions[0].label }} network to your wallet. <br />
    Or change network to {{ networksOptions[0].label }} inside your wallet. <br />
  </div>

  @for (net of networksOptions; track net) {
    <button
      class="btn-network app-btn-red-4 pointer"
      (click)="switchNetwork(+net.id)">
      {{ mapNetworkNameToDescription(net.label) }}
    </button>
  }
</div>
