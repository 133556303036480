@if (!isFilterChanging) {
  <app-dialog-title
    class="g-flex-column__item-fixed"
    [title]="'settings-dialog.title' | translate"
    (closeDialog)="close()">
  </app-dialog-title>
}

<div class="content g-flex-column g-flex--align-center">
  <div class="content__base g-flex-column g-flex--align-start gap-12">
    <app-checkbox
      [formControl]="askSpeedUpControl"
      label="Ask for Web3Auth Speed-up">
    </app-checkbox>

    <app-checkbox
      [formControl]="askDelegateControl"
      label="Ask for Transaction Delegation">
    </app-checkbox>

    <div class="g-flex g-flex--align-center gap-12">
      <app-checkbox
        [formControl]="customGasControl"
        label="Custom gas price:">
      </app-checkbox>

      <input
        class="app-input app-input--medium app-btn_font-size"
        [formControl]="customGasValueControl" />
    </div>
  </div>

  <div
    class="item"
    [class.item--hide]="isFilterChanging"
    [class.item--show]="activeFilter === FILTERS.BRIGHTNESS">
    <div class="item__title app-btn-small_font-size">
      {{ 'settings-dialog.transaction-speed' | translate }}
    </div>

    <app-slider
      class="slider"
      size="medium-long"
      [value]="transactionSpeedValue"
      [labels]="transactionSpeedLabels"
      postfix=""
      [maxValue]="transactionSpeedMax"
      (valueChanging)="onSpeedChange($event)"
      (valueChanged)="onSpeedChange($event)">
    </app-slider>
  </div>

  <app-scratch
    class="item__scratch"
    [class.item--hide]="isFilterChanging"
    backgroundType="middle">
  </app-scratch>

  <div class="g-flex-column gap-20">
    <div class="g-flex-column gap-12">
      <app-checkbox
        class="checkbox__url"
        [formControl]="subgraphUrlControl"
        [label]="'settings-dialog.graph-url' | translate">
      </app-checkbox>

      <input
        class="input-url app-input app-input--nickname app-btn-small_font-size"
        [formControl]="subgraphUrlValueControl" />
    </div>

    <div class="g-flex-column gap-12">
      <app-checkbox
        class="checkbox__url"
        [formControl]="rpcUrlControl"
        [label]="'settings-dialog.rpc-url' | translate">
      </app-checkbox>

      <input
        class="input-url app-input app-input--nickname app-btn-small_font-size"
        [formControl]="rpcUrlValueControl" />
    </div>
  </div>

  <button
    class="btn-apply app-btn-blue-3"
    [class.item--hide]="isFilterChanging"
    (click)="saveUrl()">
    {{ 'settings-dialog.buttons.apply-url' | translate }}
  </button>

  <div
    class="item"
    [class.item--hide]="isFilterChanging">
    <app-scratch
      class="item__scratch"
      [class.item--hide]="isFilterChanging"
      backgroundType="middle">
    </app-scratch>

    <app-checkbox
      class="checkbox"
      [formControl]="isPlayMusicControl"
      [label]="'settings-dialog.music' | translate">
    </app-checkbox>

    <app-slider
      class="slider"
      size="medium-long"
      [isDisabled]="isPlayMusicControl.value === CHECKBOX_STATE.NONE"
      [value]="sounds[SOUNDS.MUSIC].level"
      [isShowTooltip]="false"
      (valueChanging)="onSoundLevelChange($event, SOUNDS.MUSIC)"
      (valueChanged)="onSoundLevelChange($event, SOUNDS.MUSIC)">
    </app-slider>
  </div>

  <div
    class="item"
    [class.item--hide]="isFilterChanging">
    <app-checkbox
      class="checkbox"
      [formControl]="isPlayEffectsControl"
      [label]="'settings-dialog.effects' | translate">
    </app-checkbox>

    <app-slider
      class="slider"
      size="medium-long"
      [isDisabled]="isPlayEffectsControl.value === CHECKBOX_STATE.NONE"
      [value]="sounds[SOUNDS.EFFECTS].level"
      [isShowTooltip]="false"
      (valueChanging)="onSoundLevelChange($event, SOUNDS.EFFECTS)"
      (valueChanged)="onSoundLevelChange($event, SOUNDS.EFFECTS)">
    </app-slider>
  </div>
</div>
