import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DestroyService } from '@services/destroy.service';

@Component({
  selector: 'app-loading-small',
  standalone: true,
  templateUrl: './loading-small.component.html',
  styleUrls: ['./loading-small.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center g-flex--justify-center',
  },
})
export class LoadingSmallComponent {
  @Input() text = 'Loading';
  @Input() isShowAnimation = true;
}
