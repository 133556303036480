import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DailyQuestDayState } from '@models/daily-quest-day.model';

@Component({
  selector: 'app-day-point',
  standalone: true,
  templateUrl: './day-point.component.html',
  styleUrls: ['./day-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center g-flex--justify-center',
  },
})
export class DayPointComponent {
  @Input() state: DailyQuestDayState = 'default';
  @Input() dayNumber: number = 0;
}
