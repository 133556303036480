<div class="inventory__all_slot-items g-flex g-flex--align-start g-flex--wrap g-scroll-y">
  @for (item of itemsFilteredAndSorted; track item) {
    @if (viewMode.query === VIEW_MODE.ICON) {
      <app-item-slot
        class="inventory__all_slot-item"
        [slotItem]="item"
        [clickOnItemImg]="true"
        slotSize="huge"
        (clickEmit)="openItemDescriptionDialog(item)">
      </app-item-slot>
    } @else {
      <app-item-card
        [item]="item"
        itemWidth="986px"
        idSize="long-1"
        idWidth="198px"
        (selectItem)="openItemDescriptionDialog(item)"
        (selectItemImg)="openItemDescriptionDialog(item)">
        <div class="item__actions g-flex-column g-flex__item-fixed">
          @if (hero && item.meta.isConsumableItem) {
            <button
              class="buttons__item app-btn-red-1"
              appButtonClick
              [infoDesc]="'item-description-dialog.disabled.use' | translate"
              (hostClick)="onUse(item, $event)">
              {{ 'item-description-dialog.use' | translate }}
            </button>
          }

          @if (!item.meta.isConsumableItem && !item.equipped) {
            <button
              class="buttons__item app-btn-blue-augment"
              (click)="onForge($event, item)">
              <div class="btn-augmentation__level app-btn-small_font-size">
                {{ item.augmentationLevel }}
              </div>
            </button>
          }
        </div>
      </app-item-card>
    }
  }
</div>
