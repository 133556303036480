import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DestroyService } from '@services/destroy.service';
import { RelayService } from '@services/onchain/relay.service';
import { ProviderService } from '@services/provider.service';
import { StorageService } from '@services/storage.service';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { ADDRESS_ZERO } from '@shared/constants/addresses/addresses.constant';
import { CHAIN_FIELDS, getChainByChainId } from '@shared/constants/chain-ids.constant';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { getDateInUTC } from '@shared/time-utils';
import * as CryptoJS from 'crypto-js';
import { ethers, formatUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';

export const STORAGE_DELEGATE_HIDE = 'TETU_GAME_DELEGATE_HIDE';
export const STORAGE_DELEGATE_EMPTY_PASS = 'STORAGE_DELEGATE_EMPTY_PASS';

@Component({
  selector: 'app-delegate-dialog',
  standalone: true,
  templateUrl: './delegate-dialog.html',
  styleUrls: ['./delegate-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, LoadingSmallComponent, ScratchComponent],
})
export class DelegateDialogComponent implements OnInit {
  customWidth = 990;
  forceOpen = false;

  @HostBinding('style.width.px')
  private get modalWidth() {
    return this.customWidth;
  }

  account: string;
  chainId: number;
  loading = true;
  processing = false;
  errorMsg = '';

  delegator = ADDRESS_ZERO;
  nonce = 0n;
  delegatorDeadline = 0n;
  delegatorBalance = 0;
  accountBalance = 0;

  isNeverAskAgainControl: FormControl<CHECKBOX_STATE | null> = new FormControl<CHECKBOX_STATE>(CHECKBOX_STATE.NONE);

  passwordControl: FormControl<string | null> = new FormControl('', {
    validators: [Validators.required],
  });

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      customWidth: number;
      forceOpen: boolean;
    } = {
      customWidth: 990,
      forceOpen: false,
    },
    private dialogRef: DialogRef<null, DelegateDialogComponent>,
    private destroy$: DestroyService,
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private relayService: RelayService,
    private storageService: StorageService,
    private logger: NGXLogger,
    private router: Router,
  ) {
    this.customWidth = data.customWidth;
    this.forceOpen = data.forceOpen;
  }

  ngOnInit() {
    if (this.storageService.get(STORAGE_DELEGATE_HIDE)) {
      const isHide = JSON.parse(this.storageService.get(STORAGE_DELEGATE_HIDE));

      this.isNeverAskAgainControl.setValue(isHide);
    }

    this.isNeverAskAgainControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(STORAGE_DELEGATE_HIDE, JSON.stringify(this.isNeverAskAgainControl.value));
    });

    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.init();
      },
    );
  }

  close(): void {
    this.dialogRef.close(null);
  }

  storageDelegatePkKey() {
    return 'TETU_GAME_DELEGATE_PK_' + this.account;
  }

  init() {
    if (this.account && this.chainId) {
      this.loading = true;
      this.relayService
        .userInfo$(this.chainId, this.account)
        .pipe(takeUntil(this.destroy$))
        .subscribe(userInfo => {
          this.delegator = userInfo.delegator;
          this.nonce = userInfo.nonce;
          this.delegatorDeadline = userInfo.delegatorDeadline;

          this.logger.trace('this.delegator', this.delegator);
          this.logger.trace('this.nonce', this.nonce);
          this.logger.trace('this.delegatorDeadline', this.delegatorDeadline);

          this.loadDelegatorBalance();
          this.loadAccountBalance();

          this.loading = false;

          this.changeDetectorRef.detectChanges();
        });
    }
  }

  instantDelegate() {
    const isEmptyPassword = this.isEmptyPassword();
    if (
      !this.forceOpen &&
      this.delegator !== ADDRESS_ZERO &&
      isEmptyPassword &&
      this.isEnoughBalanceOnDelegator() &&
      !this.isDelegatorOutOfDate() &&
      this.isPKExist()
    ) {
      this.useDelegation();
    }
  }

  isEmptyPassword() {
    return this.storageService.get(STORAGE_DELEGATE_EMPTY_PASS) === 'true';
  }

  loadDelegatorBalance() {
    if (this.delegator !== ADDRESS_ZERO) {
      from(this.providerService.getProviderForRead().provider.getBalance(this.delegator))
        .pipe(takeUntil(this.destroy$))
        .subscribe(balance => {
          this.logger.trace('delegator balance', +formatUnits(balance));
          this.delegatorBalance = +formatUnits(balance);
          this.changeDetectorRef.detectChanges();

          this.instantDelegate();
        });
    }
  }

  loadAccountBalance() {
    from(this.providerService.getProviderForRead().provider.getBalance(this.account))
      .pipe(takeUntil(this.destroy$))
      .subscribe(balance => {
        this.logger.trace('account balance', balance);
        this.accountBalance = +formatUnits(balance);
        this.changeDetectorRef.detectChanges();
      });
  }

  ///////////////////////////////// MAIN LOGIC /////////////////////////////////

  isDelegatorEmpty() {
    return this.delegator === ADDRESS_ZERO;
  }

  isDelegatorOutOfDate() {
    return this.delegatorDeadline < BigInt((getDateInUTC().getTime() / 1000).toFixed(0));
  }

  amountToDelegated() {
    const baseAmount = getChainByChainId(this.chainId)[CHAIN_FIELDS.COINS_FOR_DELEGATION] ?? 1;
    if (this.accountBalance * 0.9 < baseAmount) {
      return this.accountBalance / 2;
    }

    return baseAmount;
  }

  symbolNetworkCoin() {
    return getChainByChainId(this.chainId)[CHAIN_FIELDS.TOKEN_NAME] ?? '???';
  }

  getPrivateKeyFromStorage(): string {
    const pkEncrypted = this.storageService.get(this.storageDelegatePkKey());
    const pwd = this.passwordControl.value || '';
    return pkEncrypted ? CryptoJS.AES.decrypt(pkEncrypted, pwd).toString(CryptoJS.enc.Utf8) : '';
  }

  isPasswordCorrect() {
    try {
      const pk = this.getPrivateKeyFromStorage();
      this.validatePK(pk);
      return true;
    } catch (e) {
      this.logger.trace(e);
      return false;
    }
  }

  isPKExist() {
    return this.storageService.get(this.storageDelegatePkKey()) !== '';
  }

  isEnoughBalanceOnDelegator() {
    return this.delegatorBalance > (getChainByChainId(this.chainId)[CHAIN_FIELDS.COINS_FOR_DELEGATION] ?? 1) / 10;
  }

  validatePK(pk: string): string {
    // A valid private key should not include the '0x' prefix and must be 64 hex characters long
    // if (!/^[0-9a-fA-F]{64}$/.test(pk)) {
    //   throw new Error('Invalid private key symbols ' + pk);
    // }
    try {
      // Use ethers to convert the private key into an address to further validate it
      const wallet = new ethers.SigningKey(pk);
      // If ethers creates the wallet without throwing an error, the private key is valid
      if (!wallet.compressedPublicKey) {
        throw new Error('no compressedPublicKey');
      }
    } catch (error) {
      this.logger.trace('signer check error', error);
      // If any error occurs during wallet creation, the private key is invalid
      throw new Error('Invalid private key ' + pk);
    }
    return pk;
  }

  useDelegation() {
    this.errorMsg = '';
    let pk = '';
    if (this.isPKExist() && !this.isPasswordCorrect()) {
      this.errorMsg = 'Invalid password';
      return;
    }
    if (this.isPKExist()) {
      try {
        pk = this.getPrivateKeyFromStorage();
      } catch {
        return;
      }
    }

    if (new ethers.Wallet(pk).address.toLowerCase() !== this.delegator.toLowerCase()) {
      this.logger.error('Private key for not delegator', new ethers.Wallet(pk).address, this.delegator);
      this.errorMsg = 'Private key for not delegator';
      return;
    }

    if (pk !== '') {
      this.providerService.setupPrivateKeyDelegated(pk);
      this.close();
    }
    this.changeDetectorRef.detectChanges();
  }

  goToSettings() {
    this.router.navigate([MAIN_ROUTES.DELEGATE]);
    this.close();
  }
}
