import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNull',
  standalone: true,
})
export class FilterNullPipe implements PipeTransform {
  transform(value: any[]): any[] {
    return value.filter(it => !!it);
  }
}
