<div class="m-modal-bg"></div>

<div
  class="m-modal-container g-scroll-y g-flex--align-center"
  [ngClass]="modalBg">
  @if (allowClose) {
    <button
      class="btn-close app-btn-square-cross"
      (click)="close($event)"></button>
  }

  <div class="title app-black-paint-for-text g-flex-column g-flex--align-center g-flex-column__item-fixed">
    <img
      class="round__border"
      src="/assets/images/ui/dividers/borders_v/border_v_red_down.png"
      alt="" />

    <div class="title__text title-a app-btn-huge_font-size">
      {{ modalTitle }}
    </div>

    @if (!!modalSubTitle) {
      <div
        class="app-subtitle text-shadow-positive"
        [ngStyle]="modalSubTitleStyles">
        {{ modalSubTitle }}
      </div>
    }

    <app-scratch
      class="scratch"
      backgroundType="middle">
    </app-scratch>
  </div>

  <ng-content></ng-content>
</div>
