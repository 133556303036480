import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IdSlotSize } from '@shared/types/id-size.type';

@Component({
  selector: 'app-item-id',
  standalone: true,
  templateUrl: './item-id.component.html',
  styleUrls: ['./item-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center g-flex--space-between gap-12',
  },
})
export class ItemIdComponent {
  @Input() itemId: number | string;
  @Input() size: 'default' | 'small' = 'default';
  @Input() hasIdText = true;
  @Input() prefixIcon?: string;
  @Input() bgSize: IdSlotSize = 'small';

  @Output() prefixIconClick: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('style.background-image')
  get _bgSize() {
    return `url('/assets/images/ui/frames/id/${this.bgSize}.avif')`;
  }
}
