<app-dialog-title
  [title]="'account-dialog.title' | translate"
  (closeDialog)="close()">
</app-dialog-title>

<div class="content g-flex-column g-flex--align-center g-flex--justify-center">
  <div class="app-btn-small_font-size">
    {{ data.account }}
  </div>

  <app-avatar
    class="avatar g-flex__item-fixed"
    [isCenteredImage]="true"
    avatarType="medium"
    [imagePath]="avatarImg ? avatarImg : 'assets/images/ui/guilds/account-avatar.avif'"
    (click)="onChangeAvatar()">
  </app-avatar>

  @if (userInfo) {
    <div class="app-btn-small_font-size">
      {{ userInfo.email }}
    </div>
  }

  <div class="g-flex-column g-flex--align-start">
    <div class="account-name__desc app-btn-small_font-size text-shadow">
      {{ 'account-dialog.name-desc' | translate }}:
    </div>

    <input
      class="account-name__form_input app-input app-input--nickname app-btn-huge_font-size"
      [formControl]="nameValueControl"
      [placeholder]="isNameLoading ? '' : ('account-dialog.name-placeholder' | translate)"
      [class.app-text-error]="isNameTooLong || isNotLatinLetters" />

    <div
      class="account-name__form_validations app-btn-small_font-size text-shadow g-flex g-flex--align-center g-flex--space-between">
      <span
        [class.app-text-error]="isNotLatinLetters"
        [class.text-shadow-negative]="isNotLatinLetters">
        {{ 'hero-name-dialog.latin-letters' | translate }}
      </span>

      <div class="uniq-error g-flex g-flex--align-center">
        <span
          [class.app-text-warning]="isNameUnique"
          [class.text-shadow-positive]="isNameUnique"
          [class.app-text-error]="isNameNotUnique"
          [class.text-shadow-negative]="isNameNotUnique">
          {{ 'hero-name-dialog.unique-name' | translate }}
        </span>

        @if (isNameLoading) {
          <app-loading-small
            class="uniq-loader"
            text="">
          </app-loading-small>
        }
      </div>

      <span
        [class.app-text-warning]="isNameShorEnough"
        [class.text-shadow-positive]="isNameShorEnough"
        [class.app-text-error]="isNameTooLong"
        [class.text-shadow-negative]="isNameTooLong">
        {{ 'hero-name-dialog.max-symbols' | translate }}
      </span>
    </div>

    <button
      class="btn-apply app-btn-blue-3"
      appButtonClick
      [isDisabled]="!nameValueControl.valid || isNotLatinLetters"
      (hostClick)="saveName()">
      {{ 'account-dialog.buttons.save-name' | translate }}
    </button>
  </div>

  <app-scratch
    class="scratch"
    backgroundType="middle">
  </app-scratch>

  <div class="buttons g-flex g-flex--align-center g-flex--space-between">
    <button
      class="app-btn-copy"
      (click)="onCopy()"></button>

    <button
      class="app-btn-link"
      (click)="openNetworkScan()"></button>

    <button
      class="app-btn-exit"
      (click)="onDisconnect()"></button>
  </div>

  <app-scratch
    class="scratch"
    backgroundType="middle">
  </app-scratch>

  <div class="network-title">
    {{ 'account-dialog.network' | translate }}
  </div>

  <app-dropdown
    [size]="DROPDOWN_SIZE.LARGE"
    [items]="networksOptions"
    [selectedItem]="networkSelected"
    (selectItem)="updateSelected($event)">
  </app-dropdown>

  <!--  <div *ngIf="isShowDelegate()">-->
  <!--    <button-->
  <!--      class="app-btn-blue-3"-->
  <!--      (click)="openDelegate()">-->
  <!--      Delegate control-->
  <!--    </button>-->
  <!--  </div>-->

  <!--  <div *ngIf="userInfo">-->
  <!--    <button-->
  <!--      class="app-btn-blue-3"-->
  <!--      (click)="openWeb3AuthSettings()">-->
  <!--      Web3Auth settings-->
  <!--    </button>-->
  <!--  </div>-->
</div>
