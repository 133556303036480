import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ScratchBackgroundType } from '@shared/components/scratch/scratch-background-type';

@Component({
  selector: 'app-scratch',
  standalone: true,
  templateUrl: './scratch.component.html',
  styleUrls: ['./scratch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScratchComponent {
  @Input()
  set backgroundType(type: ScratchBackgroundType) {
    if (!type) {
      return;
    }
    this.hostClass = `scratch-${type}`;
  }

  @HostBinding('class')
  hostClass = 'scratch-small';
}
