@if (heroLoading) {
  <app-loading-small class="page-loader"></app-loading-small>
} @else {
  @if (hero) {
    <app-hero-all-stats
      class="all-stats"
      [hero]="hero">
    </app-hero-all-stats>
  }

  @if (hero && hero.items.length) {
    <div class="items-container">
      <div>
        {{ 'hero-stat.equipped-items' | translate }}
      </div>

      <div class="items g-flex g-flex--wrap g-flex--align-center">
        @for (item of hero.items; track item; let i = $index) {
          <app-item-slot
            class="item-slot g-flex"
            [class.pointer]="hero.items[i]"
            [slotItem]="hero.items[i]"
            [clickOnItemImg]="true"
            slotSize="middle-small"
            (clickEmit)="hero.items[i] ? openItemDescriptionDialog(hero.items[i]) : null">
          </app-item-slot>
        }
      </div>
    </div>
  }

  <app-achievements
    class="achievements"
    [hero]="hero"
    [account]="account">
  </app-achievements>
}
