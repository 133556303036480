export enum ACHIEVEMENT_TYPE {
  GOOD = 'good',
  EVIL = 'evil',
  EXPERIENCE = 'experience',
  STUPID = 'stupid',
  GOAL = 'goal',
}

export class AchievementModel {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly type: ACHIEVEMENT_TYPE;
  readonly minValue: number;
  readonly imageUrl?: string;
  isUnread?: boolean = false;

  constructor(data: Partial<AchievementModel> = {}) {
    Object.assign(this, data);
  }
}
