import { TranslateService } from '@ngx-translate/core';
import { DropdownItemModel } from '@shared/components/dropdown/model/dropdown-item.model';
import { ATTRIBUTE_TO_ICON, ATTRIBUTES } from '@shared/constants/attributes.constant';

export enum FILTER_TYPE {
  COMMON = 'COMMON',
  MAGIC_ATTACK = 'MAGIC_ATTACK',
  SKILL_BUFF_CASTER = 'SKILL_BUFF_CASTER',
  IMPACT = 'IMPACT',
  REQUIRED = 'REQUIRED',
  HERO_CLASS = 'HERO_CLASS',
  HERO_STATS = 'HERO_STATS',
}

export enum FILTER_ORDER_BY_TYPE {
  COMMON = 0,
  MAGIC_ATTACK = 1,
  SKILL_BUFF_CASTER = 2,
  IMPACT = 3,
  REQUIRED = 4,
  HERO_CLASS = 5,
  HERO_STATS = 6,
}

export const FILTER_TO_ICONS = new Map<FILTER_TYPE, string>([
  [FILTER_TYPE.MAGIC_ATTACK, 'attack'],
  [FILTER_TYPE.SKILL_BUFF_CASTER, 'skill'],
  [FILTER_TYPE.IMPACT, 'use'],
  [FILTER_TYPE.HERO_CLASS, 'helmet'],
]);

export interface IAttributesChoose {
  formName: FILTER_TYPE;
  attributes: DropdownItemModel[];
  selectedAttribute: DropdownItemModel | null;
  icon: string;
  placeholder: string;
}

export interface IFilter {
  id: string;
  filterType: FILTER_TYPE;
  filterTypeIcon?: string;
  attribute: DropdownItemModel | null;
  and: string | boolean;
  value: string;
  order: number;
}

export interface IFilterTemplate {
  [name: string]: {
    filters: IFilter[];
    general: {};
  };
}

export function getMagicAttackOptions(translateService: TranslateService) {
  const result: DropdownItemModel[] = [];

  result.push({
    id: 'manaConsumption',
    label: translateService.instant('item-description-dialog.mana-cost'),
    prefixIconPath: '/assets/images/ui/icons/attributes/MANA_COST.png',
    iconHeight: '40px',
  });

  result.push({
    id: 'minDmg',
    label: translateService.instant('attributes-names.damage-min'),
    prefixIconPath: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DAMAGE_MIN) ?? '',
    iconHeight: '40px',
  });

  result.push({
    id: 'maxDmg',
    label: translateService.instant('attributes-names.damage-max'),
    prefixIconPath: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DAMAGE_MAX) ?? '',
    iconHeight: '40px',
  });

  // result.push({
  //   id: 'attackType',
  //   label: 'Attack type',
  //   prefixIconPath: '/assets/images/ui/icons/item-props/attack.png',
  //   iconHeight: '40px',
  // });

  result.push({
    id: 'attributesFactorDexterity',
    label: translateService.instant('item-description-dialog.add-to-dmg') + ' % of DEX',
    prefixIconPath: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DEXTERITY) ?? 'unknown',
    iconHeight: '40px',
  });

  result.push({
    id: 'attributesFactorEnergy',
    label: translateService.instant('item-description-dialog.add-to-dmg') + ' % of ENG',
    prefixIconPath: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.ENERGY) ?? 'unknown',
    iconHeight: '40px',
  });

  result.push({
    id: 'attributesFactorStrength',
    label: translateService.instant('item-description-dialog.add-to-dmg') + ' % of STR',
    prefixIconPath: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.STRENGTH) ?? 'unknown',
    iconHeight: '40px',
  });

  result.push({
    id: 'attributesFactorVitality',
    label: translateService.instant('item-description-dialog.add-to-dmg') + ' % of VIT',
    prefixIconPath: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.VITALITY) ?? 'unknown',
    iconHeight: '40px',
  });

  return result;
}
