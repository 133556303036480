import { animate, style, transition, trigger } from '@angular/animations';
import { animationDurationDefault } from '@shared/constants/animation/animation.constants';
import { easeInOutCubic } from '@shared/constants/animation/easing/ease-in-out-cubic';

const timings = `${animationDurationDefault} ${easeInOutCubic}`;

export const openCloseByWidth = trigger('openCloseByWidth', [
  transition(':leave', [style({ width: '*', opacity: 1 }), animate(timings, style({ width: 0, opacity: 0 }))]),
  transition(':enter', [style({ width: 0, opacity: 0 }), animate(timings, style({ width: '*', opacity: 1 }))]),
]);
