import { Injectable } from '@angular/core';
import { UserController__factory } from '@generated/abi';
import { TransactionDataModel } from '@models/transaction-data.model';
import { ErrorService } from '@services/error.service';
import { FeesExtension, ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { RelayService } from '@services/onchain/relay.service';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { adjustGasLimit } from '@shared/utils';
import { catchError, from, retry } from 'rxjs';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { switchMap } from 'rxjs/internal/operators/switchMap';

@Injectable({
  providedIn: 'root',
})
export class UserControllerService extends FeesExtension {
  constructor(
    private providerService: ProviderService,
    private errorService: ErrorService,
    private relayService: RelayService,
  ) {
    super();
  }

  // --- FACTORIES ---

  private createUserController(chainId: number, signer?: string) {
    return UserController__factory.connect(
      GET_CORE_ADDRESSES(chainId).userController,
      this.providerService.getProviderForRead(signer),
    );
  }

  // --- VIEWS ---

  userAccountName$(chainId: number, account: string) {
    return from(this.createUserController(chainId).userAccountName(account)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  userAvatar$(chainId: number, account: string) {
    return from(this.createUserController(chainId).userAvatar(account)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  nameToUserAccount$(chainId: number, name: string) {
    return from(this.createUserController(chainId).nameToUserAccount(name)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  userActivity$(chainId: number, account: string) {
    return from(this.createUserController(chainId).userActivity(account)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  counterLootBoxes$(chainId: number, account: string) {
    return from(this.createUserController(chainId).counterLootBoxes(account)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  lootBoxConfig$(chainId: number, lootBoxKind: number) {
    return from(this.createUserController(chainId).lootBoxConfig(lootBoxKind)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  feeRenaming$(chainId: number) {
    return from(this.createUserController(chainId).feeRenaming()).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  // --- CALLS ---

  setUserName$(chainId: number, account: string, name: string) {
    return from(this.createUserController(chainId, account).setUserName.estimateGas(name)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'SetUserName',
            subgraphWaitUserData: false,
            showLoadingScreen: true,
            txPopulated: this.createUserController(chainId, account).setUserName.populateTransaction(name),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  setUserAvatar$(chainId: number, account: string, value: string) {
    return from(this.createUserController(chainId, account).setUserAvatar.estimateGas(value)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'setUserAvatar',
            subgraphWaitUserData: false,
            isNeedUpdateBalances: true,
            showLoadingScreen: true,
            txPopulated: this.createUserController(chainId, account).setUserAvatar.populateTransaction(value),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  openLootBox$(chainId: number, account: string, lootBoxKind: number) {
    return from(this.createUserController(chainId, account).openLootBox.estimateGas(lootBoxKind)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'OpenLootBox',
            subgraphWaitUserData: false,
            showLoadingScreen: true,
            txPopulated: this.createUserController(chainId, account).openLootBox.populateTransaction(lootBoxKind),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }
}
