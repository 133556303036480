import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-left-right-frame',
  standalone: true,
  templateUrl: './left-right-frame.component.html',
  styleUrls: ['./left-right-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column',
  },
})
export class LeftRightFrameComponent {
  @Input() isArrows = false;
  @Input() isMinimal = false;
  @Input() isLeftDisabled = false;
  @Input() isRightDisabled = false;
  @Input() isShowTopBottom = false;
  @Input() isTopDisabled = false;
  @Input() isBottomDisabled = false;
  @Input() isShowDivider = true;

  @Output() leftClick = new EventEmitter<void>();
  @Output() rightClick = new EventEmitter<void>();
  @Output() topClick = new EventEmitter<void>();
  @Output() bottomClick = new EventEmitter<void>();

  @Input() size: string;
  @HostBinding('class')
  get hostSizeClass() {
    return this.size ? 'app-frame-' + this.size : '';
  }
}
