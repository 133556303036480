<app-dialog-title
  title="Templates"
  [canClose]="true"
  (closeDialog)="close()">
</app-dialog-title>

<div class="templates g-flex-column__item g-flex-column g-flex--align-center g-scroll-y">
  @for (template of templates | keyvalue; track template) {
    <div
      class="templates__item g-flex g-flex--align-center g-flex--space-between"
      (click)="applyTemplate(template.key)">
      <div class="filter__name">
        {{ template.key }}
      </div>

      <button
        class="templates__trash app-btn-trash"
        (click)="removeTemplate(template.key)"></button>
    </div>
  }
</div>

<div class="controls g-flex-column__item-fixed g-flex-column g-flex--align-center">
  <input
    class="name app-input app-input--huge app-btn_font-size"
    placeholder="template name"
    [formControl]="nameControl" />

  <button
    class="btn-save app-btn-blue-2"
    [disabled]="nameControl.invalid"
    (click)="onSave()">
    Save
  </button>
</div>
