<app-dialog-title
  class="g-flex-column__item-fixed"
  title="{{ 'repair-result-dialog.title' | translate }}&nbsp;{{
    (data.isSuccess ? 'repair-result-dialog.successful' : 'repair-result-dialog.unsuccessful') | translate
  }}"
  (closeDialog)="close()">
</app-dialog-title>

<div class="container g-flex-column g-flex--align-center">
  <div class="title__text">
    {{
      (data.isSuccess ? 'repair-result-dialog.successful-text' : 'repair-result-dialog.unsuccessful-text') | translate
    }}
  </div>

  <button
    class="btn-apply app-btn-blue-3"
    appButtonClick
    (hostClick)="close()">
    {{ 'repair-result-dialog.buttons.close' | translate }}
  </button>
</div>
