import { Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { AttributesModel } from '@models/attributes.model';
import { TranslateModule } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { DestroyService } from '@services/destroy.service';
import { SoundService } from '@services/sound.service';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemSlotComponent } from '@shared/components/item-slot/item-slot.component';
import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTE_TO_NAME,
  ATTRIBUTES,
} from '@shared/constants/attributes.constant';
import { HERO_CORE_STATS_META } from '@shared/constants/hero-levels.constant';
import { ItemActionType } from '@shared/constants/inventory.constants';
import { ITEM_CLASS, ITEM_SLOT, ITEM_SLOT_NAMES } from '@shared/constants/items.constant';
import { getValueByKey } from '@shared/utils';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs';

import { HeroSlotDialogData } from '../hero-slot-dialog/hero-slot-dialog-data';
import { HeroSlotDialogComponent } from '../hero-slot-dialog/hero-slot-dialog.component';

@Component({
  selector: 'app-inventory-equipped',
  standalone: true,
  templateUrl: './inventory-equipped.component.html',
  styleUrls: ['./inventory-equipped.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'g-flex g-flex--align-stretch',
  },
  providers: [DestroyService],
  imports: [ItemSlotComponent, ButtonClickDirective, TranslateModule],
})
export class InventoryEquippedComponent implements OnDestroy {
  @Input() set hero(hero: HeroEntity) {
    if (hero) {
      this.innerHero = hero;

      this.initHeroLevels(hero);
    }
  }

  innerHero: HeroEntity;

  @Input() equippedItemBySlots = new Map<ITEM_SLOT, ItemEntity>();
  @Input() availableItemsBySlots = new Map<ITEM_SLOT, ItemEntity[]>();
  @Input() isStaked = false;
  @Output() equipEmit = new EventEmitter<{ item: ItemEntity; slot: number }>();
  @Output() takeOffEmit = new EventEmitter<{ item?: ItemEntity; slot?: number } | undefined>();
  @Output() takeOffAllEmit = new EventEmitter<undefined>();

  heroCoreStats: HeroStatItemInputInterface[] = [];
  heroAdditionalLevels: HeroStatItemInputInterface[] = [];
  heroAttributes: AttributesModel;
  ITEM_SLOT = ITEM_SLOT;
  ITEM_CLASS = ITEM_CLASS;

  private dialogRef: DialogRef<{ item: ItemEntity; equip: boolean }, HeroSlotDialogComponent> | undefined;
  private dialogRefDescription: DialogRef<ItemActionType, ItemDescriptionDialogComponent> | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private appStateService: AppStateService,
    private dialog: Dialog,
    private destroy$: DestroyService,
    private logger: NGXLogger,
    private soundService: SoundService,
  ) {}

  ngOnDestroy(): void {
    this.dialogRef?.close();
    this.dialogRefDescription?.close();
  }

  isFreeHero() {
    return this.innerHero?.meta?.feeToken?.amount === '0';
  }

  isSlotDisabled(slot: ITEM_SLOT): boolean {
    if (this.isFreeHero() || this.isStaked) {
      return true;
    }

    if (slot === ITEM_SLOT.RIGHT_HAND) {
      return (
        !this.equippedItemBySlots.has(slot) &&
        !this.equippedItemBySlots.has(ITEM_SLOT.TWO_HAND) &&
        (this.availableItemsBySlots.get(slot)?.length ?? 0) === 0 &&
        (this.availableItemsBySlots.get(ITEM_SLOT.TWO_HAND)?.length ?? 0) === 0
      );
    }

    if (slot === ITEM_SLOT.LEFT_HAND && this.equippedItemBySlots.has(ITEM_SLOT.TWO_HAND)) {
      return true;
    }

    return !this.equippedItemBySlots.has(slot) && (this.availableItemsBySlots.get(slot)?.length ?? 0) === 0;
  }

  isSlotCanBeEquipped(slot: ITEM_SLOT): boolean {
    if (slot === ITEM_SLOT.RIGHT_HAND) {
      return (
        (!this.equippedItemBySlots.has(slot) && (this.availableItemsBySlots.get(slot)?.length ?? 0) !== 0) ||
        (!this.equippedItemBySlots.has(ITEM_SLOT.TWO_HAND) &&
          (this.availableItemsBySlots.get(ITEM_SLOT.TWO_HAND)?.length ?? 0) !== 0)
      );
    } else {
      return !this.equippedItemBySlots.has(slot) && (this.availableItemsBySlots.get(slot)?.length ?? 0) !== 0;
    }
  }

  onItemSlotClick(event: MouseEvent, slot: ITEM_SLOT): void {
    this.logger.trace(
      'onItemSlotClick',
      this.equippedItemBySlots.has(slot),
      this.availableItemsBySlots.get(slot)?.length,
      slot,
    );
    if (this.equippedItemOn(slot) === undefined) {
      if (!this.innerHero.dungeon) {
        this.logger.trace('Open for equip items');
        const data: HeroSlotDialogData = {
          slot,
          equippedItem: this.equippedItemBySlots.get(slot),
          availableItems: this.availableItemsBySlots.get(slot),
          hero: this.innerHero,
          isCanUse: true,
        };
        this.dialogRef = this.dialog.open(HeroSlotDialogComponent, {
          panelClass: 'app-overlay-pane',
          data,
        });

        this.dialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(result => {
          if (result) {
            this.equipEmit.emit({ item: result.item, slot: slot });
          }
        });
      }
    } else {
      this.logger.trace('Open for take off');
      const item = this.equippedItemOn(slot);
      if (item) {
        this.dialogRefDescription = this.dialog.open(ItemDescriptionDialogComponent, {
          panelClass: 'app-overlay-pane-full-width',
          data: { item, slot, hero: this.innerHero },
        });

        this.dialogRefDescription.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data?.takeOff) {
            this.takeOffEmit.emit({
              item: data.takeOff.item,
              slot: data.takeOff.equippedSlot,
            });
          }
        });
      }
    }
  }

  takeOffAll() {
    this.soundService.play({ key: 'get_naked' });

    this.takeOffAllEmit.emit();
  }

  private initHeroLevels(hero: HeroEntity): void {
    if (!this.innerHero) {
      this.heroCoreStats = [];
      this.heroAdditionalLevels = [];
      return;
    }

    this.heroAttributes = AttributesModel.fromStringArray(hero.attributes);

    this.heroCoreStats = HERO_CORE_STATS_META.map((stat): HeroStatItemInputInterface => {
      return {
        ...stat,
        text: getValueByKey<AttributesModel>(this.heroAttributes, stat.id),
        attributeIndex: stat.index ?? 0,
      };
    });

    this.heroAdditionalLevels = [
      {
        id: 'lightningResistance',
        name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
        icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
        text: this.heroAttributes.lightningResistance,
        description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
        attributeIndex: ATTRIBUTES.LIGHTNING_RESISTANCE,
      },
      {
        id: 'fireResistance',
        name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
        icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
        text: this.heroAttributes.fireResistance,
        description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
        attributeIndex: ATTRIBUTES.FIRE_RESISTANCE,
      },
      {
        id: 'coldResistance',
        name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
        icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
        text: this.heroAttributes.coldResistance,
        description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
        attributeIndex: ATTRIBUTES.COLD_RESISTANCE,
      },
    ];
  }

  equippedItemOn(slot: ITEM_SLOT): ItemEntity | undefined {
    if (slot === ITEM_SLOT.RIGHT_HAND && this.equippedItemBySlots.has(ITEM_SLOT.TWO_HAND)) {
      return this.equippedItemBySlots.get(ITEM_SLOT.TWO_HAND);
    } else {
      return this.equippedItemBySlots.get(slot);
    }
  }

  getSlotIcon(slot: ITEM_SLOT): string {
    return `url(assets/images/ui/frames/item-icons/${ITEM_SLOT_NAMES.get(slot)?.toLowerCase().replace(' ', '-')}.avif)`;
  }
}
