import { MediatorAction } from '@shared/types/action.interface';

export namespace ErrorActions {
  export class Toggle implements MediatorAction {
    constructor(
      public isShow: boolean,
      public message: string,
    ) {}
  }
}
