@if (item.isAddEnemyIcon) {
  <img
    class="hero-stat-item__enemy-icon shadow__icon"
    [class.hero-stat-item__enemy-icon--compact]="isCompact"
    src="/assets/images/ui/icons/item-props/enemy-target.png"
    alt="item image" />
}

<div
  class="g-flex g-flex--align-center g-flex--space-between"
  [class.hero-stat-item__vertical]="isVertical">
  <img
    class="hero-stat-item__icon shadow__icon"
    [class.hero-stat-item__icon--space]="!isCompact && !isInline && !isVertical"
    [class.hero-stat-item__icon_row]="isRowLayout"
    [class.hero-stat-item__icon--small]="isSmallFont"
    [class.g-flex__item-fixed]="isRowLayout && !isInline"
    [style.width]="iconCustomWidth > 0 ? iconCustomWidth + 'px' : null"
    [src]="isDisabled ? item.iconDisabled : item.icon"
    alt=""
    (click)="openDialog()" />

  @if (isVertical) {
    <div
      class="hero-stat-item__value text-shadow"
      [class.hero-stat-item__value--space]="!isCompact && !isInline && !isVertical"
      [class.hero-stat-item__value--space2]="isInline && !isVertical"
      [class.hero-stat-item__value--plus]="useColors && (+item.text >= 0 || Number.isNaN(+item.text))"
      [class.hero-stat-item__value--minus]="useColors && +item.text < 0"
      [class.app-btn_font-size]="!isSmallFont && !isMediumFont"
      [class.app-btn-tiny_font-size]="isSmallFont"
      [class.app-btn-small_font-size]="isMediumFont"
      [class.hero-stat-item__value--compact]="isCompact">
      {{ valueText() }}
    </div>
  }
</div>

<div
  class="name g-flex g-flex--align-center"
  [class.name__row]="isRowLayout"
  [class.name__column]="!isRowLayout"
  [class.name__column--small]="isSmallFont"
  [class.g-flex--space-between]="!isInline"
  [class.g-flex__item]="isRowLayout && !isCompact && !isInline"
  [class.g-flex--reverse]="isInline">
  @if (!isCompact) {
    @if (item.chance) {
      <div class="hero-stat-item__chance app-btn_font-size text-shadow">{{ item.chance }}%</div>
    }

    <div class="hero-stat-item__title app-btn_font-size text-shadow">
      {{ item.name | translate }}
    </div>
  }

  @if (!isVertical) {
    <div
      class="hero-stat-item__value text-shadow"
      [class.hero-stat-item__value--space]="!isCompact && !isInline"
      [class.hero-stat-item__value--space2]="isInline"
      [class.hero-stat-item__value--plus]="useColors && (+item.text >= 0 || Number.isNaN(+item.text))"
      [class.hero-stat-item__value--minus]="useColors && +item.text < 0"
      [class.app-btn_font-size]="!isSmallFont && !isMediumFont"
      [class.app-btn-tiny_font-size]="isSmallFont"
      [class.app-btn-small_font-size]="isMediumFont"
      [class.hero-stat-item__value--compact]="isCompact">
      {{ valueText() }}
    </div>
  }
</div>
