import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DestroyService } from '@services/destroy.service';
import { ProviderService } from '@services/provider.service';
import { StorageService } from '@services/storage.service';
import { Web3authService } from '@services/web3auth.service';
import { CheckPasswordComponent } from '@shared/components/check-password/check-password.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { strongPasswordRegExp } from '@shared/utils';
import { UserInfo } from '@web3auth/mpc-core-kit';
import { catchError, finalize, switchMap, takeUntil } from 'rxjs';

export const STORAGE_SPEEDUP_HIDE = 'TETU_GAME_SPEEDUP_HIDE';

@Component({
  selector: 'app-store-private-key-dialog',
  standalone: true,
  templateUrl: './store-private-key-dialog.html',
  styleUrls: ['./store-private-key-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, ReactiveFormsModule, CheckPasswordComponent, LoadingSmallComponent],
})
export class StorePrivateKeyDialogComponent implements OnInit {
  customWidth = 990;

  @HostBinding('style.width.px')
  private get modalWidth() {
    return this.customWidth;
  }

  userInfo?: UserInfo;
  oldPasswordControl: FormControl<string | null> = new FormControl('', {
    validators: [Validators.required, Validators.pattern(strongPasswordRegExp)],
  });
  newPasswordControl: FormControl<string | null> = new FormControl('', {
    validators: [Validators.required, Validators.pattern(strongPasswordRegExp)],
  });
  web3authMsg = '';
  speedUpCalled = false;
  isWeb3authActionInProgress = false;
  isDecodePasswordInProgress = false;

  isNeverAskAgainControl: FormControl<CHECKBOX_STATE | null> = new FormControl<CHECKBOX_STATE>(CHECKBOX_STATE.NONE);

  protected readonly strongPasswordRegExp = strongPasswordRegExp;

  closurePK(pk: string) {
    const _pk = pk;
    return function () {
      return _pk;
    };
  }

  private privateKey?: () => string;

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      customWidth: number;
    } = {
      customWidth: 990,
    },
    private dialogRef: DialogRef<null, StorePrivateKeyDialogComponent>,
    private destroy$: DestroyService,
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private web3authService: Web3authService,
    private storageService: StorageService,
  ) {
    this.customWidth = data.customWidth;
  }

  ngOnInit() {
    this.userInfo = this.web3authService.getUserInfo();

    if (this.storageService.get(STORAGE_SPEEDUP_HIDE)) {
      const isHideSpeedup = JSON.parse(this.storageService.get(STORAGE_SPEEDUP_HIDE));

      this.isNeverAskAgainControl.setValue(isHideSpeedup);
    }

    this.isNeverAskAgainControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(
        STORAGE_SPEEDUP_HIDE,
        JSON.stringify(this.isNeverAskAgainControl.value === CHECKBOX_STATE.CHECKED),
      );
    });
  }

  isPrivateKeyExtracted() {
    return !!this.privateKey;
  }

  close(): void {
    this.dialogRef.close(null);
  }

  ///////////////////////////////// WEB 3 AUTH /////////////////////////////////

  isPasswordExist() {
    return this.web3authService.isPasswordEnabled();
  }

  // if setup new password initially no need to provide old
  upsertPassword() {
    this.web3authMsg = '';
    this.isWeb3authActionInProgress = true;
    this.newPasswordControl.disable();
    this.oldPasswordControl.disable();
    this.changeDetectorRef.detectChanges();

    this.web3authService
      .upsertPassword$(this.newPasswordControl.value || '', this.oldPasswordControl.value || '')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        console.log('Password updated successfully');

        this.web3authMsg = 'Password updated successfully';
        this.isWeb3authActionInProgress = false;
        this.newPasswordControl.enable();
        this.oldPasswordControl.enable();

        this.exportPrivateKey();

        // this.newPasswordControl.reset();
        // this.oldPasswordControl.reset();

        this.changeDetectorRef.detectChanges();
      });
  }

  exportPrivateKey() {
    this.web3authMsg = '';
    this.isWeb3authActionInProgress = true;
    this.isDecodePasswordInProgress = true;
    this.newPasswordControl.disable();
    this.oldPasswordControl.disable();
    this.changeDetectorRef.detectChanges();

    this.web3authService
      .recoverWithPassword$(this.oldPasswordControl.value || '')
      .pipe(
        switchMap(() => this.web3authService.exportPrivateKey()),
        catchError(e => {
          if (e.message === 'Invalid answer') {
            this.web3authMsg = 'Invalid password.';
          } else {
            this.web3authMsg = 'Something went wrong.';
          }
          throw e;
        }),
        finalize(() => {
          this.newPasswordControl.reset();
          this.oldPasswordControl.reset();
          this.isWeb3authActionInProgress = false;
          this.isDecodePasswordInProgress = false;
          this.newPasswordControl.enable();
          this.oldPasswordControl.enable();
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(pk => {
        this.privateKey = this.closurePK(pk);

        // speedup immediately, no need to second click
        this.speedUpSigning();
      });
  }

  speedUpSigning() {
    this.speedUpCalled = true;
    this.changeDetectorRef.detectChanges();
    this.providerService.setupPrivateKey(this.privateKey ? this.privateKey() : '');
    this.close();
  }
}
