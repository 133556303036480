import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AchievementModel } from '@models/achievement.model';

@Component({
  selector: 'app-achievement',
  standalone: true,
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementComponent {
  @Input() item: AchievementModel;
}
