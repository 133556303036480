import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { ItemEntityExtendedType } from '@models/item-entity-extended.type';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { ItemControllerService } from '@services/onchain/item-controller.service';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemSlotComponent } from '@shared/components/item-slot/item-slot.component';
import { ItemActionType } from '@shared/constants/inventory.constants';
import { finalize, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bottles-dialog',
  standalone: true,
  templateUrl: './bottles-dialog.component.html',
  styleUrls: ['./bottles-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, ItemSlotComponent, TranslateModule],
})
export class BottlesDialogComponent {
  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      items: ItemEntity[];
      chainId: number;
      account: string;
      heroToken: string;
      heroTokenId: number;
      hero: HeroEntity;
    },
    private dialogRef: DialogRef<null, BottlesDialogComponent>,
    private dialog: Dialog,
    private changeDetectorRef: ChangeDetectorRef,
    private itemControllerService: ItemControllerService,
    private destroy$: DestroyService,
  ) {}

  close(): void {
    this.dialogRef.close(null);
  }

  openItemDescriptionDialog(item?: ItemEntityExtendedType): void {
    if (!item) {
      return;
    }

    const dialogRef: DialogRef<ItemActionType, ItemDescriptionDialogComponent> | undefined = this.dialog.open(
      ItemDescriptionDialogComponent,
      {
        panelClass: 'app-overlay-pane',
        data: { item, isShowButtons: true, isCanUse: true, slot: 0, chainId: this.data.chainId, hero: this.data.hero },
      },
    );

    dialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data?.useItem) {
        this.use(data.useItem);
      }
    });
  }

  use(item: ItemEntity) {
    this.dialogRef.close(null);

    this.changeDetectorRef.markForCheck();
    this.itemControllerService
      .use$(this.data.account, this.data.chainId, item.meta.id, item.itemId, this.data.heroToken, this.data.heroTokenId)
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }
}
