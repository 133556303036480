import { ISound, SOUND_TYPES } from '@shared/types/sound-types';

export const SOUNDS_COMMON: { [key: string]: ISound[] } = {
  main: [
    {
      id: 'main-0',
      type: SOUND_TYPES.MUSIC,
      file: 'bg/main/main-loop-0.mp3',
    },
  ],
  story: [
    {
      id: 'story-0',
      type: SOUND_TYPES.MUSIC,
      file: 'bg/story/story-loop-0.mp3',
    },
    {
      id: 'story-1',
      type: SOUND_TYPES.MUSIC,
      file: 'bg/story/story-loop-1.mp3',
    },
    {
      id: 'story-2',
      type: SOUND_TYPES.MUSIC,
      file: 'bg/story/story-loop-2.mp3',
    },
  ],
  fight: [
    {
      id: 'fight-0',
      type: SOUND_TYPES.MUSIC,
      name: 'Fight for your right',
      file: 'bg/fight/fight-loop-0.mp3',
    },
    {
      id: 'fight-1',
      type: SOUND_TYPES.MUSIC,
      name: 'Fight for your honor',
      file: 'bg/fight/fight-loop-1.mp3',
    },
    {
      id: 'fight-2',
      type: SOUND_TYPES.MUSIC,
      name: 'Dark Fantazy',
      file: 'bg/fight/fight-loop-2.mp3',
    },
  ],
  boss: [
    {
      id: 'boss-0',
      type: SOUND_TYPES.MUSIC,
      file: 'bg/boss/boss-loop-0.mp3',
    },
  ],
  rare: [
    {
      id: 'rare-0',
      type: SOUND_TYPES.MUSIC,
      file: 'bg/fight/0.mp3',
    },
  ],
  attack: [
    {
      id: 'attack-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/attack.mp3',
    },
  ],
  finished: [
    {
      id: 'finished-0',
      type: SOUND_TYPES.EFFECT,
      file: 'finished.mp3',
    },
  ],
  battle_history: [
    {
      id: 'battle_history-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/battle_history.mp3',
    },
  ],
  battle: [
    {
      id: 'battle-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/battle.mp3',
    },
  ],
  keyboard: [
    {
      id: 'keyboard-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/keyboard_4.mp3',
    },
  ],
  click: [
    {
      id: 'click-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/click_2.mp3',
    },
  ],
  enter_game: [
    {
      id: 'enter_game-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/enter_game.mp3',
    },
  ],
  faq_button: [
    {
      id: 'faq_button-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/faq_button.mp3',
    },
  ],
  map: [
    {
      id: 'map-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/map.mp3',
    },
  ],
  new_adventure: [
    {
      id: 'new_adventure-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/new_adventure.mp3',
    },
  ],
  potions: [
    {
      id: 'potions-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/potions.mp3',
    },
  ],
  reinforcement: [
    {
      id: 'reinforcement-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/reinforcement.mp3',
    },
  ],
  search_for_new_adventures: [
    {
      id: 'search_for_new_adventures-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/search_for_new_adventures.mp3',
    },
  ],
  buy_coins: [
    {
      id: 'buy_coins-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/buy_coins.mp3',
    },
  ],
  counter: [
    {
      id: 'counter-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/counter.mp3',
    },
  ],
  error: [
    {
      id: 'error-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/error.mp3',
    },
  ],
  claim_reward: [
    {
      id: 'claim_reward-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/claim_reward.mp3',
    },
  ],
  go_to_map: [
    {
      id: 'go_to_map-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/go_to_map.mp3',
    },
  ],
  inventory: [
    {
      id: 'inventory-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/inventory.mp3',
    },
  ],
  level_up: [
    {
      id: 'level_up-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/level_up.mp3',
    },
  ],
  slot_choose: [
    {
      id: 'slot_choose-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/slot_choose.mp3',
    },
  ],
  get_naked: [
    {
      id: 'get_naked-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/get_naked.mp3',
    },
  ],
  equip: [
    {
      id: 'equip-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/equip.mp3',
    },
  ],
  repair: [
    {
      id: 'repair-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/repair.mp3',
    },
  ],
  forge: [
    {
      id: 'forge-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/forge.mp3',
    },
  ],
  forge_success: [
    {
      id: 'forge_success-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/forge_success.mp3',
    },
  ],
  forge_fail: [
    {
      id: 'forge_fail-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/forge_fail.mp3',
    },
  ],
  biome_enter: [
    {
      id: 'biome_enter-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/biome_enter.mp3',
    },
  ],
  new_dungeon: [
    {
      id: 'new_dungeon-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/new_dungeon.mp3',
    },
  ],
  dungeon_enter: [
    {
      id: 'dungeon_enter-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/dungeon_enter.mp3',
    },
  ],
  shrine_open: [
    {
      id: 'shrine_open-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/shrine_open.mp3',
    },
  ],
  shrine_end: [
    {
      id: 'shrine_end-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/shrine_end.mp3',
    },
  ],
  victory: [
    {
      id: 'victory-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/victory.mp3',
    },
  ],
  loss: [
    {
      id: 'loss-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/loss.mp3',
    },
  ],
  death: [
    {
      id: 'death-0',
      type: SOUND_TYPES.EFFECT,
      file: 'actions/death.mp3',
    },
  ],
  effect_burn: [
    {
      id: 'effect_burn-0',
      type: SOUND_TYPES.EFFECT,
      file: 'effects/burn.mp3',
    },
  ],
  effect_confuse: [
    {
      id: 'effect_confuse-0',
      type: SOUND_TYPES.EFFECT,
      file: 'effects/confuse.mp3',
    },
  ],
  effect_curse: [
    {
      id: 'effect_curse-0',
      type: SOUND_TYPES.EFFECT,
      file: 'effects/curse.mp3',
    },
  ],
  effect_freeze: [
    {
      id: 'effect_freeze-0',
      type: SOUND_TYPES.EFFECT,
      file: 'effects/freeze.mp3',
    },
  ],
  effect_poison: [
    {
      id: 'effect_poison-0',
      type: SOUND_TYPES.EFFECT,
      file: 'effects/poison.mp3',
    },
  ],
  effect_stun: [
    {
      id: 'effect_stun-0',
      type: SOUND_TYPES.EFFECT,
      file: 'effects/stun.mp3',
    },
  ],
  phrases_thrall: [
    {
      id: 'phrases_thrall-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/phrases/0.mp3',
    },
    {
      id: 'phrases_thrall-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/phrases/1.mp3',
    },
    {
      id: 'phrases_thrall-2',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/phrases/2.mp3',
    },
  ],

  attack_ghost: [
    {
      id: 'attack_ghost-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100023/attack/0.mp3',
    },
    {
      id: 'attack_ghost-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100023/attack/1.mp3',
    },
  ],
  damage_ghost: [
    {
      id: 'damage_ghost-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100023/damage/0.mp3',
    },
    {
      id: 'damage_ghost-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100023/damage/1.mp3',
    },
  ],
  miss_ghost: [
    {
      id: 'miss_ghost-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100023/miss/0.mp3',
    },
    {
      id: 'miss_ghost-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100023/miss/1.mp3',
    },
  ],

  attack_thrall: [
    {
      id: 'attack_thrall-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/attack/0.mp3',
    },
    {
      id: 'attack_thrall-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/attack/1.mp3',
    },
  ],
  damage_thrall: [
    {
      id: 'damage_thrall-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/damage/0.mp3',
    },
    {
      id: 'damage_thrall-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/damage/1.mp3',
    },
  ],
  miss_thrall: [
    {
      id: 'miss_thrall-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/miss/0.mp3',
    },
    {
      id: 'miss_thrall-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100018/miss/1.mp3',
    },
  ],

  phrases_savage: [
    {
      id: 'phrases_savage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/phrases/0.mp3',
    },
    {
      id: 'phrases_savage-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/phrases/1.mp3',
    },
  ],
  attack_savage: [
    {
      id: 'attack_savage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/attack/0.mp3',
    },
    {
      id: 'attack_savage-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/attack/1.mp3',
    },
    {
      id: 'attack_savage-2',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/attack/2.mp3',
    },
  ],
  damage_savage: [
    {
      id: 'damage_savage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/damage/0.mp3',
    },
    {
      id: 'damage_savage-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/damage/1.mp3',
    },
    {
      id: 'damage_savage-2',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/damage/2.mp3',
    },
  ],
  miss_savage: [
    {
      id: 'miss_savage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100019/miss/0.mp3',
    },
  ],

  phrases_mage: [
    {
      id: 'phrases_mage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/phrases/0.mp3',
    },
    {
      id: 'phrases_mage-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/phrases/1.mp3',
    },
    {
      id: 'phrases_mage-2',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/phrases/2.mp3',
    },
    {
      id: 'phrases_mage-3',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/phrases/3.mp3',
    },
  ],
  attack_mage: [
    {
      id: 'attack_mage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/attack/0.mp3',
    },
    {
      id: 'attack_mage-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/attack/1.mp3',
    },
  ],
  damage_mage: [
    {
      id: 'damage_mage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/damage/0.mp3',
    },
    {
      id: 'damage_mage-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/damage/1.mp3',
    },
  ],
  miss_mage: [
    {
      id: 'miss_mage-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/miss/0.mp3',
    },
    {
      id: 'miss_mage-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100020/miss/1.mp3',
    },
  ],

  phrases_assassin: [
    {
      id: 'phrases_assassin-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/phrases/0.mp3',
    },
    {
      id: 'phrases_assassin-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/phrases/1.mp3',
    },
    {
      id: 'phrases_assassin-2',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/phrases/2.mp3',
    },
  ],
  attack_assassin: [
    {
      id: 'attack_assassin-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/attack/0.mp3',
    },
    {
      id: 'attack_assassin-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/attack/1.mp3',
    },
  ],
  damage_assassin: [
    {
      id: 'damage_assassin-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/damage/0.mp3',
    },
    {
      id: 'damage_assassin-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/damage/1.mp3',
    },
  ],
  miss_assassin: [
    {
      id: 'miss_assassin-0',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/miss/0.mp3',
    },
    {
      id: 'miss_assassin-1',
      type: SOUND_TYPES.EFFECT,
      file: 'monsters/1100021/miss/1.mp3',
    },
  ],

  market: [
    {
      id: 'market-0',
      type: SOUND_TYPES.MUSIC,
      file: 'market/Bazaar_ambience_Marketplace_v3.mp3',
      name: 'Bazaar ambience Marketplace',
    },
  ],
};
