@if (size !== 'small' && hasIdText) {
  <div class="id text-shadow">ID</div>
}

<div class="value text-shadow">
  {{ itemId }}
</div>

@if (prefixIcon) {
  <div
    class="prefix-icon"
    (click)="prefixIconClick.emit()">
    {{ prefixIcon }}
  </div>
}
