export const ITEM_DESCRIPTIONS = new Map<string, string>([
  ['CONS_1', 'item-descriptions.CONS_1'],
  ['CONS_2', 'item-descriptions.CONS_2'],
  ['CONS_3', 'item-descriptions.CONS_3'],
  ['CONS_4', 'item-descriptions.CONS_4'],
  ['CONS_17', 'item-descriptions.CONS_17'],
  ['CONS_18', 'item-descriptions.CONS_18'],
  ['CONS_19', 'item-descriptions.CONS_19'],
  ['CONS_20', 'item-descriptions.CONS_20'],
  ['CONS_21', 'item-descriptions.CONS_21'],
  ['CONS_27', 'item-descriptions.CONS_27'],
  ['CONS_28', 'item-descriptions.CONS_28'],
  ['CONS_29', 'item-descriptions.CONS_29'],
  ['CONS_33', 'item-descriptions.CONS_33'],
  ['CONS_34', 'item-descriptions.CONS_34'],
  ['CONS_35', 'item-descriptions.CONS_35'],
  ['HELM_1', 'item-descriptions.HELM_1'],
  ['HELM_6', 'item-descriptions.HELM_6'],
  ['BODY_1', 'item-descriptions.BODY_1'],
  ['GLOVES_1', 'item-descriptions.GLOVES_1'],
  ['GLOVES_6', 'item-descriptions.GLOVES_6'],
  ['BELT_1', 'item-descriptions.BELT_1'],
  ['AMULET_1', 'item-descriptions.AMULET_1'],
  ['AMULET_2', 'item-descriptions.AMULET_2'],
  ['AMULET_12', 'item-descriptions.AMULET_12'],
  ['RING_1', 'item-descriptions.RING_1'],
  ['RING_2', 'item-descriptions.RING_2'],
  ['RING_9', 'item-descriptions.RING_9'],
  ['OFF_1', 'item-descriptions.OFF_1'],
  ['OFF_2', 'item-descriptions.OFF_2'],
  ['OFF_10', 'item-descriptions.OFF_10'],
  ['BOOTS_1', 'item-descriptions.BOOTS_1'],
  ['BOOTS_5', 'item-descriptions.BOOTS_5'],
  ['OHW_1', 'item-descriptions.OHW_1'],
  ['OHW_2', 'item-descriptions.OHW_2'],
  ['OHW_3', 'item-descriptions.OHW_3'],
  ['THW_1', 'item-descriptions.THW_1'],
  ['THW_2', 'item-descriptions.THW_2'],
  ['FEAR', 'item-descriptions.FEAR'],
  ['DEMON_SPEED', 'item-descriptions.DEMON_SPEED'],
  ['BRUTE_FORCE', 'item-descriptions.BRUTE_FORCE'],
  ['SAVAGE_CHARM', 'item-descriptions.SAVAGE_CHARM'],
  ['GOLDEN_EYE', 'item-descriptions.GOLDEN_EYE'],
  ['SABBAH_POISON', 'item-descriptions.SABBAH_POISON'],
  ['SPIT_IN_FACE', 'item-descriptions.SPIT_IN_FACE'],
  ['ONSLAUGHT', 'item-descriptions.ONSLAUGHT'],
  ['STRONG_BLOCK', 'item-descriptions.STRONG_BLOCK'],
  ['BEEF_PATTY', 'item-descriptions.BEEF_PATTY'],
  ['HEAT_STRIKE', 'item-descriptions.HEAT_STRIKE'],
  ['STATIC_DISCHARGE', 'item-descriptions.STATIC_DISCHARGE'],
  ['CONS_5', 'item-descriptions.CONS_5'],
  ['CONS_6', 'item-descriptions.CONS_6'],
  ['CONS_7', 'item-descriptions.CONS_7'],
  ['CONS_8', 'item-descriptions.CONS_8'],
  ['CONS_22', 'item-descriptions.CONS_22'],
  ['CONS_30', 'item-descriptions.CONS_30'],
  ['HELM_2', 'item-descriptions.HELM_2'],
  ['HELM_7', 'item-descriptions.HELM_7'],
  ['BODY_2', 'item-descriptions.BODY_2'],
  ['BODY_6', 'item-descriptions.BODY_6'],
  ['GLOVES_2', 'item-descriptions.GLOVES_2'],
  ['GLOVES_7', 'item-descriptions.GLOVES_7'],
  ['BELT_2', 'item-descriptions.BELT_2'],
  ['AMULET_3', 'item-descriptions.AMULET_3'],
  ['AMULET_4', 'item-descriptions.AMULET_4'],
  ['AMULET_9', 'item-descriptions.AMULET_9'],
  ['RING_3', 'item-descriptions.RING_3'],
  ['RING_4', 'item-descriptions.RING_4'],
  ['RING_10', 'item-descriptions.RING_10'],
  ['OFF_3', 'item-descriptions.OFF_3'],
  ['OFF_4', 'item-descriptions.OFF_4'],
  ['OFF_11', 'item-descriptions.OFF_11'],
  ['BOOTS_2', 'item-descriptions.BOOTS_2'],
  ['OHW_4', 'item-descriptions.OHW_4'],
  ['OHW_5', 'item-descriptions.OHW_5'],
  ['OHW_6', 'item-descriptions.OHW_6'],
  ['THW_3', 'item-descriptions.THW_3'],
  ['THW_4', 'item-descriptions.THW_4'],
  ['THW_5', 'item-descriptions.THW_5'],
  ['STEEL_SKIN', 'item-descriptions.STEEL_SKIN'],
  ['LIFEDRAIN', 'item-descriptions.LIFEDRAIN'],
  ['FROSTBITE', 'item-descriptions.FROSTBITE'],
  ['LIKE_A_PUSSY', 'item-descriptions.LIKE_A_PUSSY'],
  ['RAGE', 'item-descriptions.RAGE'],
  ['DEAD_STRIKE', 'item-descriptions.DEAD_STRIKE'],
  ['FIRM_HAND', 'item-descriptions.FIRM_HAND'],
  ['JUSTICE', 'item-descriptions.JUSTICE'],
  ['STEP_BACK', 'item-descriptions.STEP_BACK'],
  ['PATIENCE', 'item-descriptions.PATIENCE'],
  ['TIME_AND_EXPERIENCE', 'item-descriptions.TIME_AND_EXPERIENCE'],
  ['MELT', 'item-descriptions.MELT'],
  ['ICE_SHARD', 'item-descriptions.ICE_SHARD'],
  ['CONS_9', 'item-descriptions.CONS_9'],
  ['CONS_10', 'item-descriptions.CONS_10'],
  ['CONS_11', 'item-descriptions.CONS_11'],
  ['CONS_12', 'item-descriptions.CONS_12'],
  ['CONS_23', 'item-descriptions.CONS_23'],
  ['CONS_24', 'item-descriptions.CONS_24'],
  ['CONS_31', 'item-descriptions.CONS_31'],
  ['HELM_3', 'item-descriptions.HELM_3'],
  ['HELM_8', 'item-descriptions.HELM_8'],
  ['BODY_3', 'item-descriptions.BODY_3'],
  ['BODY_7', 'item-descriptions.BODY_7'],
  ['GLOVES_3', 'item-descriptions.GLOVES_3'],
  ['GLOVES_8', 'item-descriptions.GLOVES_8'],
  ['BELT_3', 'item-descriptions.BELT_3'],
  ['AMULET_5', 'item-descriptions.AMULET_5'],
  ['AMULET_6', 'item-descriptions.AMULET_6'],
  ['AMULET_10', 'item-descriptions.AMULET_10'],
  ['AMULET_11', 'item-descriptions.AMULET_11'],
  ['RING_5', 'item-descriptions.RING_5'],
  ['RING_6', 'item-descriptions.RING_6'],
  ['OFF_5', 'item-descriptions.OFF_5'],
  ['OFF_6', 'item-descriptions.OFF_6'],
  ['BOOTS_3', 'item-descriptions.BOOTS_3'],
  ['BOOTS_6', 'item-descriptions.BOOTS_6'],
  ['OHW_7', 'item-descriptions.OHW_7'],
  ['OHW_8', 'item-descriptions.OHW_8'],
  ['OHW_9', 'item-descriptions.OHW_9'],
  ['OHW_12', 'item-descriptions.OHW_12'],
  ['OHW_13', 'item-descriptions.OHW_13'],
  ['OHW_14', 'item-descriptions.OHW_14'],
  ['THW_6', 'item-descriptions.THW_6'],
  ['THW_7', 'item-descriptions.THW_7'],
  ['THW_10', 'item-descriptions.THW_10'],
  ['PARALYZE', 'item-descriptions.PARALYZE'],
  ['GUIDING_LIGHT', 'item-descriptions.GUIDING_LIGHT'],
  ['GUT_PUNCH', 'item-descriptions.GUT_PUNCH'],
  ['LAST_DANCE', 'item-descriptions.LAST_DANCE'],
  ['I_SURVIVE', 'item-descriptions.I_SURVIVE'],
  ['ARMOR_OF_FAITH', 'item-descriptions.ARMOR_OF_FAITH'],
  ['THUNDERBOLT', 'item-descriptions.THUNDERBOLT'],
  ['HELM_4', 'item-descriptions.HELM_4'],
  ['HELM_5', 'item-descriptions.HELM_5'],
  ['BODY_4', 'item-descriptions.BODY_4'],
  ['BODY_5', 'item-descriptions.BODY_5'],
  ['GLOVES_4', 'item-descriptions.GLOVES_4'],
  ['GLOVES_5', 'item-descriptions.GLOVES_5'],
  ['BELT_4', 'item-descriptions.BELT_4'],
  ['AMULET_7', 'item-descriptions.AMULET_7'],
  ['AMULET_8', 'item-descriptions.AMULET_8'],
  ['RING_7', 'item-descriptions.RING_7'],
  ['RING_8', 'item-descriptions.RING_8'],
  ['OFF_7', 'item-descriptions.OFF_7'],
  ['OFF_8', 'item-descriptions.OFF_8'],
  ['OFF_9', 'item-descriptions.OFF_9'],
  ['BOOTS_4', 'item-descriptions.BOOTS_4'],
  ['BOOTS_7', 'item-descriptions.BOOTS_7'],
  ['OHW_10', 'item-descriptions.OHW_10'],
  ['OHW_11', 'item-descriptions.OHW_11'],
  ['THW_8', 'item-descriptions.THW_8'],
  ['THW_9', 'item-descriptions.THW_9'],
  ['CONS_13', 'item-descriptions.CONS_13'],
  ['CONS_14', 'item-descriptions.CONS_14'],
  ['CONS_15', 'item-descriptions.CONS_15'],
  ['CONS_16', 'item-descriptions.CONS_16'],
  ['CONS_32', 'item-descriptions.CONS_32'],
  ['REFLECTION', 'item-descriptions.REFLECTION'],
  ['SCARLET_BRANCH_ADEPT', 'item-descriptions.SCARLET_BRANCH_ADEPT'],
  ['ANATHEMA', 'item-descriptions.ANATHEMA'],
  ['GAINTS_BLOOD', 'item-descriptions.GAINTS_BLOOD'],
  ['BACKSTAB', 'item-descriptions.BACKSTAB'],
  ['VETERAN_CONFIDENCE', 'item-descriptions.VETERAN_CONFIDENCE'],
  ['LIVE_FORTRESS', 'item-descriptions.LIVE_FORTRESS'],
  ['COLD_SHOULDER', 'item-descriptions.COLD_SHOULDER'],
  ['ERASE', 'item-descriptions.ERASE'],
  ['HELM_9', 'item-descriptions.HELM_9'],
  ['BELT_5', 'item-descriptions.BELT_5'],
  ['AMULET_13', 'item-descriptions.AMULET_13'],
  ['BODY_8', 'item-descriptions.BODY_8'],
  ['RING_11', 'item-descriptions.RING_11'],
]);
