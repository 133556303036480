export const DELEGATOR_ENCRYPT_MSG =
  'I, the owner of this wallet, hereby authorize the execution of transactions for the Sacra game using the delegated private key encoded and stored on-chain.';

const getShiftedCharCode = (charCode: number, shift: number, min: number, max: number): number => {
  return ((charCode - min + shift) % (max - min + 1)) + min;
};

export const encrypt = (text: string, shift: number): string => {
  const minCharCode: number = 32;
  const maxCharCode: number = 126;

  return text
    .split('')
    .map(char => {
      const charCode: number = char.charCodeAt(0);
      if (charCode < minCharCode || charCode > maxCharCode) {
        return char;
      }
      const shiftedCharCode: number = getShiftedCharCode(charCode, shift, minCharCode, maxCharCode);
      return String.fromCharCode(shiftedCharCode);
    })
    .join('');
};

export const decrypt = (encryptedText: string, shift: number): string => {
  const minCharCode: number = 32;
  const maxCharCode: number = 126;

  const reverseShift: number = maxCharCode - minCharCode + 1 - (shift % (maxCharCode - minCharCode + 1));

  const text = encryptedText
    .split('')
    .map(char => {
      const charCode: number = char.charCodeAt(0);
      if (charCode < minCharCode || charCode > maxCharCode) {
        return char;
      }
      const originalCharCode: number = getShiftedCharCode(charCode, reverseShift, minCharCode, maxCharCode);
      return String.fromCharCode(originalCharCode);
    })
    .join('');

  return text;
};
