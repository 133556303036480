/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  StoryController,
  StoryControllerInterface,
} from "../StoryController";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint16",
        name: "pageId",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "pageIdFromAnswerHash",
        type: "uint16",
      },
    ],
    name: "AnswerPageIdMismatch",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "storyIdFromAnswerHash",
        type: "uint16",
      },
    ],
    name: "AnswerStoryIdMismatch",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotObjectController",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NotAnswer",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "NotGovernance",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroStoryIdStoryAction",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "dungeonId",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "objectId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "nftToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "nftId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stageId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "iteration",
        type: "uint256",
      },
    ],
    name: "ItemBroken",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "adjustTokenAmountToGameToken",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "allStoryAnswers",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
    ],
    name: "allStoryAnswersLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "allStoryPages",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
    ],
    name: "allStoryPagesLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint80",
        name: "heroId",
        type: "uint80",
      },
    ],
    name: "currentHeroAnswers",
    outputs: [
      {
        internalType: "bytes32[]",
        name: "",
        type: "bytes32[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "buildHash",
        type: "uint256",
      },
    ],
    name: "finalizeStoryRegistration",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint80",
        name: "heroId",
        type: "uint80",
      },
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
    ],
    name: "heroPage",
    outputs: [
      {
        internalType: "uint16",
        name: "pageId",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
    ],
    name: "idToStory",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "isStoryAvailableForHero",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC721Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "registeredStories",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "removeStory",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "maxIterations",
        type: "uint256",
      },
    ],
    name: "removeStoryAnswersMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint256",
        name: "maxIterations",
        type: "uint256",
      },
    ],
    name: "removeStoryPagesMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "destination",
        type: "address",
      },
    ],
    name: "salvage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint16",
            name: "storyId",
            type: "uint16",
          },
          {
            internalType: "bytes32[]",
            name: "requiredCustomDataIndex",
            type: "bytes32[]",
          },
          {
            internalType: "uint64[]",
            name: "requiredCustomDataMinValue",
            type: "uint64[]",
          },
          {
            internalType: "uint64[]",
            name: "requiredCustomDataMaxValue",
            type: "uint64[]",
          },
          {
            internalType: "bool[]",
            name: "requiredCustomDataIsHero",
            type: "bool[]",
          },
          {
            internalType: "uint256",
            name: "minLevel",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "answerPageIds",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "answerHeroClasses",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerIds",
                type: "uint16[]",
              },
            ],
            internalType: "struct IStoryController.AnswersMeta",
            name: "answersMeta",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "answerResultIds",
                type: "uint8[]",
              },
              {
                internalType: "uint16[][]",
                name: "answerNextPageIds",
                type: "uint16[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerNextPageMeta",
            name: "answerNextPage",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "bool[][]",
                name: "cores",
                type: "bool[][]",
              },
              {
                internalType: "uint8[][]",
                name: "ids",
                type: "uint8[][]",
              },
              {
                internalType: "int32[][]",
                name: "values",
                type: "int32[][]",
              },
            ],
            internalType:
              "struct IStoryController.AnswerAttributeRequirementsMeta",
            name: "answerAttributeRequirements",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "address[][]",
                name: "requireItems",
                type: "address[][]",
              },
              {
                internalType: "bool[][]",
                name: "requireItemBurn",
                type: "bool[][]",
              },
              {
                internalType: "bool[][]",
                name: "requireItemEquipped",
                type: "bool[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerItemRequirementsMeta",
            name: "answerItemRequirements",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "address[][]",
                name: "requireToken",
                type: "address[][]",
              },
              {
                internalType: "uint88[][]",
                name: "requireAmount",
                type: "uint88[][]",
              },
              {
                internalType: "bool[][]",
                name: "requireTransfer",
                type: "bool[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerTokenRequirementsMeta",
            name: "answerTokenRequirements",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "uint32[]",
                name: "randomRequirements",
                type: "uint32[]",
              },
              {
                internalType: "uint32[]",
                name: "delayRequirements",
                type: "uint32[]",
              },
              {
                internalType: "bool[]",
                name: "isFinalAnswer",
                type: "bool[]",
              },
            ],
            internalType: "struct IStoryController.AnswerAttributesMeta",
            name: "answerAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "bytes32[][]",
                name: "dataIndexes",
                type: "bytes32[][]",
              },
              {
                internalType: "bool[][]",
                name: "mandatory",
                type: "bool[][]",
              },
              {
                internalType: "uint64[][]",
                name: "dataValuesMin",
                type: "uint64[][]",
              },
              {
                internalType: "uint64[][]",
                name: "dataValuesMax",
                type: "uint64[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerCustomDataMeta",
            name: "answerHeroCustomDataRequirement",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "bytes32[][]",
                name: "dataIndexes",
                type: "bytes32[][]",
              },
              {
                internalType: "bool[][]",
                name: "mandatory",
                type: "bool[][]",
              },
              {
                internalType: "uint64[][]",
                name: "dataValuesMin",
                type: "uint64[][]",
              },
              {
                internalType: "uint64[][]",
                name: "dataValuesMax",
                type: "uint64[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerCustomDataMeta",
            name: "answerGlobalCustomDataRequirement",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[][]",
                name: "slots",
                type: "uint8[][]",
              },
              {
                internalType: "uint64[][]",
                name: "chances",
                type: "uint64[][]",
              },
              {
                internalType: "bool[][]",
                name: "isStopIfBurnt",
                type: "bool[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerBurnRandomItemMeta",
            name: "answerBurnRandomItemMeta",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "nextObjPageIds",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "nextObjHeroClasses",
                type: "uint8[]",
              },
              {
                internalType: "uint32[][]",
                name: "nextObjIds",
                type: "uint32[][]",
              },
            ],
            internalType: "struct IStoryController.NextObjRewriteMeta",
            name: "nextObjRewriteMeta",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[][]",
                name: "attributeIds",
                type: "uint8[][]",
              },
              {
                internalType: "int32[][]",
                name: "attributeValues",
                type: "int32[][]",
              },
              {
                internalType: "uint32[]",
                name: "experience",
                type: "uint32[]",
              },
              {
                internalType: "int32[]",
                name: "heal",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "manaRegen",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "lifeChancesRecovered",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "damage",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "manaConsumed",
                type: "int32[]",
              },
              {
                internalType: "address[][]",
                name: "mintItems",
                type: "address[][]",
              },
              {
                internalType: "uint32[][]",
                name: "mintItemsChances",
                type: "uint32[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerResultMeta",
            name: "successInfo",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[][]",
                name: "attributeIds",
                type: "uint8[][]",
              },
              {
                internalType: "int32[][]",
                name: "attributeValues",
                type: "int32[][]",
              },
              {
                internalType: "uint32[]",
                name: "experience",
                type: "uint32[]",
              },
              {
                internalType: "int32[]",
                name: "heal",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "manaRegen",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "lifeChancesRecovered",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "damage",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "manaConsumed",
                type: "int32[]",
              },
              {
                internalType: "address[][]",
                name: "mintItems",
                type: "address[][]",
              },
              {
                internalType: "uint32[][]",
                name: "mintItemsChances",
                type: "uint32[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerResultMeta",
            name: "failInfo",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "bytes32[][]",
                name: "dataIndexes",
                type: "bytes32[][]",
              },
              {
                internalType: "int16[][]",
                name: "dataValues",
                type: "int16[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerCustomDataResultMeta",
            name: "successHeroCustomData",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "bytes32[][]",
                name: "dataIndexes",
                type: "bytes32[][]",
              },
              {
                internalType: "int16[][]",
                name: "dataValues",
                type: "int16[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerCustomDataResultMeta",
            name: "failHeroCustomData",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "bytes32[][]",
                name: "dataIndexes",
                type: "bytes32[][]",
              },
              {
                internalType: "int16[][]",
                name: "dataValues",
                type: "int16[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerCustomDataResultMeta",
            name: "successGlobalCustomData",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint16[]",
                name: "pageId",
                type: "uint16[]",
              },
              {
                internalType: "uint8[]",
                name: "heroClass",
                type: "uint8[]",
              },
              {
                internalType: "uint16[]",
                name: "answerId",
                type: "uint16[]",
              },
              {
                internalType: "bytes32[][]",
                name: "dataIndexes",
                type: "bytes32[][]",
              },
              {
                internalType: "int16[][]",
                name: "dataValues",
                type: "int16[][]",
              },
            ],
            internalType: "struct IStoryController.AnswerCustomDataResultMeta",
            name: "failGlobalCustomData",
            type: "tuple",
          },
        ],
        internalType: "struct IStoryController.StoryMetaInfo",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAllStoryFields",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "bool[][]",
            name: "cores",
            type: "bool[][]",
          },
          {
            internalType: "uint8[][]",
            name: "ids",
            type: "uint8[][]",
          },
          {
            internalType: "int32[][]",
            name: "values",
            type: "int32[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerAttributeRequirementsMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAnswerAttributeRequirements",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "uint32[]",
            name: "randomRequirements",
            type: "uint32[]",
          },
          {
            internalType: "uint32[]",
            name: "delayRequirements",
            type: "uint32[]",
          },
          {
            internalType: "bool[]",
            name: "isFinalAnswer",
            type: "bool[]",
          },
        ],
        internalType: "struct IStoryController.AnswerAttributesMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAnswerAttributes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "bytes32[][]",
            name: "dataIndexes",
            type: "bytes32[][]",
          },
          {
            internalType: "bool[][]",
            name: "mandatory",
            type: "bool[][]",
          },
          {
            internalType: "uint64[][]",
            name: "dataValuesMin",
            type: "uint64[][]",
          },
          {
            internalType: "uint64[][]",
            name: "dataValuesMax",
            type: "uint64[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerCustomDataMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAnswerGlobalCustomDataRequirementMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "bytes32[][]",
            name: "dataIndexes",
            type: "bytes32[][]",
          },
          {
            internalType: "bool[][]",
            name: "mandatory",
            type: "bool[][]",
          },
          {
            internalType: "uint64[][]",
            name: "dataValuesMin",
            type: "uint64[][]",
          },
          {
            internalType: "uint64[][]",
            name: "dataValuesMax",
            type: "uint64[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerCustomDataMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAnswerHeroCustomDataRequirementMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "address[][]",
            name: "requireItems",
            type: "address[][]",
          },
          {
            internalType: "bool[][]",
            name: "requireItemBurn",
            type: "bool[][]",
          },
          {
            internalType: "bool[][]",
            name: "requireItemEquipped",
            type: "bool[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerItemRequirementsMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAnswerItemRequirements",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "answerResultIds",
            type: "uint8[]",
          },
          {
            internalType: "uint16[][]",
            name: "answerNextPageIds",
            type: "uint16[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerNextPageMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAnswerNextPageMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "address[][]",
            name: "requireToken",
            type: "address[][]",
          },
          {
            internalType: "uint88[][]",
            name: "requireAmount",
            type: "uint88[][]",
          },
          {
            internalType: "bool[][]",
            name: "requireTransfer",
            type: "bool[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerTokenRequirementsMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setAnswerTokenRequirementsMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "uint16[]",
        name: "answerPageIds",
        type: "uint16[]",
      },
      {
        internalType: "uint8[]",
        name: "answerHeroClasses",
        type: "uint8[]",
      },
      {
        internalType: "uint16[]",
        name: "answerIds",
        type: "uint16[]",
      },
    ],
    name: "setAnswersMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[][]",
            name: "slots",
            type: "uint8[][]",
          },
          {
            internalType: "uint64[][]",
            name: "chances",
            type: "uint64[][]",
          },
          {
            internalType: "bool[][]",
            name: "isStopIfBurnt",
            type: "bool[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerBurnRandomItemMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setBurnItemsMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "bytes32[][]",
            name: "dataIndexes",
            type: "bytes32[][]",
          },
          {
            internalType: "int16[][]",
            name: "dataValues",
            type: "int16[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerCustomDataResultMeta",
        name: "meta",
        type: "tuple",
      },
      {
        internalType: "enum IStoryController.CustomDataResult",
        name: "type_",
        type: "uint8",
      },
    ],
    name: "setCustomDataResult",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[][]",
            name: "attributeIds",
            type: "uint8[][]",
          },
          {
            internalType: "int32[][]",
            name: "attributeValues",
            type: "int32[][]",
          },
          {
            internalType: "uint32[]",
            name: "experience",
            type: "uint32[]",
          },
          {
            internalType: "int32[]",
            name: "heal",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "manaRegen",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "lifeChancesRecovered",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "damage",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "manaConsumed",
            type: "int32[]",
          },
          {
            internalType: "address[][]",
            name: "mintItems",
            type: "address[][]",
          },
          {
            internalType: "uint32[][]",
            name: "mintItemsChances",
            type: "uint32[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerResultMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setFailInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "nextObjPageIds",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "nextObjHeroClasses",
            type: "uint8[]",
          },
          {
            internalType: "uint32[][]",
            name: "nextObjIds",
            type: "uint32[][]",
          },
        ],
        internalType: "struct IStoryController.NextObjRewriteMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setNextObjRewriteMeta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        internalType: "bytes32[]",
        name: "requiredCustomDataIndex",
        type: "bytes32[]",
      },
      {
        internalType: "uint64[]",
        name: "requiredCustomDataMinValue",
        type: "uint64[]",
      },
      {
        internalType: "uint64[]",
        name: "requiredCustomDataMaxValue",
        type: "uint64[]",
      },
      {
        internalType: "bool[]",
        name: "requiredCustomDataIsHero",
        type: "bool[]",
      },
      {
        internalType: "uint256",
        name: "minLevel",
        type: "uint256",
      },
    ],
    name: "setStoryCustomDataRequirements",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
      {
        components: [
          {
            internalType: "uint16[]",
            name: "pageId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[]",
            name: "heroClass",
            type: "uint8[]",
          },
          {
            internalType: "uint16[]",
            name: "answerId",
            type: "uint16[]",
          },
          {
            internalType: "uint8[][]",
            name: "attributeIds",
            type: "uint8[][]",
          },
          {
            internalType: "int32[][]",
            name: "attributeValues",
            type: "int32[][]",
          },
          {
            internalType: "uint32[]",
            name: "experience",
            type: "uint32[]",
          },
          {
            internalType: "int32[]",
            name: "heal",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "manaRegen",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "lifeChancesRecovered",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "damage",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "manaConsumed",
            type: "int32[]",
          },
          {
            internalType: "address[][]",
            name: "mintItems",
            type: "address[][]",
          },
          {
            internalType: "uint32[][]",
            name: "mintItemsChances",
            type: "uint32[][]",
          },
        ],
        internalType: "struct IStoryController.AnswerResultMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    name: "setSuccessInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "dungeonId",
        type: "uint64",
      },
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "stageId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "iteration",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "storyAction",
    outputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "kill",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "completed",
            type: "bool",
          },
          {
            internalType: "address",
            name: "heroToken",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "int32",
            name: "heal",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaRegen",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "lifeChancesRecovered",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "damage",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "manaConsumed",
            type: "int32",
          },
          {
            internalType: "uint32",
            name: "objectId",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint256",
            name: "heroTokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "iteration",
            type: "uint256",
          },
          {
            internalType: "uint32[]",
            name: "rewriteNextObject",
            type: "uint32[]",
          },
        ],
        internalType: "struct IGOC.ActionResult",
        name: "result",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "storyId",
        type: "uint16",
      },
    ],
    name: "storyBuildHash",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "objectId",
        type: "uint32",
      },
    ],
    name: "storyIds",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class StoryController__factory {
  static readonly abi = _abi;
  static createInterface(): StoryControllerInterface {
    return new Interface(_abi) as StoryControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): StoryController {
    return new Contract(address, _abi, runner) as unknown as StoryController;
  }
}
