import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { animationDurationDefault } from '@shared/constants/animation/animation.constants';
import { easeInOutCubic } from '@shared/constants/animation/easing/ease-in-out-cubic';

const timings = `${animationDurationDefault} ${easeInOutCubic}`;
const staggerDelay = 10;

/**
 * Use with incremental trigger:
 * <div [@filterXShrinkAnimation]="incrementedTrigger">
 * Increment the trigger for any changes to animate: sorting or filtering.
 */
export const filterXShrinkAnimation = trigger('filterXShrinkAnimation', [
  transition(':increment', [
    query(
      ':enter',
      [style({ opacity: 0, width: 0 }), stagger(staggerDelay, [animate(timings, style({ opacity: 1, width: '*' }))])],
      { optional: true },
    ),
    query(':leave', [stagger(staggerDelay, [animate(timings, style({ opacity: 0, width: 0 }))])], { optional: true }),
  ]),
]);
