import { Dialog } from '@angular/cdk/dialog';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemEntity } from '@generated/gql';
import { changeIPFSDomain, getActualUri } from '@helpers/data-helper';
import { AttributesModel } from '@models/attributes.model';
import { ItemEntityExtendedType } from '@models/item-entity-extended.type';
import { StatsType } from '@models/stats.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AugmentationLevelComponent } from '@shared/components/augmentation-level/augmentation-level.component';
import { BarComponent } from '@shared/components/bar/bar.component';
import { HeroStatItemComponent } from '@shared/components/hero-stat-item/hero-stat-item.component';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { ItemIdComponent } from '@shared/components/item-id/item-id.component';
import { FilterNullPipe } from '@shared/components/pipes/filter-null/filter-null.pipe';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { SkillAreaComponent } from '@shared/components/skill-area/skill-area.component';
import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTES,
  MAGIC_ATTACK_ICON,
  MAGIC_ATTACK_NAME,
} from '@shared/constants/attributes.constant';
import { getItemNameBySymbol } from '@shared/constants/game.constant';
import {
  getBackgroundColorName,
  getTextColorName,
  getTextShadowColorName,
  ITEM_CLASS,
  ITEM_RARITY_TO_NAME,
  ITEM_SLOT_BACKGROUND,
  ITEM_TYPE,
  ITEM_TYPE_ICONS,
  ITEM_TYPE_IS_COMMON,
  ITEM_TYPE_NAMES,
} from '@shared/constants/items.constant';
import { IdSlotSize } from '@shared/types/id-size.type';
import { ItemSlotBgName } from '@shared/types/item-slot-bg-name.type';
import { ItemSlotSize } from '@shared/types/item-slot-size.type';

@Component({
  selector: 'app-item-card',
  standalone: true,
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    AugmentationLevelComponent,
    ItemIdComponent,
    ScratchComponent,
    NgTemplateOutlet,
    HeroStatItemComponent,
    BarComponent,
    TranslateModule,
    SkillAreaComponent,
    FilterNullPipe,
  ],
})
export class ItemCardComponent implements OnInit {
  @Input() item: ItemEntityExtendedType;
  @Input() slotSize: ItemSlotSize = 'middle';
  @Input() idSize: IdSlotSize = 'middle';
  @Input() isShowId = true;
  @Input() itemWidth?: string;
  @Input() idWidth?: string;
  @Input() isMarketplace = false;
  @Input() isCompactView = false;
  @Input() isShowDurability = true;

  @Output() selectItem: EventEmitter<ItemEntity> = new EventEmitter<ItemEntity>();
  @Output() selectItemImg: EventEmitter<ItemEntity> = new EventEmitter<ItemEntity>();

  itemAttributes: AttributesModel;
  // itemAttributesNegative: AttributesModel;
  isCommonItem = false;
  itemName = '';
  itemRarityName: string | undefined = '';
  itemsTypeName: string | undefined = '';
  itemsTypeIcon: string | undefined = '';
  skillBuffCasterAttributes?: AttributesModel;
  // skillDebuffCasterAttributes?: AttributesModel;
  // skillDebuffTargetAttributes?: AttributesModel;
  skillBuffTargetAttributes?: AttributesModel;
  skillManaConsumption = 0;
  consumableAttributes?: AttributesModel;
  // consumableDebuffAttributes?: AttributesModel;
  consumableStats?: StatsType;
  durabilityPercentage: number;
  itemDurability: string = '';

  protected readonly ATTRIBUTE_TO_ICON = ATTRIBUTE_TO_ICON;
  protected readonly ATTRIBUTES = ATTRIBUTES;
  protected readonly MAGIC_ATTACK_ICON = MAGIC_ATTACK_ICON;
  protected readonly MAGIC_ATTACK_NAME = MAGIC_ATTACK_NAME;
  protected readonly ATTRIBUTE_TO_DESCRIPTION = ATTRIBUTE_TO_DESCRIPTION;

  constructor(
    private dialog: Dialog,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.itemName = getItemNameBySymbol(this.item.meta.name);
    this.itemAttributes = AttributesModel.fromStringArray(this.item.attributes);
    this.itemsTypeIcon = ITEM_TYPE_ICONS.get(this.item.meta.itemType);
    this.itemsTypeName = ITEM_TYPE_NAMES.get(this.item.meta.itemType);
    this.isCommonItem = ITEM_TYPE_IS_COMMON.get(this.item.meta.itemType) ?? false;

    if (this.isCommonItem) {
      this.itemRarityName = ITEM_RARITY_TO_NAME.get(this.item.rarity);
    }

    if (this.item.consumableInfo) {
      this.consumableAttributes = AttributesModel.fromStringArray(this.item.consumableInfo.attributes);
      this.consumableStats = this.item.consumableInfo.buffStats;
    }

    if (this.item.buffInfo) {
      this.skillBuffCasterAttributes = AttributesModel.fromStringArray(this.item.buffInfo.casterAttributes);
      this.skillBuffTargetAttributes = AttributesModel.fromStringArray(this.item.buffInfo.targetAttributes);
      this.skillManaConsumption = this.item.buffInfo.manaConsumption;
    }

    this.slotDurability();
  }

  changeURL(url: string) {
    return changeIPFSDomain(url);
  }

  getBackgroundClass(item): string {
    if (item.meta.itemType === ITEM_TYPE.SKILL) {
      return `app-item-slots-${this.slotSize}_aquamarine-skill${item.meta.isAttackItem ? '-attack' : ''}`;
    }

    if (item.meta.itemType === ITEM_CLASS.ATTACK) {
      return `app-item-slots-${this.slotSize}_aquamarine`;
    }

    const itemSlotBgName: ItemSlotBgName = getBackgroundColorName(item);

    if (item.meta.itemType === ITEM_TYPE.SKILL) {
      return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}-skill${
        item.meta.isAttackItem ? '-attack' : ''
      }`;
    }

    return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}`;
  }

  titleShadowColor(item?: ItemEntity): string {
    return item ? getTextShadowColorName(item) : 'inherit';
  }

  itemBackground(item?: ItemEntity): string {
    return item
      ? `url(assets/images/ui/paint/for-market/${getBackgroundColorName(item)}.png)`
      : 'url(assets/images/ui/paint/for-market/black.png)';
  }

  titleColor(item?: ItemEntity): string {
    return item ? getTextColorName(item) : 'inherit';
  }

  toAttributes(atr: readonly string[]) {
    return AttributesModel.fromStringArray(atr);
  }

  toAttributesFiltered(atr: readonly string[]) {
    return atr.filter(it => it !== '0');
  }

  hasItemAttributes() {
    return this.itemAttributes && this.itemAttributes.attributesArray.some(attr => attr > 0);
  }

  showFragilityInfo(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: this.translateService.instant('item-description-dialog.fragility'),
        infoDesc: this.translateService.instant('item-description-dialog.fragility-info'),
      },
    });
  }

  private slotDurability() {
    const curDurability = this.item?.durability ?? 0;
    const totalDurability = this.item?.meta?.durability ?? 0;

    if (totalDurability === 0) {
      this.itemDurability = '';
    } else {
      this.durabilityPercentage = Math.round((curDurability / totalDurability) * 100);

      this.itemDurability =
        this.durabilityPercentage <= 20 || this.durabilityPercentage === 0 ? this.durabilityPercentage + '%' : '';
    }
  }

  protected readonly getActualUri = getActualUri;
}
