import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  standalone: true,
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class AvatarComponent implements OnInit {
  @Input() avatarType: 'default' | 'boss' | 'small' | 'medium' | 'micro' | 'tiny' | 'reinforcement' = 'default';
  @Input() imagePath: string;
  @Input() isCenteredImage = false;

  @HostBinding('class')
  get hostTypeClass() {
    return this.avatarType;
  }

  avatarPath: string = 'assets/images/ui/frames/avatar';

  ngOnInit() {
    switch (this.avatarType) {
      case 'default':
        this.avatarPath = `${this.avatarPath}/avatar-huge.avif`;
        break;

      case 'boss':
        this.avatarPath = `${this.avatarPath}/boss.avif`;
        break;

      case 'small':
        this.avatarPath = `${this.avatarPath}/avatar-small.avif`;
        break;

      case 'tiny':
        this.avatarPath = `${this.avatarPath}/avatar-tiny.avif`;
        break;

      case 'medium':
        this.avatarPath = `${this.avatarPath}/avatar.avif`;
        break;

      case 'micro':
        this.avatarPath = `${this.avatarPath}/avatar-small.avif`;
        break;

      case 'reinforcement':
        this.avatarPath = `${this.avatarPath}/avatar-reinforcement.avif`;
        break;
    }
  }
}
