<div class="item-description-dialog g-flex-column">
  <div
    class="header g-flex g-flex--align-start g-flex-column__item-fixed"
    [style.background-image]="headerBackground">
    <div
      class="item"
      [ngClass]="backgroundClass ? backgroundClass : ''">
      @if (getActualMetaUri(item)) {
        <img
          class="item__image"
          [src]="imageUrl"
          alt="no image" />
      }
    </div>

    <div class="header__title-container g-flex__item g-flex-column">
      <div class="header__title g-flex">
        <div
          class="header__title-text text-shadow"
          [style.color]="titleColor()">
          {{ name }}
        </div>
      </div>

      <div
        [class.g-flex]="name.length > 26"
        [class.g-flex--space-between]="name.length > 26">
        <div class="rarity g-flex g-flex--align-center">
          <img
            class="rarity-icon"
            [src]="'/assets/images/ui/icons/item-type/' + itemsTypeIcon + '.png'"
            alt="item image" />

          @if (itemsTypeName !== 'no slot') {
            <div>
              {{ itemsTypeName ?? '' | translate }}
            </div>
          }
        </div>
      </div>
    </div>

    @if (isShowClose) {
      <button
        class="item-description-dialog__cross-btn app-btn-cross-light"
        (click)="onClose()"></button>
    }
  </div>

  <div class="skills g-flex-column">
    @if (itemMetaAttributes) {
      @for (attr of itemMetaAttributes.sortedAttributes; track attr; let i = $index) {
        @if (attr.max !== 0 && ATTRIBUTES.DAMAGE_MIN !== attr.id && ATTRIBUTES.DAMAGE_MAX !== attr.id) {
          <app-hero-stat-item
            class="stats__item"
            [isRowLayout]="true"
            [isShowSign]="true"
            [isInline]="true"
            [isMetaItem]="true"
            [item]="{
              icon: itemMetaAttributes.getAttributeIcon(attr.id),
              name: itemMetaAttributes.getAttributeName(attr.id),
              text: attr.min,
              description: itemMetaAttributes.getAttributeDescription(attr.id),
              attributeIndex: attr.id,
              additionalText: attr.max,
              chance: attr.chance,
            }">
          </app-hero-stat-item>
        }

        @if (attr.max !== 0 && ATTRIBUTES.DAMAGE_MIN === attr.id) {
          <app-hero-stat-item
            class="stats__item"
            [isRowLayout]="true"
            [isShowSign]="true"
            [isInline]="true"
            [isMetaItem]="true"
            [item]="{
              icon: itemMetaAttributes.getAttributeIcon(attr.id),
              name: 'Melee Damage',
              text: attr.min,
              description: itemMetaAttributes.getAttributeDescription(attr.id),
              attributeIndex: attr.id,
              additionalText: attr.max,
            }">
          </app-hero-stat-item>
        }
      }
    }

    @if (skillBuffCasterAttributes || skillBuffTargetAttributes) {
      <app-skill-area
        title="Skill"
        type="skill"
        [manaConsumption]="skillManaConsumption"
        [skillsAttributes]="[skillBuffCasterAttributes]"
        [skillsSigns]="['+', '-']"
        [hasEnemyAttributes]="
          skillBuffTargetAttributes && (skillBuffTargetAttributes.attributesArray | filterNull).length > 0
        "
        [enemyGotAttributes]="[skillBuffTargetAttributes]">
      </app-skill-area>
    }

    @if (isMagicAttack) {
      <div class="skills g-flex-column">
        <div class="skills__top">
          <img
            class="skills__icon"
            src="/assets/images/ui/icons/item-props/attack.png"
            alt="item image" />

          <div class="g-flex g-flex--align-center g-flex--space-between">
            <div>
              {{ 'item-description-dialog.magic-attack' | translate }}
            </div>

            <div class="mana-cost g-flex g-flex--align-center">
              <img
                src="/assets/images/ui/icons/attributes/MANA_COST.png"
                alt="item image" />
              <div class="color-mana text-shadow">
                {{ skillManaConsumption }}
              </div>

              <div>
                {{ 'item-description-dialog.mana-cost' | translate }}
              </div>
            </div>
          </div>

          <app-scratch class="skills__scratch"></app-scratch>
        </div>

        <div class="skills__middle">
          <app-hero-stat-item
            class="stat-item"
            [isRowLayout]="true"
            [isInline]="true"
            [item]="{
              icon: MAGIC_ATTACK_ICON.get(+(item.params?.genAttackInfo?.aType || 0)) ?? 'unknown',
              name: MAGIC_ATTACK_NAME.get(+(item.params?.genAttackInfo?.aType || 0)) ?? '',
              text: item.params?.genAttackInfo?.min + ' - ' + item.params?.genAttackInfo?.max,
              attributeIndex: ATTRIBUTES.DAMAGE_MAX,
            }">
          </app-hero-stat-item>

          @if (item.params?.genAttackInfo?.attributeFactors?.strength !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.STRENGTH) ?? 'unknown',
                name: ('item-description-dialog.add-to-dmg' | translate),
                text: item.params?.genAttackInfo?.attributeFactors?.strength + '% of STR',
                description: ('attributes-factor-description.strength' | translate),
                attributeIndex: ATTRIBUTES.STRENGTH,
              }">
            </app-hero-stat-item>
          }

          @if (item.params?.genAttackInfo?.attributeFactors?.dexterity !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DEXTERITY) ?? 'unknown',
                name: ('item-description-dialog.add-to-dmg' | translate),
                text: item.params?.genAttackInfo?.attributeFactors?.dexterity + '% of DEX',
                description: ('attributes-factor-description.dexterity' | translate),
                attributeIndex: ATTRIBUTES.DEXTERITY,
              }">
            </app-hero-stat-item>
          }

          @if (item.params?.genAttackInfo?.attributeFactors?.vitality !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.VITALITY) ?? 'unknown',
                name: ('item-description-dialog.add-to-dmg' | translate),
                text: item.params?.genAttackInfo?.attributeFactors?.vitality + '% of VIT',
                description: ('attributes-factor-description.vitality' | translate),
                attributeIndex: ATTRIBUTES.VITALITY,
              }">
            </app-hero-stat-item>
          }

          @if (item.params?.genAttackInfo?.attributeFactors?.energy !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.EXTRA_ENERGY) ?? 'unknown',
                name: ('item-description-dialog.add-to-dmg' | translate),
                text: item.params?.genAttackInfo?.attributeFactors?.energy + '% of ENG',
                description: ('attributes-factor-description.energy' | translate),
                attributeIndex: ATTRIBUTES.ENERGY,
              }">
            </app-hero-stat-item>
          }
        </div>
      </div>
    }
  </div>

  @if (
    (consumableStats &&
      (consumableStats.life !== '0' ||
        consumableStats.mana !== '0' ||
        consumableStats.lifeChances !== '0' ||
        consumableStats.experience !== '0')) ||
    +item.consumableActionMask > 0 ||
    (consumableAttributes && (consumableAttributes.attributesArray | filterNull).length > 0)
  ) {
    <div
      class="skills g-flex-column"
      [class.skills--consumable]="item.isConsumableItem">
      <div class="skills__top">
        <img
          class="skills__icon"
          src="/assets/images/ui/icons/item-props/use.png"
          alt="item image" />

        <div class="g-flex g-flex--align-center g-flex--space-between">
          <div>
            {{ 'item-description-dialog.impact' | translate }}
          </div>

          <div class="mana-cost g-flex g-flex--align-center">
            {{ 'item-description-dialog.one-time-use' | translate }}
          </div>
        </div>

        <app-scratch class="skills__scratch"></app-scratch>
      </div>

      @if (consumableStats) {
        <div class="skills__middle">
          @if (consumableStats.life && consumableStats.life !== '0') {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIFE) ?? '',
                name: ('item-description-dialog.hp-restored' | translate),
                text: consumableStats.life,
                description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIFE) ?? '',
                attributeIndex: ATTRIBUTES.LIFE,
              }">
            </app-hero-stat-item>
          }

          @if (consumableStats.mana !== '0') {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.MANA) ?? '',
                name: ('item-description-dialog.mp-restored' | translate),
                text: consumableStats.mana || '',
                description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.MANA) ?? '',
                attributeIndex: ATTRIBUTES.MANA,
              }">
            </app-hero-stat-item>
          }

          @if (consumableStats.lifeChances !== '0') {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIFE_CHANCES) ?? '',
                name: ('item-description-dialog.life-chances-restored' | translate),
                text: consumableStats.lifeChances || '',
                description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIFE_CHANCES) ?? '',
                attributeIndex: ATTRIBUTES.LIFE_CHANCES,
              }">
            </app-hero-stat-item>
          }

          @if (consumableStats.experience !== '0') {
            <app-hero-stat-item
              class="stat-item"
              [isRowLayout]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.EXTRA_ENERGY) ?? '',
                name: ('item-description-dialog.experience' | translate),
                text: consumableStats.experience || '',
                description: 'attributes-descriptions.experience',
                attributeIndex: ATTRIBUTES.END_SLOT,
              }">
            </app-hero-stat-item>
          }

          @if (consumableAttributes) {
            @for (attr of consumableAttributes.attributesArray; track attr; let i = $index) {
              @if (attr !== 0) {
                <app-hero-stat-item
                  class="stat-item"
                  [isRowLayout]="true"
                  [isShowSign]="true"
                  [isInline]="true"
                  [item]="{
                    icon: consumableAttributes.getAttributeIcon(i),
                    name: consumableAttributes.getAttributeName(i),
                    text: attr,
                    description: consumableAttributes.getAttributeDescription(i),
                    attributeIndex: i,
                  }">
                </app-hero-stat-item>
              }
            }
          }
        </div>
      }

      @if (+item.consumableActionMask > 0) {
        <div class="skills__middle">
          <app-hero-stat-item
            class="stat-item"
            [isRowLayout]="true"
            [isShowSign]="true"
            [isInline]="true"
            [item]="{
              icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.MAGIC_FIND) ?? '',
              name: ('item-description-dialog.consumable-action.' + item.consumableActionMask | translate),
              text: '',
              attributeIndex: ATTRIBUTES.END_SLOT,
            }">
          </app-hero-stat-item>
        </div>
      }
    </div>
  }

  @if (item.params?.itemMeta?.maxRandomAttributes) {
    <div>
      {{ 'item-description-dialog.available-attributes' | translate }}
      {{ item.params?.itemMeta?.maxRandomAttributes || 0 }}
    </div>
  }

  @if (avgPrice !== 0) {
    <div class="price g-flex g-flex--align-center gap-20">
      <div>Floor price on marketplace</div>

      <app-balance
        [isReverse]="false"
        [balance]="avgPrice"
        [balanceUnformatted]="avgPrice"
        [isShowBalanceUsd]="true"
        [isShowZeroBalanceUsd]="false"
        tokenType="sacra">
      </app-balance>
    </div>
  }

  <div class="g-flex-column__item-fixed">
    <app-scratch
      class="scratch"
      backgroundType="middle">
    </app-scratch>

    <div class="item__desc-text text-shadow">
      {{ description | translate }}
    </div>
  </div>

  @if (isShowMarketButton) {
    <button
      class="btn-buy app-btn-silver-4 pointer g-flex-column__item-fixed"
      (click)="onBuyOnMarket()">
      Buy on marketplace
    </button>
  }
</div>
