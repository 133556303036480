import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ItemEntity } from '@generated/gql';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { SoundService } from '@services/sound.service';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { ItemCardComponent } from '@shared/components/item-card/item-card.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemActionType } from '@shared/constants/inventory.constants';
import {
  ITEM_SLOT_NAMES,
  ITEM_SLOTS_TO_ITEM_TYPE_MAPPING,
  ITEM_TYPE,
  ITEM_TYPE_NAMES,
} from '@shared/constants/items.constant';
import { takeUntil } from 'rxjs';

import { HeroSlotDialogData } from './hero-slot-dialog-data';

@Component({
  standalone: true,
  templateUrl: './hero-slot-dialog.component.html',
  styleUrls: ['./hero-slot-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column app-window-responsive-background--full',
  },
  providers: [DestroyService],
  imports: [DialogTitleComponent, ItemCardComponent, TranslateModule],
})
export class HeroSlotDialogComponent {
  readonly itemSlotName: string | undefined;
  readonly itemsTypeName: string | undefined;
  selectedItem: ItemEntity | undefined;

  private itemDescriptionDialogRef: DialogRef<ItemActionType, ItemDescriptionDialogComponent> | undefined;

  constructor(
    @Inject(DIALOG_DATA)
    public data: HeroSlotDialogData,
    private dialogRef: DialogRef<{ item: ItemEntity; equip: boolean }, HeroSlotDialogComponent>,
    private dialog: Dialog,
    private destroy$: DestroyService,
    private soundService: SoundService,
  ) {
    this.itemSlotName = ITEM_SLOT_NAMES.get(data.slot);
    const itemType: ITEM_TYPE | undefined = ITEM_SLOTS_TO_ITEM_TYPE_MAPPING.get(data.slot);
    this.itemsTypeName = itemType ? ITEM_TYPE_NAMES.get(itemType) : '';
  }

  selectItem(item: ItemEntity): void {
    if (this.selectedItem === item) {
      this.selectedItem = undefined;
    } else {
      this.selectedItem = item;
    }

    this.choseItem();
  }

  close(): void {
    this.dialogRef.close();
  }

  isEquippedItem(item: ItemEntity | undefined) {
    return this.data.equippedItem === item;
  }

  choseItem(): void {
    const { selectedItem } = this;

    if (selectedItem) {
      this.soundService.play({ key: 'equip' });

      this.dialogRef.close({
        item: selectedItem,
        equip: !this.isEquippedItem(this.selectedItem),
      });
    }
  }

  openItemDescriptionDialog(item?: ItemEntity): void {
    if (!item) {
      return;
    }

    this.itemDescriptionDialogRef = this.dialog.open(ItemDescriptionDialogComponent, {
      panelClass: 'app-overlay-pane-full-width',
      data: { item, isShowButtons: false, chainId: 0, hero: this.data.hero, isCanUse: this.data.isCanUse },
    });

    this.itemDescriptionDialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data?.useItem) {
        this.selectItem(data.useItem);
      }
    });
  }
}
