import { IStatController } from '@data/abi/IStatController';
import { StatLib } from '@data/abi/StatReader';
import { AttributesModel } from '@models/attributes.model';
import { HeroMetadataModel } from '@models/hero-metadata.model';
import { StatsModel } from '@models/stats.model';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';

import CoreAttributesStructOutput = IStatController.CoreAttributesStructOutput;
import BaseMultiplierStructOutput = StatLib.BaseMultiplierStructOutput;
import LevelUpStructOutput = StatLib.LevelUpStructOutput;

export class HeroModel {
  readonly meta: HeroMetadataModel;
  readonly heroUniqName: string;
  readonly heroId: number;

  readonly attributes: AttributesModel;
  readonly stats: StatsModel;

  readonly nextLevelExperience: number;
  readonly nextLevelExperienceRequire: number;
  readonly previousLevelExperienceRequire: number;

  readonly isStaked: boolean;
  readonly help: HeroModel | null;

  initialAttributes: [CoreAttributesStructOutput, BaseMultiplierStructOutput, LevelUpStructOutput];
  coreStatsInfo: HeroStatItemInputInterface[] = [];
  additionalStatsInfo: HeroStatItemInputInterface[] = [];

  constructor(
    meta: HeroMetadataModel,
    heroUniqName: string,
    heroId: number,
    attributes: AttributesModel,
    stats: StatsModel,
    nextLevelExperience: number,
    nextLevelExperienceRequire: number,
    previousLevelExperienceRequire: number,
    isStaked: boolean,
    help: HeroModel | null,
  ) {
    this.meta = meta;
    this.heroUniqName = heroUniqName;
    this.heroId = heroId;
    this.attributes = attributes;
    this.stats = stats;
    this.nextLevelExperience = nextLevelExperience;
    this.nextLevelExperienceRequire = nextLevelExperienceRequire;
    this.previousLevelExperienceRequire = previousLevelExperienceRequire;
    this.isStaked = isStaked;
    this.help = help;
  }

  getExperiencePercent(): string {
    return (
      ((this.stats.experience - this.previousLevelExperienceRequire) / this.nextLevelExperienceRequire) *
      100
    ).toFixed(0);
  }
}
