@if (tokenName && isUsdNextLine) {
  <img
    class="balance__icon shadow__icon"
    [class.balance__icon--reverse]="isReverse"
    [src]="'assets/images/ui/icons/tokens/' + tokenName + '.png'"
    alt="" />
}

<div
  [class.g-flex]="!isUsdNextLine"
  [class.g-flex-column]="isUsdNextLine"
  [class.g-flex--align-center]="isUsdNextLine">
  <div class="g-flex g-flex--align-center">
    @if (tokenName && !isUsdNextLine) {
      <img
        class="balance__icon shadow__icon"
        [class.balance__icon--reverse]="isReverse"
        [src]="'assets/images/ui/icons/tokens/' + tokenName + '.png'"
        alt="" />
    }

    <div class="g-flex-column balances">
      @if (oldBalance) {
        <div
          class="balance__text balance__text--prev color-negative-value text-shadow-negative"
          [class.app-btn-medium-small_font-size]="size === 'default'"
          [class.app-btn-tiny_font-size]="size === 'small'"
          [class.app-btn_font-size]="size === 'medium'"
          [class.g-flex-column]="oldBalanceUnformatted && oldBalanceUnformatted > 15000"
          [class.balance__text-column]="oldBalanceUnformatted && oldBalanceUnformatted > 15000">
          <span>
            {{ oldBalance }}
          </span>

          @if (isShowBalanceUsd && oldBalanceUsd && (+oldBalanceUsd > 0 || isShowZeroBalanceUsd) && !isUsdNextLine) {
            <span> (${{ formatAmount(oldBalanceUsd) }}) </span>
          }
        </div>
      }

      <div
        class="balance__text balance__text--main"
        [class.app-btn_font-size]="size === 'default'"
        [class.app-btn-small_font-size]="size === 'small'"
        [class.app-btn-medium_font-size]="size === 'medium'"
        [class.g-flex-column]="balanceUnformatted && balanceUnformatted > 15000"
        [class.balance__text-column]="balanceUnformatted && balanceUnformatted > 15000">
        @if (checkBalance()) {
          <span>
            {{ balance }}
          </span>

          @if (isShowBalanceUsd && balanceUsd && (+balanceUsd > 0 || isShowZeroBalanceUsd) && !isUsdNextLine) {
            <span> (${{ formatAmount(balanceUsd) }}) </span>
          }
        } @else {
          <ng-content></ng-content>
        }
      </div>
    </div>
  </div>

  @if (isShowBalanceUsd && balanceUsd && (+balanceUsd > 0 || isShowZeroBalanceUsd) && isUsdNextLine) {
    <div
      [class.app-btn_font-size]="size === 'default'"
      [class.app-btn-small_font-size]="size === 'small'"
      [class.app-btn-medium_font-size]="size === 'medium'">
      ${{ formatAmount(balanceUsd) }}
    </div>
  }
</div>
