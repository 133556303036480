@if (mode === 'checkbox') {
  <div class="img">
    <img
      class="img__checkbox"
      [class.hide]="value !== CHECKBOX_STATE.CHECKED"
      [src]="'assets/images/ui/elements/checkbox/checkbox-active' + (isDisabled ? '-disabled' : '') + '.png'"
      alt="" />

    <img
      class="img__checkbox"
      [class.hide]="value === CHECKBOX_STATE.CHECKED"
      [src]="'assets/images/ui/elements/checkbox/checkbox' + (isDisabled ? '-disabled' : '') + '.png'"
      alt="" />
  </div>
} @else {
  <div class="toggle">
    <img
      class="toggle__checkbox"
      [class.hide]="value !== CHECKBOX_STATE.CHECKED"
      [src]="'assets/images/ui/elements/toggle/on' + (isDisabled ? '-disabled' : '') + '.png'"
      alt="" />

    <img
      class="toggle__checkbox"
      [class.hide]="value === CHECKBOX_STATE.CHECKED"
      [src]="'assets/images/ui/elements/toggle/off' + (isDisabled ? '-disabled' : '') + '.png'"
      alt="" />
  </div>
}

@if (label) {
  <div
    [ngClass]="fontSizeLabelClass"
    class="label text-shadow"
    [class.checkbox--white]="value === CHECKBOX_STATE.CHECKED">
    {{ label }}
  </div>
}
