@if (topLabel && +topLabel !== 0) {
  <div
    class="bar__top-label app-btn-tiny_font-size text-bar-shadow"
    [ngClass]="topLabelClasses">
    {{ topLabel }}
  </div>
}

<img
  class="bar__bg"
  [src]="'assets/images/ui/bars/new/' + size + '/bg.avif'"
  alt="" />

@if (isShowPoint) {
  <img
    class="bar__point"
    [src]="'assets/images/ui/bars/new/' + size + '/point.avif'"
    alt="" />
}

<img
  class="bar__level"
  [class.bar__level--menu]="size === 'menu'"
  [class.bar__level--spec]="size === 'spec'"
  [src]="'assets/images/ui/bars/new/' + size + '/' + barType + '.avif'"
  [style.clip-path]="'inset(0 ' + (100 - percentageValue * 100) + '% 0 0)'"
  alt="" />

<div
  class="bar__label app-btn-micro_font-size text-bar-shadow"
  [ngClass]="'bar-' + barType">
  {{ label }}
</div>
