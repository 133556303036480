export class HeaderStateModel {
  isBackButtonShown? = true;
  isInventoryShown? = true;
  isInventoryOpen? = true;
  isAccountAddressShown? = true;

  isAccountBalanceShown? = false;
  isHeroNameShown? = false;
  isHeroClassNameShown? = false;

  backUrl?: string[];
}
