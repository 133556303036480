import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ItemEntity } from '@generated/gql';
import { changeIPFSDomain } from '@helpers/data-helper';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { ItemCardComponent } from '@shared/components/item-card/item-card.component';
import { ITEM_RARITY_TO_NAME, ITEM_TYPE_IS_COMMON, ITEM_TYPE_NAMES } from '@shared/constants/items.constant';
import { ItemSlotSize } from '@shared/types/item-slot-size.type';

export type ForgeResultType = {
  oldItem: ItemEntity;
  newItem: ItemEntity;
  isSuccess: boolean;
};

@Component({
  selector: 'app-forge-success-dialog',
  standalone: true,
  templateUrl: './forge-success-dialog.component.html',
  styleUrls: ['./forge-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, ItemCardComponent, ButtonClickDirective, TranslateModule],
})
export class ForgeSuccessDialogComponent implements OnInit {
  account: string;
  chainId: number;
  itemsTypeName: string | undefined = '';
  itemRarityName: string | undefined = '';
  isCommonItem = false;
  oldItem?: ItemEntity;
  newItem?: ItemEntity;
  slotSize: ItemSlotSize = 'middle-small';

  constructor(
    @Inject(DIALOG_DATA) public data: ForgeResultType,
    private dialogRef: DialogRef<string, ForgeSuccessDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.oldItem = this.data.oldItem;
    this.newItem = this.data.newItem;
    this.itemsTypeName = ITEM_TYPE_NAMES.get(this.oldItem?.meta?.itemType ?? -1);

    this.isCommonItem = ITEM_TYPE_IS_COMMON.get(this.oldItem?.meta?.itemType ?? -1) ?? false;

    if (this.isCommonItem) {
      this.itemRarityName = ITEM_RARITY_TO_NAME.get(this.oldItem?.rarity ?? -1);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  changeURL(url: string) {
    return changeIPFSDomain(url);
  }
}
