import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemEntity } from '@generated/gql';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { ItemDescriptionComponent } from '@shared/components/item-description/item-description.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-item-shared',
  standalone: true,
  templateUrl: './item-shared.component.html',
  styleUrls: ['./item-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ItemDescriptionComponent],
})
export class ItemSharedComponent implements OnInit {
  item!: ItemEntity;

  constructor(
    private subgraphService: SubgraphService,
    private destroy$: DestroyService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subgraphService
      .item$(
        this.activatedRoute.snapshot.queryParamMap.get('itemAdr')!,
        +this.activatedRoute.snapshot.queryParamMap.get('itemId')!,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(item => {
        this.item = item;

        this.changeDetectorRef.detectChanges();
      });
  }
}
