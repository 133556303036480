/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type { StatReader, StatReaderInterface } from "../StatReader";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
    ],
    name: "TooLowX",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
    ],
    name: "UnknownHeroClass",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
    ],
    name: "baseLifeChances",
    outputs: [
      {
        internalType: "int32",
        name: "",
        type: "int32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "int32",
        name: "attackersAttackRating",
        type: "int32",
      },
      {
        internalType: "int32",
        name: "defendersDefenceRating",
        type: "int32",
      },
      {
        internalType: "int32",
        name: "attackersLevel",
        type: "int32",
      },
      {
        internalType: "int32",
        name: "defendersLevel",
        type: "int32",
      },
      {
        internalType: "int32",
        name: "arFactor",
        type: "int32",
      },
    ],
    name: "chanceToHit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "exp",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "startFromLevel",
        type: "uint256",
      },
    ],
    name: "experienceToLvl",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "level",
        type: "uint32",
      },
    ],
    name: "levelExperience",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "int32",
        name: "strength",
        type: "int32",
      },
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
    ],
    name: "minDamage",
    outputs: [
      {
        internalType: "int32",
        name: "",
        type: "int32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "heroClass",
        type: "uint256",
      },
    ],
    name: "startHeroAttributes",
    outputs: [
      {
        components: [
          {
            internalType: "int32",
            name: "strength",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "dexterity",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "vitality",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "energy",
            type: "int32",
          },
        ],
        internalType: "struct IStatController.CoreAttributes",
        name: "",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "minDamage",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxDamage",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "attackRating",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "defense",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "blockRating",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "life",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "mana",
            type: "uint256",
          },
        ],
        internalType: "struct StatLib.BaseMultiplier",
        name: "",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "life",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "mana",
            type: "uint256",
          },
        ],
        internalType: "struct StatLib.LevelUp",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class StatReader__factory {
  static readonly abi = _abi;
  static createInterface(): StatReaderInterface {
    return new Interface(_abi) as StatReaderInterface;
  }
  static connect(address: string, runner?: ContractRunner | null): StatReader {
    return new Contract(address, _abi, runner) as unknown as StatReader;
  }
}
