@if (isShow) {
  <div
    class="loading g-flex-column g-flex--align-center"
    [style.zoom]="appZoom">
    <div class="gif"></div>

    <img
      class="loading__shadow"
      src="assets/video/shadow.avif"
      alt="" />

    <div class="loading__container g-flex-column g-flex--align-center">
      <div class="loading__text g-scroll-y text-shadow">
        {{ texts[currentTextIndex].text }}
      </div>
    </div>

    <a
      class="loading__title app-btn-huge_font-size g-flex-column g-flex--align-center g-flex--justify-center"
      [attr.href]="transactionData?.txLink"
      target="_blank">
      <div class="loading__title-text g-flex g-flex--align-center g-flex--justify-center title-a">
        <div>Blockchain Transaction Processing</div>

        <img
          src="assets/images/ui/icons/other/link.png"
          alt="" />
      </div>

      <img
        class="loading__title-div"
        src="assets/images/ui/dividers/borders_v/border_v_down.png"
        alt="" />
    </a>
  </div>
}
