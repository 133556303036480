import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { reloadPage } from '@helpers/common-helper';
import { ErrorService } from '@services/error.service';
import { StorageService } from '@services/storage.service';
import { CONNECT_EVENT, ERROR_EVENT, IProviderUserOptions, Web3WalletConnector } from '@services/web3modal-ts/src';
// import EthereumProvider from '@walletconnect/ethereum-provider';
import * as EthereumProvider from '@walletconnect/ethereum-provider';
// import EthereumProvider from '@walletconnect/ethereum-provider/dist/types/EthereumProvider';
import { NGXLogger } from 'ngx-logger';
import { from, Subject, take } from 'rxjs';

import { getNetworkName } from './web3modal-ts/src';

@Injectable({
  providedIn: 'root',
})
export class Web3ModalService {
  public web3WalletConnector: Web3WalletConnector;

  public shouldOpen: EventEmitter<boolean> = new EventEmitter();
  public providers: EventEmitter<IProviderUserOptions[]> = new EventEmitter();

  provider$: Subject<any> = new Subject<any>();

  constructor(
    private logger: NGXLogger,
    private storageService: StorageService,
    private errorService: ErrorService,
  ) {
    const chainId = environment.CHAIN_ID;
    if (!chainId) {
      throw new Error('Chain id is not defined');
    }

    this.web3WalletConnector = new Web3WalletConnector(errorService, {
      cacheProvider: true,
      disableInjectedProvider: false,
      network: getNetworkName(Number(chainId)),
      providerOptions: {
        walletconnect: {
          package: EthereumProvider,
          options: {
            projectId: `${environment.WALLET_CONNECT_PROJECT_ID}`,
          },
        },
      },
    });

    this.web3WalletConnector.providerController.on(CONNECT_EVENT, provider => {
      console.log('get provider from CONNECT EVENT', provider);
      this.provider$.next(provider);
    });
  }

  get cachedProvider(): string {
    return this.web3WalletConnector.cachedProvider;
  }

  async open() {
    const connectToCached = await this.web3WalletConnector.providerController.connectToCachedProvider();

    this.providers.next(this.web3WalletConnector.providers);

    return await new Promise((resolve /*reject*/) => {
      this.web3WalletConnector.providerController.on(CONNECT_EVENT, provider => {
        console.log('CONNECT EVENT', provider);
        resolve(provider);
      });

      this.web3WalletConnector.providerController.on(
        ERROR_EVENT,
        /*error*/ () => {
          console.log('ERROR EVENT');
          // as temporally solution we assume that the modal will be never closed until user connect a provider
          // reject(error);
        },
      );

      if (!connectToCached) {
        this.shouldOpen.next(true);
      }

      this.shouldOpen.pipe(take(1)).subscribe({
        next: open => {
          if (!open) {
            // as temporally solution we assume that the modal will be never closed until user connect a provider
            // reject('Dismissed modal');
          }
        },
      });
    }).finally(() => {
      this.close();
    });
  }

  clearCachedProvider() {
    return this.web3WalletConnector.providerController.clearCachedProvider();
  }

  close() {
    this.shouldOpen.next(false);
  }

  disconnect() {
    from(this.clearCachedProvider()).subscribe(() => {
      this.close();
      this.logger.trace('Disconnected');
      this.storageService.clearWalletConnect();
      this.storageService.clearOauth();
      reloadPage();
    });
  }
}
