import { DialogRef } from '@angular/cdk/dialog';
import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DestroyService } from '@services/destroy.service';
import { StorageService } from '@services/storage.service';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';

import { STORAGE_KEY_FILTER_TEMPLATES } from '../../constants/filters.constant';
import { IFilter, IFilterTemplate } from '../../interfaces/filters.interface';

@Component({
  selector: 'app-filter-templates',
  standalone: true,
  templateUrl: './filter-templates.component.html',
  styleUrls: ['./filter-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, KeyValuePipe, ReactiveFormsModule],
})
export class FilterTemplatesComponent implements OnInit {
  nameControl: FormControl = new FormControl(null, { validators: Validators.required });
  templates: IFilterTemplate;

  constructor(
    public dialogRef: DialogRef<
      { name?: string; filters?: IFilter[]; general?: {}; key?: string } | null,
      FilterTemplatesComponent
    >,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    if (this.storageService.get(STORAGE_KEY_FILTER_TEMPLATES)) {
      this.templates = JSON.parse(this.storageService.get(STORAGE_KEY_FILTER_TEMPLATES)) as IFilterTemplate;
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  removeTemplate(templateName: string) {
    delete this.templates[templateName];

    this.storageService.set(STORAGE_KEY_FILTER_TEMPLATES, JSON.stringify(this.templates));
  }

  onSave() {
    this.dialogRef.close({ name: this.nameControl.value });
  }

  applyTemplate(key: string) {
    this.dialogRef.close({
      ...this.templates[key],
      key,
    });
  }
}
