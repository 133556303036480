export const SONIC_AIRDROP_VALUES = [
  ['0x0159d7b9951115fb95c75d0d9e1078421f06753d', '1'],
  ['0x0194f929fd00bca74f4ed5396e6eb60e03ca6559', '1'],
  ['0x03151fa94aba95e993a2c37266c0b813a20fe370', '2'],
  ['0x0357543c6ffde1cc28e13dbe8b6d3e8a3b372cb1', '2'],
  ['0x04c31a1b3a0845c82c3aa457fb5d966e19a2ce05', '1'],
  ['0x07092930e1c78f4dbae4c459d76646193d97057e', '1'],
  ['0x07516f97d2c5e253f4ecbbf6e311fe118b2a8a0c', '1'],
  ['0x089920a4a315bb8ecb9e426d26341f6c5a63e3f4', '1'],
  ['0x08d963942668541bf4e40988fadf9c97f417b12d', '3'],
  ['0x09a5205cd38abad4414e7b95a002d6a846a84095', '1'],
  ['0x0b350cb9b0b8f040553c6383b282b9d9b235f40c', '1'],
  ['0x0b962643b68de0b514a568a164819c1122c73764', '1'],
  ['0x0c47b24b0c2da79f61e3b48faddfc57db1dbf2bf', '2'],
  ['0x0df91f7f44cf7adcca25e3b7c89798d60b3b98fc', '1'],
  ['0x120aefd160b43ad5ecc79a1413acd246568093fa', '1'],
  ['0x12af82fcf54fd126b6b370d2b91b9466b3bc80fa', '1'],
  ['0x16bbd6489121b2c8bedb91b41a36e81a7778f728', '1'],
  ['0x1cb7ed7f68995ad64c563c0df75f5696e855510c', '1'],
  ['0x1d89c54d41fd595bdaf716ca7c149b1bc376f3ac', '1'],
  ['0x1ed92ebe7a0cb883c37f5cea5e2efa34a6d314cd', '1'],
  ['0x1f67ea67d0af040706c306a97e9250cbafc7d2ae', '1'],
  ['0x219d36a1b617f6408bb351754243141534bed456', '1'],
  ['0x23d5078f41534a2c120918919dfbacd13c4b136b', '1'],
  ['0x24cbee5428abb723b5828498ccad7009ad2c9d35', '1'],
  ['0x25adcfb4eab40a935c23f4860d0dd6729207307c', '1'],
  ['0x25edb7dda2b8062c94d37acaf4803d44f1858711', '1'],
  ['0x25fdc18073b8e38f8a27be34e913d85a3e07b346', '1'],
  ['0x27463d66256fcb3d7f9efc49c00633794103f5f9', '1'],
  ['0x2b1f4c65e6f1c5786938a62422e9c1c75df1adf2', '1'],
  ['0x2d9ee7d43aae3c2d337c95419cb1fbc70ef83e85', '3'],
  ['0x2dd56df8c33a08cb9c4ec2a32636d44134d5f000', '1'],
  ['0x2f23a32cc31c3b5bba39c567c77681373c3847a9', '1'],
  ['0x302ddee239e3fa9628fed075e0654d612ec499a6', '2'],
  ['0x337f7f6c76f2a72b66ca0bea1e834afab9819f16', '1'],
  ['0x37412ad36110576f4d01922b0b843b44a4610941', '1'],
  ['0x39577d9c1b0a6fb227da0d0831e059d30087b88b', '1'],
  ['0x3a72f6a55dd2c9ec2d483da6e55e4b6824552f9d', '1'],
  ['0x3b56f1bdae788734ac0b97975e1272a93c47f97d', '1'],
  ['0x3c98b89c9d0d01fadbb7abec04fee0535779e582', '1'],
  ['0x3f0e911539490fab1a55434b8a89e9b061c1701b', '1'],
  ['0x410d4d159067b636614a74778c4b38709aa38c51', '3'],
  ['0x423612e333bc95b1cb022e3398a99d580c5846aa', '1'],
  ['0x43a8770ce2ae9b3bb6e209ef9bec9169cd269a30', '1'],
  ['0x45dedbe39813821687b18ca9153582565eb6edef', '1'],
  ['0x4aa4ec7f0e3d541288dfc3acde99a5958ec0c0db', '1'],
  ['0x4c1c30deae40443a71d3c880c9a43237df03cc87', '6'],
  ['0x4cd2dc36659ef1bb9e8862db75af3e7555ae5d83', '1'],
  ['0x4de8e7ebee41884ba8a59424818ecf4add6ab2b4', '3'],
  ['0x4e7f4b6cff1e515ea5c8cd461f82f21baa7478dd', '1'],
  ['0x515d509daf38172ae1465e0cefa372d2b658d8e2', '1'],
  ['0x5167c11e67c975eda56d95ea94662bbdb0d7002a', '2'],
  ['0x517267e094f55bae0ae0bdd69b516dd7feee68a6', '1'],
  ['0x52f3aca84aa2f8fc75c3a7e9473be401d8208d6e', '1'],
  ['0x53e8d71174a9041fa0fb718349b247d9e647637b', '3'],
  ['0x54779756e0046668c882bc10defab37c787145d0', '1'],
  ['0x54da5a669dc39adcbf753f3ba64b0cf6ce9f6838', '1'],
  ['0x554c284b04a423a19d3fe4c4b68cf5379c30deb4', '1'],
  ['0x5993c00fc2673d5939114a89c0463a3d095deb50', '1'],
  ['0x5aea07d2aa03a0ae7756104619e1bafc85980cad', '1'],
  ['0x5bb7c7d5f5616d90b43ccd449c834292828d0dd3', '1'],
  ['0x5f01d02c30cad65f39eebad7ab12d08185263f9a', '2'],
  ['0x6291209f4ae99edfaabd9e4e6a544e564a535659', '2'],
  ['0x656882073cc5b73dd1cd318cb8075260bd9538db', '1'],
  ['0x66af94834b250fe7788bd7bd0c2b0486a9022d39', '1'],
  ['0x66fbe3c7566cc101f2c346df7e9c187050fdb461', '1'],
  ['0x6881aa267964326043ae85363103a1076ee0620a', '1'],
  ['0x68967f0f78e00efec1d7f4f6d2eca5c6f6afb924', '1'],
  ['0x6c4d39f9e53e4edcc4593defdc811fdf8bc52c30', '3'],
  ['0x6d015f37e99cf948c6ce63e8279341212f2d00f6', '1'],
  ['0x6ea4d4f6fe9ae9dbafc71cba78ac70c7e3729209', '1'],
  ['0x7434661c023b34423fad0363a834f2d85442b36f', '1'],
  ['0x76c07087bb4eb86d2516081f79f400507ac2370f', '4'],
  ['0x7792cade26e4dd1700be02764e6e75e69c50ab49', '3'],
  ['0x7eef061df84437a5a50d7f9b79744a775f233965', '1'],
  ['0x7f847648bf0f3e005cd9c02abc07affa1176fe70', '1'],
  ['0x7fca21bd0166839a7130121c4462b48af8e83865', '2'],
  ['0x7fd94ae39f5f522ee8b482d87251f440e0e6b385', '2'],
  ['0x81c0707e72bba4151fc1c95c394ee9b5211aebeb', '1'],
  ['0x81ffdd63fe99262eb16f2258db935516cee887fc', '1'],
  ['0x8471a5c2663d54da16e7d903ea320b7d97c7719b', '1'],
  ['0x86a97ce0cf38b87e92d8c854fbefac8bb46a9faa', '4'],
  ['0x877eb30517c1238f89816e5526c4755100e7c2dd', '1'],
  ['0x87969ac983e5f26b7ed20521829ba6c803eb7a30', '1'],
  ['0x87cfa186ba24549d5ca4ae598936efd6d90cc40f', '1'],
  ['0x8936b1c560bbe960a54e83f44b34b1b1144d27db', '1'],
  ['0x89af68660fc49273e1596c1229eab1b7d208d557', '1'],
  ['0x8f19be18941a8f0a1c11a5de31071f41d38989d4', '1'],
  ['0x928e871efa74f6645330b32d80b738ef05e0138c', '1'],
  ['0x98d472ba8a842d8b3c29907b35498a390ce49728', '1'],
  ['0x9c62f03ecb92ba1fdef2660f91a70762af7e8e9e', '1'],
  ['0x9cc98be9fa8059d5cd262c62b475770682545ac0', '1'],
  ['0x9ecef491fe8b94852a18669f63c0e12b08cfcd62', '3'],
  ['0x9f39c61758c9880ce2c0cc22f58482a22d1f4421', '1'],
  ['0x9f545ecf4e994b6e21204de7e4c41fe611f1f98b', '1'],
  ['0xa14d7a37e0da95d6aafd37a2a6332d9fc98b3e5d', '1'],
  ['0xa4b7c016deba4f6500ade6ccc2c644b02cad54eb', '1'],
  ['0xa7b601396cfd7448bf850b9da34639c0957fe855', '1'],
  ['0xa8ba7d998e35a398b8a57de06ce65f27bff3a994', '1'],
  ['0xaa79498766a787bd1dea8ce53de7603f62dcd2f6', '1'],
  ['0xab0181c9d9bef10cb7642d511407756e820dbab9', '2'],
  ['0xac3b9362407fc0495655eff49f267d90c91002f9', '5'],
  ['0xace6eb0a2915deb514caa4c7e0af57704778b54a', '1'],
  ['0xb21b1ff926dd5503baf9a71140ffcde00739123c', '1'],
  ['0xb3ad5673ab63c2d24a73cfb199a6471475c39289', '1'],
  ['0xb42a558a7bc2e79dab74ddef5f6c0d262ebd4ac9', '1'],
  ['0xbfb157e091b7c874ffc70f05ace80062d512ff52', '1'],
  ['0xc17b375c3d25eb714b4bc45879857561d560420d', '1'],
  ['0xc39cef6472c2d702abb0f383ce78a79f122a6621', '3'],
  ['0xc44a3fff335379daf0999af79f0a64493b3b0833', '1'],
  ['0xc49f5143b27d594106e0265294ed6745124964d4', '1'],
  ['0xc5a542528985473a80c6c822638ff203eb1ef5a5', '2'],
  ['0xc72da7b99459b8763877d5510bd87310f1206497', '1'],
  ['0xc74e6a73deafd3587398455efb5978427cb83c20', '1'],
  ['0xc760a99b86679391097e522f382254f157697cf0', '1'],
  ['0xca43c0b1e1c073ec813851e6b6621cc9cfc51bbf', '1'],
  ['0xcac520f980a07357c040283545c18a0b01bb0b87', '1'],
  ['0xcb4cbd39cce49ffc44b385f0a59c5fd4d26c2617', '1'],
  ['0xcc6ad7807605ca7c11051d30bd912189c2bf8f01', '1'],
  ['0xcd9c8563403a6af6efa1f751377f95c8cf257ffa', '1'],
  ['0xce1a9c49df330a26d25785e570a3393586b2c946', '1'],
  ['0xce4cff19553c10a898671a1500a83ab176d2b8c3', '2'],
  ['0xd0801354020bea8038f6fd14e9d54b7fd7230ae7', '1'],
  ['0xd124d9baad44e4acbc361a2ad005a166ef58a246', '2'],
  ['0xd13d6a2d123a8e400d50472583eb5a6acecdceeb', '1'],
  ['0xd6962a755634be18c63da84dd9806d7766a87016', '1'],
  ['0xdd85d5d0f1b90574d2c22dae67c1c8cee31a5699', '1'],
  ['0xdfabdbee2989542b3f08374c8b655e1fdffd89dd', '2'],
  ['0xe092250ea4d1d22b952a8a1ad3a8394c52ceb6f4', '1'],
  ['0xe3ac24ff9f6a04e110fd1cd9fbc7281629729358', '1'],
  ['0xe4c5c72083f289e6aeea5a41f43130a6bda8ee27', '1'],
  ['0xe983bcf2ff259fc2273cc664869f6dd3fa18305c', '1'],
  ['0xeaffb09910508b753dec4b983ffcec49e88a2606', '1'],
  ['0xec60760e1f563d619239c2657f6f390d2a2b1575', '1'],
  ['0xf332332d64f8677ec7a79ed6d47404fe6471df97', '1'],
  ['0xf354b3146d627a5a06d17c41d10ed214827f1222', '1'],
  ['0xf6704008a7f181a04375bf91e269c05b964031bd', '1'],
  ['0xf8e6368f2f6480d64f10116012ab29c41b63ea55', '1'],
  ['0xfa5ca148ac0c2b3b3860bb1148e112d70ca3c060', '2'],
  ['0xfac4273dddac32c38e56ef136087e4694c020c3f', '1'],
  ['0xfb119266097a73d1a3f81f2927f6fa5019b91af2', '1'],
  ['0xfc371e9697d670e008d8c57e5d7e8ffce2a2e281', '1'],
  ['0xfddd44d09b9a23923da270c4fa0d20879aea5b27', '1'],
  ['0xfe67aaf1f7b1d9e4b92c7dbf652e7376f31eccc7', '1'],
  ['0x02c9a808e599aeba78c13ad94573a4470065f1ae', '1'],
  ['0x045efcfb8817ea213d55eb711a0cdc367c63db3e', '2'],
  ['0x048e08ba59a0aa654f77895a6d7a5f92134e18f8', '1'],
  ['0x05a9ce87796fd95bc82613e5e15f2d59b6bc008d', '1'],
  ['0x061eef2ba07d572fda3f8aafbc811f0bab5b0112', '1'],
  ['0x08c4ccbdd736b85a652e86878c492b3c4cf6ab4a', '1'],
  ['0x0949bfd75ef52ef162595a1bd98c848adc969b71', '1'],
  ['0x0c2e5916033e761379f87dfc7a950fe658e9dc53', '4'],
  ['0x10b051230d805f80fa73abbc1511b13816cb2e18', '1'],
  ['0x11b1ed9521ee6d16e9c6cbf9a9726bf974a6da0a', '1'],
  ['0x11bf664212d0bc7142ffcc38eebc8cbb94ca0af1', '4'],
  ['0x12032528c2a7ace22561ecbeae05c2515e6a8df1', '1'],
  ['0x12d33b958b53a00b43c779dd0bf77ee368b92803', '1'],
  ['0x17a1f43b187a57757e04c82b687f69140cb0a303', '1'],
  ['0x1816ea248470b0e9eea373f0b4f473c91ecdabac', '2'],
  ['0x19531769399fe5f3fe2cf58928634659f17ad58b', '1'],
  ['0x1d9d3db45bc8a23f432115211633da4c8254539b', '1'],
  ['0x1ec2224c145cb898f237a2875a4c386e43e0b9b9', '1'],
  ['0x200948bb92282f434b0bced50f6f9a277ecb5f5e', '1'],
  ['0x28c37726bc71f87c06552f3b2ade6713d6148208', '1'],
  ['0x2a9ac69652b110cad953d3431f2edba039e0bcdf', '1'],
  ['0x2bb58656d6c34fcd563d42da4e45d6b4d1e5d143', '1'],
  ['0x2c5e82553e78e9328c6c780b1663ea15b8a94f6b', '4'],
  ['0x2c8368a8dbc4cfe55e50e256cbccfcaf4c6bf0c3', '1'],
  ['0x2d8857169dbd3368f1cccaf533104b1b7d80ea9f', '1'],
  ['0x2ecfa4c09d0501230006aa75657d12658c997f70', '1'],
  ['0x2f20bd04bdca1c4b071fbded3984d962db0ec0d9', '1'],
  ['0x2f5a31ffcd5dc6ad69cdb24c23a224d08a5c3d5e', '1'],
  ['0x3038bcab373008aa093a18ed47bbe66122025f92', '1'],
  ['0x31984b0509302c2832a5040c93208124b047ce32', '4'],
  ['0x330ac8ee534af96cd21e695537db9730c425559d', '1'],
  ['0x330eb5111bbd97d59fbb4a2022d3e0c981fe79ca', '1'],
  ['0x340a11b441cf367a96dd0b15cf418a02e18d4d65', '1'],
  ['0x35dee1d24acf24cc7b4067dbfc0e105dff16cfc5', '1'],
  ['0x37fba2f5c38004f079da7a540dec8d5f7b1053fe', '1'],
  ['0x38e589b1becc251dcb4893613a89226f79ba6560', '1'],
  ['0x3977f0624df75cdd264a62159109592df10c5c05', '1'],
  ['0x3999f3f4f4698935d69e3c8d55f963a65e70d9fd', '4'],
  ['0x399bf4109ac8617f3531a76c0e1cd6941abfc3d2', '1'],
  ['0x3a23f46f765947e5f28e5ec96d5bcfdece9de844', '1'],
  ['0x3a5ccd31ab002da84a052d5daa969cf8d79a7fbb', '1'],
  ['0x3a6ace8f96949d5fbac006b0e0be781ef48d46c6', '1'],
  ['0x3a83fbeaa064348f7fd2928cf1489ace5de8fe3d', '1'],
  ['0x3babbf02d9c4c38ce731b51ddc98bcb7497a2ef7', '1'],
  ['0x3c2ff6816020a937a87dc0947be2143466835fa0', '1'],
  ['0x3dac8af200f59b72b1ce277c7a012a2644500a9b', '1'],
  ['0x3dcd4dbd7dc6aec16d46e1c55479c407b7e3d302', '1'],
  ['0x4083e57f539882257f007ae75f1121f35ec7b29e', '4'],
  ['0x41178be269f65ee8c618cf9326864a829e4f98ec', '1'],
  ['0x42482d896e94a320d2f2d55e9d82e7cc3bd13675', '1'],
  ['0x44b7b7bf9b3f37f4b7ffdc769d404c6ddb0fe8db', '1'],
  ['0x47431fb2361f870f5832663bb46cd89b620d4b75', '1'],
  ['0x479cbcefb8612ff89cb7ced092be52c485e34444', '1'],
  ['0x4af59fb935ad28f3e6780cd71ba07d8d95042d41', '1'],
  ['0x4c5bff23fb55d2d427c68a76bf0106444707e26a', '1'],
  ['0x4ca3d7e44925565603d58eeefb6ca2011e55e2ab', '1'],
  ['0x4e5f21f33a0546e754ddc004bf07e85bcc58ff7e', '1'],
  ['0x508f59ad5e9e12b047ff472f9c443adde139288c', '1'],
  ['0x51846f60a5d060105d10e962e8a5676285e2575c', '4'],
  ['0x5262b8b5d73ad3f80721116f77232a95f5291f8c', '1'],
  ['0x52739303ab90df98aa0bd04c544e1463b2fe0a42', '1'],
  ['0x538159bf9c3d98c34050a8ef074d4b002f730148', '1'],
  ['0x5484538a6ab1c4dec7a7596e976474cdef6ad33a', '1'],
  ['0x5739e7c979ff1ffd7fe874bbe980479c714f25cb', '1'],
  ['0x58a898f503c9c9239a04f904380202dafe428dbc', '1'],
  ['0x5a1bc6687f7f6df2cbff33b6624f407d00bab292', '1'],
  ['0x5a1f88899abeb569bc8898fa628a115a156fff99', '1'],
  ['0x5a819857dd40fc04d5bca0ddd266e80574ee6f1a', '1'],
  ['0x5cc4e62ba7c5cbfe608611311ca9be47255e64a8', '1'],
  ['0x64a4e59fc0c4b5c72bfd17dad07010a5e7d3992f', '1'],
  ['0x6700abcbff00ceb974ff65ff8e0a3f84043bb601', '1'],
  ['0x680796bc6014d35250184aff429fa8bf1f542850', '1'],
  ['0x69529a77af73c49ce600af4634b7aba32356cf85', '1'],
  ['0x6aa1aa1bf5a7087df543d3f639ced62e5644f624', '1'],
  ['0x6c9ee942e87714926f3ff272be2b19d52c943b63', '2'],
  ['0x6d9e4c11b781f46fe2d5f81a1ee4eadf1d1e1341', '1'],
  ['0x6ea9fa000ee8c9ef4c506c8549a3ed4823298fc2', '1'],
  ['0x6f674bc8ecab9c5f25716abdb722d2fee74cd0fb', '4'],
  ['0x71070d8a75d44a62df89879e8abcbb372d76af2c', '1'],
  ['0x749168d550392eb8cf5f78e4b55716d5c461d480', '1'],
  ['0x77b4c9184ee2b7a0cdadc08db5412f5e1eca0624', '1'],
  ['0x7872073a281cfdf30c98b52c80396d5d8bc1e762', '1'],
  ['0x7be6532772631905cbce4a4402bb6b5364b3e489', '1'],
  ['0x7bf94542e816eb117455eb35f52297e3e52ba55e', '1'],
  ['0x7c3801ae98717962c4f1ee4dfe13190820bd1be8', '2'],
  ['0x7dcd96e78af846352d96a74cff8883b33f7476ca', '1'],
  ['0x7f13a63e14d5d6749ecf73e3080e2e14c4c6523f', '1'],
  ['0x808b14cf09a7411b36ab199dcc50ba91bdfc5e91', '1'],
  ['0x81cce6fd284b5c76c9725389e29315cb7e181501', '1'],
  ['0x825134e48ecfa2dd4afb24e41b9147ed63319b43', '1'],
  ['0x826cec90646a88f1fdbcb4a12ca4242c598e08e1', '1'],
  ['0x857443f81d420c620d9e8fb0a34cb861b015383c', '1'],
  ['0x86a05f8d0f9f5dfd7a3ebacdbfb1dfe8a465f10c', '1'],
  ['0x8751a5bfaed0a2b9dac06da799d9e77191a06dec', '1'],
  ['0x879fcfb5e399ad152cf9b4c1d4784c6a9bcd2fdd', '1'],
  ['0x8b0d3c5e41903c974480281f179cd13e5ede67d7', '1'],
  ['0x8cab5eeb6dc4025518ba71b739a42981202a0780', '1'],
  ['0x9120b5ba9cddf9b6dbbbe05ca44c586fe1ac8de5', '1'],
  ['0x974284bd5a4b3571a1c8871181da07930c410780', '1'],
  ['0x975cb2231df1b6fc9feb45b5aaed0a215110c9f6', '1'],
  ['0x9b0dbdc85edebfeb49c4df397cc4b6c0a6ccf1d5', '1'],
  ['0x9c0fd8c2f40c20fdbe8a7e01a8ca6a71a28434f6', '1'],
  ['0x9d204b6f33822646e05880bab795cc4e6516f83b', '1'],
  ['0x9d40cd7d95a00cfae3bb81e477a394f3a070a136', '1'],
  ['0x9f4727b0457c191d23eb8b6ac7d317da99be7056', '1'],
  ['0xa090229a3cc74f13d504aa901cd366e8ace8e4da', '1'],
  ['0xa30d7896b93ee6b9f1541d063f23b57d54952ead', '2'],
  ['0xa4227f858d479cd7245403a6cb584246051a26cf', '1'],
  ['0xa4a7863743382656f51932c235010bea908e3495', '2'],
  ['0xa54a56fea8c904de8337cf58a911edacfb5e502f', '1'],
  ['0xa6f55ba7ffad105512904d0516aa20b29d4ac90b', '1'],
  ['0xa84871a6600306e7720916999be543e86ab662a3', '1'],
  ['0xaa5116fe4194d1679a0011600b0c3ff68861068f', '1'],
  ['0xaa8ca7d8859e21f359e45160b4be371f5276002f', '1'],
  ['0xab220df787a3f9190dabb39893a33f439fe937aa', '1'],
  ['0xac28a58177bd025a8b5c831472badcab0504f5b7', '1'],
  ['0xb03717bbdec4e91db0297b3ac85bff080b5dce75', '1'],
  ['0xb075dece0b3592e7c8ca6b9832b18cc810eece0c', '1'],
  ['0xb301063f438ba20dad9f2582d9eaf67cec96e2fa', '1'],
  ['0xb4e5e8954bf409358b5bba6928d38fc80adab2a6', '1'],
  ['0xb5bdce686b54f07696b1477c842f29bf6f693dd8', '4'],
  ['0xb5ef3d1d47b7799cc651582050f47fe063d9376f', '1'],
  ['0xb75b439de4545587c09535e3227e5263a3cb1483', '1'],
  ['0xb89ed450499cd227503b21e48501715b4c11b4f7', '4'],
  ['0xbbf2c9ff47bc66a3d7108022685e4de293020289', '1'],
  ['0xbcdeaacab495606e7d66cc6db9054a9f535549b1', '1'],
  ['0xbd164a445af87d7c9a8abdfae8f35b0240705d91', '1'],
  ['0xbeae6d2e5894199e6deb2fbce0baee3a4cf67797', '1'],
  ['0xbf9817ca997722d33c4dc9d797b09abfe507eb4a', '1'],
  ['0xc064fed646dffa5e215f48ead89840fb692c8f59', '1'],
  ['0xc2d708ec3ef92c912444087d4a337f6a2ca05466', '1'],
  ['0xc3f6ac34ffbbfdb33ed4bc35561b5b4b0584957a', '2'],
  ['0xc5177be1387e2135b7123925dde6152832dbe1b0', '1'],
  ['0xc6deb8c01595ade32428d4ed89260717b4dcbf85', '1'],
  ['0xc8e18db277f2cf47b41325c1cd25ad923960ecc4', '1'],
  ['0xc90b9c980ecaaee5f60d1ccb0743381ab1d0c7c2', '1'],
  ['0xc91fa8bcc82daaf4ac4d7a2c150147b19c3c673b', '1'],
  ['0xcaf6864294bc90f452a8538a66d8d3c6ba0358d6', '2'],
  ['0xcbb4e9cdb166b26cc3218311b920390422872b1f', '1'],
  ['0xd24ca3a6cd312df2250cbd6fd84c8cd43aa35e52', '1'],
  ['0xd364400bc3373b4265254701674f4249d9a1d531', '1'],
  ['0xd54b5b6c01332ec48938d9875657bde43ef02b1e', '1'],
  ['0xd55694b3e17c08fe52adfc139f551e6e97a18d1e', '1'],
  ['0xd870b3fcd2ca9d1d374a6f95a31d6612f58d6251', '1'],
  ['0xd9d78ea4d178c0dc6647966547cd97102a0c16fb', '4'],
  ['0xda4ee19de306cd1a9e8e4189ae937d345ac6a327', '1'],
  ['0xda9503e11be27e0b1a33b1d983ab7aaf0c5e4dd8', '1'],
  ['0xdad786239a323058541ee5576133265d1e7742cf', '2'],
  ['0xdb94ddb73637f567bcbd67d4a606c9aa54fcfd72', '1'],
  ['0xdd8b2bbb195df940326ce6dbed534699f42ae699', '1'],
  ['0xdde80b2bc9a9054d886a24abcf203c096fe466a0', '1'],
  ['0xdde910c5d7216a9860d34a6fa68b9dbdeb9bbdde', '1'],
  ['0xdf5dbfe7b8465d8221d7151bcbbc8c21aac521e9', '1'],
  ['0xe052462ddf5c3d263993c41ce4cc7379b92e22b9', '1'],
  ['0xe09ee767a3cfa4db41726a7761e50f0e6fb7ed97', '4'],
  ['0xe1e6f36527b01e8152f4b69e4abb0ae60d1643e6', '1'],
  ['0xe3171ff152f3bd3614dca3ac59e04bebbd1d763b', '1'],
  ['0xe4b7df54af06f7502c23ff4e2c93f21d7f77b98b', '4'],
  ['0xe84fc191a4b38bdab4a4219c3335bb5c1c1d6213', '1'],
  ['0xeb0a1619b90d6525c2de216b501b7d2833bac697', '1'],
  ['0xef41421593f0211154665670ffb925c63243c3c6', '1'],
  ['0xefcce0e719e982e927f0ebd0874644bf980679b1', '1'],
  ['0xf073dd21c9c25ce98614cc52eee98e3d8385d5d8', '1'],
  ['0xf2cfd6bc22d458216cebad56ea05477b0546e463', '1'],
  ['0xf2dbf8255d7e52e8e89f7887a8700ceef570fe0e', '1'],
  ['0xf32e58976da7aea16677eddddb0f7841a31456fd', '1'],
  ['0xf4c228eedddb40c38c5e05c44e67f82dd68f365c', '1'],
  ['0xf63ce05b637469fb9e4cb30b1ef4b713d8af8662', '1'],
  ['0xf741ab0d9bd89a228dd9ef20c7b57f4327c08c3a', '1'],
  ['0xf9cad2d0859e6c0e7748e52a18c07644b80e62b5', '1'],
  ['0xf9da918423820403401632741ac3dacc4c9f5a6e', '1'],
  ['0xfc90e2e19e200de73b9914abadacdd994e801c02', '1'],
  ['0xfe13f9cb05d95bb40b619baa3abf5db2b963363e', '1'],
  ['0xfe911f1243ce3e4a4959666b8e0e9ccfbddc0320', '1'],
];
