import { TetuGame } from '@shared/constants/addresses/TetuGame';
import { ISound, SOUND_TYPES } from '@shared/types/sound-types';

const M = TetuGame.MONSTERS;

export enum ACTION_KIND {
  PHRASES = 'phrases',
  ATTACK = 'attack',
  DAMAGE = 'damage',
  MISS = 'miss',
}

export type SOUND_MONSTER_TYPE = {
  attack: ISound[];
  damage: ISound[];
  miss: ISound[];
  phrases?: ISound[];
};

function monsterNode(monsterId: number, countAttack: number, countDamage: number, countMiss: number, countPhrases = 0) {
  const attack: ISound[] = [];
  const damage: ISound[] = [];
  const miss: ISound[] = [];

  const result: SOUND_MONSTER_TYPE = {
    attack,
    damage,
    miss,
  };

  for (let i = 0; i < countAttack; i++) {
    attack.push({
      id: `${ACTION_KIND.ATTACK}-${monsterId}-${i}`,
      type: SOUND_TYPES.EFFECT,
      file: `monsters/${monsterId}/${ACTION_KIND.ATTACK}/${i}.mp3`,
    });
  }
  for (let i = 0; i < countDamage; i++) {
    damage.push({
      id: `${ACTION_KIND.DAMAGE}-${monsterId}-${i}`,
      type: SOUND_TYPES.EFFECT,
      file: `monsters/${monsterId}/${ACTION_KIND.DAMAGE}/${i}.mp3`,
    });
  }
  for (let i = 0; i < countMiss; i++) {
    miss.push({
      id: `${ACTION_KIND.MISS}-${monsterId}-${i}`,
      type: SOUND_TYPES.EFFECT,
      file: `monsters/${monsterId}/${ACTION_KIND.MISS}/${i}.mp3`,
    });
  }
  for (let i = 0; i < countPhrases; i++) {
    miss.push({
      id: `${ACTION_KIND.PHRASES}-${monsterId}-${i}`,
      type: SOUND_TYPES.EFFECT,
      file: `monsters/${monsterId}/${ACTION_KIND.PHRASES}/${i}.mp3`,
    });
  }
  return result;
}

/***
 MIDNIGHTER 1020014
 INFECTED_SPIRIT_OF_THE_FOREST 1030016
 BANDIT_CROWD 1100012
 REBELLIOUS_PEASANT 1100017
 THRALL 1100018
 SAVAGE 1100019
 MAGE 1100020
 ASSASSIN 1100021
 BARBARIAN_BITCH 1100022
 RESTLESS_SPIRIT 1100023
 HANGMANS_PATROL 1100026
 A_LITTLE_MORE_BANDITS_THAN_USUAL 1100027
 REBELLIOUS_PEASANT 1100028
 DRUNK_CROSSBOWMAN 1100029
 SOMBER_HANGMAN 1100030
 FILTHY_WILLY 1100031
 BANISHED_PEASANT 1100032
 STARVING_WOLFS 1100033
 PEDOPHILE_HUNTER 1100034
 FRESH_INFECTED_DEADMAN 1100035
 STYLE_POLICE 1100036
 BAD_BEAR 1100037
 REBELLIOUS_PEASANT 1320006
 TOUGH_DESERTER 1320007
 BLOODTHIRSTY_MADMAN 1320008
 CROSSBOW_MAN 1320009
 INFECTED_DEADMAN_JUNIOR 1320024
 HANGMANS_PATROL 1330013
 REBELLIOUS_PEASANT 1340001
 TOUGH_DESERTER 1340002
 BLOODTHIRSTY_MADMAN 1340003
 FEROCIOUS_WOLFS 1340004
 CROSSBOW_MAN 1340005
 INFECTED_DEADMAN_JUNIOR 1340025
 VILE_SERPENT 1350010
 THE_MAN_EATING_BEAR 1350011
 FOREST_CHORT 1350015

 FUNH 2030016
 RUINS_KEEPER 2100014
 STRABAN_DOUCHEBAGS_AVENGERS 2100015
 REBELLIOUS_PEASANT 2100017
 SOMBER_HANGMAN 2320006
 THRALL_HUNTER 2320007
 WILDED_ANCHORITE 2320008
 CHEERFUL_HANGMAN 2320009
 FOREST_SHAMAN 2330012
 FILTHY_WILLY 2330013
 BLOODCURDLING_WOLFS 2340001
 SOMBER_HANGMAN 2340002
 THRALL_HUNTER 2340003
 WILDED_ANCHORITE 2340004
 CHEERFUL_HANGMAN 2340005
 FOREST_SHAMAN 2350010
 FILTHY_WILLY 2350011

 HUNGRY_BROOD 3030014
 CHASER 3100008
 A_LITTLE_MORE_BANDITS_THAN_USUAL 3100009
 OGRE_BARON 3100010
 ELITE_PRINCE_FELLOWS 3100011
 BROTHER_DAMOUR 3100013
 MOCK3 3100015
 DEMOLDER_WARRIOR 3340001
 DEMOLDER_SERGEANT 3340002
 DEMOLDER_BOWMAN 3340003
 RUNE_PRIEST 3340004
 DEMOLDER_HEADBRINGER 3340005
 UNFRIENDLY_HOUND 3340012
 ANCIENT_SERPENT 3350006
 RUNE_KING 3350007

 MOTHER_MIOLANIA 4030019
 LOST_BROTHER_DAMOUR 4100008
 HAKKAMANDA 4100013
 MELADZI 4100014
 PUG_PANIN 4100015
 LEGION_COMMANDER_DOGWAN 4100016
 RUNE_KING_VISWANATHAN 4100017
 GIANT_WASP 4340001
 MOONHUNTER 4340002
 GELDED_ONE 4340003
 RUNEBEARER 4340004
 DEMOLDER_BERSERK 4340005
 CULTIST_BOY_OF_MOTHER 4340011
 CULTIST_GIRL_OF_MOTHER 4340012
 SWAMP_TROLL 4350006
 INFECTED_DEADMAN 4350007
 BARD_CHICK 4350009
 WASP_QUEEN 4350010
 GIRLS_AFTER_PARTY 4350018
**/

export const SOUNDS_MONSTER = new Map<number, SOUND_MONSTER_TYPE>([
  [M.REBELLIOUS_PEASANT_34, monsterNode(M.REBELLIOUS_PEASANT_34, 2, 2, 2)],
  [M.TOUGH_DESERTER_34, monsterNode(M.TOUGH_DESERTER_34, 2, 2, 1)],
  [M.BLOODTHIRSTY_MADMAN_34, monsterNode(M.BLOODTHIRSTY_MADMAN_34, 2, 2, 2)],
  [M.FEROCIOUS_WOLFS_34, monsterNode(M.FEROCIOUS_WOLFS_34, 2, 2, 1)],
  [M.DRUNK_CROSSBOWMAN_34, monsterNode(M.DRUNK_CROSSBOWMAN_34, 2, 1, 1)],
  [M.REBELLIOUS_PEASANT_32, monsterNode(M.REBELLIOUS_PEASANT_32, 2, 2, 2)],
  [M.TOUGH_DESERTER_32, monsterNode(M.TOUGH_DESERTER_32, 2, 2, 1)],
  [M.BLOODTHIRSTY_MADMAN_32, monsterNode(M.BLOODTHIRSTY_MADMAN_32, 2, 2, 2)],
  [M.DRUNK_CROSSBOWMAN_32, monsterNode(M.DRUNK_CROSSBOWMAN_32, 2, 1, 1)],
  [M.VILE_SERPENT_35, monsterNode(M.VILE_SERPENT_35, 2, 2, 2)],
  [M.THE_MAN_EATING_BEAR_35, monsterNode(M.THE_MAN_EATING_BEAR_35, 2, 1, 1)],
  [M.BANDIT_CROWD_10, monsterNode(M.BANDIT_CROWD_10, 2, 2, 1)],
  [M.HANGMANS_PATROL_33, monsterNode(M.HANGMANS_PATROL_33, 2, 2, 0)], // todo
  [M.MIDNIGHTER_2, monsterNode(M.MIDNIGHTER_2, 3, 2, 2)],
  [M.FOREST_CHORT_35, monsterNode(M.FOREST_CHORT_35, 2, 2, 1)],
  [M.INFECTED_SPIRIT_OF_THE_FOREST_3, monsterNode(M.INFECTED_SPIRIT_OF_THE_FOREST_3, 2, 2, 1)],
  [M.MOCK1, monsterNode(M.MOCK1, 2, 2, 2)],
  [M.THRALL_10, monsterNode(M.THRALL_10, 2, 2, 2, 3)],
  [M.SAVAGE_10, monsterNode(M.SAVAGE_10, 3, 3, 1, 2)],
  [M.MAGE_10, monsterNode(M.MAGE_10, 2, 2, 2, 4)],
  [M.ASSASSIN_10, monsterNode(M.ASSASSIN_10, 2, 2, 2, 3)],
  [M.BARBARIAN_BITCH_10, monsterNode(M.BARBARIAN_BITCH_10, 3, 3, 1, 2)],
  [M.RESTLESS_SPIRIT_10, monsterNode(M.RESTLESS_SPIRIT_10, 2, 2, 2)],
  [M.INFECTED_DEADMAN_JUNIOR_32, monsterNode(M.INFECTED_DEADMAN_JUNIOR_32, 2, 2, 2)],
  [M.INFECTED_DEADMAN_JUNIOR_34, monsterNode(M.INFECTED_DEADMAN_JUNIOR_34, 2, 2, 2)],
  [M.HANGMANS_PATROL_F2P, monsterNode(M.HANGMANS_PATROL_F2P, 2, 2, 0)], // todo
  [M.A_LITTLE_MORE_BANDITS_THAN_USUAL_F2P, monsterNode(M.A_LITTLE_MORE_BANDITS_THAN_USUAL_F2P, 1, 1, 1)],
  [M.REBELLIOUS_PEASANT_F2P, monsterNode(M.REBELLIOUS_PEASANT_F2P, 2, 2, 2)],
  [M.DRUNK_CROSSBOWMAN_F2P, monsterNode(M.DRUNK_CROSSBOWMAN_F2P, 2, 1, 1)],
  [M.SOMBER_HANGMAN_F2P, monsterNode(M.SOMBER_HANGMAN_F2P, 2, 2, 2)],
  [M.FILTHY_WILLY_F2P, monsterNode(M.FILTHY_WILLY_F2P, 2, 2, 1)],
  [M.BANISHED_PEASANT_10, monsterNode(M.BANISHED_PEASANT_10, 2, 2, 2)],
  [M.STARVING_WOLFS_10, monsterNode(M.STARVING_WOLFS_10, 2, 2, 1)],
  [M.PEDOPHILE_HUNTER_10, monsterNode(M.PEDOPHILE_HUNTER_10, 2, 1, 1)],
  [M.FRESH_INFECTED_DEADMAN_10, monsterNode(M.FRESH_INFECTED_DEADMAN_10, 2, 2, 2)],
  [M.STYLE_POLICE_10, monsterNode(M.STYLE_POLICE_10, 2, 2, 0)], // todo
  [M.BAD_BEAR_10, monsterNode(M.BAD_BEAR_10, 2, 1, 1)],
  [M.ORDINARY_GOOSE_32, monsterNode(M.ORDINARY_GOOSE_32, 4, 4, 4)],
  [M.ORDINARY_GOOSE_34, monsterNode(M.ORDINARY_GOOSE_34, 4, 4, 4)],
  [M.LOCAL_DUDE_32, monsterNode(M.LOCAL_DUDE_32, 2, 2, 2)],
  [M.LOCAL_DUDE_34, monsterNode(M.LOCAL_DUDE_34, 2, 2, 2)],
  [M.HUNGRY_DOG_32, monsterNode(M.HUNGRY_DOG_32, 2, 2, 2)],
  [M.HUNGRY_DOG_34, monsterNode(M.HUNGRY_DOG_34, 2, 2, 2)],
  [M.ROWDY_10, monsterNode(M.ROWDY_10, 1, 1, 1)],
  [M.OLD_WHORE_10, monsterNode(M.OLD_WHORE_10, 2, 1, 1)],
  [M.TRAINING_BUDDY_33, monsterNode(M.TRAINING_BUDDY_33, 2, 2, 2)],
  [M.FEROCIOUS_WIFE_33, monsterNode(M.FEROCIOUS_WIFE_33, 1, 1, 1)],
  [M.STRABAN_KNIGHT_10, monsterNode(M.STRABAN_DOUCHEBAGS_AVENGERS_10, 2, 2, 2)],

  [M.BLOODCURDLING_WOLFS_34, monsterNode(M.BLOODCURDLING_WOLFS_34, 3, 2, 1)],
  [M.SOMBER_HANGMAN_34, monsterNode(M.SOMBER_HANGMAN_34, 2, 2, 2)],
  [M.THRALL_HUNTER_34, monsterNode(M.THRALL_HUNTER_34, 2, 1, 2)],
  [M.WILDED_ANCHORITE_34, monsterNode(M.WILDED_ANCHORITE_34, 4, 2, 2)],
  [M.CHEERFUL_HANGMAN_34, monsterNode(M.CHEERFUL_HANGMAN_34, 0, 0, 0)],
  [M.SOMBER_HANGMAN_32, monsterNode(M.SOMBER_HANGMAN_32, 2, 2, 2)],
  [M.THRALL_HUNTER_32, monsterNode(M.THRALL_HUNTER_32, 2, 1, 2)],
  [M.WILDED_ANCHORITE_32, monsterNode(M.WILDED_ANCHORITE_32, 4, 2, 2)],
  [M.CHEERFUL_HANGMAN_32, monsterNode(M.CHEERFUL_HANGMAN_32, 2, 2, 1)],
  [M.FOREST_SHAMAN_35, monsterNode(M.FOREST_SHAMAN_35, 3, 1, 1)],
  [M.FILTHY_WILLY_35, monsterNode(M.FILTHY_WILLY_35, 2, 2, 1)],
  [M.FOREST_SHAMAN_33, monsterNode(M.FOREST_SHAMAN_33, 3, 1, 1)],
  [M.FILTHY_WILLY_33, monsterNode(M.FILTHY_WILLY_33, 2, 2, 1)],
  [M.RUINS_KEEPER_10, monsterNode(M.RUINS_KEEPER_10, 2, 2, 2)],
  [M.STRABAN_DOUCHEBAGS_AVENGERS_10, monsterNode(M.STRABAN_DOUCHEBAGS_AVENGERS_10, 2, 2, 2)],
  [M.FUNH_3, monsterNode(M.FUNH_3, 2, 2, 2)],
  [M.MOCK2, monsterNode(M.MOCK2, 2, 2, 2)],
  [M.FUGITIVE_KUDJA_33, monsterNode(M.FUGITIVE_KUDJA_33, 2, 2, 2)],
  [M.FUGITIVE_KUDJA_35, monsterNode(M.FUGITIVE_KUDJA_35, 2, 2, 2)],
  [M.SMELLY_CANNIBAL_34, monsterNode(M.SMELLY_CANNIBAL_34, 2, 2, 2)],
  [M.T_POSER_33, monsterNode(M.T_POSER_33, 4, 4, 4)],
  [M.T_POSER_35, monsterNode(M.T_POSER_35, 4, 4, 4)],
  [M.BAD_SEED_33, monsterNode(M.BAD_SEED_33, 0, 0, 0)], // todo
  [M.BAD_SEED_35, monsterNode(M.BAD_SEED_35, 0, 0, 0)], // todo
  [M.RIPE_MIDNIGHTER_2, monsterNode(M.RIPE_MIDNIGHTER_2, 3, 2, 2)],
  [M.WOUNDED_DEMOLDER_10, monsterNode(M.DEMOLDER_BOWMAN_34, 1, 1, 1)],
  [M.HANGOVER_WILLY_10, monsterNode(M.FILTHY_WILLY_35, 2, 2, 1)],
  [M.VENOMOUS_SPIDER_10, monsterNode(M.SMALL_SPIDER_34, 2, 2, 2)],

  [M.DEMOLDER_WARRIOR_34, monsterNode(M.DEMOLDER_WARRIOR_34, 2, 2, 2)],
  [M.DEMOLDER_SERGEANT_34, monsterNode(M.DEMOLDER_SERGEANT_34, 2, 2, 2)],
  [M.DEMOLDER_BOWMAN_34, monsterNode(M.DEMOLDER_BOWMAN_34, 1, 1, 1)],
  [M.RUNE_PRIEST_34, monsterNode(M.RUNE_PRIEST_34, 1, 1, 0)], // todo
  [M.DEMOLDER_HEADBRINGER_34, monsterNode(M.DEMOLDER_HEADBRINGER_34, 1, 1, 1)],
  [M.ANCIENT_SERPENT_35, monsterNode(M.ANCIENT_SERPENT_35, 1, 1, 1)],
  [M.RUNE_KING_35, monsterNode(M.RUNE_KING_35, 0, 0, 0)], // todo
  [M.CHASER_35, monsterNode(M.CHASER_35, 1, 1, 0)],
  [M.A_LITTLE_MORE_BANDITS_THAN_USUAL_10, monsterNode(M.A_LITTLE_MORE_BANDITS_THAN_USUAL_10, 1, 1, 1)],
  [M.OGRE_BARON_10, monsterNode(M.OGRE_BARON_10, 1, 1, 1)],
  [M.ELITE_PRINCE_FELLOWS_10, monsterNode(M.ELITE_PRINCE_FELLOWS_10, 0, 0, 0)], // todo
  [M.UNFRIENDLY_HOUND_34, monsterNode(M.UNFRIENDLY_HOUND_34, 1, 1, 1)],
  [M.BROTHER_DAMOUR_10, monsterNode(M.BROTHER_DAMOUR_10, 1, 1, 1)],
  [M.HUNGRY_BROOD_3, monsterNode(M.HUNGRY_BROOD_3, 1, 1, 1)],
  [M.MOCK3, monsterNode(M.MOCK3, 2, 2, 2)],
  [M.JUICY_MIDNIGHTER_2, monsterNode(M.JUICY_MIDNIGHTER_2, 3, 2, 2)],
  [M.MERGULLIAN_HEADHUNTER_34, monsterNode(M.MERGULLIAN_HEADHUNTER_34, 2, 2, 2)],
  [M.SMALL_SPIDER_34, monsterNode(M.SMALL_SPIDER_34, 2, 2, 2)],
  [M.YOUNG_TROLL_35, monsterNode(M.YOUNG_TROLL_35, 2, 2, 2)],
  [M.FACELESS_10, monsterNode(M.FACELESS_10, 4, 4, 4)],
  [M.LONE_BARD_35, monsterNode(M.LONE_BARD_35, 2, 2, 1)],
  [M.FOREST_WITCH_35, monsterNode(M.FOREST_WITCH_35, 1, 1, 1)],
  [M.DEMOLDER_CHAMPION_SCALD_10, monsterNode(M.DEMOLDER_WARRIOR_34, 2, 2, 2)],
  [M.DEMOLDER_CHAMPION_THORN_10, monsterNode(M.DEMOLDER_BOWMAN_34, 1, 1, 1)],
  [M.DEMOLDER_CHAMPION_GEKK_10, monsterNode(M.DEMOLDER_HEADBRINGER_34, 1, 1, 1)],
  [M.MIGHTY_RUIN_KEEPER_10, monsterNode(M.RUINS_KEEPER_10, 2, 2, 2)],

  [M.GIANT_WASP_34, monsterNode(M.GIANT_WASP_34, 0, 0, 0)], // todo
  [M.MOONHUNTER_34, monsterNode(M.MOONHUNTER_34, 1, 1, 1)],
  [M.GELDED_ONE_34, monsterNode(M.GELDED_ONE_34, 1, 1, 1)],
  [M.RUNEBEARER_34, monsterNode(M.RUNEBEARER_34, 1, 1, 1)],
  [M.DEMOLDER_BERSERK_34, monsterNode(M.DEMOLDER_BERSERK_34, 0, 0, 1)], // todo
  [M.SWAMP_TROLL_35, monsterNode(M.SWAMP_TROLL_35, 1, 1, 1)],
  [M.INFECTED_DEADMAN_35, monsterNode(M.INFECTED_DEADMAN_35, 2, 2, 2)],
  [M.LOST_BROTHER_DAMOUR_10, monsterNode(M.LOST_BROTHER_DAMOUR_10, 1, 1, 1)],
  [M.BARD_CHICK_35, monsterNode(M.BARD_CHICK_35, 0, 0, 0)], // todo
  [M.WASP_QUEEN_35, monsterNode(M.WASP_QUEEN_35, 0, 0, 0)], // todo
  [M.CULTIST_BOY_OF_MOTHER_34, monsterNode(M.CULTIST_BOY_OF_MOTHER_34, 0, 0, 0)], // todo
  [M.CULTIST_GIRL_OF_MOTHER_34, monsterNode(M.CULTIST_GIRL_OF_MOTHER_34, 0, 0, 0)], // todo
  [M.HAKKAMANDA_10, monsterNode(M.HAKKAMANDA_10, 0, 0, 0)], // todo
  [M.MELADZI_10, monsterNode(M.MELADZI_10, 0, 0, 0)], // todo
  [M.PUG_PANIN_10, monsterNode(M.PUG_PANIN_10, 0, 0, 0)], // todo
  [M.LEGION_COMMANDER_DOGWAN_10, monsterNode(M.LEGION_COMMANDER_DOGWAN_10, 0, 0, 0)], // todo
  [M.RUNE_KING_VISWANATHAN_10, monsterNode(M.RUNE_KING_VISWANATHAN_10, 1, 1, 1)],
  [M.GIRLS_AFTER_PARTY_35, monsterNode(M.GIRLS_AFTER_PARTY_35, 0, 0, 0)], // todo
  [M.MOTHER_MIOLANIA_3, monsterNode(M.MOTHER_MIOLANIA_3, 0, 0, 0)], // todo
  [M.POSTAL_HEADMISTRESS_10, monsterNode(M.POSTAL_HEADMISTRESS_10, 2, 2, 1)],
  [M.DARK_ADVENTURERS_35, monsterNode(M.DARK_ADVENTURERS_35, 2, 2, 2)],
  [M.SPICY_MIDNIGHTER_2, monsterNode(M.SPICY_MIDNIGHTER_2, 3, 2, 2)],
  [M.DARK_ESSENCE_35, monsterNode(M.DARK_ESSENCE_35, 0, 0, 0)], // todo
  [M.KNIGHT_HAMMERGINA_10, monsterNode(M.KNIGHT_HAMMERGINA_10, 0, 0, 0)], // todo
  [M.FULLMOON_DARK_ESSENCE_10, monsterNode(M.FULLMOON_DARK_ESSENCE_10, 0, 0, 0)], // todo
  [M.SUPERGOOSE_10, monsterNode(M.ORDINARY_GOOSE_34, 4, 4, 4)],
]);
