@if (!iconOnly) {
  <div
    class="selected g-flex-column g-flex--space-between"
    [class.selected--special]="size === DROPDOWN_SIZE.MEDIUM_SPECIAL"
    [ngClass]="size"
    [class.selected--hide]="isRearrangeItems && isOpen"
    [style.min-width.px]="DROPDOWN_WIDTHS[size]"
    [style.min-height.px]="!!selectedItemBgHeight ? selectedItemBgHeight : DROPDOWN_ITEM_HEIGHT"
    [style.background-image]="'url(/assets/images/ui/elements/dropdown/' + size + '/1.avif)'"
    (click)="items.length > minForShow ? onToggle() : null"
    (keydown.escape)="isOpen = false"
    (clickOutside)="isOpen = false">
    @if (selectedItem) {
      <ng-container
        [ngTemplateOutlet]="itemTpl"
        [ngTemplateOutletContext]="{
          item: selectedItem,
          arrow: isRearrangeItems ? 'down' : isOpen ? 'up' : 'down',
          prefixIconPath: selectedItem.selectedItemPrefixIconPath || selectedItem.prefixIconPath,
          isTopItem: true,
        }">
      </ng-container>
    } @else {
      <ng-container [ngTemplateOutlet]="placeholderTpl"></ng-container>
    }
  </div>
} @else {
  <button
    [class]="iconOnly"
    (click)="hasCustomContent || items.length > minForShow ? onToggle() : null"
    (clickOutside)="isOpen = false"></button>
}

<ng-template #placeholderTpl>
  <div class="g-flex g-flex--align-center g-flex--space-between">
    <div class="color-caption">
      {{ placeholder || 'Select item' }}
    </div>

    @if (!iconOnly && isShowArrow && items.length > minForShow) {
      <div
        class="arrow no-action"
        [class.arrow__down]="!isOpen"
        [class.arrow__up]="isOpen"></div>
    }
  </div>
</ng-template>

@if (isOpen) {
  @if (hasCustomContent) {
    <div
      class="items g-flex-column"
      [class.items--icon-only]="!!iconOnly">
      <ng-content></ng-content>
    </div>
  } @else {
    <div
      class="items g-flex-column"
      [class.items--icon-only]="!!iconOnly"
      [class.items--no-rearrange]="!isRearrangeItems"
      [class.items--special]="size === DROPDOWN_SIZE.MEDIUM_SPECIAL"
      [ngClass]="size"
      [style.min-width.px]="DROPDOWN_WIDTHS[size]"
      [style.height.px]="DROPDOWN_ITEM_HEIGHT * itemsBgLength"
      [style.min-height.px]="!!selectedItemBgHeight ? selectedItemBgHeight : DROPDOWN_ITEM_HEIGHT * itemsBgLength"
      [style.background-image]="'url(/assets/images/ui/elements/dropdown/' + size + '/' + itemsBgLength + '.avif)'"
      (click)="onToggle()">
      @if (isRearrangeItems) {
        @if (selectedItem) {
          <ng-container
            [ngTemplateOutlet]="itemTpl"
            [ngTemplateOutletContext]="{
              item: items[0],
              arrow: 'up',
              prefixIconPath: items[0].prefixIconPath,
              isShowSuffixText: false,
            }">
          </ng-container>
        } @else {
          <ng-container [ngTemplateOutlet]="placeholderTpl"></ng-container>
        }
      }

      <div class="g-scroll-y">
        @for (item of items; track item; let index = $index) {
          @if ((isRearrangeItems && selectedItem && index > 0) || !selectedItem || !isRearrangeItems) {
            <ng-container
              [ngTemplateOutlet]="itemTpl"
              [ngTemplateOutletContext]="{
                item,
                prefixIconPath: item.prefixIconPath,
                isShowSuffixText: true,
                isSelected: !isRearrangeItems && selectedItem && selectedItem.id === item.id,
              }">
            </ng-container>
          }
        }
      </div>
    </div>
  }
}

<ng-template
  #itemTpl
  let-item="item"
  let-arrow="arrow"
  let-prefixIconPath="prefixIconPath"
  let-isShowSuffixText="isShowSuffixText"
  let-isSelected="isSelected"
  let-isTopItem="isTopItem">
  <div
    [attr.id]="isTopItem ? 'dropdown-top-item' : 'dropdown-item-' + item.id"
    class="item g-flex g-flex--align-center g-flex--space-between"
    [class.color-yellow-fire]="(iconOnly && selectedItem && selectedItem.id === item.id) || isSelected"
    [class.text-shadow-positive]="(iconOnly && selectedItem && selectedItem.id === item.id) || isSelected"
    [style.min-height.px]="DROPDOWN_ITEM_HEIGHT"
    (click)="!isRearrangeItems && isTopItem ? null : onSelectItem(item)">
    <div
      class="g-flex g-flex--align-center"
      [class.g-flex--space-between]="isShowSortDirection && selectedItem && selectedItem.id === item.id"
      [class.width-100]="isShowSortDirection && selectedItem && selectedItem.id === item.id">
      <div class="g-flex g-flex--align-center">
        @if (prefixIconPath) {
          <img
            class="prefix-img"
            [src]="prefixIconPath"
            [style.height]="item.iconHeight ? item.iconHeight : 'auto'"
            alt="img" />
        }

        <div>
          {{ item.label }}
        </div>
      </div>

      @if (isShowSortDirection && selectedItem?.id === item.id) {
        <img
          class="sort-img"
          [src]="
            sortDirection === 1 || sortDirection === 'asc'
              ? '/assets/images/ui/icons/arrows/up.png'
              : '/assets/images/ui/icons/arrows/down.png'
          "
          alt="sort-img" />
      }
    </div>

    @if (item.suffixIconPat) {
      <img
        class="suffix-img"
        [src]="item.suffixIconPath"
        [style.height]="item.iconHeight ? item.iconHeight : 'auto'"
        alt="img" />
    } @else {
      @if (!iconOnly && isShowArrow && arrow && items.length > minForShow) {
        <div class="arrow {{ 'arrow__' + arrow }}"></div>
      }
    }

    @if (isShowSuffixText && item.suffixText) {
      <div class="color-caption">
        {{ item.suffixText }}
      </div>
    }
  </div>
</ng-template>
