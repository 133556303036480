import { Injectable } from '@angular/core';
import { StatReader__factory } from '@data/abi';
import { StatController__factory } from '@data/abi/factories/StatController__factory';
import { ErrorService } from '@services/error.service';
import { ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { NGXLogger } from 'ngx-logger';
import { catchError, from, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatControllerService {
  constructor(
    private providerService: ProviderService,
    private errorService: ErrorService,
    private logger: NGXLogger,
  ) {}

  // --- FACTORIES ---

  private createStatController(chainId: number) {
    return StatController__factory.connect(
      GET_CORE_ADDRESSES(chainId).statController,
      this.providerService.getProviderForRead(),
    );
  }

  private createStatReader(chainId: number) {
    return StatReader__factory.connect(
      GET_CORE_ADDRESSES(chainId).statReader,
      this.providerService.getProviderForRead(),
    );
  }

  // --- VIEWS ---

  isConsumableUsed$(chainId: number, heroAdr: string, heroId: number, itemAdr: string) {
    this.logger.trace('isConsumableUsed', chainId, heroAdr, heroId, itemAdr);
    return from(this.createStatController(chainId).isConsumableUsed(heroAdr, heroId, itemAdr)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }
}
