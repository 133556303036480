<div
  class="avatar__mask"
  [ngClass]="'avatar__mask--is-' + avatarType">
  <img
    class="avatar__img"
    [ngClass]="'avatar__img--is-' + avatarType"
    [class.avatar__img--centered]="isCenteredImage"
    [src]="imagePath"
    alt="" />
</div>

<img
  class="avatar__frame"
  [ngClass]="'avatar__frame--is-' + avatarType"
  [src]="avatarPath"
  alt="" />
