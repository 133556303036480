import { isRealMetamask } from '@services/web3modal-ts/src';

const ConnectToInjected = async () => {
  let provider = null;
  if (isRealMetamask()) {
    provider = (window as any).ethereum;
    console.log('INJECTED PROVIDER', provider);
    try {
      await retryAsyncOperation(checkInjectedProvider, 1000, 30)
        .then(result => console.log('Success connect to Metamask:', result))
        .catch(error => console.error('Failure connect to Metamask:', error));
    } catch (error) {
      console.error('Metamask initial request failed!', error);
      throw new Error('Metamask initial request failed');
    }
  } else {
    console.error('No Web3 Provider found!');
    throw new Error('No Web3 Provider found');
  }
  return provider;
};

function checkInjectedProvider() {
  return (window as any).ethereum.request({ method: 'eth_requestAccounts' });
}

function timeoutPromise(ms: number): Promise<never> {
  return new Promise((_, reject) => setTimeout(() => reject(new Error('Operation timed out')), ms));
}

async function retryAsyncOperation<T>(operation: () => Promise<T>, timeoutMs: number, retries: number = 3): Promise<T> {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await Promise.race([operation(), timeoutPromise(timeoutMs)]);
    } catch (error) {
      if (attempt === retries || (error as Error).message !== 'Operation timed out') {
        throw error;
      }
      console.log(`Connect Metamask attempt ${attempt} failed, retrying...`);
    }
  }
  throw new Error('All retries failed');
}

export default ConnectToInjected;
