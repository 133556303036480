<app-dialog-title
  class="g-flex-column__item-fixed"
  [title]="data.title | translate"
  (closeDialog)="close()">
</app-dialog-title>

<div class="container g-flex-column g-flex--align-center gap-40">
  <div class="g-flex-column">
    <input
      class="app-input app-input--nickname app-btn_font-size"
      [type]="type"
      [placeholder]="data.title | translate"
      [formControl]="inputControl" />

    @if (data.maxLength) {
      <div
        class="error app-btn-small_font-size"
        [class.app-text-warning]="!isValueToLong"
        [class.text-shadow-positive]="!isValueToLong"
        [class.app-text-error]="isValueToLong"
        [class.text-shadow-negative]="isValueToLong">
        {{ 'guilds.max-length' | translate: { maxLength: data.maxLength } }}
      </div>
    }
  </div>

  @if (!!data.allowanceTarget) {
    <div class="balance g-flex gap-20">
      <div>
        {{ 'guilds.cost' | translate }}
      </div>

      <app-balance
        class="app-btn_font-size color-yellow-fire text-shadow"
        tokenType="sacra"
        [isReverse]="false"
        [isShowZeroBalance]="true"
        [isShowZeroBalanceUsd]="true"
        [balance]="data.amount ?? 0"
        [isShowBalanceUsd]="true">
      </app-balance>
    </div>
  }

  <button
    class="app-btn-red-3 g-flex g-flex--align-center g-flex--justify-center gap-20"
    appButtonClick
    [isDisabled]="inputControl.invalid || isLoading"
    (hostClick)="isNeedApprove ? callApprove() : onSave()">
    @if (isLoading) {
      <app-loading-small
        class="loader-button"
        text="">
      </app-loading-small>
    }

    <div>
      {{ (isNeedApprove ? 'guilds.buttons.approve' : 'guilds.buttons.change') | translate }}
    </div>
  </button>
</div>
