<div class="g-flex g-flex--align-center g-flex--space-between">
  @if (isShowTopBottom) {
    <button
      class="app-btn-square-minus app-btn-square-minus--all pointer"
      [disabled]="isTopDisabled"
      (click)="topClick.emit()"></button>
  }

  <button
    class="app-btn-square-minus pointer"
    [class.app-btn-square-minus--arrows]="isArrows"
    [class.app-btn-square-minus--minimal]="isMinimal"
    [disabled]="isLeftDisabled"
    (click)="leftClick.emit()"></button>

  <ng-content></ng-content>

  <button
    class="app-btn-square-plus pointer"
    [class.app-btn-square-plus--arrows]="isArrows"
    [class.app-btn-square-plus--minimal]="isMinimal"
    [disabled]="isRightDisabled"
    (click)="rightClick.emit()"></button>

  @if (isShowTopBottom) {
    <button
      class="app-btn-square-plus app-btn-square-plus--all pointer"
      [disabled]="isBottomDisabled"
      (click)="bottomClick.emit()"></button>
  }
</div>

@if (isShowDivider) {
  <div class="app-divider-scratches-border-bolt"></div>
}
