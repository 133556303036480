/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  PawnShopRouterFactory,
  PawnShopRouterFactoryInterface,
} from "../PawnShopRouterFactory";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "pawnShop_",
        type: "address",
      },
      {
        internalType: "address",
        name: "gameToken_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "deployed",
        type: "address",
      },
    ],
    name: "AlreadyDeployed",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroAddress",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "pawnShop",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "gameToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "routerOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "deployed",
        type: "address",
      },
    ],
    name: "PawnShopRouterDeployed",
    type: "event",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "deployRouter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "gameToken",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pawnShop",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "router",
    outputs: [
      {
        internalType: "address",
        name: "router",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class PawnShopRouterFactory__factory {
  static readonly abi = _abi;
  static createInterface(): PawnShopRouterFactoryInterface {
    return new Interface(_abi) as PawnShopRouterFactoryInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): PawnShopRouterFactory {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as PawnShopRouterFactory;
  }
}
