import { Injectable } from '@angular/core';
import { StoryController__factory } from '@data/abi';
import { ErrorService } from '@services/error.service';
import { ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { catchError, from, of, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  constructor(
    private providerService: ProviderService,
    private errorService: ErrorService,
  ) {}

  // --- FACTORIES ---

  private createStoryController(storyController: string) {
    return StoryController__factory.connect(storyController, this.providerService.getProviderForRead());
  }

  // --- VIEWS ---

  heroPage$(chainId: number, heroAdr: string, heroId: number, storyId: number) {
    return from(
      this.createStoryController(GET_CORE_ADDRESSES(chainId).storyController).heroPage(heroAdr, heroId, storyId),
    ).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  heroLastActionTS$() {
    return of(new Date().getTime());
  }

  storyBuildHash$(storyId: number, chainId: number) {
    return from(this.createStoryController(GET_CORE_ADDRESSES(chainId).storyController).storyBuildHash(storyId)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  currentHeroAnswers$(storyId: number, hero: string, heroId: number, chainId: number) {
    return from(
      this.createStoryController(GET_CORE_ADDRESSES(chainId).storyController).currentHeroAnswers(storyId, hero, heroId),
    ).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }
}
