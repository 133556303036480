import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';

@Component({
  selector: 'app-modal',
  standalone: true,
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '[hidden]': 'hidden',
  },
  imports: [NgClass, NgStyle, ScratchComponent],
})
export class ModalComponent {
  @Input() allowClose = true;
  @Input() modalTitle = '';
  @Input() modalSubTitle = '';
  @Input() modalSubTitleStyles = {};
  @Input() modalBg = 'app-window-ask-for-reinforcement-background';

  @Input()
  set open(value: boolean) {
    this.hidden = !value;
  }

  @Output() closed: EventEmitter<any> = new EventEmitter();

  hidden = true;

  // @ts-ignore
  close(event) {
    if (!this.allowClose) {
      return;
    }

    this.hidden = !this.hidden;
    this.closed.next(true);
    event.stopPropagation();
  }
}
