import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTE_TO_NAME,
  ATTRIBUTES,
} from '@shared/constants/attributes.constant';
import { formatUnits } from 'ethers';

export class AttributesMetaModel {
  readonly chances: string[];
  readonly sortedAttributes: AttributeInfo[];
  readonly attributesArray: number[];

  readonly strength: string;
  readonly dexterity: string;
  readonly vitality: string;
  readonly energy: string;
  readonly damageMin: string;
  readonly damageMax: string;
  readonly attackRating: string;
  readonly defense: string;
  readonly blockRating: string;
  readonly life: string;
  readonly mana: string;
  readonly fireResistance: string;
  readonly coldResistance: string;
  readonly lightningResistance: string;
  readonly dmgAgainstHuman: string;
  readonly dmgAgainstUndead: string;
  readonly dmgAgainstDaemon: string;
  readonly dmgAgainstBeast: string;
  readonly defAgainstHuman: string;
  readonly defAgainstUndead: string;
  readonly defAgainstDaemon: string;
  readonly defAgainstBeast: string;
  readonly lifeChances: string;
  readonly magicFind: string;
  readonly destroyItems: string;
  readonly criticalHit: string;
  readonly meleeDmgFactor: string;
  readonly fireDmgFactor: string;
  readonly coldDmgFactor: string;
  readonly lightningDmgFactor: string;
  readonly arFactor: string;
  readonly lifeStolenPerHit: string;
  readonly manaAfterKill: string;
  readonly damageReduction: string;
  readonly stun: string;
  readonly burn: string;
  readonly freeze: string;
  readonly confuse: string;
  readonly curse: string;
  readonly reflectDamageMelee: string;
  readonly reflectDamageMagic: string;
  readonly poison: string;
  readonly resistToStatuses: string;

  constructor(
    readonly ids: number[],
    readonly minArr: number[],
    readonly maxArr: number[],
    readonly chancesArr: string[],
    readonly minRandomAttributes: number = 0,
    readonly maxRandomAttributes: number = 0,
  ) {
    this.chances = chancesArr.map(chance => formatUnits(chance, 7));
    const attributes: AttributeInfo[] = ids.map((id, index) => ({
      id,
      min: minArr[index],
      max: maxArr[index],
      chance: this.chances[index],
    }));

    attributes.sort((a, b) => {
      const boostA = a.min === a.max ? 100 : 0;
      const boostB = b.min === b.max ? 100 : 0;
      return +b.chance + boostB - (+a.chance + boostA);
    });

    this.sortedAttributes = attributes;
    this.attributesArray = minArr;

    this.strength = '' + minArr[0];
    this.dexterity = '' + minArr[1];
    this.vitality = '' + minArr[2];
    this.energy = '' + minArr[3];
    this.damageMin = '' + minArr[4];
    this.damageMax = '' + minArr[5];
    this.attackRating = '' + minArr[6];
    this.defense = '' + minArr[7];
    this.blockRating = '' + minArr[8];
    this.life = '' + minArr[9];
    this.mana = '' + minArr[10];
    this.fireResistance = '' + minArr[11];
    this.coldResistance = '' + minArr[12];
    this.lightningResistance = '' + minArr[13];
    this.dmgAgainstHuman = '' + minArr[14];
    this.dmgAgainstUndead = '' + minArr[15];
    this.dmgAgainstDaemon = '' + minArr[16];
    this.dmgAgainstBeast = '' + minArr[17];
    this.defAgainstHuman = '' + minArr[18];
    this.defAgainstUndead = '' + minArr[19];
    this.defAgainstDaemon = '' + minArr[20];
    this.defAgainstBeast = '' + minArr[21];
    this.lifeChances = '' + minArr[22];
    this.magicFind = '' + minArr[23];
    this.destroyItems = '' + minArr[24];
    this.criticalHit = '' + minArr[25];
    this.meleeDmgFactor = '' + minArr[26];
    this.fireDmgFactor = '' + minArr[27];
    this.coldDmgFactor = '' + minArr[28];
    this.lightningDmgFactor = '' + minArr[29];
    this.arFactor = '' + minArr[30];
    this.lifeStolenPerHit = '' + minArr[31];
    this.manaAfterKill = '' + minArr[32];
    this.damageReduction = '' + minArr[33];
    this.stun = '' + minArr[34];
    this.burn = '' + minArr[35];
    this.freeze = '' + minArr[36];
    this.confuse = '' + minArr[37];
    this.curse = '' + minArr[38];
    this.reflectDamageMelee = '' + minArr[39];
    this.reflectDamageMagic = '' + minArr[40];
    this.poison = '' + minArr[41];
    this.resistToStatuses = '' + minArr[42];
  }

  getAttributeName(id: ATTRIBUTES): string {
    return ATTRIBUTE_TO_NAME.get(id) ?? 'unknown';
  }

  getAttributeIcon(id: ATTRIBUTES): string {
    return ATTRIBUTE_TO_ICON.get(id) ?? 'unknown';
  }

  getAttributeDescription(id: ATTRIBUTES): string {
    return ATTRIBUTE_TO_DESCRIPTION.get(id) ?? '';
  }
}

interface AttributeInfo {
  id: number;
  min: number;
  max: number;
  chance: string;
}
