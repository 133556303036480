import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ItemEntity } from '@generated/gql';
import { changeIPFSDomain } from '@helpers/data-helper';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { ITEM_RARITY_TO_NAME, ITEM_TYPE_IS_COMMON, ITEM_TYPE_NAMES } from '@shared/constants/items.constant';
import { ItemSlotSize } from '@shared/types/item-slot-size.type';

export type RepairResultType = {
  item: ItemEntity;
  isSuccess: boolean;
};

@Component({
  selector: 'app-repair-success-dialog',
  standalone: true,
  templateUrl: './repair-success-dialog.component.html',
  styleUrls: ['./repair-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, ButtonClickDirective, TranslateModule],
})
export class RepairSuccessDialogComponent implements OnInit {
  account: string;
  chainId: number;
  itemsTypeName: string | undefined = '';
  itemRarityName: string | undefined = '';
  isCommonItem = false;
  item: ItemEntity;
  slotSize: ItemSlotSize = 'middle-small';

  constructor(
    @Inject(DIALOG_DATA) public data: RepairResultType,
    private dialogRef: DialogRef<string, RepairSuccessDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.item = this.data.item;
    this.itemsTypeName = ITEM_TYPE_NAMES.get(this.item?.meta?.itemType ?? -1);

    this.isCommonItem = ITEM_TYPE_IS_COMMON.get(this.item?.meta?.itemType ?? -1) ?? false;

    if (this.isCommonItem) {
      this.itemRarityName = ITEM_RARITY_TO_NAME.get(this.item?.rarity ?? -1);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  changeURL(url: string) {
    return changeIPFSDomain(url);
  }
}
