@if (passwordControl) {
  <div class="content app-btn-small_font-size text-shadow">
    <div>At least:</div>

    <div
      [class.color-red-fire]="passwordControl && passwordControl.value && !passwordControl.value.match('^(?=.*[A-Z])')"
      [class.text-shadow-negative]="
        passwordControl && passwordControl.value && !passwordControl.value.match('^(?=.*[A-Z])')
      ">
      1 uppercase letter
    </div>

    <div
      [class.color-red-fire]="passwordControl && passwordControl.value && !passwordControl.value.match('(?=.*[a-z])')"
      [class.text-shadow-negative]="
        passwordControl && passwordControl.value && !passwordControl.value.match('(?=.*[a-z])')
      ">
      1 lowercase letter
    </div>

    <div
      [class.color-red-fire]="passwordControl && passwordControl.value && !passwordControl.value.match('(.*[0-9].*)')"
      [class.text-shadow-negative]="
        passwordControl && passwordControl.value && !passwordControl.value.match('(.*[0-9].*)')
      ">
      1 digit
    </div>

    <div
      [class.color-red-fire]="
        passwordControl && passwordControl.value && !passwordControl.value.match('(?=.*[!@#$%^&*])')
      "
      [class.text-shadow-negative]="
        passwordControl && passwordControl.value && !passwordControl.value.match('(?=.*[!@#$%^&*])')
      ">
      1 special character
    </div>

    <div
      [class.color-red-fire]="passwordControl && passwordControl.value && !passwordControl.value.match('.{8,}')"
      [class.text-shadow-negative]="passwordControl && passwordControl.value && !passwordControl.value.match('.{8,}')">
      8 characters long
    </div>

    @if (!!newPasswordControl) {
      <div
        [class.color-red-fire]="passwordControl.value !== newPasswordControl.value"
        [class.text-shadow-negative]="passwordControl.value !== newPasswordControl.value">
        Passwords must match
      </div>
    }
  </div>
}
