<app-dialog-title
  [title]="data.infoTitle || 'Disabled'"
  [canClose]="canClose"
  [descTitle]="data.descTitle"
  [descText]="data.descText"
  (closeDialog)="close()">
</app-dialog-title>

<div
  class="content g-flex-column"
  [innerHTML]="infoDesc"></div>

@if (data.buttons) {
  <div class="controls g-flex g-flex--align-center g-flex--justify-center g-flex--wrap">
    @for (btn of data.buttons; track btn) {
      <button
        [ngClass]="btn.class ? btn.class : 'app-btn-red-2'"
        (click)="onBtnClick(btn.id)">
        {{ btn.label }}
      </button>
    }
  </div>
}
