import { Injectable } from '@angular/core';
import { Web3ModalService } from '@services/web3-modal.service';
import { from, map, Subject } from 'rxjs';
import Web3 from 'web3';

@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  web3$: Subject<Web3> = new Subject<Web3>();
  connected = false;

  constructor(private web3ModalService: Web3ModalService) {}

  connect$() {
    if (this.connected) {
      return this.web3$;
    } else {
      return from(this.web3ModalService.open()).pipe(
        map((w: any) => {
          const web3 = new Web3(w);

          this.web3$.next(web3);

          this.connected = true;

          return web3;
        }),
      );
    }
  }
}
