import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { getNetworkTokenName } from '@helpers/data-helper';
import { Formatter } from '@helpers/formatter';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { MultiCallService } from '@services/onchain/multi-call.service';
import { TokenService } from '@services/onchain/token.service';
import { UniswapV3Service } from '@services/onchain/uniswap-v3.service';
import { ProviderService } from '@services/provider.service';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { DROPDOWN_SIZE } from '@shared/components/dropdown/constants/dropdown-sizes.constant';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { DropdownItemModel } from '@shared/components/dropdown/model/dropdown-item.model';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import {
  GET_CORE_ADDRESSES,
  GET_DEX_ADDRESSES,
  GET_POOL_ADDRESSES,
  GET_TOKEN_INFO,
  IS_POOL_AVAILABLE_CHAIN,
} from '@shared/constants/addresses/addresses.constant';
import { CHAIN_IDS } from '@shared/constants/chain-ids.constant';
import { NUMBERS } from '@shared/constants/numbers.constant';
import { DigitOnlyDirective } from '@shared/directives/digit-only/digit-only.directive';
import { formatUnits, parseUnits } from 'ethers';
import { debounceTime, distinctUntilChanged, finalize, forkJoin, map, takeUntil } from 'rxjs';

@Component({
  selector: 'app-swap-coins-dialog',
  standalone: true,
  templateUrl: './swap-coins-dialog.component.html',
  styleUrls: ['./swap-coins-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [
    DialogTitleComponent,
    LoadingSmallComponent,
    DropdownComponent,
    ReactiveFormsModule,
    NgClass,
    ButtonClickDirective,
    TranslateModule,
    DigitOnlyDirective,
  ],
})
export class SwapCoinsDialogComponent implements OnInit {
  @HostBinding('class.hide-swap')
  get isHideSwap() {
    return this.hideSwap();
  }

  account: string;
  chainId: number;

  tokenAddresses: string[] = [];

  coinsOptions: DropdownItemModel[] = [];
  sellOptions: DropdownItemModel[] = [];
  buyOptions: DropdownItemModel[] = [];

  sellSelected!: DropdownItemModel;
  buySelected!: DropdownItemModel;

  sellPriceControl = new FormControl<string>('0');
  buyPriceControl = new FormControl<string>('0');

  isEnoughAllowance = true;

  DROPDOWN_SIZE = DROPDOWN_SIZE;

  txProcessing = false;
  isLoading = true;

  sqrtPriceX96: bigint = 0n;
  sqrtPriceX96After: bigint = 0n;
  price: number = 0;
  priceAfter: number = 0;
  priceImpact: number = 0;

  SLIPPAGE = 100n; // 0.1%
  SLIPPAGE_DENOMINATOR = 100000n; // 100%

  sacraPrice: number = 0;

  constructor(
    @Inject(DIALOG_DATA) public data: { infoTitle: string; infoDesc: string },
    private dialogRef: DialogRef<null, SwapCoinsDialogComponent>,
    private destroy$: DestroyService,
    private providerService: ProviderService,
    private tokenService: TokenService,
    private multiCallService: MultiCallService,
    private changeDetectorRef: ChangeDetectorRef,
    private uniswapV3Service: UniswapV3Service,
  ) {}

  ngOnInit() {
    this.sellPriceControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(value => (!value || isNaN(Number(value)) ? '0' : value)),
        takeUntil(this.destroy$),
      )
      .subscribe(value => {
        this.setBuyValueOnSellChange(value || '0');
      });

    this.buyPriceControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(value => (!value || isNaN(Number(value)) ? '0' : value)),
        takeUntil(this.destroy$),
      )
      .subscribe(value => {
        this.setSellValueOnBuyChange(value || '0');
      });

    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.init();
      },
    );
  }

  init() {
    if (this.hideSwap()) {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
      return;
    }

    if (this.account && this.chainId) {
      this.sellPriceControl.reset();
      this.buyPriceControl.reset();
      this.coinsOptions = [];

      this.tokenAddresses = [
        GET_CORE_ADDRESSES(this.chainId).gameToken,
        GET_CORE_ADDRESSES(this.chainId).magicToken,
        GET_CORE_ADDRESSES(this.chainId).strengthToken,
        GET_CORE_ADDRESSES(this.chainId).dexterityToken,
      ].filter((v, i, a) => a.findIndex(t => t === v) === i);

      this.multiCallService
        .aggregate$(
          [
            ...this.tokenAddresses.map(adr => this.tokenService.balanceOf(adr, this.account)),
            this.multiCallService.getEthBalance(this.chainId, this.account),
          ],
          this.chainId,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          for (let i = 0; i < this.tokenAddresses.length; i++) {
            const tokenAdr = this.tokenAddresses[i];
            const info = GET_TOKEN_INFO(this.chainId, tokenAdr);
            const balance = Formatter.formatCurrency(+formatUnits(result.returnData[i], info.decimals));

            this.coinsOptions.push({
              address: tokenAdr,
              label: info.symbol,
              id: info.symbol.toLowerCase(),
              prefixIconPath: `/assets/images/ui/icons/tokens/${info.symbol.toLowerCase()}.png`,
              suffixText: balance ?? '0',
              valueFormatted: balance ?? '0',
              valueBN: BigInt(result.returnData[i]),
              valueN: +formatUnits(result.returnData[i], info.decimals),
              iconHeight: '54px',
            });
          }

          const tokenAdr = GET_CORE_ADDRESSES(this.chainId).networkToken;
          const info = GET_TOKEN_INFO(this.chainId, tokenAdr);
          const balance = Formatter.formatCurrency(
            +formatUnits(result.returnData[this.tokenAddresses.length], info.decimals),
          );

          // console.log('tokenAdr', tokenAdr, info, balance, result);
          this.coinsOptions.push({
            address: tokenAdr,
            label: info.symbol,
            id: info.symbol.toLowerCase(),
            prefixIconPath: `/assets/images/ui/icons/tokens/${getNetworkTokenName().toLowerCase()}.png`,
            suffixText: balance ?? '0',
            valueFormatted: balance ?? '0',
            valueBN: BigInt(result.returnData[this.tokenAddresses.length]),
            valueN: +formatUnits(result.returnData[this.tokenAddresses.length], info.decimals),
            iconHeight: '54px',
          });

          if (this.coinsOptions.length > 0) {
            this.buySelected = this.coinsOptions[0];
            this.sellSelected = this.coinsOptions[this.coinsOptions.length - 1];
          }

          this.updateTokenLists();

          this.isLoading = false;

          this.changeDetectorRef.detectChanges();
        });
    }
  }

  hideSwap() {
    const chain = Number(environment['CHAIN_ID']);
    return chain === CHAIN_IDS.REAL || chain === CHAIN_IDS.SONIC;
  }

  updateTokenLists() {
    this.sellOptions = [];
    this.buyOptions = [];

    if (this.isSellNetworkToken()) {
      this.sellOptions.push(
        ...this.coinsOptions.filter(c => c.address === GET_CORE_ADDRESSES(this.chainId).networkToken),
      );
    } else {
      this.sellOptions.push(
        ...this.coinsOptions.filter(c => c.address !== GET_CORE_ADDRESSES(this.chainId).networkToken),
      );
    }

    if (this.isSellNetworkToken()) {
      this.buyOptions.push(
        ...this.coinsOptions.filter(c => c.address !== GET_CORE_ADDRESSES(this.chainId).networkToken),
      );
    } else {
      this.buyOptions.push(
        ...this.coinsOptions.filter(c => c.address === GET_CORE_ADDRESSES(this.chainId).networkToken),
      );
    }
  }

  isSellNetworkToken() {
    return this.sellSelected?.address?.toLowerCase() === GET_CORE_ADDRESSES(this.chainId).networkToken.toLowerCase();
  }

  priceImpactFormatted() {
    return this.priceImpact.toFixed(2);
  }

  sacraPriceFormatted() {
    return this.sacraPrice.toFixed(4);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  updateSellSelected(sellSelected) {
    this.sellSelected = sellSelected;
  }

  updateBuySelected(buySelected) {
    this.buySelected = buySelected;
  }

  setMax() {
    if (this.sellSelected?.valueBN) {
      const infoSell = GET_TOKEN_INFO(this.chainId, this.sellSelected?.address ?? 'ADR_UNK');
      this.sellPriceControl.setValue(formatUnits(this.sellSelected?.valueBN, infoSell.decimals), { emitEvent: true });
    }
  }

  onSwapFields() {
    const sellSelected = this.sellSelected;
    // const sellPrice = this.sellPriceControl.value;

    this.sellSelected = this.buySelected;
    this.buySelected = sellSelected;

    this.sellPriceControl.setValue(this.buyPriceControl.value, { emitEvent: true });
    // this.buyPriceControl.setValue(sellPrice);

    this.updateTokenLists();

    this.changeDetectorRef.detectChanges();
  }

  setBuyValueOnSellChange(sellValue: string) {
    this.updateAllowance();

    this.buyPriceControl.setValue('0', { emitEvent: false });
    if (parseUnits(sellValue) === 0n) {
      return;
    }

    if (IS_POOL_AVAILABLE_CHAIN(this.chainId)) {
      forkJoin([
        this.uniswapV3Service.quoteExactIn(
          GET_DEX_ADDRESSES(this.chainId).QUOTER_V2,
          GET_POOL_ADDRESSES(this.chainId).ethSacraPool,
          this.sellSelected?.address || '',
          this.buySelected?.address || '',
          parseUnits(sellValue, 18),
        ),
        this.uniswapV3Service.slot0$(GET_POOL_ADDRESSES(this.chainId).ethSacraPool),
        this.uniswapV3Service.sacraPrice$(this.chainId),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([r, slot0, sacraPrice]) => {
          this.buyPriceControl.setValue(formatUnits(r[0], 18), { emitEvent: false });
          this.sqrtPriceX96After = r[1];
          this.priceAfter = 2 ** 192 / (+this.sqrtPriceX96After.toString()) ** 2;

          this.sqrtPriceX96 = slot0[0];
          // this.price = ((+this.sqrtPriceX96.toString()) ** 2 / 2 ** 192);
          this.price = 2 ** 192 / (+this.sqrtPriceX96.toString()) ** 2;

          this.priceImpact = ((this.price - this.priceAfter) / this.price) * 100;
          if (this.priceImpact < 0) {
            this.priceImpact = -this.priceImpact;
          }

          this.sacraPrice = sacraPrice;

          this.changeDetectorRef.detectChanges();
        });
    } else {
      const faucetToken =
        (this.isSellNetworkToken() ? this.buySelected?.address : this.sellSelected?.address) ?? 'ADR_UNK';
      const infoSell = GET_TOKEN_INFO(this.chainId, this.sellSelected?.address ?? 'ADR_UNK');
      (this.isSellNetworkToken()
        ? this.tokenService.priceTokenToEth$(
            this.account,
            faucetToken,
            parseUnits(sellValue, infoSell.decimals),
            this.chainId,
          )
        : this.tokenService.priceEthToToken$(this.account, faucetToken, parseUnits(sellValue, 18), this.chainId)
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          const info = GET_TOKEN_INFO(this.chainId, this.buySelected?.address ?? 'ADR_UNK');
          this.buyPriceControl.setValue(formatUnits(result, info.decimals), { emitEvent: false });
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  setSellValueOnBuyChange(buyValue: string) {
    this.updateAllowance();

    this.sellPriceControl.setValue('0', { emitEvent: false });
    if (parseUnits(buyValue) === 0n) {
      return;
    }

    if (IS_POOL_AVAILABLE_CHAIN(this.chainId)) {
      forkJoin(
        this.uniswapV3Service.quoteExactOut(
          GET_DEX_ADDRESSES(this.chainId).QUOTER_V2,
          GET_POOL_ADDRESSES(this.chainId).ethSacraPool,
          this.sellSelected?.address || '',
          this.buySelected?.address || '',
          parseUnits(buyValue, 18),
        ),
        this.uniswapV3Service.slot0$(GET_POOL_ADDRESSES(this.chainId).ethSacraPool),
        this.uniswapV3Service.sacraPrice$(this.chainId),
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(([r, slot0, sacraPrice]) => {
          this.sqrtPriceX96 = slot0[0];
          this.sqrtPriceX96After = r[1];
          this.price = 2 ** 192 / (+this.sqrtPriceX96.toString()) ** 2;
          this.priceAfter = 2 ** 192 / (+this.sqrtPriceX96After.toString()) ** 2;

          this.sellPriceControl.setValue(formatUnits(r[0], 18), { emitEvent: false });

          this.priceImpact = ((this.price - this.priceAfter) / this.price) * 100;
          if (this.priceImpact < 0) {
            this.priceImpact = -this.priceImpact;
          }

          this.sacraPrice = sacraPrice;

          this.changeDetectorRef.detectChanges();
        });
    } else {
      const faucetToken =
        (this.isSellNetworkToken() ? this.buySelected?.address : this.sellSelected?.address) ?? 'ADR_UNK';
      const infoBuy = GET_TOKEN_INFO(this.chainId, this.buySelected?.address ?? 'ADR_UNK');
      (this.isSellNetworkToken()
        ? this.tokenService.priceEthToToken$(this.account, faucetToken, parseUnits(buyValue, 18), this.chainId)
        : this.tokenService.priceTokenToEth$(
            this.account,
            faucetToken,
            parseUnits(buyValue, infoBuy.decimals),
            this.chainId,
          )
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          const info = GET_TOKEN_INFO(this.chainId, this.sellSelected?.address ?? 'ADR_UNK');
          this.sellPriceControl.setValue(formatUnits(result, info.decimals), { emitEvent: false });
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  resultPrice() {
    const sell = parseUnits(this.checkInputValue(this.sellPriceControl.value));
    const buy = parseUnits(this.checkInputValue(this.buyPriceControl.value));
    if (sell === 0n || buy === 0n) {
      return '0';
    }
    return Formatter.formatCurrency(+formatUnits((buy * parseUnits('1')) / sell), 6);
  }

  isEnoughSellAmount() {
    const infoSell = GET_TOKEN_INFO(this.chainId, this.sellSelected?.address ?? 'ADR_UNK');
    const sell = parseUnits(this.checkInputValue(this.sellPriceControl.value), infoSell.decimals);
    const balance = this.coinsOptions.filter(t => t.address === this.sellSelected?.address)[0]?.valueBN ?? 0n;
    return balance >= sell;
  }

  getSwapButtonDisabledDesc() {
    if (!this.isEnoughSellAmount()) {
      return 'Not enough balance';
    }
    if (this.txProcessing) {
      return 'Transaction processing';
    }
    if (this.sellPriceControl.invalid || this.sellPriceControl.value === '0' || this.sellPriceControl.value === '0') {
      return 'Invalid sell amount';
    }
    if (this.buyPriceControl.invalid || this.buyPriceControl.value === '0' || this.buyPriceControl.value === '0') {
      return 'Invalid buy amount';
    }
    return '';
  }

  private checkInputValue(value) {
    return isNaN(Number(value)) ? '0' : value || '0';
  }

  updateAllowance() {
    this.isEnoughAllowance = false;
    if (this.isSellNetworkToken()) {
      this.isEnoughAllowance = true;
      this.changeDetectorRef.detectChanges();
    } else {
      const sellToken = this.sellSelected?.address;
      if (sellToken) {
        const infoSell = GET_TOKEN_INFO(this.chainId, sellToken);
        const sell = parseUnits(this.sellPriceControl.value || '0', infoSell.decimals);
        if (sell !== 0n) {
          this.tokenService
            .allowance$(sellToken, this.account, GET_DEX_ADDRESSES(this.chainId).SWAP_ROUTER_V2)
            .pipe(takeUntil(this.destroy$))
            .subscribe(allowance => {
              this.isEnoughAllowance = allowance >= sell;
              this.changeDetectorRef.detectChanges();
            });
        }
      }
    }
  }

  swapOnDex() {
    this.tokenService.openBuyTokenExternalLink(GET_CORE_ADDRESSES(this.chainId).gameToken, this.chainId);
  }

  blockUi() {
    this.txProcessing = true;
    this.sellPriceControl.disable();
    this.buyPriceControl.disable();
    this.changeDetectorRef.detectChanges();
  }

  unblockUi() {
    this.txProcessing = false;
    this.sellPriceControl.enable();
    this.buyPriceControl.enable();
    this.changeDetectorRef.detectChanges();
  }

  onSwap() {
    this.blockUi();

    if (IS_POOL_AVAILABLE_CHAIN(this.chainId)) {
      const amount = parseUnits(this.sellPriceControl.value || '0');
      const amountOut =
        (parseUnits(this.buyPriceControl.value || '0') * (this.SLIPPAGE_DENOMINATOR - this.SLIPPAGE)) /
        this.SLIPPAGE_DENOMINATOR;
      if (this.isSellNetworkToken()) {
        this.uniswapV3Service
          .wrapAndSwap$(
            GET_DEX_ADDRESSES(this.chainId).SWAP_ROUTER_V2,
            this.sellSelected?.address || '',
            this.buySelected?.address || '',
            10000,
            this.account,
            amount,
            amountOut,
          )
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => {
              this.unblockUi();
            }),
          )
          .subscribe(() => {
            this.init();
          });
      } else {
        this.uniswapV3Service
          .swapAndUnwrap$(
            GET_DEX_ADDRESSES(this.chainId).SWAP_ROUTER_V2,
            this.sellSelected?.address || '',
            this.buySelected?.address || '',
            10000,
            this.account,
            amount,
            amountOut,
          )
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => {
              this.unblockUi();
            }),
          )
          .subscribe(() => {
            this.init();
          });
      }
    } else {
      const sellAmount = parseUnits(this.sellPriceControl.value || '0');
      (this.isSellNetworkToken()
        ? this.tokenService.networkTokenToMockToken(
            this.account,
            this.buySelected?.address ?? 'ADR_UNK',
            sellAmount,
            this.chainId,
          )
        : this.tokenService.mockTokenToNetworkToken(
            this.account,
            this.sellSelected?.address ?? 'ADR_UNK',
            sellAmount,
            this.chainId,
          )
      )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.unblockUi();
          }),
        )
        .subscribe(() => {
          this.init();
        });
    }
  }

  onApprove() {
    this.blockUi();

    const sellTokenAdr = this.sellSelected?.address ?? 'ADR_UNK';
    // const faucet = getFaucetForToken(sellTokenAdr, this.chainId);
    this.tokenService
      .approve$(this.account, sellTokenAdr, GET_DEX_ADDRESSES(this.chainId).SWAP_ROUTER_V2, BigInt(NUMBERS.MAX_UINT))
      .pipe(
        finalize(() => {
          this.unblockUi();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateAllowance();
      });
  }
}
