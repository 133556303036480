@if (achievements.length) {
  <app-title
    class="title"
    fontType="large"
    backgroundType="middle">
    {{ 'achievements.title' | translate }}
  </app-title>

  <app-bar
    class="bar"
    barType="achievements"
    [percentageValue]="(achievements.length / totalAchievements) * 100">
    <div
      label
      class="bar__label app-btn-micro_font-size text-bar-shadow bar-exp g-flex g-flex--align-center gap-8">
      <div
        [class.color-positive-value]="!hasUnread"
        [class.color-yellow-fire]="hasUnread"
        [class.text-shadow-positive]="hasUnread">
        {{ achievements.length }}
      </div>

      <div>/</div>

      <div>
        {{ totalAchievements }}
      </div>
    </div>
  </app-bar>

  @for (item of achievements; track item) {
    <app-achievement
      class="item"
      [item]="item">
    </app-achievement>
  }
}
