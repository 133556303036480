import { Injectable } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { MediatorActionType } from '@shared/types/action-type.interface';
import { MediatorAction } from '@shared/types/action.interface';
import { Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Mediator {
  private readonly subjectsMap: WeakMap<MediatorActionType<MediatorAction>, Subject<MediatorAction>> = new WeakMap();

  dispatch(action: MediatorAction): void {
    const actionType = action.constructor as MediatorActionType<MediatorAction>;
    const subject = this.subjectsMap.get(actionType);

    if (subject) {
      subject.next(action);
    }
  }

  ofAction<T extends MediatorAction>(actionType: MediatorActionType<T>, destroy$?: DestroyService): Observable<T> {
    if (!this.subjectsMap.has(actionType)) {
      this.subjectsMap.set(actionType, new Subject());
    }

    const relatedSubject = this.subjectsMap.get(actionType);

    // @ts-ignore
    return (destroy$ ? relatedSubject.pipe(takeUntil(destroy$)) : relatedSubject.asObservable()) as Observable<T>;
  }
}
