<div class="item-description-dialog g-flex-column">
  <div
    class="header g-flex g-flex--align-start g-flex-column__item-fixed"
    [style.background-image]="headerBackground">
    <div
      class="item"
      [ngClass]="backgroundClass ? backgroundClass : ''">
      @if (item.count && item.count > 1) {
        <div class="count app-btn-tiny_font-size text-shadow g-flex g-flex--align-center g-flex--justify-center">
          {{ item.countFormatted }}
        </div>
      }

      @if (!isHideDurability && itemDurability && durabilityPercentage <= 20 && item.meta.durability !== 0) {
        <img
          class="item__broken"
          src="/assets/images/ui/frames/item-slots/huge/broken.avif"
          alt="item image" />
      }

      @if (getActualUri(item)) {
        <img
          class="item__image"
          [src]="imageUrl"
          alt="no image" />
      }
    </div>

    <div class="header__title-container g-flex__item g-flex-column">
      <div class="header__title g-flex">
        <div
          class="header__title-text text-shadow"
          [style.color]="titleColor(item)">
          {{ name }}
        </div>

        <app-scratch class="header__title-scratch"></app-scratch>
      </div>

      <div
        [class.g-flex]="name.length > 26"
        [class.g-flex--space-between]="name.length > 26">
        <div class="rarity g-flex g-flex--align-center">
          <img
            class="rarity-icon"
            [src]="'/assets/images/ui/icons/item-type/' + itemsTypeIcon + '.png'"
            alt="item image" />

          @if (itemRarityName) {
            <div
              id="rarity-name"
              class="rarity-name"
              [style.text-shadow]="'0px 0px 8px ' + titleShadowColor(item)"
              [style.color]="titleColor(item)">
              {{ itemRarityName | translate }}
            </div>
          }

          @if (itemsTypeName !== 'no slot') {
            <div>
              {{ itemsTypeName ?? '' | translate }}
            </div>
          }
        </div>

        <div
          class="g-flex"
          [class.g-flex--space-between]="!item.meta.isConsumableItem"
          [class.g-flex--justify-end]="item.meta.isConsumableItem">
          @if (!item.meta.isConsumableItem) {
            <div class="augmentation g-flex g-flex--align-center">
              <app-augmentation-level
                id="augmentation-level"
                class="augmentation-level"
                [level]="item.augmentationLevel">
              </app-augmentation-level>

              <div class="augmentation-text">
                {{ 'item-description-dialog.level' | translate }}
              </div>
            </div>
          }

          @if (!item.count) {
            <app-item-id
              id="item-id"
              class="item-id"
              [class.item-id--offset]="isShowClose"
              [class.item-id--big-title]="name.length > 26"
              [itemId]="item.itemId"
              [bgSize]="slotSize">
            </app-item-id>
          }
        </div>
      </div>
    </div>

    @if (isShowClose) {
      <button
        class="item-description-dialog__cross-btn app-btn-cross-light"
        (click)="onClose({})"></button>
    }
  </div>

  <div class="items-container g-flex-column__item g-flex-column">
    <div class="items g-flex g-flex--space-between g-flex-column__item">
      <div class="item-values g-flex__item">
        @if (!item.meta.isConsumableItem) {
          <div class="item-values__title g-flex g-flex--align-center app-btn-huge_font-size">
            <div class="item-values__title-value">
              {{ item.score }}
            </div>

            <div id="gear-score">
              {{ 'item-description-dialog.gear-score' | translate }}
            </div>
          </div>
        }

        <div
          id="attributes-area"
          class="items__attrs g-flex-column"
          [class.items__attrs--consumable]="item.meta.isConsumableItem">
          @if (itemAttributes) {
            @for (attr of itemAttributes.attributesArray; track attr; let i = $index) {
              @if (attr !== 0 && ATTRIBUTES.DAMAGE_MIN !== i && ATTRIBUTES.DAMAGE_MAX !== i) {
                <app-hero-stat-item
                  class="stats__item"
                  [isRowLayout]="true"
                  [isShowSign]="true"
                  [isInline]="true"
                  [item]="{
                    icon: itemAttributes.getAttributeIcon(i),
                    name: itemAttributes.getAttributeName(i),
                    text: attr,
                    description: itemAttributes.getAttributeDescription(i),
                    attributeIndex: i,
                  }">
                </app-hero-stat-item>
              }

              @if (attr !== 0 && ATTRIBUTES.DAMAGE_MIN === i) {
                <app-hero-stat-item
                  class="stats__item"
                  [isRowLayout]="true"
                  [isShowSign]="true"
                  [isInline]="true"
                  [item]="{
                    icon: itemAttributes.getAttributeIcon(i),
                    name: 'Melee Damage',
                    text:
                      itemAttributes.attributesArray[ATTRIBUTES.DAMAGE_MIN] +
                      ' - ' +
                      itemAttributes.attributesArray[ATTRIBUTES.DAMAGE_MAX],
                    description: itemAttributes.getAttributeDescription(i),
                    attributeIndex: i,
                  }">
                </app-hero-stat-item>
              }
            }
          }

          @if (skillBuffCasterAttributes || skillBuffTargetAttributes) {
            <app-skill-area
              title="Skill"
              type="skill"
              [manaConsumption]="skillManaConsumption"
              [skillsAttributes]="[skillBuffCasterAttributes]"
              [skillsSigns]="['+', '-']"
              [hasEnemyAttributes]="
                skillBuffTargetAttributes && (skillBuffTargetAttributes.attributesArray | filterNull).length > 0
              "
              [enemyGotAttributes]="[skillBuffTargetAttributes]">
            </app-skill-area>
          }

          @if (item.magicAttackInfo) {
            <div class="skills g-flex-column">
              <div class="skills__top">
                <img
                  class="skills__icon"
                  src="/assets/images/ui/icons/item-props/attack.png"
                  alt="item image" />

                <div class="g-flex g-flex--align-center g-flex--space-between">
                  <div>
                    {{ 'item-description-dialog.magic-attack' | translate }}
                  </div>

                  <div class="mana-cost g-flex g-flex--align-center">
                    <img
                      src="/assets/images/ui/icons/attributes/MANA_COST.png"
                      alt="item image" />
                    <div class="color-mana text-shadow">
                      {{ item.meta.manaCost }}
                    </div>

                    <div>
                      {{ 'item-description-dialog.mana-cost' | translate }}
                    </div>
                  </div>
                </div>

                <app-scratch class="skills__scratch"></app-scratch>
              </div>

              <div class="skills__middle">
                <app-hero-stat-item
                  class="stat-item"
                  [isRowLayout]="true"
                  [isInline]="true"
                  [item]="{
                    icon: MAGIC_ATTACK_ICON.get(+item.magicAttackInfo.attackType) ?? 'unknown',
                    name: MAGIC_ATTACK_NAME.get(+item.magicAttackInfo.attackType) ?? '',
                    text: item.magicAttackInfo.minDmg + ' - ' + item.magicAttackInfo.maxDmg,
                    attributeIndex: ATTRIBUTES.DAMAGE_MAX,
                  }">
                </app-hero-stat-item>

                @if (item.magicAttackInfo.attributesFactor.strength !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.STRENGTH) ?? 'unknown',
                      name: ('item-description-dialog.add-to-dmg' | translate),
                      text: item.magicAttackInfo.attributesFactor.strength + '% of STR',
                      description: ('attributes-factor-description.strength' | translate),
                      attributeIndex: ATTRIBUTES.STRENGTH,
                    }">
                  </app-hero-stat-item>
                }

                @if (item.magicAttackInfo.attributesFactor.dexterity !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DEXTERITY) ?? 'unknown',
                      name: ('item-description-dialog.add-to-dmg' | translate),
                      text: item.magicAttackInfo.attributesFactor.dexterity + '% of DEX',
                      description: ('attributes-factor-description.dexterity' | translate),
                      attributeIndex: ATTRIBUTES.DEXTERITY,
                    }">
                  </app-hero-stat-item>
                }

                @if (item.magicAttackInfo.attributesFactor.vitality !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.VITALITY) ?? 'unknown',
                      name: ('item-description-dialog.add-to-dmg' | translate),
                      text: item.magicAttackInfo.attributesFactor.vitality + '% of VIT',
                      description: ('attributes-factor-description.vitality' | translate),
                      attributeIndex: ATTRIBUTES.VITALITY,
                    }">
                  </app-hero-stat-item>
                }

                @if (item.magicAttackInfo.attributesFactor.energy !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.EXTRA_ENERGY) ?? 'unknown',
                      name: ('item-description-dialog.add-to-dmg' | translate),
                      text: item.magicAttackInfo.attributesFactor.energy + '% of ENG',
                      description: ('attributes-factor-description.energy' | translate),
                      attributeIndex: ATTRIBUTES.ENERGY,
                    }">
                  </app-hero-stat-item>
                }
              </div>
            </div>
          }

          @if (
            (consumableStats &&
              (consumableStats.life !== 0 ||
                consumableStats.mana !== 0 ||
                consumableStats.lifeChances !== 0 ||
                consumableStats.experience !== 0)) ||
            +item.meta.consumableActionMask > 0 ||
            (consumableAttributes && (consumableAttributes.attributesArray | filterNull).length > 0)
          ) {
            <div
              class="skills g-flex-column"
              [class.skills--consumable]="item.meta.isConsumableItem">
              <div class="skills__top">
                <img
                  class="skills__icon"
                  src="/assets/images/ui/icons/item-props/use.png"
                  alt="item image" />

                <div class="g-flex g-flex--align-center g-flex--space-between">
                  <div>
                    {{ 'item-description-dialog.impact' | translate }}
                  </div>

                  <div class="mana-cost g-flex g-flex--align-center">
                    {{ 'item-description-dialog.one-time-use' | translate }}
                  </div>
                </div>

                <app-scratch class="skills__scratch"></app-scratch>
              </div>

              <div class="skills__middle">
                @if (consumableStats?.life !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isShowSign]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIFE) ?? '',
                      name: ('item-description-dialog.hp-restored' | translate),
                      text: consumableStats?.life || '',
                      description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIFE) ?? '',
                      attributeIndex: ATTRIBUTES.LIFE,
                    }">
                  </app-hero-stat-item>
                }

                @if (consumableStats?.mana !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isShowSign]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.MANA) ?? '',
                      name: ('item-description-dialog.mp-restored' | translate),
                      text: consumableStats?.mana || '',
                      description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.MANA) ?? '',
                      attributeIndex: ATTRIBUTES.MANA,
                    }">
                  </app-hero-stat-item>
                }

                @if (consumableStats?.lifeChances !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isShowSign]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIFE_CHANCES) ?? '',
                      name: ('item-description-dialog.life-chances-restored' | translate),
                      text: consumableStats?.lifeChances || '',
                      description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIFE_CHANCES) ?? '',
                      attributeIndex: ATTRIBUTES.LIFE_CHANCES,
                    }">
                  </app-hero-stat-item>
                }

                @if (consumableStats?.experience !== 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isShowSign]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.EXTRA_ENERGY) ?? '',
                      name: ('item-description-dialog.experience' | translate),
                      text: consumableStats?.experience || '',
                      description: 'attributes-descriptions.experience',
                      attributeIndex: ATTRIBUTES.END_SLOT,
                    }">
                  </app-hero-stat-item>
                }

                @if (+item.meta.consumableActionMask > 0) {
                  <app-hero-stat-item
                    class="stat-item"
                    [isRowLayout]="true"
                    [isShowSign]="true"
                    [isInline]="true"
                    [item]="{
                      icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.MAGIC_FIND) ?? '',
                      name: ('item-description-dialog.consumable-action.' + item.meta.consumableActionMask | translate),
                      text: '',
                      attributeIndex: ATTRIBUTES.END_SLOT,
                    }">
                  </app-hero-stat-item>
                }

                @if (consumableAttributes) {
                  @for (attr of consumableAttributes.attributesArray; track attr; let i = $index) {
                    @if (attr !== 0) {
                      <app-hero-stat-item
                        class="stat-item"
                        [isRowLayout]="true"
                        [isShowSign]="true"
                        [isInline]="true"
                        [item]="{
                          icon: consumableAttributes.getAttributeIcon(i),
                          name: consumableAttributes.getAttributeName(i),
                          text: attr,
                          description: consumableAttributes.getAttributeDescription(i),
                          attributeIndex: i,
                        }">
                      </app-hero-stat-item>
                    }
                  }
                }
              </div>
            </div>
          }
        </div>
      </div>

      <div class="g-flex-column">
        @if (isCanUse && hero && !item.meta.isConsumableItem && !isFreeHero()) {
          <button
            id="btn-use"
            class="btn-equip buttons__item app-btn-blue-1"
            appButtonClick
            [infoDesc]="'item-description-dialog.disabled.use' | translate"
            [isDisabled]="isUsed"
            [isNeedConfirm]="true"
            (hostClick)="onUse('equip')">
            {{ 'item-description-dialog.equip' | translate }}
          </button>
        }

        <div class="g-flex__item-fixed g-flex-column">
          @if (isShowButtons || itemSlotName !== 'unknown') {
            <div class="buttons g-flex-column">
              @if (itemSlotName !== 'unknown' && !isFreeHero()) {
                <button
                  id="btn-take-off"
                  class="buttons__item app-btn-red-1"
                  appButtonClick
                  [infoDesc]="'inventory.disabled.desc' | translate"
                  [isDisabled]="!!hero?.dungeon || hero?.staked"
                  [isNeedConfirm]="true"
                  (hostClick)="onTakeOff()">
                  {{ 'item-description-dialog.take-off' | translate }}
                </button>
              }

              @if (isCanUse || (hero && item.meta.isConsumableItem && !isFreeHero())) {
                <button
                  id="btn-use2"
                  class="buttons__item app-btn-red-1"
                  appButtonClick
                  [infoDesc]="'item-description-dialog.disabled.use' | translate"
                  [isDisabled]="isUsed"
                  [isNeedConfirm]="true"
                  (hostClick)="onUse('potions')">
                  {{ 'item-description-dialog.use' | translate }}
                </button>
              }

              @if (!item.meta.isConsumableItem && !item.equipped) {
                <button
                  id="btn-repair"
                  class="buttons__item app-btn-blue-salvage"
                  appButtonClick
                  [infoDesc]="'item-description-dialog.disabled.repair' | translate"
                  [isDisabled]="+item.durability === +item.meta.durability"
                  (hostClick)="onRepair()"></button>
              }
            </div>
          }

          @if (isShowClose) {
            <button
              class="btn-share buttons__item app-btn-red-1"
              appButtonClick
              (hostClick)="onShare()">
              {{ 'item-description-dialog.share' | translate }}
            </button>
          }

          @if (isItemOwner()) {
            <button
              class="btn-transfer buttons__item app-btn-red-1"
              appButtonClick
              (hostClick)="onTransfer()">
              {{ 'item-description-dialog.transfer' | translate }}
            </button>
          }

          @if ((isShowButtons || itemSlotName !== 'unknown') && !item.meta.isConsumableItem && !item.equipped) {
            <button
              id="btn-forge"
              class="btn-forge buttons__item app-btn-blue-augment"
              (click)="onForge()">
              <div class="btn-augmentation__level app-btn-small_font-size">
                {{ item.augmentationLevel }}
              </div>
            </button>
          }
        </div>
      </div>
    </div>

    <div class="g-flex-column__item-fixed">
      @if (!item.meta.isConsumableItem) {
        @if (!isHideDurability) {
          <div class="durability g-flex g-flex--align-center g-flex--space-between app-btn-small_font-size">
            <div class="g-flex g-flex--align-center">
              <div
                id="durability"
                class="durability__label">
                {{ 'item-description-dialog.durability' | translate }}
              </div>

              <div>
                <span
                  [class.color-positive-value]="(+item.durability / +item.meta.durability) * 100 > 16"
                  [class.color-negative-value]="(+item.durability / +item.meta.durability) * 100 <= 16"
                  [class.text-shadow-negative]="(+item.durability / +item.meta.durability) * 100 <= 16">
                  {{ item.durability }}
                </span>
                / {{ item.meta.durability }}
              </div>

              @if (item.durability === 0) {
                <img
                  class="durability__info"
                  src="/assets/images/ui/icons/other/info-holder-question.png"
                  alt=""
                  (click)="onShowDurabilityDialog()" />
              }
            </div>

            <app-bar
              class="durability-bar"
              barType="durability"
              [percentageValue]="(+item.durability / +item.meta.durability) * 100">
            </app-bar>

            <img
              class="durability__icon"
              src="/assets/images/ui/icons/other/salvage.png"
              alt=""
              (click)="showDurabilityInfo()" />
          </div>
        }

        <div class="fragility g-flex g-flex--align-center g-flex--space-between app-btn-small_font-size">
          <div class="g-flex g-flex--align-center">
            <div
              id="fragility"
              class="fragility__label">
              {{ 'item-description-dialog.fragility' | translate }}
            </div>

            <div class="color-positive-value">
              {{ +item.fragility > 0 ? (+item.fragility / 1000).toFixed(0) : '0' }}%
            </div>
          </div>

          <app-bar
            class="fragility-bar"
            barType="fragility"
            [percentageValue]="+item.fragility / 1000">
          </app-bar>

          <img
            class="fragility__icon"
            src="/assets/images/ui/icons/other/fragility.png"
            alt=""
            (click)="showFragilityInfo()" />
        </div>
      }

      <div class="item-values__item g-flex g-flex--align-center">
        <div class="item-values__value">
          {{ 'item-description-dialog.item-level' | translate }}
        </div>

        <div id="item-level">
          {{ item.meta.level }}
        </div>
      </div>

      @if (requiredAttributes) {
        <div class="g-flex g-flex--align-center">
          <div
            id="requirements"
            class="item-values__value1">
            {{ 'item-description-dialog.required-stats' | translate }}
          </div>

          <div class="required g-flex">
            @for (level of heroCoreStats; track level) {
              <app-hero-stat-item
                class="required__item"
                [item]="level"
                [isRowLayout]="true"
                [useColors]="false"
                [isDisabled]="true"
                [isCompact]="true">
              </app-hero-stat-item>
            }
          </div>
        </div>
      }

      @if (avgPrice !== 0) {
        <div class="price g-flex g-flex--align-center gap-20">
          <div>Floor price on marketplace</div>

          <app-balance
            [isReverse]="false"
            [balance]="avgPrice"
            [balanceUnformatted]="avgPrice"
            [isShowBalanceUsd]="true"
            [isShowZeroBalanceUsd]="false"
            tokenType="sacra">
          </app-balance>
        </div>
      }

      <app-scratch
        class="scratch"
        backgroundType="middle">
      </app-scratch>

      <div class="item__desc-text text-shadow">
        {{ description | translate }}
      </div>

      @if (isApplyItem) {
        <button
          class="btn-apply app-btn-red-3"
          (click)="onClose({ isApply: true })">
          {{ applyItemLabel }}
        </button>
      }
    </div>
  </div>
</div>
