import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ItemMetaEntity } from '@generated/gql';
import { changeIPFSDomain, getActualMetaUri, getActualUri } from '@helpers/data-helper';
import { ItemEntityExtendedType } from '@models/item-entity-extended.type';
import { SoundService } from '@services/sound.service';
import { AugmentationLevelComponent } from '@shared/components/augmentation-level/augmentation-level.component';
import { ItemIdComponent } from '@shared/components/item-id/item-id.component';
import { getBackgroundColorName, ITEM_CLASS, ITEM_SLOT_BACKGROUND, ITEM_TYPE } from '@shared/constants/items.constant';
import { ItemSlotBgName } from '@shared/types/item-slot-bg-name.type';
import { ItemSlotSize } from '@shared/types/item-slot-size.type';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-item-slot',
  standalone: true,
  templateUrl: './item-slot.component.html',
  styleUrls: ['./item-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, ItemIdComponent, AugmentationLevelComponent],
})
export class ItemSlotComponent implements OnInit, OnChanges {
  @Input() slotItem?: ItemEntityExtendedType;
  @Input() slotItemMeta?: ItemMetaEntity;
  @Input() clickOnItemImg = false;
  @Input() slotSize: ItemSlotSize = 'middle';
  @Input() isDisabled = false;
  @Input() noColor = false;
  @Input() slotIcon?: string = '';
  @Input() isShowLevel = true;
  @Input() isShowId = true;
  @Input() isShowDurability = true;
  @Input() isShowBrokenFrame = false;

  @Output() clickEmit = new EventEmitter<MouseEvent>();

  @HostBinding('class')
  backgroundClass: string | undefined;

  @HostListener('click', ['$event'])
  onClick($event) {
    this.onItemClick($event);
  }

  itemDurability: string = '';
  imageUrl: string = '';
  durabilityPercentage: number;

  constructor(
    private logger: NGXLogger,
    private soundService: SoundService,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  onItemClick(event: MouseEvent): void {
    this.logger.trace('onItemClick', this.isDisabled);
    // event.stopPropagation();

    if (this.isDisabled) {
      return;
    }

    // this.soundService.play({ key: 'slot_choose' });

    this.clickEmit.emit(event);
  }

  private init() {
    this.backgroundClass = this.getBackgroundClass();

    if (this.slotItem) {
      this.changeURL(getActualUri(this.slotItem));
    }

    if (this.slotItemMeta) {
      this.changeURL(getActualMetaUri(this.slotItemMeta));
    }

    this.slotDurability();
  }

  private slotDurability() {
    const curDurability = this.slotItem?.durability ?? 0;
    const totalDurability = this.slotItem?.meta?.durability ?? 0;

    if (totalDurability === 0) {
      this.itemDurability = '';
    } else {
      this.durabilityPercentage = Math.round((curDurability / totalDurability) * 100);

      this.itemDurability =
        this.durabilityPercentage <= 20 || this.durabilityPercentage === 0 ? this.durabilityPercentage + '%' : '';
    }
  }

  private changeURL(url: string) {
    this.imageUrl = changeIPFSDomain(url);
  }

  private getBackgroundClass(): string | undefined {
    if (this.isDisabled) {
      return `app-item-slots-${this.slotSize}_unable`;
    }

    if (!this.slotItem || this.slotItem.itemId === 0) {
      return `app-item-slots-${this.slotSize}_empty`;
    }

    if (this.slotItem?.meta.itemType === ITEM_TYPE.SKILL) {
      return `app-item-slots-${this.slotSize}_aquamarine-skill${this.slotItem?.meta.isAttackItem ? '-attack' : ''}`;
    }

    if (this.slotItem?.meta.itemType === ITEM_CLASS.ATTACK) {
      return `app-item-slots-${this.slotSize}_aquamarine`;
    }

    const itemSlotBgName: ItemSlotBgName = getBackgroundColorName(this.slotItem);

    if (this.slotItem?.meta.itemType === ITEM_TYPE.SKILL) {
      return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}-skill${
        this.slotItem?.meta.isAttackItem ? '-attack' : ''
      }`;
    }

    if (this.noColor && itemSlotBgName !== 'aquamarine' && itemSlotBgName !== 'purple' && itemSlotBgName !== 'green') {
      return ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get('empty');
    }

    return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}`;
  }

  protected readonly getActualUri = getActualUri;
  protected readonly getActualMetaUri = getActualMetaUri;
}
