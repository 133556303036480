import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTE_TO_NAME,
  ATTRIBUTES,
} from '@shared/constants/attributes.constant';

export class AttributesModel {
  readonly attributesArray: number[];

  readonly strength: number;
  readonly dexterity: number;
  readonly vitality: number;
  readonly energy: number;
  readonly damageMin: number;
  readonly damageMax: number;
  readonly attackRating: number;
  readonly defense: number;
  readonly blockRating: number;
  readonly life: number;
  readonly mana: number;
  readonly fireResistance: number;
  readonly coldResistance: number;
  readonly lightningResistance: number;
  readonly dmgAgainstHuman: number;
  readonly dmgAgainstUndead: number;
  readonly dmgAgainstDaemon: number;
  readonly dmgAgainstBeast: number;
  readonly defAgainstHuman: number;
  readonly defAgainstUndead: number;
  readonly defAgainstDaemon: number;
  readonly defAgainstBeast: number;
  readonly lifeChances: number;
  readonly magicFind: number;
  readonly destroyItems: number;
  readonly criticalHit: number;
  readonly meleeDmgFactor: number;
  readonly fireDmgFactor: number;
  readonly coldDmgFactor: number;
  readonly lightningDmgFactor: number;
  readonly arFactor: number;
  readonly lifeStolenPerHit: number;
  readonly manaAfterKill: number;
  readonly damageReduction: number;
  readonly stun: number;
  readonly burn: number;
  readonly freeze: number;
  readonly confuse: number;
  readonly curse: number;
  readonly reflectDamageMelee: number;
  readonly reflectDamageMagic: number;
  readonly poison: number;
  readonly resistToStatuses: number;

  constructor(arrBN: bigint[]) {
    const arr = arrBN.map(x => Number(x));
    this.attributesArray = arr;

    this.strength = arr[0];
    this.dexterity = arr[1];
    this.vitality = arr[2];
    this.energy = arr[3];
    this.damageMin = arr[4];
    this.damageMax = arr[5];
    this.attackRating = arr[6];
    this.defense = arr[7];
    this.blockRating = arr[8];
    this.life = arr[9];
    this.mana = arr[10];
    this.fireResistance = arr[11];
    this.coldResistance = arr[12];
    this.lightningResistance = arr[13];
    this.dmgAgainstHuman = arr[14];
    this.dmgAgainstUndead = arr[15];
    this.dmgAgainstDaemon = arr[16];
    this.dmgAgainstBeast = arr[17];
    this.defAgainstHuman = arr[18];
    this.defAgainstUndead = arr[19];
    this.defAgainstDaemon = arr[20];
    this.defAgainstBeast = arr[21];
    this.lifeChances = arr[22];
    this.magicFind = arr[23];
    this.destroyItems = arr[24];
    this.criticalHit = arr[25];
    this.meleeDmgFactor = arr[26];
    this.fireDmgFactor = arr[27];
    this.coldDmgFactor = arr[28];
    this.lightningDmgFactor = arr[29];
    this.arFactor = arr[30];
    this.lifeStolenPerHit = arr[31];
    this.manaAfterKill = arr[32];
    this.damageReduction = arr[33];
    this.stun = arr[34];
    this.burn = arr[35];
    this.freeze = arr[36];
    this.confuse = arr[37];
    this.curse = arr[38];
    this.reflectDamageMelee = arr[39];
    this.reflectDamageMagic = arr[40];
    this.poison = arr[41];
    this.resistToStatuses = arr[42];
  }

  public static fromStringArray(arr: readonly string[]) {
    return new AttributesModel(arr.map(attr => BigInt(attr)));
  }

  public static fromMeta(ids: readonly number[], values: readonly number[]) {
    const array: number[] = [];
    for (let i = 0; i <= ATTRIBUTES.DAMAGE; i++) {
      array.push(0);
    }
    for (let i = 0; i < ids.length; i++) {
      array[ids[i]] = values[i];
    }
    return new AttributesModel(array.map(attr => BigInt(attr)));
  }

  getAttribute(id: ATTRIBUTES) {
    return this.attributesArray[id];
  }

  getAttributeName(id: ATTRIBUTES): string {
    return ATTRIBUTE_TO_NAME.get(id) ?? 'unknown';
  }

  getAttributeIcon(id: ATTRIBUTES): string {
    return ATTRIBUTE_TO_ICON.get(id) ?? 'unknown';
  }

  getAttributeDescription(id: ATTRIBUTES): string {
    return ATTRIBUTE_TO_DESCRIPTION.get(id) ?? '';
  }

  print() {
    let text = '';
    for (let i = 0; i < this.attributesArray.length; i++) {
      if (this.attributesArray[i] !== 0) {
        text += this.getAttributeName(i) + ': ' + this.attributesArray[i] + '\n';
      }
    }
    return text;
  }
}

export type CoreAttributesType = {
  readonly strength: number;
  readonly dexterity: number;
  readonly vitality: number;
  readonly energy: number;
};
