<div class="skills g-flex-column">
  @if (type) {
    <img
      class="skills__icon"
      [class.skills__icon--compact]="isCompact"
      [src]="'/assets/images/ui/icons/item-props/' + type + '.png'"
      alt="item image" />
  }

  @if (!isCompact) {
    <div class="skills__top">
      <div class="g-flex g-flex--align-center g-flex--space-between">
        <div title="">
          {{ title }}
        </div>

        @if (manaConsumption && manaConsumption !== 0) {
          <div class="skills__mana-cost g-flex g-flex--align-center">
            <img
              src="/assets/images/ui/icons/attributes/MANA_COST.png"
              alt="item image" />

            <div class="color-mana text-shadow">
              {{ manaConsumption }}
            </div>

            <div>Mana cost</div>
          </div>
        }
      </div>

      <!--<app-scratch class="skills__scratch"></app-scratch>-->
    </div>
  }

  <div
    class="skills__middle"
    [class.skills__middle--compact-bg]="isCompactBg"
    [class.skills__middle--compact]="isCompact"
    [class.g-flex]="isCompact"
    [class.g-flex--space-between]="isCompact">
    <div
      class="skills__middle-content"
      [class.g-flex]="isCompact">
      @for (skillAttribute of skillsAttributes; track skillAttribute; let index = $index) {
        @if (skillAttribute && (skillAttribute.attributesArray | filterNull).length > 0) {
          @for (attr of skillAttribute.attributesArray; track attr; let i = $index) {
            @if (attr !== 0) {
              <app-hero-stat-item
                class="item"
                [class.item--compact]="isCompact"
                [isCompact]="isCompact"
                [isSmallFont]="isSmallFont"
                [isRowLayout]="!isCompact"
                [isShowSign]="true"
                [isInline]="true"
                [item]="{
                  icon: skillAttribute.getAttributeIcon(i),
                  name: skillAttribute.getAttributeName(i),
                  text: attr > 0 ? +(skillsSigns[index] + attr) : attr,
                  description: skillAttribute.getAttributeDescription(i),
                  attributeIndex: index,
                }">
              </app-hero-stat-item>
            }
          }
        }
      }

      @if (hasEnemyAttributes && !isCompact) {
        <div class="app-btn-small_font-size">
          <app-scratch class="skills__scratch"></app-scratch>

          <div class="g-flex g-flex--align-center">
            <img
              src="/assets/images/ui/icons/item-props/enemy-target.png"
              alt="item image" />

            <div class="color-caption">
              This item will affect on the <span class="color-enemy-name">Enemy target:</span>
            </div>
          </div>
        </div>
      }

      @for (skillAttribute of enemyGotAttributes; track skillAttribute; let index = $index) {
        @if (skillAttribute && (skillAttribute.attributesArray | filterNull).length > 0) {
          @for (attr of skillAttribute.attributesArray; track attr; let i = $index) {
            @if (attr !== 0) {
              <app-hero-stat-item
                class="item"
                [class.item--compact]="isCompact"
                [isCompact]="isCompact"
                [isSmallFont]="isSmallFont"
                [isRowLayout]="!isCompact"
                [isShowSign]="true"
                [isInline]="true"
                [item]="{
                  isAddEnemyIcon: true,
                  icon: skillAttribute.getAttributeIcon(i),
                  name: skillAttribute.getAttributeName(i),
                  text: attr > 0 ? +(skillsSigns[index] + attr) : attr,
                  description: skillAttribute.getAttributeDescription(i),
                  attributeIndex: index,
                }">
              </app-hero-stat-item>
            }
          }
        }
      }
    </div>

    @if (isCompact && manaConsumption && manaConsumption !== 0) {
      <div class="skills__mana-cost g-flex g-flex--align-center">
        <img
          src="/assets/images/ui/icons/attributes/MANA_COST.png"
          alt="item image" />

        <div class="color-mana text-shadow">
          {{ manaConsumption }}
        </div>
      </div>
    }
  </div>
</div>
