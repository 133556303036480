<img
  class="bar__bg"
  [src]="'assets/images/ui/bars/' + (barType === 'card-durability' ? 'durability' : barType) + '/bg.avif'"
  alt="" />

<img
  class="bar__level"
  [class.bar__level--achievements]="barType === 'achievements'"
  [src]="'assets/images/ui/bars/' + (barType === 'card-durability' ? 'durability' : barType) + '/color.avif'"
  [style.clip-path]="'inset(0 ' + (100 - percentageValue) + '% 0 0)'"
  alt="" />

@if (!!label) {
  <div class="bar__label app-btn-micro_font-size text-bar-shadow bar-exp g-flex g-flex--align-center gap-8">
    {{ label }}
  </div>
}

<ng-content select="[label]"></ng-content>
