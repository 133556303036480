import { BiomeType } from '../../pages/map/constants/biome-type';

export const DUNGEON_NAMES = new Map<string, string>([
  ['BIOME_1_ALIVE_AGAIN_1', 'dungeon-names.BIOME_1_ALIVE_AGAIN_1'],
  ['BIOME_1_ALIVE_AGAIN_1_F2P', 'dungeon-names.BIOME_1_ALIVE_AGAIN_1'],
  ['BIOME_1_DEPTHS_OF_THE_FOREST_2', 'dungeon-names.BIOME_1_DEPTHS_OF_THE_FOREST_2'],
  ['BIOME_1_LOF_BLAID_3', 'dungeon-names.BIOME_1_LOF_BLAID_3'],
  ['BIOME_1_OUTSKIRTS_OF_THE_CITY_4', 'dungeon-names.BIOME_1_OUTSKIRTS_OF_THE_CITY_4'],
  ['BIOME_1_DESPERATION_5', 'dungeon-names.BIOME_1_DESPERATION_5'],
  ['BIOME_1_NIGHT_FOREST_6', 'dungeon-names.BIOME_1_NIGHT_FOREST_6'],
  ['BIOME_1_ANOTHER_DAY_7', 'dungeon-names.BIOME_1_ANOTHER_DAY_7'],
  ['BIOME_1_PILGRIMAGE_8', 'dungeon-names.BIOME_1_PILGRIMAGE_8'],
  ['BIOME_1_THIS_IS_RIDICULOUS_9', 'dungeon-names.BIOME_1_THIS_IS_RIDICULOUS_9'],
  ['BIOME_1_BLOOD_TEARS_10', 'dungeon-names.BIOME_1_BLOOD_TEARS_10'],
  ['BIOME_1_DANGEROUS_TRAILS_11', 'dungeon-names.BIOME_1_DANGEROUS_TRAILS_11'],
  ['BIOME_1_GRIMLARNS_HOSPITALITY_12', 'dungeon-names.BIOME_1_GRIMLARNS_HOSPITALITY_12'],
  ['BIOME_1_HAMMERGINA_119', 'dungeon-names.BIOME_1_HAMMERGINA_119'],
  ['BIOME_1_FORGOTTEN_LAND_13', 'dungeon-names.BIOME_1_FORGOTTEN_LAND_13'],
  ['BIOME_1_SACRA_HUNT_14', 'dungeon-names.BIOME_1_SACRA_HUNT_14'],
  ['BIOME_1_IN_THE_WILD_15', 'dungeon-names.BIOME_1_IN_THE_WILD_15'],
  ['BIOME_1_ABANDONED_TRACT_16', 'dungeon-names.BIOME_1_ABANDONED_TRACT_16'],
  ['BIOME_1_TERRITORY_OF_BANDITS_17', 'dungeon-names.BIOME_1_TERRITORY_OF_BANDITS_17'],
  ['BIOME_1_BURNED_VILLAGES_18', 'dungeon-names.BIOME_1_BURNED_VILLAGES_18'],
  ['BIOME_2_DEPTHS_OF_THE_FOREST_2', 'dungeon-names.BIOME_2_DEPTHS_OF_THE_FOREST_2'],
  ['BIOME_2_LOF_BLAID_3', 'dungeon-names.BIOME_2_LOF_BLAID_3'],
  ['BIOME_2_OUTSKIRTS_OF_THE_CITY_4', 'dungeon-names.BIOME_2_OUTSKIRTS_OF_THE_CITY_4'],
  ['BIOME_2_DESPERATION_5', 'dungeon-names.BIOME_2_DESPERATION_5'],
  ['BIOME_2_NIGHT_FOREST_6', 'dungeon-names.BIOME_2_NIGHT_FOREST_6'],
  ['BIOME_2_ANOTHER_DAY_7', 'dungeon-names.BIOME_2_ANOTHER_DAY_7'],
  ['BIOME_2_PILGRIMAGE_8', 'dungeon-names.BIOME_2_PILGRIMAGE_8'],
  ['BIOME_2_THIS_IS_RIDICULOUS_9', 'dungeon-names.BIOME_2_THIS_IS_RIDICULOUS_9'],
  ['BIOME_2_BLOOD_TEARS_10', 'dungeon-names.BIOME_2_BLOOD_TEARS_10'],
  ['BIOME_2_DANGEROUS_TRAILS_11', 'dungeon-names.BIOME_2_DANGEROUS_TRAILS_11'],
  ['BIOME_2_GRIMLARNS_HOSPITALITY_12', 'dungeon-names.BIOME_2_GRIMLARNS_HOSPITALITY_12'],
  ['BIOME_2_FORGOTTEN_LAND_13', 'dungeon-names.BIOME_2_FORGOTTEN_LAND_13'],
  ['BIOME_2_SACRA_HUNT_14', 'dungeon-names.BIOME_2_SACRA_HUNT_14'],
  ['BIOME_2_ABANDONED_TRACT_16', 'dungeon-names.BIOME_2_ABANDONED_TRACT_16'],
  ['BIOME_2_TERRITORY_OF_BANDITS_17', 'dungeon-names.BIOME_2_TERRITORY_OF_BANDITS_17'],
  ['BIOME_2_BURNED_VILLAGES_18', 'dungeon-names.BIOME_2_BURNED_VILLAGES_18'],

  ['BIOME_3_WAYS_OF_THE_DROWNED', 'dungeon-names.BIOME_3_WAYS_OF_THE_DROWNED'],
  ['BIOME_3_TRAPS_ARE_EVERYWHERE', 'dungeon-names.BIOME_3_TRAPS_ARE_EVERYWHERE'],
  ['BIOME_3_FEED_THE_MOTHER', 'dungeon-names.BIOME_3_FEED_THE_MOTHER'],
  ['BIOME_3_GREAT_EXODUS', 'dungeon-names.BIOME_3_GREAT_EXODUS'],
  ['BIOME_3_LAST_SPARKS', 'dungeon-names.BIOME_3_LAST_SPARKS'],
  ['BIOME_3_GROVE_OF_GIANTS', 'dungeon-names.BIOME_3_GROVE_OF_GIANTS'],
  ['BIOME_3_CREEPY_PLACE', 'dungeon-names.BIOME_3_CREEPY_PLACE'],
  ['BIOME_3_WHAT_DOES_THE_THICK_FOG_HIDE', 'dungeon-names.BIOME_3_WHAT_DOES_THE_THICK_FOG_HIDE'],
  ['BIOME_3_ITS_GETTING_COLDER', 'dungeon-names.BIOME_3_ITS_GETTING_COLDER'],
  ['BIOME_3_FORGOTTEN_FAITH', 'dungeon-names.BIOME_3_FORGOTTEN_FAITH'],
]);

export const BIOME_NAMES = new Map<number, string>([
  [0, 'biomes.biome-unknown.title'],
  [1, 'biomes.biome-1.title'],
  [2, 'biomes.biome-2.title'],
  [3, 'biomes.biome-3.title'],
  [4, 'biomes.biome-4.title'],
]);

export const BIOMES_TITLES: { [id: string]: BiomeType } = {
  1: {
    id: 1,
    title: 'biomes.biome-1.title',
    description: 'biomes.biome-1.description',
    biomeAvatar: 'assets/images/ui/art/biomes/biome-1.avif',
    bossAvatar: 'assets/images/ui/art/biomes/boss-1.avif',
    bossImage: 'assets/images/ui/art/boss/boss-1.avif',
    bossName: 'biomes.biome-1.bossName',
    bossDescription: 'biomes.biome-1.bossDescription',
    bossLevel: 5,
  },
  2: {
    id: 2,
    title: 'biomes.biome-2.title',
    description: 'biomes.biome-2.description',
    biomeAvatar: 'assets/images/ui/art/biomes/biome-2.avif',
    bossAvatar: 'assets/images/ui/art/biomes/boss-2.avif',
    bossImage: 'assets/images/ui/art/boss/boss-2.avif',
    bossName: 'biomes.biome-2.bossName',
    bossDescription: 'biomes.biome-2.bossDescription',
    bossLevel: 10,
  },
  3: {
    id: 3,
    title: 'biomes.biome-3.title',
    description: 'biomes.biome-3.description',
    biomeAvatar: 'assets/images/ui/art/biomes/biome-3.avif',
    bossAvatar: 'assets/images/ui/art/biomes/boss-3.avif',
    bossImage: 'assets/images/ui/art/boss/boss-3.avif',
    bossName: 'biomes.biome-3.bossName',
    bossDescription: 'biomes.biome-3.bossDescription',
    bossLevel: 15,
  },
  4: {
    id: 4,
    title: 'biomes.biome-4.title',
    description: 'biomes.biome-4.description',
    biomeAvatar: 'assets/images/ui/art/biomes/biome-4.avif',
    bossAvatar: 'assets/images/ui/art/biomes/boss-4.avif',
    bossImage: 'assets/images/ui/art/boss/boss-4.avif',
    bossName: 'biomes.biome-4.bossName',
    bossDescription: 'biomes.biome-4.bossDescription',
    bossLevel: 20,
  },
  5: {
    id: 5,
    title: 'biomes.biome-5.title',
    description: 'biomes.biome-5.description',
    biomeAvatar: 'assets/images/ui/art/biomes/biome-4.avif',
    bossAvatar: 'assets/images/ui/art/biomes/boss-4.avif',
    bossImage: 'assets/images/ui/art/boss/boss-4.avif',
    bossName: 'biomes.biome-5.bossName',
    bossDescription: 'biomes.biome-5.bossDescription',
    bossLevel: 25,
  },
};
