import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { Mediator } from '@services/mediator.service';
import { TokenService } from '@services/onchain/token.service';
import { UserControllerService } from '@services/onchain/user-controller.service';
import { LoadingActions } from '@shared/actions/loading.actions';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { NUMBERS } from '@shared/constants/numbers.constant';
import { formatUnits } from 'ethers';
import { finalize, takeUntil } from 'rxjs';

export enum CHANGE_TYPE {
  CHANGE_LOGO,
  CHANGE_NAME,
  CHANGE_JOIN_FEE,
  CHANGE_DESCRIPTION,
}

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    DialogTitleComponent,
    BalanceComponent,
    ButtonClickDirective,
    LoadingSmallComponent,
  ],
  templateUrl: './edit-input-dialog.component.html',
  styleUrl: './edit-input-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
})
export class EditInputDialogComponent implements OnInit {
  type = 'text';
  inputControl: FormControl;

  isLoading = false;
  isNeedApprove = false;
  isValueToLong = false;

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      value: string | number;
      title: string;
      isNumber: boolean;
      account: string;
      chainId: number;
      actionType: CHANGE_TYPE;
      amount?: number;
      allowanceTarget?: string;
      maxLength?: number;
      mediatorAction?: any;
    },
    private dialogRef: DialogRef<string, EditInputDialogComponent>,
    private destroy$: DestroyService,
    private changeDetectorRef: ChangeDetectorRef,
    private tokenService: TokenService,
    private userControllerService: UserControllerService,
    private mediator: Mediator,
  ) {}

  ngOnInit() {
    if (this.data.maxLength) {
      this.inputControl = new FormControl<string>('', {
        updateOn: 'change',
        validators: [Validators.required, Validators.maxLength(this.data.maxLength)],
      });
    } else {
      this.inputControl = new FormControl<string>('', {
        updateOn: 'change',
        validators: [Validators.required],
      });
    }

    if (this.data.value) {
      this.inputControl.setValue(this.data.value || '');
    }

    if (this.data.isNumber) {
      this.type = 'number';
    }

    if (!!this.data.allowanceTarget && this.data.chainId) {
      this.getAllowance();
    }

    this.inputControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isValueToLong = !!this.inputControl.errors?.maxlength;
    });

    this.mediator.ofAction(LoadingActions.ChainTransaction, this.destroy$).subscribe(({ isRunning }) => {
      this.isLoading = isRunning;

      this.changeDetectorRef.detectChanges();
    });
  }

  callApprove() {
    this.isLoading = true;

    const token = GET_CORE_ADDRESSES(this.data.chainId).gameToken;
    const target = this.data.allowanceTarget ?? 'NO_TARGET';

    this.tokenService
      .approve$(this.data.account, token, target, BigInt(NUMBERS.MAX_UINT))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe(() => {
        this.getAllowance();
      });
  }

  onSave(): void {
    if (this.data.mediatorAction) {
      this.isLoading = true;

      this.mediator.dispatch(new LoadingActions.ChainTransaction(true));
      this.mediator.dispatch(new this.data.mediatorAction(this.inputControl.value));
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private getAllowance() {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();

    const token = GET_CORE_ADDRESSES(this.data.chainId).gameToken;
    const target = this.data.allowanceTarget ?? 'NO_TARGET';

    this.tokenService
      .allowance$(token, this.data.account, target)
      .pipe(takeUntil(this.destroy$))
      .subscribe(a => {
        this.isNeedApprove = +formatUnits(a) < (this.data.amount ?? 0);
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }
}
