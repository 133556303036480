import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AttributesMetaModel } from '@models/attributes-meta.model';
import { AttributesModel } from '@models/attributes.model';
import { HeroStatItemComponent } from '@shared/components/hero-stat-item/hero-stat-item.component';
import { FilterNullPipe } from '@shared/components/pipes/filter-null/filter-null.pipe';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';

@Component({
  selector: 'app-skill-area',
  standalone: true,
  templateUrl: './skill-area.component.html',
  styleUrls: ['./skill-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeroStatItemComponent, FilterNullPipe, ScratchComponent],
})
export class SkillAreaComponent {
  @Input() title = '';
  @Input() type: 'skill' | 'attack' | 'use' | null = null;
  @Input() manaConsumption: number | null = null;
  @Input() skillsAttributes: (AttributesModel | AttributesMetaModel | undefined)[] = [];
  @Input() skillsSigns: string[] = [];
  @Input() hasEnemyAttributes: boolean | undefined = false;
  @Input() enemyGotAttributes: (AttributesModel | AttributesMetaModel | undefined)[] = [];
  @Input() isCompact = false;
  @Input() isCompactBg = false;
  @Input() isSmallFont = false;
}
