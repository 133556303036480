import { Injectable } from '@angular/core';
import { Multicall, Multicall__factory } from '@data/abi';
import { ErrorService } from '@services/error.service';
import { ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { NGXLogger } from 'ngx-logger';
import { catchError, from, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MultiCallService {
  constructor(
    private providerService: ProviderService,
    private logger: NGXLogger,
    private errorService: ErrorService,
  ) {}

  // --- FACTORIES ---

  private createMultiCall(chainId: number) {
    return Multicall__factory.connect(GET_CORE_ADDRESSES(chainId).multicall, this.providerService.getProviderForRead());
  }

  // --- VIEWS ---

  aggregate$(call: Multicall.CallStruct[], chainId: number) {
    // this.logger.trace('aggregate', call);
    return from(this.createMultiCall(chainId).aggregate.staticCall(call)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  getEthBalance(chainId: number, account: string): Multicall.CallStruct {
    return {
      target: GET_CORE_ADDRESSES(chainId).multicall,
      callData: this.createMultiCall(chainId).interface.encodeFunctionData('getEthBalance', [account]),
    };
  }
}
