<div class="header g-flex g-flex-column__item">
  <div class="header__level app-lvl-default g-flex g-flex--align-center g-flex--justify-center g-flex__item-fixed">
    <div class="header__level-text">
      {{ hero.stats.level }}
    </div>
  </div>

  <div class="title g-flex-column g-flex__item text-shadow">
    <div class="color-nickname">
      {{ hero.uniqName }}
    </div>

    <div class="g-flex">
      <div class="hero-class title-a app-btn-medium_font-size">
        {{ heroClassName | translate }}
      </div>

      <app-life-chances
        class="life-chances"
        [lifeChance]="hero.stats.lifeChances">
      </app-life-chances>
    </div>

    <div class="hero-title color-caption app-btn_font-size g-flex g-flex--align-center">
      <div>{{ heroTitle }} ({{ karma }})</div>

      <img
        class="hero-title__info"
        src="/assets/images/ui/icons/other/info-holder-question.png"
        alt=""
        (click)="onShowHeroTitleDialog()" />
    </div>
  </div>

  <div class="bars g-flex-column g-flex__item-fixed">
    <app-bar-stat
      barType="exp"
      size="medium"
      [percentageValue]="expBarWidth()"
      [label]="heroExpPercentage() >= 100 ? 'up to ' + virtualLevel + ' level' : heroExpPercentage() + ' %'">
    </app-bar-stat>

    <app-bar-stat
      barType="hp"
      size="medium"
      [percentageValue]="hero.stats.life / heroAttributes.life"
      [label]="hero.stats.life + ' / ' + heroAttributes.life">
    </app-bar-stat>

    <app-bar-stat
      barType="mp"
      size="medium"
      [percentageValue]="hero.stats.mana / heroAttributes.mana"
      [label]="hero.stats.mana + ' / ' + heroAttributes.mana">
    </app-bar-stat>
  </div>
</div>

<app-scratch
  class="divider"
  backgroundType="huge"></app-scratch>

<div class="stats g-flex g-flex--wrap">
  @for (item of fightStatInfo; track item) {
    <app-hero-stat-item
      class="stats__item"
      [useColors]="false"
      [isRowLayout]="true"
      [iconCustomWidth]="90"
      [isShowAdjusted]="true"
      [item]="item">
    </app-hero-stat-item>
  }
</div>

<div class="g-flex g-flex--space-between">
  <app-scratch backgroundType="small"></app-scratch>

  <app-scratch backgroundType="small"></app-scratch>
</div>

<div class="stats g-flex g-flex--wrap">
  @for (item of coreStatInfo; track item) {
    <app-hero-stat-item
      class="stats__item"
      [useColors]="false"
      [isRowLayout]="true"
      [isShowAdjusted]="true"
      [iconCustomWidth]="90"
      [item]="item">
    </app-hero-stat-item>
  }
</div>

<app-scratch backgroundType="huge"></app-scratch>

<div class="stats g-flex g-flex--wrap">
  @for (item of otherStatInfo; track item) {
    <app-hero-stat-item
      class="stats__item"
      [useColors]="false"
      [isRowLayout]="true"
      [isShowAdjusted]="true"
      [iconCustomWidth]="90"
      [item]="item">
    </app-hero-stat-item>
  }
</div>

<!-- ONLY FOR NON PROD-->
@if (!isProductionBuild) {
  <div class="g-flex-column">
    @for (stat of customHeroData; track stat) {
      <div>{{ stat.name }}: {{ stat.text }}</div>
    }
  </div>
}
