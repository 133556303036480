import { MAIN_ROUTES } from '@shared/constants/routes.constant';

export const ROUTE_WITH_EXCLUDED_BARS = [
  MAIN_ROUTES.HERO,
  MAIN_ROUTES.STAT,
  MAIN_ROUTES.DUNGEON_FIGHT,
  MAIN_ROUTES.CREATE,
  MAIN_ROUTES.TRANSFORM,
  MAIN_ROUTES.GUILDS,
];

export const ROUTE_WITH_EXCLUDED_HEADER = [MAIN_ROUTES.AUTH];
