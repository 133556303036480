export enum DROPDOWN_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  MEDIUM_SPECIAL = 'medium_special',
  LARGE = 'large',
  HUGE = 'huge',
}

export const DROPDOWN_WIDTHS = {
  [DROPDOWN_SIZE.SMALL]: 250,
  [DROPDOWN_SIZE.MEDIUM]: 407,
  [DROPDOWN_SIZE.MEDIUM_SPECIAL]: 527,
  [DROPDOWN_SIZE.LARGE]: 536,
  [DROPDOWN_SIZE.HUGE]: 980,
};

export const DROPDOWN_ITEM_HEIGHT = 60;
