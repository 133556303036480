export enum ACTION_TYPES {
  DAMAGE = 'DAMAGE',
  MISS = 'MISS',
  BLOCK = 'BLOCK',
  CRITICAL = 'CRITICAL',
  POISON_DAMAGE = 'POISON_DAMAGE',
  RETALIATE = 'RETALIATE',
  MANA_CONSUMED = 'MANA_CONSUMED',
  STUN = 'STUN',
  BURN = 'BURN',
  FREEZE = 'FREEZE',
  CONFUSE = 'CONFUSE',
  CURSE = 'CURSE',
  POISON = 'POISON',
  POITION = 'POITION',
}

export const ACTION_STATUSES = {
  burn: ACTION_TYPES.DAMAGE,
  curse: ACTION_TYPES.CURSE,
  confuse: ACTION_TYPES.CONFUSE,
  freeze: ACTION_TYPES.FREEZE,
  poison: ACTION_TYPES.POISON,
  stun: ACTION_TYPES.STUN,
  gotCriticalHit: ACTION_TYPES.CRITICAL,
  missed: ACTION_TYPES.MISS,
  hitBlocked: ACTION_TYPES.BLOCK,
};

export enum ACTION_ICONS {
  // damages
  MISS = 'miss',
  PHYS = 'phys',
  COLD = 'cold',
  FIRE = 'fire',
  LIGHTING = 'lighting',
  CHAOS = 'chaos',
  POISON = 'poison',
  RETALIATE = 'retaliate',
  CRITICAL = 'critical',
  // hp/mp
  MANA_CONSUMED = 'mana_consumed',
  // statuses
  STUN = 'stun',
  BURN = 'burn',
  FREEZE = 'freeze',
  CONFUSE = 'confuse',
  CURSE = 'curse',
  POITION = 'poition',
}

export enum ACTION_NAMES {
  DAMAGE = 'action-names.damage',
  POISON_DAMAGE = 'action-names.poison_damage',
  MISS = 'action-names.miss',
  PHYS = 'action-names.physical',
  COLD = 'action-names.cold',
  FIRE = 'action-names.fire',
  LIGHTING = 'action-names.lighting',
  CHAOS = 'action-names.chaos',
  POISON = 'action-names.poison',
  RETALIATE = 'action-names.retaliate',
  MANA_CONSUMED = 'action-names.mana_consumed',
  STUN = 'action-names.stun',
  BURN = 'action-names.burn',
  FREEZE = 'action-names.freeze',
  CONFUSE = 'action-names.confuse',
  CURSE = 'action-names.curse',
  HEAL = 'action-names.heal',
  CRITICAL = 'action-names.critical',
}

export enum ACTION_DESCRIPTIONS {
  DAMAGE = 'action-descriptions.damage',
  POISON_DAMAGE = 'action-descriptions.poison_damage',
  MISS = 'action-descriptions.miss',
  PHYS = 'action-descriptions.physical',
  COLD = 'action-descriptions.cold',
  FIRE = 'action-descriptions.fire',
  LIGHTING = 'action-descriptions.lighting',
  CHAOS = 'action-descriptions.chaos',
  POISON = 'action-descriptions.poison',
  RETALIATE = 'action-descriptions.retaliate',
  MANA_CONSUMED = 'action-descriptions.mana_consumed',
  STUN = 'action-descriptions.stun',
  BURN = 'action-descriptions.burn',
  FREEZE = 'action-descriptions.freeze',
  CONFUSE = 'action-descriptions.confuse',
  CURSE = 'action-descriptions.curse',
  HEAL = 'action-descriptions.heal',
  CRITICAL = 'action-descriptions.critical',
}

export enum MAGIC_ATTACK_TYPES {
  UNKNOWN,
  FIRE,
  COLD,
  LIGHTNING,
  CHAOS,
}
