<div
  class="item g-flex"
  [class.item--unread]="item.isUnread">
  @if (item.imageUrl) {
    <img
      class="item__img"
      [src]="item.imageUrl"
      alt="frame" />
  } @else {
    <img
      class="item__img"
      [src]="'assets/images/ui/achievements/art/' + item.type + '.avif'"
      alt="frame" />
  }

  <img
    class="item__frame"
    [src]="'assets/images/ui/achievements/frame' + (item.isUnread ? '-active' : '') + '.avif'"
    alt="frame" />

  <div class="item__text g-flex-column g-flex--justify-center">
    <div
      class="app-btn_font-size text-shadow"
      [class.color-yellow-fire]="item.isUnread"
      [class.text-shadow-positive]="item.isUnread">
      {{ item.title }}
    </div>

    <div class="item__desc app-btn_font-size text-shadow">
      {{ item.description }}
    </div>
  </div>
</div>
