export enum SOUND_TYPES {
  MUSIC = 'music',
  EFFECT = 'effect',
}

export interface ISound {
  id: string;
  type: SOUND_TYPES;
  name?: string;
  file: string;
  volume?: number;
}
