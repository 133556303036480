import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isMobile } from '@services/web3modal-ts/src';
import { TitleBackgroundType } from '@shared/components/title/title-background-type';
import { TitleFontType } from '@shared/components/title/title-font-type';
import { isMetamask } from '@shared/utils';

@Component({
  selector: 'app-title',
  standalone: true,
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class TitleComponent implements OnChanges {
  @Input()
  backgroundType: TitleBackgroundType;

  @Input()
  fontType: TitleFontType = 'default';

  @Input()
  isShowDivider = true;

  @Input()
  description: string | null = null;

  @Input()
  isNoMargin: boolean = false;

  @HostBinding('class')
  private hostClasses: string[];

  @HostBinding('class.is-metamask')
  get isMetamask() {
    return isMetamask || isMobile();
  }

  ngOnChanges({ backgroundType }: SimpleChanges): void {
    const type = backgroundType?.currentValue;
    const className = this.getHostClass(type);

    this.hostClasses = className.split(',');
  }

  private getHostClass(type: TitleBackgroundType): string {
    switch (type) {
      case 'middle':
        return 'app-paint-titles-1-middle';
      case 'small':
        return 'app-paint-titles-1-small';
      case 'medium2':
        return 'app-paint-titles-2-medium';
      case 'long':
      default:
        return 'app-paint-titles-1-long,title-long';
    }
  }
}
