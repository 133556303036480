import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TransactionDataModel } from '@models/transaction-data.model';
import { LoadTextService } from '@services/data/load-text.service';
import { DestroyService } from '@services/destroy.service';
import { Mediator } from '@services/mediator.service';
import { isMobile } from '@services/web3modal-ts/src/helpers/utils';
import { LoadingActions } from '@shared/actions/loading.actions';
import { LOADING_STORIES } from '@shared/constants/assets-paths.constant';
import * as THREE from 'three';

interface LoadingTextInterface {
  header: string;
  text: string;
  image: string;
}

@Component({
  selector: 'app-loading',
  standalone: true,
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column g-flex--align-center',
  },
})
export class LoadingComponent implements OnInit {
  @Input() appZoom: number = 1;

  currentTextIndex = 0;
  isShow = false;
  transactionData?: TransactionDataModel;
  texts: LoadingTextInterface[] = [];
  isMobile = isMobile();

  private timeout = 60_000;
  private showInterval: number = 0;
  private lastTextUpdate = 0;

  constructor(
    private loadTextService: LoadTextService,
    private mediator: Mediator,
    private destroy$: DestroyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadTextService.loadJson<LoadingTextInterface[]>(LOADING_STORIES).subscribe(data => {
      this.texts = data;

      this.texts.sort(() => Math.random() - 0.5);
    });

    this.mediator.ofAction(LoadingActions.Toggle, this.destroy$).subscribe(({ isShow, transactionData }) => {
      this.isShow = isShow;
      this.transactionData = transactionData;

      this.randomData();

      clearTimeout(this.showInterval);

      if (isShow) {
        // setTimeout(() => this.createThreeJsBox());

        this.showInterval = window.setInterval(() => {
          this.randomData();

          this.changeDetectorRef.markForCheck();
        }, this.timeout);
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  randomData() {
    if (this.lastTextUpdate !== 0 && Date.now() - this.lastTextUpdate < this.timeout) {
      return;
    }
    this.currentTextIndex = Math.floor(Math.random() * this.texts.length);
    if (this.currentTextIndex >= this.texts.length) {
      this.currentTextIndex = 0;
    }
    this.lastTextUpdate = Date.now();
  }

  createThreeJsBox(): void {
    const canvas = document.querySelector('#canvas-box');

    const scene = new THREE.Scene();

    const material = new THREE.MeshToonMaterial();

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 0.5);
    pointLight.position.x = 2;
    pointLight.position.y = 2;
    pointLight.position.z = 2;
    scene.add(pointLight);

    const box = new THREE.Mesh(new THREE.BoxGeometry(1.5, 1.5, 1.5), material);

    const torus = new THREE.Mesh(new THREE.TorusGeometry(5, 1.5, 16, 100), material);

    scene.add(torus, box);

    const canvasSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const camera = new THREE.PerspectiveCamera(75, canvasSizes.width / canvasSizes.height, 0.001, 1000);
    camera.position.z = 30;
    scene.add(camera);

    if (!canvas) {
      return;
    }

    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
    });
    renderer.setClearColor(0xe232222, 0);
    renderer.setSize(canvasSizes.width, canvasSizes.height);

    const clock = new THREE.Clock();

    const animateGeometry = () => {
      const elapsedTime = clock.getElapsedTime();

      // Update animaiton objects
      box.rotation.x = elapsedTime;
      box.rotation.y = elapsedTime;
      box.rotation.z = elapsedTime;

      torus.rotation.x = -elapsedTime;
      torus.rotation.y = -elapsedTime;
      torus.rotation.z = -elapsedTime;

      // Render
      renderer.render(scene, camera);

      // Call tick again on the next frame
      window.requestAnimationFrame(animateGeometry);
    };

    animateGeometry();
  }
}
