import { ProviderService } from '@services/provider.service';
import { from, of, switchMap, tap } from 'rxjs';

export const ON_CHAIN_CALL_RETRY = 3;
export const ON_CHAIN_CALL_DELAY = 3000;

export abstract class FeesExtension {
  maxFeePerGas?: bigint;
  maxPriorityFeePerGas?: bigint;
  gasPrice?: bigint;

  adjustFees() {
    // if (chainId === 5) {
    //   this.maxPriorityFeePerGas = GOERLI_FEE;
    //   this.maxFeePerGas = GOERLI_FEE_MAX;
    // }
    // if (chainId === 80001) {
    //   this.maxPriorityFeePerGas = MUMBAI_FEE;
    //   this.maxFeePerGas = MUMBAI_FEE_MAX;
    // }
    // else if (chainId === 80001) {
    //   this.maxPriorityFeePerGas = GOERLI_FEE;
    //   this.maxFeePerGas = GOERLI_FEE_MAX;
    // }
  }

  updateCurrentFees$(providerService: ProviderService, gasEstimation: bigint) {
    console.log('update Current Fees', gasEstimation);
    return from(providerService.getActualFees()).pipe(
      tap(data => {
        this.maxFeePerGas = data.maxFeePerGas;
        this.maxPriorityFeePerGas = data.maxPriorityFeePerGas;
        this.gasPrice = data.gasPrice;

        if (BigInt(this.maxPriorityFeePerGas ?? 0n) > BigInt(this.maxFeePerGas ?? 0n)) {
          this.maxPriorityFeePerGas = this.maxFeePerGas;
        }

        if (this.maxFeePerGas === 0n) {
          this.maxFeePerGas = undefined;
        }
        if (this.maxPriorityFeePerGas === 0n) {
          this.maxPriorityFeePerGas = undefined;
        }

        if (this.gasPrice === 0n || this.maxFeePerGas !== undefined) {
          this.gasPrice = undefined;
        }
      }),
      switchMap(() => of(gasEstimation)),
    );
  }
}
