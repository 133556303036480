export const FANTOM_AIRDROP_VALUES = [
  ['0x0159d7b9951115fb95c75d0d9e1078421f06753d', '1'],
  ['0x0194f929fd00bca74f4ed5396e6eb60e03ca6559', '1'],
  ['0x03151fa94aba95e993a2c37266c0b813a20fe370', '1'],
  ['0x0357543c6ffde1cc28e13dbe8b6d3e8a3b372cb1', '1'],
  ['0x04c31a1b3a0845c82c3aa457fb5d966e19a2ce05', '1'],
  ['0x07092930e1c78f4dbae4c459d76646193d97057e', '1'],
  ['0x07516f97d2c5e253f4ecbbf6e311fe118b2a8a0c', '1'],
  ['0x089920a4a315bb8ecb9e426d26341f6c5a63e3f4', '1'],
  ['0x08d963942668541bf4e40988fadf9c97f417b12d', '1'],
  ['0x09a5205cd38abad4414e7b95a002d6a846a84095', '1'],
  ['0x0b350cb9b0b8f040553c6383b282b9d9b235f40c', '1'],
  ['0x0b962643b68de0b514a568a164819c1122c73764', '1'],
  ['0x0c47b24b0c2da79f61e3b48faddfc57db1dbf2bf', '1'],
  ['0x0df91f7f44cf7adcca25e3b7c89798d60b3b98fc', '1'],
  ['0x120aefd160b43ad5ecc79a1413acd246568093fa', '1'],
  ['0x12af82fcf54fd126b6b370d2b91b9466b3bc80fa', '1'],
  ['0x16bbd6489121b2c8bedb91b41a36e81a7778f728', '1'],
  ['0x1cb7ed7f68995ad64c563c0df75f5696e855510c', '1'],
  ['0x1d89c54d41fd595bdaf716ca7c149b1bc376f3ac', '1'],
  ['0x1ed92ebe7a0cb883c37f5cea5e2efa34a6d314cd', '1'],
  ['0x1f67ea67d0af040706c306a97e9250cbafc7d2ae', '1'],
  ['0x219d36a1b617f6408bb351754243141534bed456', '1'],
  ['0x23d5078f41534a2c120918919dfbacd13c4b136b', '1'],
  ['0x24cbee5428abb723b5828498ccad7009ad2c9d35', '1'],
  ['0x25adcfb4eab40a935c23f4860d0dd6729207307c', '1'],
  ['0x25edb7dda2b8062c94d37acaf4803d44f1858711', '1'],
  ['0x25fdc18073b8e38f8a27be34e913d85a3e07b346', '1'],
  ['0x27463d66256fcb3d7f9efc49c00633794103f5f9', '1'],
  ['0x2b1f4c65e6f1c5786938a62422e9c1c75df1adf2', '1'],
  ['0x2d9ee7d43aae3c2d337c95419cb1fbc70ef83e85', '2'],
  ['0x2dd56df8c33a08cb9c4ec2a32636d44134d5f000', '1'],
  ['0x2f23a32cc31c3b5bba39c567c77681373c3847a9', '1'],
  ['0x302ddee239e3fa9628fed075e0654d612ec499a6', '1'],
  ['0x337f7f6c76f2a72b66ca0bea1e834afab9819f16', '1'],
  ['0x37412ad36110576f4d01922b0b843b44a4610941', '1'],
  ['0x39577d9c1b0a6fb227da0d0831e059d30087b88b', '1'],
  ['0x3a72f6a55dd2c9ec2d483da6e55e4b6824552f9d', '1'],
  ['0x3b56f1bdae788734ac0b97975e1272a93c47f97d', '1'],
  ['0x3c98b89c9d0d01fadbb7abec04fee0535779e582', '1'],
  ['0x3f0e911539490fab1a55434b8a89e9b061c1701b', '1'],
  ['0x410d4d159067b636614a74778c4b38709aa38c51', '1'],
  ['0x423612e333bc95b1cb022e3398a99d580c5846aa', '1'],
  ['0x43a8770ce2ae9b3bb6e209ef9bec9169cd269a30', '1'],
  ['0x45dedbe39813821687b18ca9153582565eb6edef', '1'],
  ['0x4aa4ec7f0e3d541288dfc3acde99a5958ec0c0db', '1'],
  ['0x4c1c30deae40443a71d3c880c9a43237df03cc87', '6'],
  ['0x4cd2dc36659ef1bb9e8862db75af3e7555ae5d83', '1'],
  ['0x4de8e7ebee41884ba8a59424818ecf4add6ab2b4', '1'],
  ['0x4e7f4b6cff1e515ea5c8cd461f82f21baa7478dd', '1'],
  ['0x515d509daf38172ae1465e0cefa372d2b658d8e2', '1'],
  ['0x5167c11e67c975eda56d95ea94662bbdb0d7002a', '1'],
  ['0x517267e094f55bae0ae0bdd69b516dd7feee68a6', '1'],
  ['0x52f3aca84aa2f8fc75c3a7e9473be401d8208d6e', '1'],
  ['0x53e8d71174a9041fa0fb718349b247d9e647637b', '2'],
  ['0x54779756e0046668c882bc10defab37c787145d0', '1'],
  ['0x54da5a669dc39adcbf753f3ba64b0cf6ce9f6838', '1'],
  ['0x554c284b04a423a19d3fe4c4b68cf5379c30deb4', '1'],
  ['0x5993c00fc2673d5939114a89c0463a3d095deb50', '1'],
  ['0x5aea07d2aa03a0ae7756104619e1bafc85980cad', '1'],
  ['0x5bb7c7d5f5616d90b43ccd449c834292828d0dd3', '1'],
  ['0x5f01d02c30cad65f39eebad7ab12d08185263f9a', '1'],
  ['0x6291209f4ae99edfaabd9e4e6a544e564a535659', '2'],
  ['0x656882073cc5b73dd1cd318cb8075260bd9538db', '1'],
  ['0x66af94834b250fe7788bd7bd0c2b0486a9022d39', '1'],
  ['0x66fbe3c7566cc101f2c346df7e9c187050fdb461', '1'],
  ['0x6881aa267964326043ae85363103a1076ee0620a', '1'],
  ['0x68967f0f78e00efec1d7f4f6d2eca5c6f6afb924', '1'],
  ['0x6c4d39f9e53e4edcc4593defdc811fdf8bc52c30', '1'],
  ['0x6d015f37e99cf948c6ce63e8279341212f2d00f6', '1'],
  ['0x6ea4d4f6fe9ae9dbafc71cba78ac70c7e3729209', '1'],
  ['0x7434661c023b34423fad0363a834f2d85442b36f', '1'],
  ['0x76c07087bb4eb86d2516081f79f400507ac2370f', '4'],
  ['0x7792cade26e4dd1700be02764e6e75e69c50ab49', '2'],
  ['0x7eef061df84437a5a50d7f9b79744a775f233965', '1'],
  ['0x7f847648bf0f3e005cd9c02abc07affa1176fe70', '1'],
  ['0x7fca21bd0166839a7130121c4462b48af8e83865', '1'],
  ['0x7fd94ae39f5f522ee8b482d87251f440e0e6b385', '1'],
  ['0x81c0707e72bba4151fc1c95c394ee9b5211aebeb', '1'],
  ['0x81ffdd63fe99262eb16f2258db935516cee887fc', '1'],
  ['0x8471a5c2663d54da16e7d903ea320b7d97c7719b', '1'],
  ['0x86a97ce0cf38b87e92d8c854fbefac8bb46a9faa', '4'],
  ['0x877eb30517c1238f89816e5526c4755100e7c2dd', '1'],
  ['0x87969ac983e5f26b7ed20521829ba6c803eb7a30', '1'],
  ['0x87cfa186ba24549d5ca4ae598936efd6d90cc40f', '1'],
  ['0x8936b1c560bbe960a54e83f44b34b1b1144d27db', '1'],
  ['0x89af68660fc49273e1596c1229eab1b7d208d557', '1'],
  ['0x8f19be18941a8f0a1c11a5de31071f41d38989d4', '1'],
  ['0x928e871efa74f6645330b32d80b738ef05e0138c', '1'],
  ['0x98d472ba8a842d8b3c29907b35498a390ce49728', '1'],
  ['0x9c62f03ecb92ba1fdef2660f91a70762af7e8e9e', '1'],
  ['0x9cc98be9fa8059d5cd262c62b475770682545ac0', '1'],
  ['0x9ecef491fe8b94852a18669f63c0e12b08cfcd62', '1'],
  ['0x9f39c61758c9880ce2c0cc22f58482a22d1f4421', '1'],
  ['0x9f545ecf4e994b6e21204de7e4c41fe611f1f98b', '1'],
  ['0xa14d7a37e0da95d6aafd37a2a6332d9fc98b3e5d', '1'],
  ['0xa4b7c016deba4f6500ade6ccc2c644b02cad54eb', '1'],
  ['0xa7b601396cfd7448bf850b9da34639c0957fe855', '1'],
  ['0xa8ba7d998e35a398b8a57de06ce65f27bff3a994', '1'],
  ['0xaa79498766a787bd1dea8ce53de7603f62dcd2f6', '1'],
  ['0xab0181c9d9bef10cb7642d511407756e820dbab9', '1'],
  ['0xac3b9362407fc0495655eff49f267d90c91002f9', '3'],
  ['0xace6eb0a2915deb514caa4c7e0af57704778b54a', '1'],
  ['0xb21b1ff926dd5503baf9a71140ffcde00739123c', '1'],
  ['0xb3ad5673ab63c2d24a73cfb199a6471475c39289', '1'],
  ['0xb42a558a7bc2e79dab74ddef5f6c0d262ebd4ac9', '1'],
  ['0xbfb157e091b7c874ffc70f05ace80062d512ff52', '1'],
  ['0xc17b375c3d25eb714b4bc45879857561d560420d', '1'],
  ['0xc39cef6472c2d702abb0f383ce78a79f122a6621', '2'],
  ['0xc44a3fff335379daf0999af79f0a64493b3b0833', '1'],
  ['0xc49f5143b27d594106e0265294ed6745124964d4', '1'],
  ['0xc5a542528985473a80c6c822638ff203eb1ef5a5', '1'],
  ['0xc72da7b99459b8763877d5510bd87310f1206497', '1'],
  ['0xc74e6a73deafd3587398455efb5978427cb83c20', '1'],
  ['0xc760a99b86679391097e522f382254f157697cf0', '1'],
  ['0xca43c0b1e1c073ec813851e6b6621cc9cfc51bbf', '1'],
  ['0xcac520f980a07357c040283545c18a0b01bb0b87', '1'],
  ['0xcb4cbd39cce49ffc44b385f0a59c5fd4d26c2617', '1'],
  ['0xcc6ad7807605ca7c11051d30bd912189c2bf8f01', '1'],
  ['0xcd9c8563403a6af6efa1f751377f95c8cf257ffa', '1'],
  ['0xce1a9c49df330a26d25785e570a3393586b2c946', '1'],
  ['0xce4cff19553c10a898671a1500a83ab176d2b8c3', '1'],
  ['0xd0801354020bea8038f6fd14e9d54b7fd7230ae7', '1'],
  ['0xd124d9baad44e4acbc361a2ad005a166ef58a246', '1'],
  ['0xd13d6a2d123a8e400d50472583eb5a6acecdceeb', '1'],
  ['0xd6962a755634be18c63da84dd9806d7766a87016', '1'],
  ['0xdd85d5d0f1b90574d2c22dae67c1c8cee31a5699', '1'],
  ['0xdfabdbee2989542b3f08374c8b655e1fdffd89dd', '1'],
  ['0xe092250ea4d1d22b952a8a1ad3a8394c52ceb6f4', '1'],
  ['0xe3ac24ff9f6a04e110fd1cd9fbc7281629729358', '1'],
  ['0xe4c5c72083f289e6aeea5a41f43130a6bda8ee27', '1'],
  ['0xe983bcf2ff259fc2273cc664869f6dd3fa18305c', '1'],
  ['0xeaffb09910508b753dec4b983ffcec49e88a2606', '1'],
  ['0xec60760e1f563d619239c2657f6f390d2a2b1575', '1'],
  ['0xf332332d64f8677ec7a79ed6d47404fe6471df97', '1'],
  ['0xf354b3146d627a5a06d17c41d10ed214827f1222', '1'],
  ['0xf6704008a7f181a04375bf91e269c05b964031bd', '1'],
  ['0xf8e6368f2f6480d64f10116012ab29c41b63ea55', '1'],
  ['0xfa5ca148ac0c2b3b3860bb1148e112d70ca3c060', '1'],
  ['0xfac4273dddac32c38e56ef136087e4694c020c3f', '1'],
  ['0xfb119266097a73d1a3f81f2927f6fa5019b91af2', '1'],
  ['0xfc371e9697d670e008d8c57e5d7e8ffce2a2e281', '1'],
  ['0xfddd44d09b9a23923da270c4fa0d20879aea5b27', '1'],
  ['0xfe67aaf1f7b1d9e4b92c7dbf652e7376f31eccc7', '1'],
  ['0x0101915fda8eef627bd70855b535052842741c68', '1'],
  ['0x0168e3295ae0e6407562002bce2f5fa06d8dd860', '2'],
  ['0x02c9a808e599aeba78c13ad94573a4470065f1ae', '1'],
  ['0x02fb8270e72b1a3fee4a60637fbb4afc73a784da', '1'],
  ['0x045efcfb8817ea213d55eb711a0cdc367c63db3e', '2'],
  ['0x048e08ba59a0aa654f77895a6d7a5f92134e18f8', '4'],
  ['0x05166c3943ca679e67e2883ec8d9ac8ccf30e1c9', '1'],
  ['0x05a9ce87796fd95bc82613e5e15f2d59b6bc008d', '1'],
  ['0x061eef2ba07d572fda3f8aafbc811f0bab5b0112', '1'],
  ['0x068805f58e4895ef9fae69f77fbfe72193cff757', '1'],
  ['0x07db6ec3f4c79f61dfb8c0e3fae99ada56622816', '1'],
  ['0x08c4ccbdd736b85a652e86878c492b3c4cf6ab4a', '1'],
  ['0x0937f0f4955d419c31abdf37b9165cec872c6d4d', '1'],
  ['0x09ab7a256e8cf30425ec6705b1f45684649d6e7a', '1'],
  ['0x0a42e223e44f19c2ed04f58f58f40a4f76f8bd7d', '1'],
  ['0x0a9ab1d09d3ceada9e2b7c8db70284ea8dd15282', '1'],
  ['0x0c11e6059c7766b8ba6f809518c730fac3b228e3', '1'],
  ['0x0c2e5916033e761379f87dfc7a950fe658e9dc53', '1'],
  ['0x0d8ef6c99417418313ebf1a3bd4780087d3a6d14', '1'],
  ['0x0e671bb07e1401ba91250d6ceaf6b4bc0352f9d1', '1'],
  ['0x11bf664212d0bc7142ffcc38eebc8cbb94ca0af1', '4'],
  ['0x125613eb965292e1f41eb61558b998e4a8bec5fd', '1'],
  ['0x13fd2050c80fed2ff80827820b0bb306ba40062e', '1'],
  ['0x1451f8b47cbc566248e2d97f43be204ce3eb2449', '1'],
  ['0x17a1f43b187a57757e04c82b687f69140cb0a303', '1'],
  ['0x1816ea248470b0e9eea373f0b4f473c91ecdabac', '1'],
  ['0x1838743cd82f940808ba7a179391bbca3a664909', '1'],
  ['0x193263154879f091efb6d5d36c1dc33bf065b7a1', '1'],
  ['0x19531769399fe5f3fe2cf58928634659f17ad58b', '1'],
  ['0x1a6f8eef0fbe79dae61adbf623fe7848d5f7cd55', '2'],
  ['0x1b40225c7aad2d0e692c0442403ccda11409fcf6', '1'],
  ['0x1c1a893dbbe0468c14690b012bd35ecd182a4248', '1'],
  ['0x1d7ad0a9c56f5aab682af5dad318a8f65914006e', '2'],
  ['0x1d9d3db45bc8a23f432115211633da4c8254539b', '1'],
  ['0x1e0f9ba2de09a4b600a64c788c917a52c4d97c46', '1'],
  ['0x1ed13f7333a671899f56f8249c0b2cf1feede9b3', '2'],
  ['0x1ff03aeededc6328ecff2037288041c90f4cb995', '1'],
  ['0x2158a9dc57cc5b15005be6df8a1e6ad0de0f779b', '1'],
  ['0x247356214febed1830ae35a057367307ababb340', '2'],
  ['0x247428f83af75b45ae1a25282dd86f7ab86233c4', '1'],
  ['0x259faefa6484d013ddce452b7d5cb2e98300be59', '1'],
  ['0x27b31a109946b72ca634f72aa608d101f55ef19a', '1'],
  ['0x27cae96fa4503991405aa75465f6f00b9a2f32ef', '1'],
  ['0x284cf79124930f1866e0bc20aa2a064448a2f6a8', '1'],
  ['0x28bbf947f59759e0f437ab49b03d7b0aa4882667', '1'],
  ['0x28c37726bc71f87c06552f3b2ade6713d6148208', '1'],
  ['0x29e8c86e289da91a47553724d8f3e309459da3ec', '1'],
  ['0x2aa45203d5fbab0e985146674215e6286dc0a317', '2'],
  ['0x2b14bf162d67c727bb64ab089e185ba939bc3102', '1'],
  ['0x2b4ea371720ada47786533be157650adac2da7de', '1'],
  ['0x2bb58656d6c34fcd563d42da4e45d6b4d1e5d143', '1'],
  ['0x2c5e82553e78e9328c6c780b1663ea15b8a94f6b', '1'],
  ['0x2d8857169dbd3368f1cccaf533104b1b7d80ea9f', '2'],
  ['0x2ecfa4c09d0501230006aa75657d12658c997f70', '1'],
  ['0x2fb5f1f0e4abfb964df8f88aa469b4f1d0abb4ee', '1'],
  ['0x31984b0509302c2832a5040c93208124b047ce32', '1'],
  ['0x33c0b9eda816ca1713ffd21b1f2dcdb37f726079', '1'],
  ['0x33e149dd113365473b40ff1ee4d7730c12c69c08', '1'],
  ['0x33e9ddab76c918b9b5e5dd9cf2289ddddd4ca5e6', '1'],
  ['0x340a11b441cf367a96dd0b15cf418a02e18d4d65', '1'],
  ['0x34c3b22885e04cd6689cff2dd7cb0de577b32048', '2'],
  ['0x34fc0d2a15ddc9bcc15b63690a99730852044313', '1'],
  ['0x352bf67d8d3256328d7e1745219cfe6a9bc0a11d', '1'],
  ['0x35671155d6590415e8d67145002fa1ab4921a5f8', '1'],
  ['0x35dee1d24acf24cc7b4067dbfc0e105dff16cfc5', '1'],
  ['0x36f55e112433b66c3017fecf2babb7eb6f38e02a', '1'],
  ['0x372cb3c5f0b756894912bec174a6a4f120d70f75', '1'],
  ['0x37cd25607d585ba471b3251665683c87e591821a', '1'],
  ['0x384587b37c3aeb5f2eb0fb096e7ce32ae6f39065', '1'],
  ['0x38e589b1becc251dcb4893613a89226f79ba6560', '1'],
  ['0x395f6bd9c08f85f03026541695539464a96458fa', '1'],
  ['0x3977f0624df75cdd264a62159109592df10c5c05', '1'],
  ['0x3999f3f4f4698935d69e3c8d55f963a65e70d9fd', '2'],
  ['0x399bf4109ac8617f3531a76c0e1cd6941abfc3d2', '2'],
  ['0x3a83fbeaa064348f7fd2928cf1489ace5de8fe3d', '1'],
  ['0x3b6a2f883ae7d4c077fd88e7c613f514ab5cc168', '1'],
  ['0x3b9aad9f2267b455f5977f10a40add9cec74abc1', '1'],
  ['0x3bb8bde9d1c11baa9601eb980c0944dd3e598500', '1'],
  ['0x3cde397c94a333196a42aa81045df4adb6a1f65f', '1'],
  ['0x3dac8af200f59b72b1ce277c7a012a2644500a9b', '1'],
  ['0x3e73ca67b9f8ed9aed8255c9ac0b11362a7ea1a4', '1'],
  ['0x3f6503e5df518d7a8245f2f7769b9bcd4a5d902e', '1'],
  ['0x3fdea0f1f48f60dd46e793cd3076b228dd765633', '1'],
  ['0x40779735a1b6d9ef26333445a9655961562d42e7', '1'],
  ['0x4083e57f539882257f007ae75f1121f35ec7b29e', '2'],
  ['0x408fbc0e62eb30344c8959b66833708dec0de516', '1'],
  ['0x40b07fd7f5ad27578a0cf110622e79bd7be6be89', '1'],
  ['0x413a198a6041bbc32a6d79b7c2d06db344f4fecd', '1'],
  ['0x4232c7136aa3cb4ec908a97e77c9d0a149d8436f', '2'],
  ['0x42482d896e94a320d2f2d55e9d82e7cc3bd13675', '1'],
  ['0x427a17468d018d2f01c5d6bb55ebd17228e4ce42', '1'],
  ['0x4613bba1e45aaecb8f1d64ca0e665c747b76a01e', '2'],
  ['0x4808523beb0203ae62db1bd483062d3c8f6e0445', '1'],
  ['0x4d512743df51f36b4b8dc5c7bf3ba818fedc389a', '1'],
  ['0x4eb3ea09cf266580ddbab62c148c820ba832f1f8', '1'],
  ['0x4f55ae2a5e3d2c56b65224507dcd49a740edc7b5', '1'],
  ['0x50ab7a16d209543e0667df33a261583a9725dd35', '1'],
  ['0x53b57dcb226e8a98e11235fa0734171403938007', '1'],
  ['0x5484538a6ab1c4dec7a7596e976474cdef6ad33a', '1'],
  ['0x54af59a5ccaeca26c6b82a6e06921e32b5f9ba56', '1'],
  ['0x5548e295d8dfc4d949c152284403551d83941772', '1'],
  ['0x563baad195a5b81e3fc6289c62088e9fe30af0a3', '1'],
  ['0x566f5034ab642729d96d9ae672922ba90720f14c', '1'],
  ['0x58a898f503c9c9239a04f904380202dafe428dbc', '1'],
  ['0x5a1bc6687f7f6df2cbff33b6624f407d00bab292', '1'],
  ['0x5a1f88899abeb569bc8898fa628a115a156fff99', '1'],
  ['0x5a819857dd40fc04d5bca0ddd266e80574ee6f1a', '2'],
  ['0x5ab3b5681ee9bca7415bf3f88847b181077b5a1c', '1'],
  ['0x5bf82915adb5562b4e5e068ff39876ed72a71ae7', '1'],
  ['0x5ef11e13a1f06077a00cf2c57fa36f0024af7370', '1'],
  ['0x64c5646c1e827780f5307714bbe19be4a6924d27', '1'],
  ['0x6532630fb55c5c2133949725b07a5719cf9548d5', '1'],
  ['0x65ade11776b286bf42a9afd406dd1612975be320', '1'],
  ['0x671dda3676957cb43295a8896368066d643340f6', '1'],
  ['0x67bf2ed452da68c3316548212296640205d44958', '1'],
  ['0x683889442c1bb6f72384b809e88ba781200cbe09', '1'],
  ['0x68d55e015c67fb89c27176f851019b2b88521186', '1'],
  ['0x6aa1aa1bf5a7087df543d3f639ced62e5644f624', '2'],
  ['0x6bea05b3e3e3024c6a48067664b5a5134387c891', '1'],
  ['0x6bf385bd193f4dda0ef254b6ebff05b0389b3ff8', '1'],
  ['0x6c11c4dbe80b823b884fda84c3acdc4e84d76b76', '2'],
  ['0x6c3ef80c601beb2eb45c2e7ed98d22db7db5e794', '1'],
  ['0x6c9ee942e87714926f3ff272be2b19d52c943b63', '1'],
  ['0x6d9e4c11b781f46fe2d5f81a1ee4eadf1d1e1341', '1'],
  ['0x6f58dbdaee53eac9e6bc05c2dd6531fb7cd379e2', '1'],
  ['0x6f674bc8ecab9c5f25716abdb722d2fee74cd0fb', '4'],
  ['0x712af2f7a6c3b21f66c0cf3a8678e7c4ee8c29cc', '1'],
  ['0x7220e39f40dd26733ee3f6b434eb742357bc4321', '1'],
  ['0x72c666e34d572e3d3687539f84b2bd1c0ebeeb00', '2'],
  ['0x72ebe731e7bb4728e5b8cb2d8f0683fc35360e82', '1'],
  ['0x73f20c5d009786bbf5ef3203c0ee6344d4dd5c42', '1'],
  ['0x741847bb525b62642c730aa4e1d535a1bc65de24', '1'],
  ['0x749168d550392eb8cf5f78e4b55716d5c461d480', '1'],
  ['0x74afbf92650124879dc03a28839fb2017852a3a9', '1'],
  ['0x74d4f3fa5d07cd7bb5d32652ec1a0b1550d92ed6', '1'],
  ['0x75adc534775faa37a963889f54881d4442021441', '1'],
  ['0x77bf1757f99d5979e7bf2c2b351157b94bec485d', '1'],
  ['0x7a6e4c7312890bce43548aae76f7385b1374e516', '1'],
  ['0x7a74591092ef0138433d813ec41ac25977bb41ce', '2'],
  ['0x7abcfaf287854768b184950f8aa9ec2492ed418c', '1'],
  ['0x7ae13c9eeb01696aa6b02a4daa875d3805397bcd', '1'],
  ['0x7b5681c40414488c1b7235c6ffd41a89ada35aaa', '2'],
  ['0x7c0252e992670272b1acdaf09e1008e05952f153', '1'],
  ['0x7c14a8f57146c8b02e14b3c2f884b49f0d31dd24', '1'],
  ['0x7c527091fe363591ca8def31e2aa045b5240fdc5', '1'],
  ['0x7da68eb64d66253fcfdeda5117cfaeacdc98d8fe', '1'],
  ['0x7dcd96e78af846352d96a74cff8883b33f7476ca', '1'],
  ['0x7dede548557facddf501c4ebfc7307f6d5e6c401', '1'],
  ['0x7edbb10353e721221eb6a2693bebd1b6c56fcb98', '1'],
  ['0x7f13a63e14d5d6749ecf73e3080e2e14c4c6523f', '1'],
  ['0x8072a809205093b52a478bca542e66ca4d9283ed', '1'],
  ['0x816c55b18bf38fb4d558969c8450427a6feafd97', '2'],
  ['0x819bdc3181174333b58dcd54dd6b1a9c1e81abc4', '1'],
  ['0x8234177e3db180f3b804818742ac06f0dc344260', '1'],
  ['0x83b1ff21c8efb32965a0c235163f7d12e821b655', '1'],
  ['0x8448d662d4c259ffbc1b37f9e3944fb8e1239fb5', '1'],
  ['0x85bc9e1e14d5827196a722e4a042e580cf6f569a', '1'],
  ['0x86087e03d823616841c9f531f9fa3bad1a2007a1', '1'],
  ['0x8616b2e5dee750489def2161f29dc199fc533b72', '1'],
  ['0x889417fa7f691dbf58c56b442cbdb8bfe00f1793', '1'],
  ['0x893ccd0967e31871d415f9ad73b98fbe08c3e12e', '1'],
  ['0x8a245a0f01b6101059d60eebbe903c1358a43583', '1'],
  ['0x8a726f45af4eb3a3c7c2cbf05480fbf4704d7651', '1'],
  ['0x8b0d3c5e41903c974480281f179cd13e5ede67d7', '1'],
  ['0x8c5573dd394dba2f12fabdd8e035f834996295c0', '1'],
  ['0x8c7fa05f870ed8a8adb6639c3a49953a8712cf68', '1'],
  ['0x8d1767f4314953079021b5146fa2b8903e11cf02', '1'],
  ['0x8e0bb1c9e0f85fe201e3d6c8c1b15cad5ed58f7c', '2'],
  ['0x9004689a6fc1001dd8b15f278fbcad4fa5576de3', '1'],
  ['0x90a7823e9ff803581d93c802257a452214b0e87e', '1'],
  ['0x9120b5ba9cddf9b6dbbbe05ca44c586fe1ac8de5', '1'],
  ['0x9166982b3df342fd92bcca2ef8aa3f9e68aead7b', '1'],
  ['0x916856fc205bda56ce8a68b8b2c9ff73b638b0dc', '1'],
  ['0x9211a24352e3dd1b9a39430cf8bb036196f651e7', '1'],
  ['0x93d4abf00cf819a60ae33d619d05158a3d2222ca', '1'],
  ['0x947814ff8dccc84bbc5bff64457c657b2392168b', '1'],
  ['0x950ee4107aea0ca9f0f15831eed1fcbecfe273a3', '1'],
  ['0x956e3f10daa4205c1acb53359d0e1437ceb12641', '1'],
  ['0x956fa2ac1f782945b025310191a56a96478e5eff', '1'],
  ['0x9644ce2ba31d984d3cc38318e7fbe7eb0b8499f5', '1'],
  ['0x974284bd5a4b3571a1c8871181da07930c410780', '1'],
  ['0x975cb2231df1b6fc9feb45b5aaed0a215110c9f6', '1'],
  ['0x976e319178eb987f9ba280b72729933d7492ec9c', '1'],
  ['0x986f8ccf718327bee6ca7702a6a5509a979de3e9', '1'],
  ['0x9a4f1e4fb899b2097fa231449d7aba838fa8c8b9', '1'],
  ['0x9b40fcfea221706f1bb13b677f53251e6ba1541d', '1'],
  ['0x9b4db1a39d96c5db3313fc7c1447745cdf8f84ea', '1'],
  ['0x9bf8e60cf955e032faeb4f7adcd3b0cf58f7ccfc', '1'],
  ['0x9cd4e6885866997d84495da7c3bb255223b48e74', '1'],
  ['0x9d40cd7d95a00cfae3bb81e477a394f3a070a136', '1'],
  ['0x9d4c02c5dfb581c01acd80b2bc83b47173c4aeea', '1'],
  ['0x9e677a557f22d4aee5b9a4b9876579b0690189cf', '2'],
  ['0x9f4727b0457c191d23eb8b6ac7d317da99be7056', '1'],
  ['0x9f6c172bfb3692ce1658d6d00cce33969ad3da7d', '2'],
  ['0xa062385bc7c6ec3b4584ccb212357498f867ef34', '1'],
  ['0xa0b9fbf837c9550eb31bf7295241f99f1ff303b3', '1'],
  ['0xa1916c6ccd4f680e7ab2144ea9870492e316afe0', '1'],
  ['0xa33f9d0aef868aaed6f191b72b46c11262b1c24a', '1'],
  ['0xa3bd0d49a9cffdd251c1f1aea619422d1519cdf7', '4'],
  ['0xa54a56fea8c904de8337cf58a911edacfb5e502f', '1'],
  ['0xa55a4ee9a1772b0eb3ccb7260a0df3b6a68ed8a7', '1'],
  ['0xa561790e27b38d06dec392744eb25d6f16e7cc17', '1'],
  ['0xa5f29830b5b682852a02bff3e3cc57b0fa4eafdc', '1'],
  ['0xa672fdab7b30ef58cb79490cf664052be94274fe', '1'],
  ['0xa73e90fc92b962143f3c679c17f64cc1715f3e53', '1'],
  ['0xa84436fe41b7ddd8fa69a1e7538534f1ed654f60', '1'],
  ['0xa8d11e2b6d2d02e3321bbb40b6cb3984e2e8acf0', '1'],
  ['0xa99208151d5c1f794e4b31eb83e2487ae6b34c3f', '1'],
  ['0xa9a31985c0db7f4dca58414978cdf7c50d2cf6f7', '1'],
  ['0xa9fe0f5928f7e22c789b293700da3180f6b7d74d', '1'],
  ['0xab220df787a3f9190dabb39893a33f439fe937aa', '1'],
  ['0xac28a58177bd025a8b5c831472badcab0504f5b7', '1'],
  ['0xafc387d8da567e73b4f2db35674326da3616f2e1', '1'],
  ['0xb15e8af600523a658728d67a7549bd614940bf11', '1'],
  ['0xb317bb6529b05ade4fdff40002f2f014092859f9', '1'],
  ['0xb35ca1712467c0d0a2e22916ba288bb61b9e1587', '1'],
  ['0xb4e5e8954bf409358b5bba6928d38fc80adab2a6', '1'],
  ['0xb51b45ec914ba42a0954083bde61c1117c7581c0', '1'],
  ['0xb592c331e919f8a6cf53dd9c9a43f5921e28871a', '1'],
  ['0xb5a321caa3556ca91fb9db048ad38f613cd9e8d6', '1'],
  ['0xb5bdce686b54f07696b1477c842f29bf6f693dd8', '2'],
  ['0xb5bf4a4ae1eba74061a2d1b77b69f6ba2977131c', '1'],
  ['0xb75b439de4545587c09535e3227e5263a3cb1483', '1'],
  ['0xb7a750926b4be8d571c4d282141efe620c019aaf', '1'],
  ['0xb7fd054eeb49166565b27f4005312af49659a527', '1'],
  ['0xb89ed450499cd227503b21e48501715b4c11b4f7', '4'],
  ['0xb91bb75bfe2e5efd202ccc61a777834bcae1fb56', '1'],
  ['0xb9d01c2729a746898eb2cd1049880488a90ac0fc', '1'],
  ['0xbbed4322ff16453101c30a3fb8a2807058f9bd64', '1'],
  ['0xbbf2c9ff47bc66a3d7108022685e4de293020289', '1'],
  ['0xbcc1ced868fba80c25dee338ee828ab0d5d66990', '1'],
  ['0xbe2efcefc9af9fa3f627a4213a2e69dc45d4ee5a', '1'],
  ['0xbeae6d2e5894199e6deb2fbce0baee3a4cf67797', '1'],
  ['0xbedddaf8477da1763b98cf64508344b7abdca39a', '4'],
  ['0xbf4cfca1926e60c4a29066fe9d65d36ab8373753', '1'],
  ['0xbf73be1e3c6b4c3ade5e3e52c8db7b06cd9e0f9d', '1'],
  ['0xbff20bd346404f599f0f8d14c03c96b67c8b67e1', '1'],
  ['0xc064fed646dffa5e215f48ead89840fb692c8f59', '1'],
  ['0xc14dc3e7a63fc32a7136e9ace95b4fb044b83c8b', '1'],
  ['0xc26c80ef9f9a9c3f06a38aa82fd72200c04fdaf3', '1'],
  ['0xc2d708ec3ef92c912444087d4a337f6a2ca05466', '1'],
  ['0xc2d71b3fdd59ab45bdf8b44d8d37fa3363ae6c76', '1'],
  ['0xc2daed2d07d8003c6485751727b2e9d73d51af04', '1'],
  ['0xc31d58630df07c2a6c8dcde79437a4a6a9bada01', '1'],
  ['0xc37b169e712d7b0962753e96ad93e9ff9490cef6', '1'],
  ['0xc3f6ac34ffbbfdb33ed4bc35561b5b4b0584957a', '2'],
  ['0xc4098f07f04f87ded8f245be8fcbd8b6602504c0', '1'],
  ['0xc5177be1387e2135b7123925dde6152832dbe1b0', '1'],
  ['0xc52cc817b8067a978e427c417064a0f646ab8e9c', '1'],
  ['0xc59ced40d2dce13447481a99a87253fa6d3b2780', '1'],
  ['0xc69bfb8d095f784eae2f336883e93d5e0d887342', '1'],
  ['0xc7f4ab5fcb0eeb5064da0352a8f41a7d2d831830', '1'],
  ['0xc8bcb1ac437ed2ca0b4d9d8451f7882c90451872', '1'],
  ['0xc90b9c980ecaaee5f60d1ccb0743381ab1d0c7c2', '1'],
  ['0xc9ba2d62e9a23556322ef0c79cb747c421a042d0', '1'],
  ['0xcbd97e7e13cba8b503d30ab37c8c640698bc732c', '4'],
  ['0xcca4aa58536477d5d58489f83f45ec6a57ee1146', '1'],
  ['0xccc44233b9877a54e52ff44478c62ae47ef1c452', '4'],
  ['0xcd8f0fcbdc519f296c8d93813eddf6dbfe61cd94', '2'],
  ['0xce2b776c28794dd4a4b10ef34cc985276d86834f', '1'],
  ['0xce5beeb3eaaaa21f8b5c202b3bb45c0526239549', '1'],
  ['0xcf92ac1bf9da538ee418c576c4aca5ea3cd720ea', '1'],
  ['0xd151360ad518ff1d2dd521664e87b89b718e8ac1', '1'],
  ['0xd1aaf19501d49db8f7c0c45fa73872a1de61db1c', '2'],
  ['0xd26c856a98a4858d84b09913414fad271dcc4403', '1'],
  ['0xd29152746f59e4d0789dd3cee0ef64de97065f46', '1'],
  ['0xd386be478bb6a978acc1b8d8ddf1abde11ec171d', '1'],
  ['0xd3a978078c906ce45993747860e7d05761305352', '2'],
  ['0xd3f0b45262705fb453506f82186a7c2ba8442042', '1'],
  ['0xd5d7d36157b1c3e3f7bc7139ede87424f83626d8', '1'],
  ['0xd66e977fd73ffd6b1f4874d6ad48a2c5f530ed5f', '1'],
  ['0xd70b5b1cd8e57804ac4c344e7f6e9d04717e462b', '1'],
  ['0xd78c11436404df26d82532a1d4d950a731afb10c', '1'],
  ['0xd79b7a7f247260926185c86c28fb92124185dbce', '2'],
  ['0xd7ca61689ffea578b9a5197994008b3915a5ae6d', '1'],
  ['0xd844f7d33c9f29fe1b607c64201dc70172ec1e87', '1'],
  ['0xd8beb35f86d6beb07c6dc3e9738dc690e2c8748e', '1'],
  ['0xd9d78ea4d178c0dc6647966547cd97102a0c16fb', '3'],
  ['0xda50d756a44dca7f7ca5c8eba8b27eb05e659f7f', '1'],
  ['0xda9503e11be27e0b1a33b1d983ab7aaf0c5e4dd8', '2'],
  ['0xdad786239a323058541ee5576133265d1e7742cf', '2'],
  ['0xdb09b8571ba8a67bd84096a158a5d138125393d0', '1'],
  ['0xdb0ad71208d98d7f650f61929e76ddf527aad068', '1'],
  ['0xdb4b26cebac4ececbfd68fb8974a229dfc38739b', '1'],
  ['0xdb4df896f14a1676a72174ff24e636394c15cf13', '2'],
  ['0xdb94ddb73637f567bcbd67d4a606c9aa54fcfd72', '1'],
  ['0xdbea76fd8e8a8020f3139817ab3a0c47483a4edf', '2'],
  ['0xddf6402c34654706b4f7be90f2194d90f4bb670b', '1'],
  ['0xdee7c2dae137e4b8f7ad8487691ee9c27435436f', '1'],
  ['0xe09ee767a3cfa4db41726a7761e50f0e6fb7ed97', '2'],
  ['0xe0b5b3f7190b76ada0ebbdf3fd52fb0989bc053e', '1'],
  ['0xe105db937cfe54312c79cebe8891d853ac3c370e', '2'],
  ['0xe221498880193a338d1fad7af3a6eca122f9add9', '1'],
  ['0xe2c60d835022c38cb9147ff88876e5f3a025f81a', '1'],
  ['0xe4b7df54af06f7502c23ff4e2c93f21d7f77b98b', '2'],
  ['0xe60741fc6fc0c24f18fb982cddd9840cb25d7962', '2'],
  ['0xe65bc348d5a69a6335a1422f70175f27be821117', '2'],
  ['0xe6f618d367139438958fe50a5809f051f97a54e3', '1'],
  ['0xe85caa2a1f53baaaaff41885e4538682b8bb2de6', '1'],
  ['0xe9be6ee3964dd9f91a6483b1abc9032ee32544e9', '1'],
  ['0xeb0a1619b90d6525c2de216b501b7d2833bac697', '1'],
  ['0xec3bc069bfae11da7b2936cad106717ab9c90ab6', '1'],
  ['0xec75fc3e942738ce45b18e73e23603be878fafbd', '2'],
  ['0xecd5e0fb686597146c56ca95873a011b4caffb06', '4'],
  ['0xee38f7af006022f2ea49f541264ec0b2604bd046', '1'],
  ['0xef5d29b93d5702a684f08a0021571c6f1b59402a', '1'],
  ['0xf0e8dd484e4dd1f00d340092d9748938275be9d9', '1'],
  ['0xf24113b3b87ac977cabf3eb3df36f75328e789a9', '2'],
  ['0xf27d336320faed46d5c4988209d3c00b6f87f8ea', '1'],
  ['0xf34b73f56a7f8ef5d3eadc6ebf75f80fd2926d92', '1'],
  ['0xf43c605d19ba51c8e9b289f86a3168afe117a38d', '1'],
  ['0xf610ea6aa8ee98a48698324fc49ebb7cd0a6517c', '1'],
  ['0xf63ce05b637469fb9e4cb30b1ef4b713d8af8662', '1'],
  ['0xf7111f1669f31f23b5a7c5a427d1ae6bf2f73828', '1'],
  ['0xf732a20e27358a04f9b28702141ddfa7e63d211d', '1'],
  ['0xf741ab0d9bd89a228dd9ef20c7b57f4327c08c3a', '1'],
  ['0xf7eef1bcec12fcd340c9b81ee5482a661120ba12', '1'],
  ['0xf988867648d845ff23bd4589cabaf5e101d9ac3e', '1'],
  ['0xf9da918423820403401632741ac3dacc4c9f5a6e', '1'],
  ['0xfab90d547e7bbdf375c505aa175b756fa29073a3', '1'],
  ['0xfb7c6c4d1c42d877541ce9ebf38f79e616963568', '1'],
  ['0xfc699ce98b241dfef30ec7b35c810e78826e0722', '1'],
  ['0xfc90e2e19e200de73b9914abadacdd994e801c02', '2'],
  ['0xfe911f1243ce3e4a4959666b8e0e9ccfbddc0320', '2'],
  ['0xfe958659820b6e9836c3815ea054e4ca72fd19f8', '1'],
  ['0xffdce90f4c8fc9bd872da5a4379e60b9c4361a4a', '1'],
  ['0x6f674bc8ECAB9c5F25716abdb722D2fEE74CD0fb', '2'],
  ['0x11bF664212d0bc7142FfcC38eEbc8cBB94Ca0Af1', '6'],
  ['0xB89ed450499CD227503B21e48501715B4C11B4f7', '3'],
  ['0xCBd97E7e13CBA8B503d30AB37C8C640698bc732c', '2'],
  ['0xD9D78ea4D178C0dC6647966547cd97102A0C16fb', '2'],
];
