import { PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Optional, Inject, Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';

const PARAMS_TO_SAFE = ['utm', 'refCode'];

@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
  ) {
    super(platformLocation, _baseHref);
  }

  override prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.search);
    const existingQueryParams = Object.fromEntries(existingURLSearchParams.entries());
    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;

    const hasKeys = Object.keys(nextQueryParams).some(key =>
      PARAMS_TO_SAFE.some(it => it.toLowerCase().indexOf(key.toLowerCase()) >= 0),
    );

    if (hasKeys) {
      const filteredExistingQueryParams = Object.fromEntries(
        Object.entries(existingQueryParams).filter(([key]) =>
          PARAMS_TO_SAFE.some(it => it.toLowerCase().indexOf(key.toLowerCase()) >= 0),
        ),
      );

      urlTree.queryParams = { ...filteredExistingQueryParams, ...nextQueryParams };
    } else {
      urlTree.queryParams = { ...nextQueryParams };
    }

    return urlTree.toString();
  }
}
