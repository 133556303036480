import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-bar',
  standalone: true,
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--justify-center',
  },
})
export class BarComponent {
  @Input() barType: 'durability' | 'achievements' | 'fragility' | 'card-durability' = 'durability';
  @Input() percentageValue = 100;
  @Input() label: string = '';

  @HostBinding('class')
  get hostTypeClass() {
    return this.barType;
  }
}
