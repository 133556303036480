import { Clipboard } from '@angular/cdk/clipboard';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { Mediator } from '@services/mediator.service';
import { UserControllerService } from '@services/onchain/user-controller.service';
import { ProviderService } from '@services/provider.service';
import { Web3ModalService } from '@services/web3-modal.service';
import { Web3authService } from '@services/web3auth.service';
import { HeroActions } from '@shared/actions/hero.actions';
import { LoadingActions } from '@shared/actions/loading.actions';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { DROPDOWN_SIZE } from '@shared/components/dropdown/constants/dropdown-sizes.constant';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { DropdownItemModel } from '@shared/components/dropdown/model/dropdown-item.model';
import { EditInputDialogComponent } from '@shared/components/edit-input-dialog/edit-input-dialog.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { CHAIN_FIELDS, getChainByChainId, getChainOptions } from '@shared/constants/chain-ids.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { onlyLatinLettersDigitsSpec } from '@shared/utils';
import { UserInfo } from '@web3auth/mpc-core-kit';
import { debounceTime, finalize, from, takeUntil } from 'rxjs';

import { MAX_HERO_NAME_LENGTH } from '../../../pages/hero-create/components/hero-name-dialog/hero-name-dialog.constants';

import { HERO_NAME_INVALID_ERROR_NAME } from './services/account-name-validator.constants';
import { AccountNameValidatorService } from './services/account-name-validator.service';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AccountNameValidatorService, DestroyService],
  standalone: true,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [
    ReactiveFormsModule,
    DialogTitleComponent,
    ScratchComponent,
    LoadingSmallComponent,
    AvatarComponent,
    DropdownComponent,
    TranslateModule,
    ButtonClickDirective,
  ],
})
export class AccountDialogComponent implements OnInit {
  networksOptions: DropdownItemModel[] = getChainOptions();
  networkSelected = this.networksOptions[0];
  DROPDOWN_SIZE = DROPDOWN_SIZE;
  userInfo?: UserInfo;
  name?: string;
  nameValueControl = new FormControl('');
  isNameEmpty = true;
  isNameNotUnique = false;
  isNameUnique = false;
  isNameTooLong = false;
  isNameShorEnough = false;
  isNotLatinLetters = false;
  isNameLoading = true;
  avatarImg?: string;

  private privateKey?: () => string;

  constructor(
    @Inject(DIALOG_DATA) public data: { account: string; chainId: number; userName: string; userAvatar: string },
    private dialogRef: DialogRef<null, AccountDialogComponent>,
    private destroy$: DestroyService,
    private web3ModalService: Web3ModalService,
    private clipboard: Clipboard,
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private web3authService: Web3authService,
    private router: Router,
    private userControllerService: UserControllerService,
    private accountNameValidatorService: AccountNameValidatorService,
    private mediator: Mediator,
    private dialog: Dialog,
  ) {}

  ngOnInit() {
    this.accountNameValidatorService.chainId = this.data.chainId;
    this.nameValueControl = new FormControl<string>(
      {
        value: '',
        disabled: false,
      },
      {
        updateOn: 'change',
        validators: [Validators.required, Validators.maxLength(MAX_HERO_NAME_LENGTH)],
        asyncValidators: [this.accountNameValidatorService.validate.bind(this.accountNameValidatorService)],
      },
    );

    this.nameValueControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      const { errors } = this.nameValueControl;
      const isNameRequiredError = !!errors?.required;
      const isNameLengthError = !!errors?.maxlength;
      const isNameInvalidError = !!errors?.[HERO_NAME_INVALID_ERROR_NAME];

      this.isNameEmpty = isNameRequiredError;

      this.isNameTooLong = !isNameRequiredError && isNameLengthError;
      this.isNameShorEnough = !isNameRequiredError && !isNameLengthError;

      this.isNotLatinLetters =
        !!this.nameValueControl.value && !this.nameValueControl?.value?.match(onlyLatinLettersDigitsSpec);

      const isShowNameValidation = !isNameRequiredError && !isNameLengthError;
      this.isNameNotUnique = isShowNameValidation && isNameInvalidError;
      this.isNameUnique = isShowNameValidation && !isNameInvalidError;

      this.changeDetectorRef.detectChanges();
    });

    this.networkSelected = this.networksOptions.filter(it => it.id === this.data.chainId.toString())[0];

    // TODO add approve and change validation
    this.userInfo = this.web3authService.getUserInfo();
    this.name = this.data.userName;
    this.avatarImg = this.data.userAvatar;
    this.nameValueControl.setValue(this.data.userName);
    this.isNameLoading = false;
  }

  isPrivateKeyExtracted() {
    return !!this.privateKey;
  }

  isShowDelegate() {
    return !this.userInfo;
  }

  close(): void {
    this.dialogRef.close(null);
  }

  updateSelected(networkSelected) {
    this.networkSelected = networkSelected;

    this.switchNetwork();
  }

  onCopy() {
    this.clipboard.copy(this.data.account || '');
  }

  openNetworkScan() {
    window.open(
      `${getChainByChainId(Number(environment['CHAIN_ID']))[CHAIN_FIELDS.BLOCK_EXPLORER]}/address/${this.data.account}`,
      '_blank',
    );
  }

  onDisconnect() {
    from(this.web3authService.logout()).subscribe(() => {
      this.web3ModalService.disconnect();
      this.close();
    });
  }

  switchNetwork() {
    this.providerService.changeNetworkAndRedirect(Number(this.networkSelected.id), this.destroy$, () => {
      this.changeDetectorRef.detectChanges();
    });
  }

  openDelegate() {
    this.router.navigate([MAIN_ROUTES.DELEGATE]);
    this.close();
  }

  openWeb3AuthSettings() {
    this.router.navigate([MAIN_ROUTES.WEB3AUTH_SETTINGS]);
    this.close();
  }

  saveName() {
    if (this.nameValueControl.value === this.name) {
      return;
    }

    if (this.nameValueControl.value) {
      this.userControllerService
        .setUserName$(this.data.chainId, this.data.account, this.nameValueControl.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.name = this.nameValueControl.value || '';

          this.mediator.dispatch(new HeroActions.UpdateName());

          this.changeDetectorRef.detectChanges();
        });
    }
  }

  onChangeAvatar() {
    const dialog: DialogRef<string, EditInputDialogComponent> = this.dialog.open(EditInputDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        value: this.avatarImg,
        title: 'Change account avatar',
        account: this.data.account,
        chainId: this.data.chainId,
        mediatorAction: HeroActions.UpdateAvatar,
      },
    });

    this.mediator.ofAction(HeroActions.UpdateAvatar, this.destroy$).subscribe(({ avatarUrl }) => {
      this.userControllerService
        .setUserAvatar$(this.data.chainId, this.data.account, avatarUrl)
        .pipe(
          finalize(() => {
            this.mediator.dispatch(new LoadingActions.ChainTransaction(false));
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          dialog.close();
          this.avatarImg = avatarUrl;
          this.changeDetectorRef.detectChanges();
        });
    });
  }
}
