@if (slotItem && slotItem.itemId !== 0) {
  <div
    class="item-slot g-flex"
    [class.item-slot--active]="false"
    [class.item-slot--unknown]="false">
    @if (slotItem.count && slotItem.count > 1) {
      <div class="count app-btn-tiny_font-size text-shadow g-flex g-flex--align-center g-flex--justify-center">
        {{ slotItem.countFormatted }}
      </div>
    }

    @if (
      isShowBrokenFrame ||
      (isShowDurability && !noColor && itemDurability && durabilityPercentage <= 20 && slotItem.meta.durability !== 0)
    ) {
      <img
        class="item-slot__broken"
        [src]="'/assets/images/ui/frames/item-slots/' + slotSize + '/broken.avif'"
        alt="item image" />
    }

    @if (getActualUri(slotItem)) {
      <img
        class="item-slot__image"
        [ngClass]="'item-slot__image--' + slotSize"
        [class.disabled]="isDisabled"
        [src]="imageUrl"
        alt="item image" />
    }

    @if (
      isShowDurability &&
      slotItem &&
      itemDurability &&
      durabilityPercentage <= 20 &&
      slotItem.meta.durability !== 0 &&
      slotSize !== 'small'
    ) {
      <div class="item-slot__durability app-btn-small_font-size">
        {{ itemDurability }}
      </div>
    }

    @if (slotItem.magicAttackInfo && slotSize !== 'small') {
      <img
        class="item-slot__attack-icon"
        [src]="
          '/assets/images/ui/icons/item-props/attack' +
          (slotSize === 'middle' || slotSize === 'middle-small' ? '-medium' : '') +
          '.png'
        "
        alt="item image" />
    }

    @if (isShowId && slotItem && !slotItem.meta.isConsumableItem && slotSize !== 'small') {
      <app-item-id
        class="item-slot__id app-btn-small_font-size"
        [itemId]="slotItem.itemId"
        [bgSize]="slotSize"
        size="small">
      </app-item-id>
    }

    @if (isShowLevel && !slotItem.meta.isConsumableItem && slotSize !== 'small' && slotItem.augmentationLevel > 0) {
      <app-augmentation-level
        class="item-slot__augmentation"
        size="small"
        [level]="slotItem.augmentationLevel">
      </app-augmentation-level>
    }
  </div>
} @else {
  @if (slotItemMeta) {
    <div
      class="item-slot g-flex"
      [class.item-slot--active]="false"
      [class.item-slot--unknown]="false">
      @if (getActualMetaUri(slotItemMeta)) {
        <img
          class="item-slot__image"
          [ngClass]="'item-slot__image--' + slotSize"
          [class.disabled]="isDisabled"
          [src]="imageUrl"
          alt="item image" />
      }
    </div>
  } @else {
    @if (slotIcon) {
      <div
        class="icon"
        [style.background-image]="slotIcon"></div>
    }
  }
}
