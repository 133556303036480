<app-dialog-title
  [title]="'Delegate transactions'"
  [canClose]="true"
  [isShowLoader]="processing"
  (closeDialog)="close()">
</app-dialog-title>

@if (loading) {
  <app-loading-small></app-loading-small>
} @else {
  <div class="content g-flex-column g-flex--align-center g-flex--justify-center">
    <div class="content-text app-btn_font-size">
      Your Delegator has balance {{ delegatorBalance.toFixed(6) }} {{ symbolNetworkCoin() }}. <br />
    </div>

    <div class="controls g-flex-column g-flex--align-center">
      <!--      <input-->
      <!--        type="password"-->
      <!--        placeholder="Password"-->
      <!--        class="app-input app-input&#45;&#45;huge app-btn_font-size"-->
      <!--        (keyup.enter)="useDelegation()"-->
      <!--        [formControl]="passwordControl" />-->

      <!--      <button-->
      <!--        *ngIf="!isDelegatorOutOfDate()"-->
      <!--        class="app-btn-red-3 g-flex g-flex&#45;&#45;align-center g-flex&#45;&#45;justify-center"-->
      <!--        [disabled]="processing || !isPKExist()"-->
      <!--        (click)="useDelegation()">-->
      <!--        Use delegation-->
      <!--      </button>-->

      <button
        class="app-btn-blue-3 g-flex g-flex--align-center g-flex--justify-center"
        (click)="goToSettings()">
        Go to session settings
      </button>

      @if (errorMsg !== '') {
        <div class="app-btn_font-size color-red-fire">
          {{ errorMsg }}
        </div>
      }
    </div>

    <app-scratch
      class="scratch"
      backgroundType="middle">
    </app-scratch>

    @if (!isEnoughBalanceOnDelegator()) {
      <div class="content-text app-btn_font-size color-red-fire">Delegator balance is low!</div>
    }

    @if (isDelegatorOutOfDate()) {
      <div class="content-text app-btn_font-size color-red-fire">Delegation is out of date!</div>
    }
  </div>
}
