<app-dialog-title
  class="g-flex-column__item-fixed"
  [title]="data.name | translate"
  [icon]="data.icon"
  [iconWidth]="64"
  (closeDialog)="close()">
</app-dialog-title>

<div class="desc app-btn_font-size g-flex g-flex--align-center g-flex--justify-center g-flex-column__item">
  {{ data.description || '' | translate }}
</div>
