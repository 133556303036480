import { MONSTER_SYMBOLS } from '@shared/constants/game.constant';
import { SOUNDS_COMMON } from '@shared/constants/sounds/sounds-common';
import { SOUNDS_MONSTER } from '@shared/constants/sounds/sounds-monster';
import { ISound } from '@shared/types/sound-types';

export const SOUNDS_CONFIG = {
  PATH: 'assets/sounds/',
  DEFAULT_VOLUME: 100,
};

export const SOUNDS: { [key: string]: ISound[] } = {
  ...SOUNDS_COMMON,
  // ...SOUNDS_MONSTER,
};

export function checkMonsterSounds() {
  for (const id of Array.from(MONSTER_SYMBOLS.keys()).sort()) {
    const name = MONSTER_SYMBOLS.get(id);
    const node = SOUNDS_MONSTER.get(id);
    if (!node) {
      console.error(`Monster ${id} ${name} has no sound at all`);
    }
    if (!node?.attack || node?.attack.length === 0) {
      console.error(`Monster ${id} ${name} has no attack sound`);
    }
    if (!node?.damage || node?.damage.length === 0) {
      console.error(`Monster ${id} ${name} has no damage sound`);
    }
    if (!node?.miss || node?.miss.length === 0) {
      console.error(`Monster ${id} ${name} has no miss sound`);
    }
  }
}
