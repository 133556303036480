export class TokenMetadataModel {
  readonly tokenAdr: string;
  readonly name: string;
  readonly decimals: number;

  constructor(tokenAdr: string, name: string, decimals: number) {
    this.tokenAdr = tokenAdr;
    this.name = name;
    this.decimals = decimals;
  }
}
