@if (loading) {
  <app-loading-small class="page-loader"></app-loading-small>
} @else {
  <app-dialog-title
    [title]="'inventory.title' | translate"
    [hasTitleScratch]="true"
    (closeDialog)="close()">
  </app-dialog-title>

  <div class="inventory__header g-flex g-flex--align-center g-flex--space-between">
    <app-radio-group
      [items]="inventoryTypes"
      [isShowDivider]="true"
      [selectedItemId]="currentInventoryView"
      size="big"
      dividerType="big"
      (choose)="onSelectInventory($event)">
    </app-radio-group>

    @if (currentInventoryView === INVENTORY_VIEW.ALL) {
      <div class="g-flex g-flex--justify-end g-width--100-percent">
        <app-dropdown
          class="inventory__filter"
          [size]="DROPDOWN_SIZE.SMALL"
          [items]="itemsTypeNamesOptions"
          [bgCustomLength]="8"
          [selectedItem]="dropdownTypeNamesSelected"
          (selectItem)="setInventoryItemTypeFilter($event)">
        </app-dropdown>
      </div>
    }
  </div>

  @if (currentInventoryView === INVENTORY_VIEW.ALL) {
    <div class="inventory__controls g-flex g-flex--align-center g-flex--justify-end">
      <app-search
        class="btn-search"
        [control]="searchControl">
      </app-search>

      <app-dropdown
        [size]="DROPDOWN_SIZE.SMALL"
        [items]="itemsSortOptions"
        iconOnly="app-btn-square-list app-btn-square-list--small"
        [selectedItem]="dropdownSortSelected"
        (selectItem)="setItemSorting($event.id)">
      </app-dropdown>

      <button
        [class.app-btn-view-mode-icon]="viewMode.query === VIEW_MODE.LIST"
        [class.app-btn-view-mode-list]="viewMode.query === VIEW_MODE.ICON"
        (click)="onChangeViewMode()"></button>

      <button
        class="app-btn-filter"
        (click)="openFilter()"></button>
    </div>
  }

  <div class="inventory__body g-flex g-flex--no-wrap g-scroll-no">
    @switch (currentInventoryView) {
      @default {
        <app-inventory-equipped
          [hero]="hero"
          [isStaked]="isStaked"
          [equippedItemBySlots]="equippedItemBySlots"
          [availableItemsBySlots]="availableItemsBySlots"
          (equipEmit)="equip($event)"
          (takeOffEmit)="takeOff($event)"
          (takeOffAllEmit)="takeOffAllEmit()">
        </app-inventory-equipped>
      }
      @case (INVENTORY_VIEW.ALL) {
        <app-inventory-all
          [currentFilterQuery]="currentFilterQuery"
          [currentItemsSort]="currentItemsSort"
          [viewMode]="viewMode"
          [itemModels]="filteredItems"
          [inputHero]="hero"
          [inputChainId]="chainId"
          [inventoryItemTypeFilter]="currentFilterItemClass"
          (useEmit)="itemAction($event)"
          (updateInventory)="init()">
        </app-inventory-all>
      }
    }
  </div>
}
