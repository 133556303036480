import { DOCUMENT, NgStyle } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { DestroyService } from '@services/destroy.service';
import { Mediator } from '@services/mediator.service';
import { StorageService } from '@services/storage.service';
import { LoadingActions } from '@shared/actions/loading.actions';
import { TutorialActions } from '@shared/actions/tutorial.actions';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { STORAGE_KEY_TUTORIAL, TUTORIALS } from '@shared/constants/tutorial.constant';
import { filter, takeUntil } from 'rxjs';

import { ITutorialConfig } from './tutorial-config.interface';

export const STORAGE_SKIP_TUTORIAL = 'TETU_SKIP_TUTORIAL';

@Component({
  selector: 'app-modal-tutorial',
  standalone: true,
  templateUrl: './modal-tutorial.component.html',
  styleUrls: ['./modal-tutorial.component.scss'],
  providers: [DestroyService],
  imports: [CheckboxComponent, ReactiveFormsModule, NgStyle],
})
export class ModalTutorialComponent implements OnInit {
  @Input() text = '';
  @Input() isShowTutorial = false;

  @ViewChild('tutorialPointer', { static: false }) tutorialPointer!: ElementRef<HTMLElement>;

  @HostListener('document:keydown.escape')
  onClose(): void {
    this.close();
  }

  isShowPointer = false;
  modalMinHeight: number = 300;
  tutorialPosition: { left?: string; top?: string; right?: string; bottom?: string } = { left: '0px', top: '200px' };
  isHasNext = false;
  nextOffset = 0;
  currentIndex = 0;
  currentConfig: ITutorialConfig[] = [];
  currentKey?: string;
  passedTutorials: { [key: string]: boolean } = {};

  skipTutorialsControl: FormControl<CHECKBOX_STATE | null> = new FormControl<CHECKBOX_STATE>(CHECKBOX_STATE.NONE);

  private appZoom: number = 1;
  private document: Document = inject(DOCUMENT);

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private mediator: Mediator,
    private destroy$: DestroyService,
    private router: Router,
    private storageService: StorageService,
    private appStateService: AppStateService,
  ) {}

  ngOnInit() {
    this.appZoom = this.appStateService.getZoom();

    if (this.storageService.get(STORAGE_KEY_TUTORIAL)) {
      this.passedTutorials = JSON.parse(this.storageService.get(STORAGE_KEY_TUTORIAL));
    }

    this.skipTutorialsControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(STORAGE_SKIP_TUTORIAL, JSON.stringify(this.skipTutorialsControl.value));

      this.close();
    });

    this.mediator
      .ofAction(TutorialActions.ToggleTutorial, this.destroy$)
      .pipe(filter(({ config }) => !!config))
      .subscribe(({ config }) => {
        this.currentConfig = config!;

        this.isHasNext = this.currentConfig.length > 1;

        this.nextOffset = this.isHasNext ? 60 : 0;

        if (!this.isHasNext) {
          this.savePassedTutorial();
        }

        if (this.isShowTutorial) {
          this.update();
        }
      });

    const tutorialsKeys = Object.keys(TUTORIALS);

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (this.storageService.get(STORAGE_SKIP_TUTORIAL)) {
        const isSkipTutorial = JSON.parse(this.storageService.get(STORAGE_SKIP_TUTORIAL));

        if (isSkipTutorial === CHECKBOX_STATE.CHECKED) {
          return;
        }
      }

      const url = (event as NavigationEnd).url;

      this.close();

      this.currentKey = tutorialsKeys.find(it => url.indexOf(it) >= 0);

      if (this.currentKey) {
        if (this.currentKey.indexOf('modal') >= 0 || this.currentKey.indexOf('inventory') >= 0) {
          this.mediator.dispatch(
            new TutorialActions.ToggleTutorial(true, TUTORIALS[this.currentKey!], false, this.currentKey!),
          );
        } else {
          this.mediator
            .ofAction(LoadingActions.PageLoaded, this.destroy$)
            .pipe(filter(({ page }) => page === this.currentKey))
            .subscribe(() => {
              this.mediator.dispatch(
                new TutorialActions.ToggleTutorial(true, TUTORIALS[this.currentKey!], false, this.currentKey!),
              );
            });
        }
      }
    });
  }

  onNext() {
    if (this.currentIndex < this.currentConfig.length - 1) {
      this.currentIndex++;

      this.update();
    } else {
      this.savePassedTutorial();

      this.close();
    }

    if (this.currentIndex === this.currentConfig.length - 1) {
      this.isHasNext = false;
      this.nextOffset = 0;
    }
  }

  close() {
    this.currentIndex = 0;

    if (this.document.querySelector('.main')) {
      this.document
        .querySelector('.main')!
        .querySelectorAll('*')
        .forEach((elm: Element) => {
          elm['style'].filter = null;
          elm['style'].transition = null;
        });
    }

    if (this.document.querySelector('.cdk-dialog-container')) {
      this.document
        .querySelector('.cdk-dialog-container')!
        .querySelectorAll('*')
        .forEach((elm: Element) => {
          elm['style'].filter = null;
          elm['style'].transition = null;
        });
    }

    this.mediator.dispatch(new TutorialActions.ToggleTutorial(false));
  }

  private savePassedTutorial() {
    if (this.currentKey) {
      this.passedTutorials = {
        ...this.passedTutorials,
        [this.currentKey!]: true,
      };

      this.storageService.set(STORAGE_KEY_TUTORIAL, JSON.stringify(this.passedTutorials));
    }
  }

  private update() {
    this.text = this.currentConfig[this.currentIndex]?.text || '';

    if (this.currentConfig[this.currentIndex]?.position && !!this.text) {
      switch (this.currentConfig[this.currentIndex].position) {
        case 'lt':
          this.tutorialPosition = { left: '10px', top: '200px' };
          break;

        case 'lm':
          this.tutorialPosition = { left: '10px', top: '50%' };
          break;

        case 'lb':
          this.tutorialPosition = { left: '10px', bottom: '0px' };
          break;

        case 'ct':
          this.tutorialPosition = { left: 'calc(50% - 300px)', top: '200px' };
          break;

        case 'cm':
          this.tutorialPosition = { left: 'calc(50% - 300px)', top: 'calc(50% - 200px)' };
          break;

        case 'cb':
          this.tutorialPosition = { left: 'calc(50% - 300px)', bottom: '0px' };
          break;

        case 'rt':
          this.tutorialPosition = { right: '30px', top: '200px' };
          break;

        case 'rm':
          this.tutorialPosition = { right: '30px', top: 'calc(50% - 200px)' };
          break;

        case 'rb':
          this.tutorialPosition = { right: '30px', bottom: '0px' };
          break;
      }

      this.changeDetectorRef.detectChanges();
    }

    if (
      this.currentConfig &&
      this.currentConfig[this.currentIndex] &&
      this.currentConfig[this.currentIndex]?.pointer &&
      this.tutorialPointer
    ) {
      let elm;
      let id;

      for (let i = 0; i < this.currentConfig[this.currentIndex].pointer!.elementsId.length || 0; i++) {
        id = this.currentConfig[this.currentIndex].pointer?.elementsId[i];
        elm = this.document.querySelector(`#${id}`);

        if (elm) {
          break;
        }
      }

      if (!elm) {
        console.error(`${id} not found`);

        this.onNext();

        return;
      }

      if (!this.isElementOnViewPort(elm)) {
        elm.scrollIntoView();
      }

      if (this.document.querySelector('.main')) {
        this.document
          .querySelector('.main')!
          .querySelectorAll('*')
          .forEach((elm: Element) => {
            elm['style'].filter = 'brightness(0.85)';
            elm['style'].transition = 'all 0.32s ease-in-out';
          });
      }

      if (this.document.querySelector('.cdk-dialog-container')) {
        this.document
          .querySelector('.cdk-dialog-container')!
          .querySelectorAll('*')
          .forEach((elm: Element) => {
            elm['style'].filter = 'brightness(0.95)';
            elm['style'].transition = 'all 0.32s ease-in-out';
          });
      }

      elm.style.filter = 'brightness(3)';

      let offsetX = 0;
      let offsetY = 10;

      if (this.currentConfig[this.currentIndex].pointer?.direction) {
        switch (this.currentConfig[this.currentIndex].pointer?.direction) {
          case 'rt':
            this.tutorialPointer.nativeElement.style.transform = 'scale(1, 1)';

            offsetX = -100;
            offsetY = -250;
            break;

          case 'lt':
            this.tutorialPointer.nativeElement.style.transform = 'scale(-1, 1)';

            offsetX = -240;
            offsetY = -250;
            break;

          case 'rb':
            this.tutorialPointer.nativeElement.style.transform = 'scale(1, -1)';

            offsetX = -100;
            offsetY = -120;
            break;

          case 'lb':
            this.tutorialPointer.nativeElement.style.transform = 'scale(-1, -1)';

            offsetX = -240;
            offsetY = -120;
            break;
        }
      }

      const elmRect = elm.getBoundingClientRect();

      this.tutorialPointer.nativeElement.style.top = `calc(${elmRect.y / this.appZoom}px + ${
        elmRect.height / 2
      }px + ${offsetY}px)`;
      this.tutorialPointer.nativeElement.style.left = `calc(${elmRect.x / this.appZoom}px + ${
        elmRect.width / 2
      }px + ${offsetX}px)`;

      this.isShowPointer = true;

      this.tutorialPointer.nativeElement.style.filter = 'brightness(2)';

      setTimeout(() => {
        this.tutorialPointer.nativeElement.style.filter = 'brightness(1)';
      }, 320);
    }

    this.changeDetectorRef.detectChanges();
  }

  private isElementOnViewPort(elm: HTMLElement): boolean {
    const rect = elm.getBoundingClientRect();

    return (
      rect.top * this.appZoom >= 0 &&
      rect.left * this.appZoom >= 0 &&
      rect.bottom * this.appZoom <= (window.innerHeight || this.document.documentElement.clientHeight) &&
      rect.right * this.appZoom <= (window.innerWidth || this.document.documentElement.clientWidth)
    );
  }
}
