// @ts-nocheck
import { environment } from '@environments/environment';
import { fullBaseUrl } from '@helpers/common-helper';
import EthereumProvider from '@walletconnect/ethereum-provider';

import { getChainId, IAbstractConnectorOptions } from '../../helpers';

export interface IWalletConnectConnectorOptions extends IAbstractConnectorOptions {
  projectId?: string;
  qrcode?: boolean;
}

const ConnectToWalletConnect = (ethereumProvider: EthereumProvider, opts: IWalletConnectConnectorOptions) => {
  return new Promise(async (resolve, reject) => {
    let chainId = getChainId(opts.network);
    const { projectId } = opts;
    if (!projectId) {
      reject('WalletConnect projectId is required');
    }

    const rpcMap = {};
    rpcMap[chainId] = environment['RPC_PROVIDER_URL'];

    const appUrl = fullBaseUrl();

    const params: EthereumProviderOptions = {
      projectId: projectId, // required
      chains: [chainId], // required
      showQrModal: opts.qrcode ? opts.qrcode : true, // requires @walletconnect/modal
      rpcMap: rpcMap,
      metadata: {
        name: 'Sacra',
        description: 'Sacra WalletConnect',
        url: appUrl,
        icons: [
          'https://raw.githubusercontent.com/tetu-io/tetu-game-assets/541bad2ed633f10b13c37b8f00bd6a960aa22134/logo/logo_red_120.png',
        ],
        verifyUrl: appUrl,
        redirect: {
          native: appUrl,
          universal: appUrl,
        },
      },
    };

    console.log('Wallet connect params', params);

    const provider = await EthereumProvider.init(params);
    try {
      await provider.enable();
      resolve(provider);
    } catch (e) {
      // console.log('WalletConnect enable error', e);
      reject(e);
    }
  });
};

export default ConnectToWalletConnect;
