import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTE_TO_NAME,
  ATTRIBUTES,
} from '@shared/constants/attributes.constant';

export interface HeroStatsInterface {
  id: string;
  name: string;
  icon: string;
  iconDisabled?: string;
  description?: string;
  index?: number;
}

export const HERO_FIGHT_STATS_META: HeroStatsInterface[] = [
  {
    id: 'attackRating',
    name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.ATTACK_RATING) ?? '',
    icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.ATTACK_RATING) ?? '',
    description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.ATTACK_RATING) ?? '',
    index: 6,
  },
  {
    id: 'defense',
    name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.DEFENSE) ?? '',
    icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DEFENSE) ?? '',
    description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.DEFENSE) ?? '',
    index: 7,
  },
  {
    id: 'damageMin:damageMax',
    name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.DAMAGE) ?? '',
    icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DAMAGE_MAX) ?? '',
    description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.DAMAGE) ?? '',
    index: 4,
  },
  {
    id: 'blockRating',
    name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.BLOCK_RATING) ?? '',
    icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.BLOCK_RATING) ?? '',
    description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.BLOCK_RATING) ?? '',
    index: 8,
  },
];

export const HERO_CORE_STATS_META: HeroStatsInterface[] = [
  {
    id: 'strength',
    name: 'attributes-names.strength',
    icon: 'assets/images/ui/icons/stats/str.png',
    iconDisabled: 'assets/images/ui/icons/stats/str-disabled.png',
    description: 'attributes-descriptions.strength',
    index: 0,
  },
  {
    id: 'dexterity',
    name: 'attributes-names.dexterity',
    icon: 'assets/images/ui/icons/stats/dex.png',
    iconDisabled: 'assets/images/ui/icons/stats/dex-disabled.png',
    description: 'attributes-descriptions.dexterity',
    index: 1,
  },
  {
    id: 'vitality',
    name: 'attributes-names.vitality',
    icon: 'assets/images/ui/icons/stats/vit.png',
    iconDisabled: 'assets/images/ui/icons/stats/vit-disabled.png',
    description: 'attributes-descriptions.vitality',
    index: 2,
  },
  {
    id: 'energy',
    name: 'attributes-names.energy',
    icon: 'assets/images/ui/icons/stats/enrg.png',
    iconDisabled: 'assets/images/ui/icons/stats/enrg-disabled.png',
    description: 'attributes-descriptions.energy',
    index: 3,
  },
];

export const HERO_CORE_STATS_RESISTANCE_META: HeroStatsInterface[] = [
  {
    id: 'lightningResistance',
    name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
    icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
    description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
    index: 11,
  },
  {
    id: 'fireResistance',
    name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
    icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
    description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
    index: 12,
  },
  {
    id: 'coldResistance',
    name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
    icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
    description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
    index: 13,
  },
];

export const HERO_CORE_STATS_EXTENDED_META: HeroStatsInterface[] = [
  ...HERO_CORE_STATS_META,
  ...HERO_CORE_STATS_RESISTANCE_META,
];
