import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioGroupModel } from '@models/radio-group.model';
import { TranslateModule } from '@ngx-translate/core';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';

@Component({
  selector: 'app-radio-group',
  standalone: true,
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ScratchComponent],
})
export class RadioGroupComponent {
  @Input() items: RadioGroupModel[] = [];
  @Input() selectedItemId?: string | number;
  @Input() isDisabled = false;
  @Input() isChangeManually = true;
  @Input() isShowDivider = true;
  @Input() dividerType: 'none' | 'small' | 'big' = 'none';
  @Input() selectedType: 'none' | 'small' | 'big' = 'big';
  @Input() size: 'small' | 'default' | 'medium' | 'medium-small' | 'big' = 'small';
  @Input() isUseStyle2 = false;
  @Input() isWrapLabel = false;

  @Output() choose: EventEmitter<RadioGroupModel> = new EventEmitter<RadioGroupModel>();

  onSelect(item: RadioGroupModel) {
    if (this.isDisabled || !this.isChangeManually) {
      return;
    }

    this.selectedItemId = item.id;

    this.choose.emit(item);
  }

  protected readonly CHECKBOX_STATE = CHECKBOX_STATE;
}
