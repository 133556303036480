import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AnimatedSpriteCanvasComponent } from '@shared/components/animated-sprite-canvas/animated-sprite-canvas.component';
import { CheckPasswordComponent } from '@shared/components/check-password/check-password.component';
import { HeroStatComponent } from '@shared/components/hero-stat/hero-stat.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { ItemDescriptionComponent } from '@shared/components/item-description/item-description.component';
import { ItemMetaDescriptionComponent } from '@shared/components/item-meta-description/item-meta-description.component';

import { ButtonClickDirective } from '../button-click/button-click.directive';

import { AchievementComponent } from './achievement/achievement.component';
import { AugmentationLevelComponent } from './augmentation-level/augmentation-level.component';
import { AvatarComponent } from './avatar/avatar.component';
import { BalanceComponent } from './balance/balance.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { HeroStatItemComponent } from './hero-stat-item/hero-stat-item.component';
import { ItemIdComponent } from './item-id/item-id.component';
import { ItemSlotComponent } from './item-slot/item-slot.component';
import { LeftRightFrameComponent } from './left-right-frame/left-right-frame.component';
import { LifeChancesComponent } from './life-chances/life-chances.component';
import { LoadingSmallComponent } from './loading-small/loading-small.component';
import { FilterNullPipe } from './pipes/filter-null/filter-null.pipe';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ScratchComponent } from './scratch/scratch.component';
import { SkillAreaComponent } from './skill-area/skill-area.component';

@NgModule({
  imports: [
    TranslateModule,
    AvatarComponent,
    DialogTitleComponent,
    ItemSlotComponent,
    LeftRightFrameComponent,
    LoadingSmallComponent,
    ScratchComponent,
    HeroStatItemComponent,
    SkillAreaComponent,
    BalanceComponent,
    FilterNullPipe,
    DropdownComponent,
    CheckboxComponent,
    AugmentationLevelComponent,
    ItemIdComponent,
    RadioGroupComponent,
    LifeChancesComponent,
    ButtonClickDirective,
    AchievementComponent,
    HeroStatComponent,
    AnimatedSpriteCanvasComponent,
    CheckPasswordComponent,
    ImageComponent,
    ItemDescriptionComponent,
    ItemMetaDescriptionComponent,
  ],
  exports: [TranslateModule],
})
export class SharedModule {}
