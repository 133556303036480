import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { Formatter } from '@helpers/formatter';
import { TranslateModule } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { HeroControllerService } from '@services/onchain/hero-controller.service';
import { ItemControllerService } from '@services/onchain/item-controller.service';
import { TokenService } from '@services/onchain/token.service';
import { ProviderService } from '@services/provider.service';
import { SoundService } from '@services/sound.service';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { ItemCardComponent } from '@shared/components/item-card/item-card.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemSlotComponent } from '@shared/components/item-slot/item-slot.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { ItemActionType } from '@shared/constants/inventory.constants';
import { ITEM_TYPE } from '@shared/constants/items.constant';
import { NUMBERS } from '@shared/constants/numbers.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { formatUnits, parseUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { finalize, forkJoin, takeUntil } from 'rxjs';

import { RepairSuccessDialogComponent } from './components/repair-success-dialog/repair-success-dialog.component';

const EMPTY_CATALYST = {
  itemId: 0,
  equippedSlot: ITEM_TYPE.NO_SLOT,
} as ItemEntity;

@Component({
  standalone: true,
  templateUrl: './repair-dialog.component.html',
  styleUrls: ['./repair-dialog.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [
    LoadingSmallComponent,
    DialogTitleComponent,
    ItemSlotComponent,
    ScratchComponent,
    ButtonClickDirective,
    BalanceComponent,
    ItemCardComponent,
    TranslateModule,
  ],
})
export class RepairDialogComponent implements OnInit {
  // common
  account: string;
  chainId: number;
  loading = true;

  heroAdr: string;
  heroId: number;
  itemAdr: string;
  itemId: number;

  MAIN_ROUTES = MAIN_ROUTES;

  hero: HeroEntity;

  targetItem: ItemEntity;

  catalystItems: ItemEntity[] = [];

  selectedCatalyst?: ItemEntity = EMPTY_CATALYST;

  accountBalance = 0n;
  accountBalanceFormatted = '0';
  isEnoughAllowance = false;
  isEnoughBalance = false;

  private successDialogRef:
    | DialogRef<{ item: ItemEntity; isSuccess: boolean }, RepairSuccessDialogComponent>
    | undefined;
  private dialogRef: DialogRef<ItemActionType, ItemDescriptionDialogComponent> | undefined;

  transactionLoading = false;

  constructor(
    @Inject(DIALOG_DATA) public data: { heroToken: string; heroId: number; itemMetaId: string; itemId: number },
    private destroy$: DestroyService,
    private logger: NGXLogger,
    private appStateService: AppStateService,
    private providerService: ProviderService,
    private heroControllerService: HeroControllerService,
    private itemControllerService: ItemControllerService,
    private tokenService: TokenService,
    private subgraphService: SubgraphService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: Dialog,
    private soundService: SoundService,
    private repairDialogRef: DialogRef<boolean, RepairDialogComponent>,
  ) {
    this.heroAdr = this.data.heroToken;
    this.heroId = this.data.heroId;
    this.itemAdr = this.data.itemMetaId;
    this.itemId = this.data.itemId;

    this.appStateService.setHeaderState({
      isAccountBalanceShown: true,
      isInventoryShown: true,
      backUrl: [MAIN_ROUTES.MAIN, MAIN_ROUTES.INVENTORY, this.heroAdr, this.heroId.toString()],
    });
  }

  ngOnInit(): void {
    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.heroControllerService.adjustFees();
        this.itemControllerService.adjustFees();
        this.tokenService.adjustFees();
        this.init();
      },
    );
  }

  private init() {
    if (this.account && this.chainId) {
      this.loadHeroInfo();
      this.loadItems();
    }
  }

  private loadHeroInfo() {
    this.subgraphService
      .hero$(this.heroAdr, this.heroId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hero => {
        if (hero) {
          this.hero = hero;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  private loadItems() {
    this.subgraphService
      .usersItems$(this.account)
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(items => {
        this.targetItem = items.filter(
          item => item.itemId === this.itemId && item.meta.id.toLowerCase() === this.itemAdr.toLowerCase(),
        )[0] as ItemEntity;

        this.catalystItems = items
          .filter(item => item.itemId !== this.itemId && item.meta.id.toLowerCase() === this.itemAdr.toLowerCase())
          .map(i => i as ItemEntity);

        this.updateFeeBalance();

        this.loading = false;
      });
  }

  updateFeeBalance() {
    forkJoin([
      this.tokenService.allowance$(
        this.targetItem.meta.feeToken.token.id,
        this.account,
        GET_CORE_ADDRESSES(this.chainId).controller,
      ),
      this.tokenService.balanceOf$(this.targetItem.meta.feeToken.token.id, this.account),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([allowance, balance]) => {
        this.isEnoughAllowance =
          allowance >= parseUnits(this.targetItem.meta.feeToken.amount, this.targetItem.meta.feeToken.token.decimals);
        this.isEnoughBalance =
          balance >= parseUnits(this.targetItem.meta.feeToken.amount, this.targetItem.meta.feeToken.token.decimals);
        this.accountBalance = balance;
        this.accountBalanceFormatted = Formatter.formatCurrency(
          +formatUnits(balance, this.targetItem.meta.feeToken.token.decimals),
        );

        this.changeDetectorRef.detectChanges();
      });
  }

  selectCatalyst(item: ItemEntity) {
    this.selectedCatalyst = item;
  }

  resetCatalyst() {
    this.selectedCatalyst = EMPTY_CATALYST;
  }

  onItemSlotClick(item?: ItemEntity) {
    if (item && item.itemId !== 0) {
      this.dialogRef = this.dialog.open(ItemDescriptionDialogComponent, {
        panelClass: 'app-overlay-pane-full-width',
        data: { item, hero: this.hero, isShowButtons: false, chainId: this.chainId },
      });
    }
  }

  repair() {
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();

    this.soundService.play({ key: 'repair' });

    this.itemControllerService
      .repairDurability$(
        this.account,
        this.chainId,
        this.targetItem.meta.id,
        this.targetItem.itemId,
        this.selectedCatalyst?.itemId ?? 0,
      )
      .pipe(
        finalize(() => {
          this.transactionLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.selectedCatalyst = EMPTY_CATALYST;
        this.openResult();
      });
  }

  openResult() {
    this.subgraphService
      .item$(this.itemAdr, this.itemId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(item => {
        this.transactionLoading = false;
        this.init();
        this.selectedCatalyst = undefined;
        this.changeDetectorRef.detectChanges();

        const success = item.durability === item.meta.durability;

        // TODO add fail sound
        this.soundService.play({ key: success ? 'repair' : 'repair' });

        this.successDialogRef = this.dialog.open(RepairSuccessDialogComponent, {
          panelClass: 'app-overlay-pane',
          data: { item: item, isSuccess: success },
        });

        this.successDialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.logger.trace('dialog res', data, success);
          this.repairDialogRef.close(true);
        });
      });
  }

  approveFeeToken() {
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();
    this.tokenService
      .approve$(
        this.account,
        this.targetItem.meta.feeToken.token.id,
        GET_CORE_ADDRESSES(this.chainId).controller,
        BigInt(NUMBERS.MAX_UINT),
      )
      .pipe(
        finalize(() => {
          this.transactionLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateFeeBalance();
      });
  }

  buyTokens() {
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();

    this.soundService.play({ key: 'buy_coins' });

    const feeTokenData = this.targetItem.meta.feeToken;
    this.tokenService
      .buyTokens$(
        this.account,
        '',
        feeTokenData.token.id,
        parseUnits(feeTokenData.amount, feeTokenData.token.decimals),
        this.chainId,
      )
      .pipe(
        finalize(() => {
          this.transactionLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateFeeBalance();
      });
  }

  close(): void {
    this.repairDialogRef.close(false);
  }
}
