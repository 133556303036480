export function reloadPage(path?: string) {
  if (path) {
    window.location.href = path;
  } else {
    window.location.href = '';
  }
}

export function fullBaseUrl() {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }`;
}
