import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HeroEntity } from '@generated/gql';
import { getHeroExperiencePercent, getHeroVirtualLevel } from '@helpers/data-helper';
import { AttributesModel } from '@models/attributes.model';
import { TranslateModule } from '@ngx-translate/core';
import { BarStatComponent } from '@shared/components/bar-stat/bar-stat.component';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';
import { HeroStatItemComponent } from '@shared/components/hero-stat-item/hero-stat-item.component';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { LifeChancesComponent } from '@shared/components/life-chances/life-chances.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { ATTRIBUTE_TO_ICON, ATTRIBUTE_TO_NAME, ATTRIBUTES } from '@shared/constants/attributes.constant';
import { getCustomHeroDataByByte32, getHeroTitle } from '@shared/constants/custom-data.constant';
import {
  HERO_CORE_STATS_EXTENDED_META,
  HERO_CORE_STATS_META,
  HERO_FIGHT_STATS_META,
} from '@shared/constants/hero-levels.constant';
import { HEROES_CLASSES_NAMES } from '@shared/constants/heroes.constant';
import { getValueByKey } from '@shared/utils';

@Component({
  selector: 'app-hero-all-stats',
  standalone: true,
  templateUrl: './hero-all-stats.component.html',
  styleUrls: ['./hero-all-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column',
  },
  imports: [LifeChancesComponent, ScratchComponent, HeroStatItemComponent, BarStatComponent, TranslateModule],
})
export class HeroAllStatsComponent implements OnInit {
  @Input() hero: HeroEntity;

  heroAttributes: AttributesModel;
  heroClassName: string;
  heroTitle: string = 'Unknown';

  fightStatInfo: HeroStatItemInputInterface[] = [];
  coreStatInfo: HeroStatItemInputInterface[] = [];
  otherStatInfo: HeroStatItemInputInterface[] = [];
  customHeroData: HeroStatItemInputInterface[] = [];
  isProductionBuild = environment.STAGING === 'false';
  karma = 0;
  virtualLevel = '0';

  constructor(private dialog: Dialog) {}

  ngOnInit(): void {
    this.heroClassName = HEROES_CLASSES_NAMES[this.hero.meta.heroClass];
    this.heroAttributes = AttributesModel.fromStringArray(this.hero.attributes);

    this.virtualLevel = getHeroVirtualLevel(this.hero.stats.experience, this.hero.stats.level).toFixed();

    this.fightStatInfo = HERO_FIGHT_STATS_META.map((stat): HeroStatItemInputInterface => {
      // console.log(getValueByKey<AttributesModel>(this.heroAttributes, stat.id), stat, this.heroAttributes);
      return {
        ...stat,
        text: getValueByKey<AttributesModel>(this.heroAttributes, stat.id),
        attributeIndex: stat.index ?? 0,
      };
    });

    this.coreStatInfo = HERO_CORE_STATS_EXTENDED_META.map((stat): HeroStatItemInputInterface => {
      let text = getValueByKey<AttributesModel>(this.heroAttributes, stat.id);
      let bonus: string | undefined;
      if (HERO_CORE_STATS_META.find(s => s.id === stat.id)) {
        bonus = (Number(text) - Number(this.hero.core[stat.id])).toString();
        text = this.hero.core[stat.id];
      }
      return {
        ...stat,
        text,
        additionalText: bonus,
        attributeIndex: stat.index ?? 0,
      };
    });

    this.otherStatInfo = [];
    for (const id of Array.from(ATTRIBUTE_TO_NAME.keys())) {
      if (id <= 13 || id === ATTRIBUTES.LIFE_CHANCES) {
        continue;
      }
      const _id = id.toString() ?? 'unknown';
      const value = this.heroAttributes.getAttribute(id);
      if (value > 0) {
        this.otherStatInfo.push({
          id: _id,
          name: this.heroAttributes.getAttributeName(id) ?? 'unknown',
          text: value,
          icon: ATTRIBUTE_TO_ICON.get(id) ?? 'unknown',
          description: this.heroAttributes.getAttributeDescription(id),
          attributeIndex: id,
        });
      }
    }

    this.heroTitle = getHeroTitle(this.hero.customData);
    this.customHeroData = [];

    const heroData = getCustomHeroDataByByte32();

    for (const d of this.hero.customData) {
      this.customHeroData.push({
        id: d.dataIndex,
        name: heroData.get(d.dataIndex) ?? 'unknown',
        text: d.value,
        icon: 'unknown',
        attributeIndex: +d.dataIndex,
      });
    }
    this.customHeroData = this.customHeroData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    this.karma = this.getKarma();
  }

  expBarWidth() {
    return Math.min(this.heroExpPercentage(), 100) / 100;
  }

  heroExpPercentage() {
    return Math.min(
      getHeroExperiencePercent(
        this.hero.stats.experience,
        this.hero.previousLevelExperienceRequire,
        this.hero.nextLevelExperienceRequire,
      ),
      100,
    );
  }

  getKarma() {
    return Number(this.customHeroData.filter(d => d.name.toLowerCase() === 'karma')[0]?.text ?? '1000') - 1000;
  }

  onShowHeroTitleDialog() {
    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: 'Hero Title',
        infoDesc: 'Title depends on your karma',
      },
    });
  }
}
