import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DestroyService } from '@services/destroy.service';
import { ClickOutsideModule } from 'ng-click-outside';

@Component({
  selector: 'app-search',
  standalone: true,
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'g-flex g-flex--align-center g-flex--justify-end',
  },
  imports: [ClickOutsideModule, ReactiveFormsModule],
})
export class SearchComponent {
  private searchInput: ElementRef<HTMLInputElement>;

  @ViewChild('searchInput') set content(searchInput: ElementRef<HTMLInputElement>) {
    if (searchInput) {
      // initially setter gets called with undefined
      this.searchInput = searchInput;
    }
  }

  @Input() size: 'huge' | 'large' = 'huge';
  @Input() isInputToRight = false;

  @Input()
  set isOpen(isOpen: boolean) {
    this.isContentOpen = isOpen;

    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }

  @Input() control: FormControl;
  @Input() isAlwaysOpen = false;

  @Output() isOpenChange = new EventEmitter<boolean>();

  isContentOpen = false;

  constructor(private destroy$: DestroyService) {}

  toggleInput(): void {
    this.toggleVisibility();
  }

  toggleVisibility(isOpen: boolean | null = null) {
    if (this.isAlwaysOpen) {
      return;
    }

    this.isOpen = isOpen === null ? !this.isContentOpen : isOpen;

    this.isOpenChange.emit(this.isContentOpen);
  }
}
