import { Injectable } from '@angular/core';
import { decodeError } from '@helpers/error-decoder';
import { Mediator } from '@services/mediator.service';
import { ErrorActions } from '@shared/actions/error.actions';
import { ErrorType } from 'ethers-decode-error';
import { from } from 'rxjs/internal/observable/from';

export const IGNORED_ERRORS = [
  'User denied transaction signature',
  'User rejected the request',
  'User rejected the transaction',
  'rejected',
  'Ignore error',
  'Your balance is empty',
  'missing r',
];
const MAP_ERRORS = new Map<string, string>([
  // ['is not available', 'WalletConnect does not support sponsored transactions. Disable them in settings.'],
  [
    'server response 504 Gateway Timeout',
    'Ho-ho-ho! Slow down your horses buddy! It seems that RPC provider is overloaded. Try again after some time.',
  ],
  ['Try again after some time', 'RPC threshold, reload the page or change your RPC provider'],
  // [
  //   'could not coalesce error ',
  //   'Wallet internal error. Try your action again or restart this page. If it does not help, contact with us on Discord.',
  // ],
  [
    'Http failure response for https://graph.tetu.io/relay',
    'Sponsored transaction failed. Try again or switch off them in settings.',
  ],
  ['GelatoRelay', 'Sponsored transaction failed. Try again or switch off them in settings.'],
  ['insufficient funds', "You don't have coins to pay this action. Top up your balance."],
  [
    'AnswerPageIdMismatch',
    "Story answer doesn't match the page id. Please reload this page. If it doesn't help, contact with us on Discord.",
  ],
  [
    'AnswerStoryIdMismatch',
    "Story ID mismatch. Please reload this page. If it doesn't help, contact with us on Discord.",
  ],
  [
    'ErrorDungeonBusy',
    'Oops! Selected dungeon already busy! It means somebody was faster than you! Choose another one please.',
  ],
  [
    '"transaction underpriced"',
    'Gas price too low! Sometimes the gas price estimation calculate too low price, its fine. Try your action again.',
  ],
  [
    '"replacement transaction underpriced"',
    'Looks like you already sent a transaction not so long ago. Need to wait a bit or try again later.',
  ],
  ['TransferAmountExceedsBalance', 'You do not have enough SACRA tokens to perform this action.'],
  [
    'Response has no error or result for request',
    'RPC node returned empty response. It sometimes happens. Just repeat your action again.',
  ],
  ['DoubleItemUsageForbidden', 'You can not equip the same items!'],
  ['rate limit exceeded', 'You RPC provider rate limit exceeded. Wait a bit and try your action again.'],
  ["Non-200 status code: '429", 'You RPC provider rate limit exceeded. Wait a bit and try your action again.'],
  ['ItemAlreadyUsedInSlot', 'You can not equip the same items!'],
  ['TPS: Position closed', 'Position already sold! Try to refresh UI and choose another one.'],
  ['EquippedItemsExist', 'You need to take off all your equipments to perform this action.'],
]);

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private mediator: Mediator) {
    // noop
  }

  public onCatchError = (errorResponse: any) => {
    if (errorResponse === 'Delegator balance is not enough.') {
      this.mediator.dispatch(new ErrorActions.Toggle(true, 'Delegator balance is not enough.'));
      throw errorResponse;
    }

    for (const ignore of IGNORED_ERRORS) {
      if (JSON.stringify(errorResponse).includes(ignore)) {
        throw errorResponse;
      }
    }

    let msg = 'Some strange error';

    if (errorResponse.error && typeof errorResponse.error === 'string') {
      msg = errorResponse.error;
    }

    if (errorResponse.message && typeof errorResponse.message === 'string') {
      msg = errorResponse.message;
    }

    if (errorResponse.data && errorResponse.data.message && typeof errorResponse.data.message === 'string') {
      msg = errorResponse.data.message;
    }

    if (msg.includes('reason="')) {
      msg = msg.split('reason="')[1].split('",')[0];
    }

    from(decodeError(errorResponse)).subscribe(decodedError => {
      if (decodedError.type === ErrorType.CustomError) {
        msg = `
        Date: ${currentDateWithTime()} |
        Error: ${decodedError.name} |
        Args: ${decodedError.args} |
        Selector: ${decodedError.selector} |
        Please contact with us on Discord with this info.
        `;
      }

      for (const [key, value] of MAP_ERRORS.entries()) {
        if (msg.includes(key)) {
          msg = `Date: ${currentDateWithTime()}. ${value}`;
          break;
        }
      }

      for (const ignore of IGNORED_ERRORS) {
        if (msg.includes(ignore)) {
          return;
        }
      }

      console.error(msg);
      this.mediator.dispatch(new ErrorActions.Toggle(true, msg));
    });
    throw errorResponse;
  };
}

function currentDateWithTime() {
  return new Date().toLocaleString();
}
