import { ITutorialConfig } from '@shared/components/modal-tutorial/tutorial-config.interface';
import { MediatorAction } from '@shared/types/action.interface';

export namespace TutorialActions {
  export class ToggleTutorial implements MediatorAction {
    constructor(
      public isShow: boolean,
      public config?: ITutorialConfig[],
      public isForceShow?: boolean,
      public key?: string,
    ) {}
  }
}
