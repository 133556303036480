<div
  class="item g-flex g-flex--align-start app-btn-small_font-size"
  [style.width]="itemWidth ? itemWidth : 'unset'"
  [style.background-image]="itemBackground(item)">
  <div
    class="item__image-container g-flex__item-fixed item__img-container"
    [ngClass]="getBackgroundClass(item)"
    (click)="selectItemImg.emit(item)">
    @if (item.count && item.count > 1) {
      <div class="count app-btn-tiny_font-size text-shadow g-flex g-flex--align-center g-flex--justify-center">
        {{ item.countFormatted }}
      </div>
    }

    @if (itemDurability && durabilityPercentage <= 20 && item.meta.durability !== 0) {
      <img
        class="item__image-broken"
        src="/assets/images/ui/frames/item-slots/middle/broken.avif"
        alt="item image" />
    }

    @if (getActualUri(item)) {
      <img
        class="item__image item__image--middle"
        [src]="getActualUri(item)"
        alt="no image" />
    }

    @if (isShowDurability && !item.meta.isConsumableItem) {
      <app-bar
        class="durability-bar"
        barType="card-durability"
        [percentageValue]="(+item.durability / +item.meta.durability) * 100">
      </app-bar>
    }
  </div>

  @if (!item.meta.isConsumableItem) {
    <app-augmentation-level
      class="item__level"
      size="small"
      [level]="item.augmentationLevel">
    </app-augmentation-level>
  }

  <div
    class="item__data g-flex__item g-flex-column"
    (click)="selectItemImg.emit(item)">
    <div
      class="g-flex g-flex--align-center g-flex--space-between"
      [class.item__title-content]="!(isShowId && !item.count)">
      <div
        class="item__title g-flex__item g-flex app-btn-medium_font-size text-shadow"
        [style.color]="titleColor(item)">
        {{ itemName }}
      </div>

      @if (+item.fragility > 0) {
        <div
          class="fragility g-flex__item-fixed fragility g-flex g-flex--align-center"
          (click)="showFragilityInfo($event)">
          <img
            class="fragility__icon"
            src="/assets/images/ui/icons/other/fragility-red.png"
            alt="fragility" />

          <div>{{ (+item.fragility / 1000).toFixed(0) }}%</div>
        </div>
      }

      @if (isShowId && !item.count) {
        <app-item-id
          class="g-flex__item-fixed item-id"
          [class.item-id--market]="isMarketplace"
          [style.width]="idWidth ? idWidth : '227px'"
          [class.item-id--big-title]="itemName.length > 26"
          [bgSize]="idSize"
          [itemId]="item.itemId">
        </app-item-id>
      }
    </div>

    <div class="g-flex">
      <div class="g-flex__item g-flex-column">
        <app-scratch class="item__title-scratch"></app-scratch>

        @if (isCompactView) {
          <ng-container [ngTemplateOutlet]="statsTpl"></ng-container>
        }

        @if (!isCompactView) {
          <div class="rarity g-flex g-flex--align-center gap-16">
            <img
              class="rarity__icon"
              [src]="'/assets/images/ui/icons/item-type/' + itemsTypeIcon + '.png'"
              alt="item image" />

            @if (itemRarityName) {
              <div
                class="rarity__name"
                [style.text-shadow]="'0px 0px 8px ' + titleShadowColor(item)"
                [style.color]="titleColor(item)">
                {{ itemRarityName | translate }}
              </div>
            }

            @if (itemsTypeName !== 'no slot') {
              <div>
                {{ itemsTypeName ?? '' | translate }}
              </div>
            }
          </div>
        }
      </div>

      @if (!isCompactView && item.score !== 0) {
        <div class="item__score g-flex-column g-flex--align-end g-flex--justify-end text-shadow">
          <div class="app-btn-huge_font-size color-positive-value">
            {{ item.score }}
          </div>

          <div class="app-btn-small_font-size">
            {{ 'item-description-dialog.gear-score' | translate }}
          </div>
        </div>
      }

      <ng-content></ng-content>
    </div>
  </div>
</div>

@if (!isCompactView) {
  <ng-container [ngTemplateOutlet]="statsTpl"></ng-container>
}

<ng-template #statsTpl>
  <div
    class="stats-container g-flex"
    [class.stats-container--compact]="isCompactView">
    @if (!isCompactView) {
      <div class="core-stats g-flex__item-fixed"></div>
    }

    <div class="g-flex g-flex--wrap width-100">
      @if (itemAttributes && itemAttributes.attributesArray.length > 0 && hasItemAttributes()) {
        <div
          class="g-flex main-stats g-flex__item-fixed"
          [class.main-stats--compact]="isCompactView"
          [class.main-stats--padding]="
            itemAttributes && itemAttributes.attributesArray.length > 0 && hasItemAttributes()
          ">
          @for (attr of itemAttributes.attributesArray; track attr; let i = $index) {
            @if (attr !== 0 && ATTRIBUTES.DAMAGE_MIN !== i && ATTRIBUTES.DAMAGE_MAX !== i) {
              <app-hero-stat-item
                class="stats__item"
                [isCompact]="true"
                [isSmallFont]="true"
                [isShowSign]="true"
                [isInline]="true"
                [item]="{
                  icon: itemAttributes.getAttributeIcon(i),
                  name: itemAttributes.getAttributeName(i),
                  text: attr,
                  description: itemAttributes.getAttributeDescription(i),
                  attributeIndex: i,
                }">
              </app-hero-stat-item>
            }

            @if (attr !== 0 && ATTRIBUTES.DAMAGE_MIN === i) {
              <app-hero-stat-item
                class="stats__item"
                [isCompact]="true"
                [isSmallFont]="true"
                [isShowSign]="true"
                [isInline]="true"
                [item]="{
                  icon: itemAttributes.getAttributeIcon(i),
                  name: 'Melee Damage',
                  text:
                    itemAttributes.attributesArray[ATTRIBUTES.DAMAGE_MIN] +
                    ' - ' +
                    itemAttributes.attributesArray[ATTRIBUTES.DAMAGE_MAX],
                  description: itemAttributes.getAttributeDescription(i),
                  attributeIndex: i,
                }">
              </app-hero-stat-item>
            }
          }
        </div>
      }

      @if (skillBuffCasterAttributes || skillBuffTargetAttributes) {
        <app-skill-area
          class="g-flex__item-fixed"
          title="Skill"
          type="skill"
          [isCompactBg]="isCompactView"
          [isCompact]="true"
          [isSmallFont]="true"
          [manaConsumption]="skillManaConsumption"
          [skillsAttributes]="[skillBuffCasterAttributes]"
          [skillsSigns]="['+', '-']"
          [hasEnemyAttributes]="
            skillBuffTargetAttributes && (skillBuffTargetAttributes.attributesArray | filterNull).length > 0
          "
          [enemyGotAttributes]="[skillBuffTargetAttributes]">
        </app-skill-area>
      }

      @if (
        (consumableStats &&
          (consumableStats.life !== 0 ||
            consumableStats.mana !== 0 ||
            consumableStats.lifeChances !== 0 ||
            consumableStats.experience !== 0)) ||
        (consumableAttributes && (consumableAttributes.attributesArray | filterNull).length > 0)
      ) {
        <div
          class="skills g-flex g-flex--align-center g-flex__item"
          [class.skills--compact]="isCompactView"
          [class.skills--consumable]="item.meta.isConsumableItem">
          <img
            class="skills__icon"
            src="/assets/images/ui/icons/item-props/use.png"
            alt="item image" />

          @if (consumableStats?.life !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isCompact]="true"
              [isSmallFont]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIFE) ?? '',
                name: ('item-description-dialog.hp-restored' | translate),
                text: consumableStats?.life || '',
                description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIFE) ?? '',
                attributeIndex: ATTRIBUTES.LIFE,
              }">
            </app-hero-stat-item>
          }

          @if (consumableStats?.mana !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isCompact]="true"
              [isSmallFont]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.MANA) ?? '',
                name: ('item-description-dialog.mp-restored' | translate),
                text: consumableStats?.mana || '',
                description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.MANA) ?? '',
                attributeIndex: ATTRIBUTES.MANA,
              }">
            </app-hero-stat-item>
          }

          @if (consumableStats?.experience !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isCompact]="true"
              [isSmallFont]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.EXTRA_ENERGY) ?? '',
                name: ('item-description-dialog.experience' | translate),
                text: consumableStats?.experience || '',
                description: 'attributes-descriptions.experience',
                attributeIndex: ATTRIBUTES.END_SLOT,
              }">
            </app-hero-stat-item>
          }

          @if (consumableStats?.lifeChances !== 0) {
            <app-hero-stat-item
              class="stat-item"
              [isCompact]="true"
              [isSmallFont]="true"
              [isShowSign]="true"
              [isInline]="true"
              [item]="{
                icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIFE_CHANCES) ?? '',
                name: ('item-description-dialog.life-chances-restored' | translate),
                text: consumableStats?.lifeChances || '',
                description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIFE_CHANCES) ?? '',
                attributeIndex: ATTRIBUTES.LIFE_CHANCES,
              }">
            </app-hero-stat-item>
          }

          @if (consumableAttributes) {
            @for (attr of consumableAttributes.attributesArray; track attr; let i = $index) {
              @if (attr !== 0) {
                <app-hero-stat-item
                  class="stat-item"
                  [isCompact]="true"
                  [isSmallFont]="true"
                  [isShowSign]="true"
                  [isInline]="true"
                  [item]="{
                    icon: consumableAttributes.getAttributeIcon(i),
                    name: consumableAttributes.getAttributeName(i),
                    text: attr,
                    description: consumableAttributes.getAttributeDescription(i),
                    attributeIndex: i,
                  }">
                </app-hero-stat-item>
              }
            }
          }

          <!--      <ng-container *ngIf="consumableDebuffAttributes">-->
          <!--        <ng-container *ngFor="let attr of consumableDebuffAttributes.attributesArray; let i = index">-->
          <!--          <app-hero-stat-item-->
          <!--            *ngIf="attr !== 0"-->
          <!--            class="stat-item"-->
          <!--            [isCompact]="true"-->
          <!--            [isSmallFont]="true"-->
          <!--            [isShowSign]="true"-->
          <!--            [isInline]="true"-->
          <!--            [item]="{-->
          <!--              icon: consumableDebuffAttributes.getAttributeIcon(i),-->
          <!--              name: consumableDebuffAttributes.getAttributeName(i),-->
          <!--              text: '-' + attr,-->
          <!--              description: consumableDebuffAttributes.getAttributeDescription(i),-->
          <!--              attributeIndex: i-->
          <!--            }">-->
          <!--          </app-hero-stat-item>-->
          <!--        </ng-container>-->
          <!--      </ng-container>-->
        </div>
      }

      @if (item.magicAttackInfo) {
        <div
          class="skills skills--magic g-flex g-flex--space-between g-flex__item"
          [class.skills--magic-compact]="isCompactView">
          <img
            class="skills__icon"
            src="/assets/images/ui/icons/item-props/attack.png"
            alt="item image" />

          <div class="g-flex">
            <app-hero-stat-item
              class="stat-item"
              [isCompact]="true"
              [isSmallFont]="true"
              [item]="{
                icon: MAGIC_ATTACK_ICON.get(+item.magicAttackInfo.attackType) ?? 'unknown',
                name: MAGIC_ATTACK_NAME.get(+item.magicAttackInfo.attackType) ?? '',
                text: item.magicAttackInfo.minDmg + ' - ' + item.magicAttackInfo.maxDmg,
                attributeIndex: ATTRIBUTES.DAMAGE_MAX,
              }">
            </app-hero-stat-item>

            @if (item.magicAttackInfo.attributesFactor.strength !== 0) {
              <app-hero-stat-item
                class="stat-item"
                [isCompact]="true"
                [isSmallFont]="true"
                [item]="{
                  icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.STRENGTH) ?? 'unknown',
                  name: ('item-description-dialog.add-to-dmg' | translate),
                  text: item.magicAttackInfo.attributesFactor.strength + '% of STR',
                  description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.STRENGTH) ?? '',
                  attributeIndex: ATTRIBUTES.STRENGTH,
                }">
              </app-hero-stat-item>
            }

            @if (item.magicAttackInfo.attributesFactor.dexterity !== 0) {
              <app-hero-stat-item
                class="stat-item"
                [isCompact]="true"
                [isSmallFont]="true"
                [item]="{
                  icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.DEXTERITY) ?? 'unknown',
                  name: ('item-description-dialog.add-to-dmg' | translate),
                  text: item.magicAttackInfo.attributesFactor.dexterity + '% of DEX',
                  description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.DEXTERITY) ?? '',
                  attributeIndex: ATTRIBUTES.DEXTERITY,
                }">
              </app-hero-stat-item>
            }

            @if (item.magicAttackInfo.attributesFactor.vitality !== 0) {
              <app-hero-stat-item
                class="stat-item"
                [isCompact]="true"
                [isSmallFont]="true"
                [item]="{
                  icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.VITALITY) ?? 'unknown',
                  name: ('item-description-dialog.add-to-dmg' | translate),
                  text: item.magicAttackInfo.attributesFactor.vitality + '% of VIT',
                  description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.VITALITY) ?? '',
                  attributeIndex: ATTRIBUTES.VITALITY,
                }">
              </app-hero-stat-item>
            }
          </div>

          <div class="mana-cost g-flex g-flex--align-center">
            <img
              src="/assets/images/ui/icons/attributes/MANA_COST.png"
              alt="item image" />

            <div class="color-mana text-shadow">
              {{ item.meta.manaCost }}
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>

<app-scratch
  class="item-scratch"
  [class.item-scratch--compact]="isCompactView"
  backgroundType="huge">
</app-scratch>
