import { Injectable } from '@angular/core';
import { AttributesModel } from '@models/attributes.model';
import { FeeTokenDataModel } from '@models/fee-token-data.model';
import { HeroMetadataModel } from '@models/hero-metadata.model';
import { HeroModel } from '@models/hero.model';
import { NftModel } from '@models/nft.model';
import { StatsModel } from '@models/stats.model';
import { TokenMetadataModel } from '@models/token-metadata.model';
import { SubgraphService } from '@services/graph/subgraph.service';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeroDataService {
  constructor(
    private subgraphService: SubgraphService,
    private logger: NGXLogger,
  ) {}

  collectHeroModel$(hero: NftModel, chainId: number): Observable<HeroModel> {
    return this.subgraphService.hero$(hero.address, hero.id).pipe(
      switchMap(data => {
        return forkJoin([
          of(data),
          data.reinforcementHelper !== null
            ? this.collectHeroModel$(
                new NftModel(data.reinforcementHelper?.meta?.id ?? 'unknown', data.reinforcementHelper?.heroId ?? -1),
                chainId,
              )
            : of(null),
        ]);
      }),
      map(([data, helper]) => {
        return new HeroModel(
          new HeroMetadataModel(
            data.meta.id,
            data.meta.name,
            data.meta.uri,
            data.meta.heroClass,
            new FeeTokenDataModel(
              new TokenMetadataModel(
                data.meta.feeToken.token.id,
                data.meta.feeToken.token.name,
                data.meta.feeToken.token.decimals,
              ),
              BigInt(data.meta.feeToken.amount),
            ),
          ),
          data.uniqName,
          hero.id,
          new AttributesModel(data.attributes.map(attr => BigInt(attr))),
          new StatsModel(
            data.stats.level,
            data.stats.experience,
            data.stats.life,
            data.stats.mana,
            data.stats.lifeChances,
          ),
          data.stats.experience,
          data.nextLevelExperienceRequire,
          data.previousLevelExperienceRequire,
          data.staked,
          helper,
        );
      }),
    );
  }
}
