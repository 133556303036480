import { Injectable } from '@angular/core';
import { HeroEntity } from '@generated/gql';
import { ACHIEVEMENT_TYPE, AchievementModel } from '@models/achievement.model';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@services/storage.service';
import { TetuGame } from '@shared/constants/addresses/TetuGame';
import { getAllHeroCustomDataConstants, getCustomHeroDataByByte32 } from '@shared/constants/custom-data.constant';

@Injectable({
  providedIn: 'root',
})
export class AchievementsService {
  ACHIEVEMENTS_ARRAY: AchievementModel[] = [];

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(() => {
      this.ACHIEVEMENTS_ARRAY = this.getAchievementsArray();
    });
  }

  getStorageKey(hero: HeroEntity) {
    if (hero) {
      const STORAGE_ACHIEVEMENTS_BASE = 'SACRA_STORAGE_ACHIEVEMENTS';
      return `${STORAGE_ACHIEVEMENTS_BASE}_${hero.meta.id}_${hero.heroId}`;
    } else {
      return 'WRONG_KEY';
    }
  }

  getNewAchievements(storageService: StorageService, hero: HeroEntity): AchievementModel[] {
    const storageKey = this.getStorageKey(hero);
    // need to compare what is stored in the storage and what is in the hero entity, return an array and show it one by one
    let achievementsStored: AchievementModel[] = [];
    if (storageService.get(storageKey)) {
      achievementsStored = JSON.parse(storageService.get(storageKey));
    }

    const achievements = this.mapCustomHeroDataToAchievements(hero);

    const newAchievements: AchievementModel[] = [];

    for (const curr of achievements) {
      const a = achievementsStored.filter(a => a.id === curr.id)[0];
      if (!a) {
        newAchievements.push(curr);
      }
    }
    const achievementsToStore = achievements.map(it => {
      const isUnread = achievementsStored.filter(a => a.id === it.id)[0]?.isUnread ?? false;
      return new AchievementModel({
        ...it,
        isUnread,
      });
    });

    storageService.set(storageKey, JSON.stringify(achievementsToStore));

    return newAchievements;
  }

  mapCustomHeroDataToAchievements(hero: HeroEntity) {
    const heroData = getCustomHeroDataByByte32();

    const result: AchievementModel[] = [];

    for (const d of hero.customData) {
      const dataName = heroData.get(d.dataIndex) ?? 'unknown';
      const a = this.ACHIEVEMENTS_ARRAY.filter(a => a.id === dataName && Number(d.value) >= a.minValue)[0];
      if (a) {
        result.push(a);
      }
    }

    return result;
  }

  getTotalAchievementsNumber() {
    return this.ACHIEVEMENTS_ARRAY.length;
  }

  checkAchievements() {
    const allData = getAllHeroCustomDataConstants();
    for (const a of this.ACHIEVEMENTS_ARRAY) {
      if (!allData.includes(a.id)) {
        console.error('Achievement not found in custom data:', a.id);
      }
    }
  }

  achievementNode(
    id: string,
    type: ACHIEVEMENT_TYPE,
    minValue: number,
    title: string,
    description: string,
  ): AchievementModel {
    return {
      id,
      type,
      minValue,
      title,
      description,
    };
  }

  getAchievementsArray(): AchievementModel[] {
    return [
      this.achievementNode(
        'SHEEPS_126',
        ACHIEVEMENT_TYPE.GOOD,
        1,
        this.translateService.instant('achievements.SHEEPS_126.title'),
        this.translateService.instant('achievements.SHEEPS_126.description'),
      ),
      this.achievementNode(
        'MONSTER_' + TetuGame.MONSTERS.BAD_BEAR_10,
        ACHIEVEMENT_TYPE.GOOD,
        1,
        this.translateService.instant('achievements.MONSTER_1100037.title'),
        this.translateService.instant('achievements.MONSTER_1100037.description'),
      ),
      this.achievementNode(
        'LIONHEART_144',
        ACHIEVEMENT_TYPE.GOOD,
        1,
        this.translateService.instant('achievements.LIONHEART_144.title'),
        this.translateService.instant('achievements.LIONHEART_144.description'),
      ),
      this.achievementNode(
        'BELAV',
        ACHIEVEMENT_TYPE.GOOD,
        20,
        this.translateService.instant('achievements.BELAV.title'),
        this.translateService.instant('achievements.BELAV.description'),
      ),
      this.achievementNode(
        'BANDITS_108',
        ACHIEVEMENT_TYPE.GOOD,
        1,
        this.translateService.instant('achievements.BANDITS_108.title'),
        this.translateService.instant('achievements.BANDITS_108.description'),
      ),
      this.achievementNode(
        'ZUCCI_143',
        ACHIEVEMENT_TYPE.EVIL,
        1,
        this.translateService.instant('achievements.ZUCCI_143.title'),
        this.translateService.instant('achievements.ZUCCI_143.description'),
      ),
      this.achievementNode(
        'PEDO_126',
        ACHIEVEMENT_TYPE.EVIL,
        1,
        this.translateService.instant('achievements.PEDO_126.title'),
        this.translateService.instant('achievements.PEDO_126.description'),
      ),
      this.achievementNode(
        'NUDE_120',
        ACHIEVEMENT_TYPE.EVIL,
        1,
        this.translateService.instant('achievements.NUDE_120.title'),
        this.translateService.instant('achievements.NUDE_120.description'),
      ),
      this.achievementNode(
        'JERK_120',
        ACHIEVEMENT_TYPE.EVIL,
        1,
        this.translateService.instant('achievements.JERK_120.title'),
        this.translateService.instant('achievements.JERK_120.description'),
      ),
      this.achievementNode(
        'DEX_107',
        ACHIEVEMENT_TYPE.EVIL,
        1,
        this.translateService.instant('achievements.DEX_107.title'),
        this.translateService.instant('achievements.DEX_107.description'),
      ),
      this.achievementNode(
        'SWORD_146',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        1,
        this.translateService.instant('achievements.SWORD_146.title'),
        this.translateService.instant('achievements.SWORD_146.description'),
      ),
      this.achievementNode(
        'DRUNK_112',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        1,
        this.translateService.instant('achievements.DRUNK_112.title'),
        this.translateService.instant('achievements.DRUNK_112.description'),
      ),
      this.achievementNode(
        'DEATH_COUNT',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        1,
        this.translateService.instant('achievements.DEATH_COUNT.title'),
        this.translateService.instant('achievements.DEATH_COUNT.description'),
      ),
      this.achievementNode(
        'DED_138',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        1,
        this.translateService.instant('achievements.DED_138.title'),
        this.translateService.instant('achievements.DED_138.description'),
      ),
      this.achievementNode(
        'SEX_115',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        1,
        this.translateService.instant('achievements.SEX_115.title'),
        this.translateService.instant('achievements.SEX_115.description'),
      ),
      this.achievementNode(
        'BRU_102',
        ACHIEVEMENT_TYPE.STUPID,
        1,
        this.translateService.instant('achievements.BRU_102.title'),
        this.translateService.instant('achievements.BRU_102.description'),
      ),
      this.achievementNode(
        'HANGMANS',
        ACHIEVEMENT_TYPE.STUPID,
        1,
        this.translateService.instant('achievements.HANGMANS.title'),
        this.translateService.instant('achievements.HANGMANS.description'),
      ),
      this.achievementNode(
        'REFLECT_127',
        ACHIEVEMENT_TYPE.STUPID,
        1,
        this.translateService.instant('achievements.REFLECT_127.title'),
        this.translateService.instant('achievements.REFLECT_127.description'),
      ),
      this.achievementNode(
        'DOG_125',
        ACHIEVEMENT_TYPE.STUPID,
        1,
        this.translateService.instant('achievements.DOG_125.title'),
        this.translateService.instant('achievements.DOG_125.description'),
      ),
      this.achievementNode(
        'POOP_116',
        ACHIEVEMENT_TYPE.STUPID,
        1,
        this.translateService.instant('achievements.POOP_116.title'),
        this.translateService.instant('achievements.POOP_116.description'),
      ),
      this.achievementNode(
        'GROB_104',
        ACHIEVEMENT_TYPE.GOAL,
        1,
        this.translateService.instant('achievements.GROB_104.title'),
        this.translateService.instant('achievements.GROB_104.description'),
      ),
      this.achievementNode(
        'MONSTER_' + TetuGame.MONSTERS.MIDNIGHTER_2,
        ACHIEVEMENT_TYPE.GOAL,
        1,
        this.translateService.instant('achievements.MONSTER_1020014.title'),
        this.translateService.instant('achievements.MONSTER_1020014.description'),
      ),
      this.achievementNode(
        'PEDANT_140',
        ACHIEVEMENT_TYPE.GOAL,
        100,
        this.translateService.instant('achievements.PEDANT_140.title'),
        this.translateService.instant('achievements.PEDANT_140.description'),
      ),
      this.achievementNode(
        'STORY_110',
        ACHIEVEMENT_TYPE.GOAL,
        10,
        this.translateService.instant('achievements.STORY_110.title'),
        this.translateService.instant('achievements.STORY_110.description'),
      ),
      this.achievementNode(
        'PLAGUE_230',
        ACHIEVEMENT_TYPE.GOOD,
        2,
        this.translateService.instant('achievements.PLAGUE_230.title'),
        this.translateService.instant('achievements.PLAGUE_230.description'),
      ),
      this.achievementNode(
        'KNUD',
        ACHIEVEMENT_TYPE.GOOD,
        10,
        this.translateService.instant('achievements.KNUD.title'),
        this.translateService.instant('achievements.KNUD.description'),
      ),
      this.achievementNode(
        'CRY_216',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.CRY_216.title'),
        this.translateService.instant('achievements.CRY_216.description'),
      ),
      this.achievementNode(
        'PHIL_228',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.PHIL_228.title'),
        this.translateService.instant('achievements.PHIL_228.description'),
      ),
      this.achievementNode(
        'MILL_238',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.MILL_238.title'),
        this.translateService.instant('achievements.MILL_238.description'),
      ),
      this.achievementNode(
        'TRAITOR_206',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.TRAITOR_206.title'),
        this.translateService.instant('achievements.TRAITOR_206.description'),
      ),
      this.achievementNode(
        'CANNI_218',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.CANNI_218.title'),
        this.translateService.instant('achievements.CANNI_218.description'),
      ),
      this.achievementNode(
        'FOX_209',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.FOX_209.title'),
        this.translateService.instant('achievements.FOX_209.description'),
      ),
      this.achievementNode(
        'HOBO_207',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.HOBO_207.title'),
        this.translateService.instant('achievements.HOBO_207.description'),
      ),
      this.achievementNode(
        'TWO_229',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.TWO_229.title'),
        this.translateService.instant('achievements.TWO_229.description'),
      ),
      this.achievementNode(
        'DESERTER1',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.DESERTER1.title'),
        this.translateService.instant('achievements.DESERTER1.description'),
      ),
      this.achievementNode(
        'STORY_210',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        10,
        this.translateService.instant('achievements.STORY_210.title'),
        this.translateService.instant('achievements.STORY_210.description'),
      ),
      this.achievementNode(
        'BOAR_225',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.BOAR_225.title'),
        this.translateService.instant('achievements.BOAR_225.description'),
      ),
      this.achievementNode(
        'STORY_205',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        1,
        this.translateService.instant('achievements.STORY_205.title'),
        this.translateService.instant('achievements.STORY_205.description'),
      ),
      this.achievementNode(
        'SHIT_215',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        20,
        this.translateService.instant('achievements.SHIT_215.title'),
        this.translateService.instant('achievements.SHIT_215.description'),
      ),
      this.achievementNode(
        'SHIT_215',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.SHIT_215_2.title'),
        this.translateService.instant('achievements.SHIT_215_2.description'),
      ),
      this.achievementNode(
        'MONSTER_2030016',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.MONSTER_2030016.title'),
        this.translateService.instant('achievements.MONSTER_2030016.description'),
      ),
      this.achievementNode(
        'HAL_203',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.HAL_203.title'),
        this.translateService.instant('achievements.HAL_203.description'),
      ),
      this.achievementNode(
        'SEX_214',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.SEX_214.title'),
        this.translateService.instant('achievements.SEX_214.description'),
      ),
      this.achievementNode(
        'BOOZE_215',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.BOOZE_215.title'),
        this.translateService.instant('achievements.BOOZE_215.description'),
      ),
      this.achievementNode(
        'GIRL_217',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.GIRL_217.title'),
        this.translateService.instant('achievements.GIRL_217.description'),
      ),
      this.achievementNode(
        'MASS_204',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.MASS_204.title'),
        this.translateService.instant('achievements.MASS_204.description'),
      ),
      this.achievementNode(
        'MASS_204',
        ACHIEVEMENT_TYPE.GOAL,
        2,
        this.translateService.instant('achievements.MASS_204_2.title'),
        this.translateService.instant('achievements.MASS_204_2.description'),
      ),
      this.achievementNode(
        'EVIL_227',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.EVIL_227.title'),
        this.translateService.instant('achievements.EVIL_227.description'),
      ),
      this.achievementNode(
        'PIG_HATER',
        ACHIEVEMENT_TYPE.GOAL,
        5,
        this.translateService.instant('achievements.PIG_HATER.title'),
        this.translateService.instant('achievements.PIG_HATER.description'),
      ),
      this.achievementNode(
        'KIDS_309',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.KIDS_309.title'),
        this.translateService.instant('achievements.KIDS_309.description'),
      ),
      this.achievementNode(
        'SOLO_322',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.SOLO_322.title'),
        this.translateService.instant('achievements.SOLO_322.description'),
      ),
      this.achievementNode(
        'MONSTER_3100013',
        ACHIEVEMENT_TYPE.GOOD,
        1,
        this.translateService.instant('achievements.MONSTER_3100013.title'),
        this.translateService.instant('achievements.MONSTER_3100013.description'),
      ),
      this.achievementNode(
        'LIVES_321',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.LIVES_321.title'),
        this.translateService.instant('achievements.LIVES_321.description'),
      ),
      this.achievementNode(
        'RESCUER',
        ACHIEVEMENT_TYPE.GOOD,
        5,
        this.translateService.instant('achievements.RESCUER.title'),
        this.translateService.instant('achievements.RESCUER.description'),
      ),
      this.achievementNode(
        'BETR_302',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.BETR_302.title'),
        this.translateService.instant('achievements.BETR_302.description'),
      ),
      this.achievementNode(
        'FLOWER_308',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.FLOWER_308.title'),
        this.translateService.instant('achievements.FLOWER_308.description'),
      ),
      this.achievementNode(
        'CANIBAL',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.CANIBAL.title'),
        this.translateService.instant('achievements.CANIBAL.description'),
      ),
      this.achievementNode(
        'PARTY_328',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.PARTY_328.title'),
        this.translateService.instant('achievements.PARTY_328.description'),
      ),
      this.achievementNode(
        'CHASER',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.CHASER.title'),
        this.translateService.instant('achievements.CHASER.description'),
      ),
      this.achievementNode(
        'SPID_317',
        ACHIEVEMENT_TYPE.EVIL,
        0,
        this.translateService.instant('achievements.SPID_317.title'),
        this.translateService.instant('achievements.SPID_317.description'),
      ),
      this.achievementNode(
        'GHOST_307',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.GHOST_307.title'),
        this.translateService.instant('achievements.GHOST_307.description'),
      ),
      this.achievementNode(
        'TRAP',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        10,
        this.translateService.instant('achievements.TRAP.title'),
        this.translateService.instant('achievements.TRAP.description'),
      ),
      this.achievementNode(
        'STORY_306',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        10,
        this.translateService.instant('achievements.STORY_306.title'),
        this.translateService.instant('achievements.STORY_306.description'),
      ),
      this.achievementNode(
        'MIMIC_315',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.MIMIC_315.title'),
        this.translateService.instant('achievements.MIMIC_315.description'),
      ),
      this.achievementNode(
        'PEDO_301',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.PEDO_301.title'),
        this.translateService.instant('achievements.PEDO_301.description'),
      ),
      this.achievementNode(
        'VICTORINA',
        ACHIEVEMENT_TYPE.STUPID,
        10,
        this.translateService.instant('achievements.VICTORINA.title'),
        this.translateService.instant('achievements.VICTORINA.description'),
      ),
      this.achievementNode(
        'CONS_24',
        ACHIEVEMENT_TYPE.STUPID,
        1,
        this.translateService.instant('achievements.CONS_24.title'),
        this.translateService.instant('achievements.CONS_24.description'),
      ),
      this.achievementNode(
        'NIG_328',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.NIG_328.title'),
        this.translateService.instant('achievements.NIG_328.description'),
      ),
      this.achievementNode(
        'DRUNK_326',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.DRUNK_326.title'),
        this.translateService.instant('achievements.DRUNK_326.description'),
      ),
      this.achievementNode(
        'GUTTERS',
        ACHIEVEMENT_TYPE.GOAL,
        2,
        this.translateService.instant('achievements.GUTTERS.title'),
        this.translateService.instant('achievements.GUTTERS.description'),
      ),
      this.achievementNode(
        'RUN_323',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.RUN_323.title'),
        this.translateService.instant('achievements.RUN_323.description'),
      ),
      this.achievementNode(
        'CHAMP_306',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.CHAMP_306.title'),
        this.translateService.instant('achievements.CHAMP_306.description'),
      ),
      this.achievementNode(
        'RUN_306',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.RUN_306.title'),
        this.translateService.instant('achievements.RUN_306.description'),
      ),
      this.achievementNode(
        'THIEF_323',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.THIEF_323.title'),
        this.translateService.instant('achievements.THIEF_323.description'),
      ),
      this.achievementNode(
        'ASSAMBLAGE_424',
        ACHIEVEMENT_TYPE.GOOD,
        10,
        this.translateService.instant('achievements.ASSAMBLAGE_424.title'),
        this.translateService.instant('achievements.ASSAMBLAGE_424.description'),
      ),
      this.achievementNode(
        'MONSTER_4100016',
        ACHIEVEMENT_TYPE.GOOD,
        1,
        this.translateService.instant('achievements.MONSTER_4100016.title'),
        this.translateService.instant('achievements.MONSTER_4100016.description'),
      ),
      this.achievementNode(
        'BROTHER_402',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.BROTHER_402.title'),
        this.translateService.instant('achievements.BROTHER_402.description'),
      ),
      this.achievementNode(
        'RUNE_430',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.RUNE_430.title'),
        this.translateService.instant('achievements.RUNE_430.description'),
      ),
      this.achievementNode(
        'GIRL_421',
        ACHIEVEMENT_TYPE.GOOD,
        0,
        this.translateService.instant('achievements.GIRL_421.title'),
        this.translateService.instant('achievements.GIRL_421.description'),
      ),
      this.achievementNode(
        'POSTAMAN',
        ACHIEVEMENT_TYPE.EVIL,
        73,
        this.translateService.instant('achievements.POSTAMAN.title'),
        this.translateService.instant('achievements.POSTAMAN.description'),
      ),
      this.achievementNode(
        'CONS_25',
        ACHIEVEMENT_TYPE.EVIL,
        10,
        this.translateService.instant('achievements.CONS_25.title'),
        this.translateService.instant('achievements.CONS_25.description'),
      ),
      this.achievementNode(
        'MONSTER_4100017',
        ACHIEVEMENT_TYPE.EVIL,
        1,
        this.translateService.instant('achievements.MONSTER_4100017.title'),
        this.translateService.instant('achievements.MONSTER_4100017.description'),
      ),
      this.achievementNode(
        'STORY_425',
        ACHIEVEMENT_TYPE.EVIL,
        1,
        this.translateService.instant('achievements.STORY_425.title'),
        this.translateService.instant('achievements.STORY_425.description'),
      ),
      this.achievementNode(
        'BARBRAPE',
        ACHIEVEMENT_TYPE.EVIL,
        5,
        this.translateService.instant('achievements.BARBRAPE.title'),
        this.translateService.instant('achievements.BARBRAPE.description'),
      ),
      this.achievementNode(
        'VASSAl_435',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.VASSAl_435.title'),
        this.translateService.instant('achievements.VASSAl_435.description'),
      ),
      this.achievementNode(
        'MAGEDOWN',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        5,
        this.translateService.instant('achievements.MAGEDOWN.title'),
        this.translateService.instant('achievements.MAGEDOWN.description'),
      ),
      this.achievementNode(
        'SPEED_432',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.SPEED_432.title'),
        this.translateService.instant('achievements.SPEED_432.description'),
      ),
      this.achievementNode(
        'SLEEP_422',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.SLEEP_422.title'),
        this.translateService.instant('achievements.SLEEP_422.description'),
      ),
      this.achievementNode(
        'GIRL_419',
        ACHIEVEMENT_TYPE.EXPERIENCE,
        0,
        this.translateService.instant('achievements.GIRL_419.title'),
        this.translateService.instant('achievements.GIRL_419.description'),
      ),
      this.achievementNode(
        'PSYMAGE',
        ACHIEVEMENT_TYPE.STUPID,
        5,
        this.translateService.instant('achievements.PSYMAGE.title'),
        this.translateService.instant('achievements.PSYMAGE.description'),
      ),
      this.achievementNode(
        'FUCK_418',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.FUCK_418.title'),
        this.translateService.instant('achievements.FUCK_418.description'),
      ),
      this.achievementNode(
        'BOLT_417',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.BOLT_417.title'),
        this.translateService.instant('achievements.BOLT_417.description'),
      ),
      this.achievementNode(
        'MAP_432',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.MAP_432.title'),
        this.translateService.instant('achievements.MAP_432.description'),
      ),
      this.achievementNode(
        'PEDO_409',
        ACHIEVEMENT_TYPE.STUPID,
        100,
        this.translateService.instant('achievements.PEDO_409.title'),
        this.translateService.instant('achievements.PEDO_409.description'),
      ),
      this.achievementNode(
        'KEY_407',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.KEY_407.title'),
        this.translateService.instant('achievements.KEY_407.description'),
      ),
      this.achievementNode(
        'TURT_423',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.TURT_423.title'),
        this.translateService.instant('achievements.TURT_423.description'),
      ),
      this.achievementNode(
        'FIGHT_438',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.FIGHT_438.title'),
        this.translateService.instant('achievements.FIGHT_438.description'),
      ),
      this.achievementNode(
        'MONSTER_4100015',
        ACHIEVEMENT_TYPE.GOAL,
        1,
        this.translateService.instant('achievements.MONSTER_4100015.title'),
        this.translateService.instant('achievements.MONSTER_4100015.description'),
      ),
      this.achievementNode(
        'BOLT_417',
        ACHIEVEMENT_TYPE.STUPID,
        0,
        this.translateService.instant('achievements.BOLT_417.title'),
        this.translateService.instant('achievements.BOLT_417.description'),
      ),
      this.achievementNode(
        'BOX_403',
        ACHIEVEMENT_TYPE.GOAL,
        0,
        this.translateService.instant('achievements.BOX_403.title'),
        this.translateService.instant('achievements.BOX_403.description'),
      ),
    ];
  }
}
