import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { HeroEntity } from '@generated/gql';
import { AchievementModel } from '@models/achievement.model';
import { TranslateModule } from '@ngx-translate/core';
import { StorageService } from '@services/storage.service';
import { AchievementComponent } from '@shared/components/achievement/achievement.component';
import { BarComponent } from '@shared/components/bar/bar.component';
import { TitleComponent } from '@shared/components/title/title.component';

import { AchievementsService } from './achievements.service';

@Component({
  selector: 'app-achievements',
  standalone: true,
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column',
  },
  imports: [AchievementComponent, BarComponent, TitleComponent, TranslateModule],
})
export class AchievementsComponent implements OnInit {
  @Input() hero!: HeroEntity | null;
  @Input() account!: string;

  achievements: AchievementModel[] = [];
  totalAchievements = 30;
  hasUnread = false;

  constructor(
    private storageService: StorageService,
    private achievementsService: AchievementsService,
  ) {}

  ngOnInit(): void {
    let achievementsStored: AchievementModel[] = [];
    if (
      this.hero &&
      this.isAccountHero() &&
      this.storageService.get(this.achievementsService.getStorageKey(this.hero))
    ) {
      achievementsStored = JSON.parse(this.storageService.get(this.achievementsService.getStorageKey(this.hero)));
    }

    this.achievementsService.checkAchievements();

    this.totalAchievements = this.achievementsService.getTotalAchievementsNumber();

    if (this.hero) {
      this.achievements = this.achievementsService.mapCustomHeroDataToAchievements(this.hero);

      this.achievements = this.achievements.map(it => {
        const isUnread = achievementsStored.filter(a => a.id === it.id)[0]?.isUnread ?? false;
        return new AchievementModel({
          ...it,
          isUnread,
        });
      });

      this.hasUnread = this.achievements.some(it => it.isUnread);

      if (this.isAccountHero()) {
        this.storageService.set(this.achievementsService.getStorageKey(this.hero), JSON.stringify(this.achievements));
      }
    }
  }

  isAccountHero() {
    return !!(this.hero && this.account && this.hero.owner.id.toLowerCase() === this.account.toLowerCase());
  }
}
