import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';

@Component({
  selector: 'app-life-chances',
  standalone: true,
  templateUrl: './life-chances.component.html',
  styleUrls: ['./life-chances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeChancesComponent {
  @Input() lifeChance: number = 0;

  constructor(
    private dialog: Dialog,
    private translateService: TranslateService,
  ) {}

  @HostListener('click')
  onClick() {
    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: this.translateService.instant('life-chances.title'),
        infoDesc: this.translateService.instant('life-chances.desc'),
      },
    });
  }
}
