import { CoreAddressesModel } from '@models/core-addresses.model';
import { PoolAddressesModel } from '@models/pool-addresses.model';
import { TokenInfo } from '@shared/constants/addresses/addresses.constant';

export const SONIC_CORE = new CoreAddressesModel(
  '0x01B3e21F1e997d87Af6c5Ae415B08B125729f40c', // controller
  '0x17E9BfA16f8c0c3eE37f724a16888eBF20822AC8', // gameToken
  '0x57Ba8E9A70a577494429975369d3b05D0C8FFc8e', // minter
  '0xE32431f88EAf3F621c71dA6DF21c308285522dE7', // goc
  '0x339E717a75CCB0C01BE1eF30B364B32e9BD85d57', // reinforcementController
  '0x2d1Fe96FF8c86b857adBB7550F71005944C18F3f', // dungeonFactory
  '0xA020e1f8b413165bb9bAfAfec89A1316fcC22032', // heroController
  '0x13931b69c4CC338c2A76E4f0FE889Caa62ceF7FC', // itemController
  '0x40aA2e32DB075cEa9aA5d2e8ceB38B3FaD590f4E', // oracle
  '0x43bfC222536B75dB60102136271f53f59f3D2e73', // statController
  '0xcBDfb0F86266383B944eF13a89bF626381c16540', // storyController
  '0x45fD9cBA5ed6Fa0b7Aa20c3c0FD6dc4B78098722', // treasury
  '0xF0F7Fc52EcEa9F26A70096a7e3B385a4E29c16ff', // statReader
  '0x4D5aD91a230c7E7707B9Af3B5a1334A3da0B7d8F', // multicall
  '0x17E9BfA16f8c0c3eE37f724a16888eBF20822AC8', // magicToken
  '0x17E9BfA16f8c0c3eE37f724a16888eBF20822AC8', // strengthToken
  '0x17E9BfA16f8c0c3eE37f724a16888eBF20822AC8', // dexterityToken
  '0x8fF90eebE9672d6EC115EAfb8Ab90AF6300BE108', // pawnsop
  '', // PawnShopRouterFactory
  '0x000000000000000000000000000000000000000E', // network token
  '0x50D8F5E54Cf0658b5568C82cEA244D2fBd97173C', // sponsored hero
  '0x52CEba41Da235Af367bFC0b0cCd3314cb901bB5F', // relay
  '0xdBBb48332b64509A72de2Bafe4f783350edfE66c', // data storage
  '0x7625B3bddf7371a9C7F8C8B2FBDdc451f727bDA8', // user controller
  '0x000000000000000000000000000000000000000E', // item controller helper
  '0x000000000000000000000000000000000000000E', // guild controller
  '', // shelterController
  '', // shelterAuctionController
  '', // rewardsPool
);

export const SONIC_TOKEN_INFO = new Map<string, TokenInfo>([
  [SONIC_CORE.gameToken.toLowerCase(), { symbol: 'SACRA', decimals: 18 }],
  [SONIC_CORE.networkToken.toLowerCase(), { symbol: 'WFTM', decimals: 18 }],
]);

export const SONIC_TEST_FAUCET = '0xB3dC238C187a0931c81Fb3736b010517bfcE14C2';

export function getSonicFaucetByToken(token: string) {
  if (SONIC_CORE.gameToken.toLowerCase() === token.toLowerCase()) {
    return SONIC_TEST_FAUCET;
  } else {
    throw Error('No faucet for token ' + token);
  }
}

export const SONIC_ITEMS = {
  SACRA_CONS_1_ITEM: '0xC28078942aF7b97D2896D37C4c671f55A0cE9bB1',
  SACRA_CONS_2_ITEM: '0x1568241EE56F390cAe153604eF61f3ad3A7D2757',
  SACRA_CONS_3_ITEM: '0x4eDd813e584107F7d9c4e7f7F18ccFcF8980485D',
  SACRA_CONS_4_ITEM: '0xCa5582445869520BA10Bad813BC269673C7f181C',
  SACRA_CONS_17_ITEM: '0xE8ce3D53ea4B3514c9791F6dFfb6d774D9c8b909',
  SACRA_CONS_18_ITEM: '0x3DA2F9f3a95a2B440972f2647160A0293F7290aa',
  SACRA_CONS_19_ITEM: '0xbD564bfD11536cA4b118Ee853282a51416846221',
  SACRA_CONS_20_ITEM: '0xED7Abb9F3aEDfc15166e73a0c1B6b2177ed00a27',
  SACRA_CONS_21_ITEM: '0xb3e3a1335813944AdB1f2e17Cd3f9B5cB565825d',
  SACRA_CONS_27_ITEM: '0x8F3E8d91651F61321B75d57960CE2c26c4F5C0fd',
  SACRA_CONS_28_ITEM: '0x4d848Ffd1Cfc186249688C1Db01aE86Ca06566a3',
  SACRA_CONS_29_ITEM: '0x8E13EB48B38e809ea55ceb6A2D8C884fE3523fDe',
  SACRA_CONS_33_ITEM: '0xD9A3E625FAcB25E88F16b01D02713b90f779fa04',
  SACRA_CONS_34_ITEM: '0xF81EaAa74Ed733b1947264589a027155878940e8',
  SACRA_CONS_35_ITEM: '0xf14e285Ec67D75615d32D82feDAD1b79F8c1E29d',
  SACRA_HELM_1_ITEM: '0xe3Ef428dE995535eF5E10f94D99c30e11B9aeA9D',
  SACRA_HELM_6_ITEM: '0xE22ea06F6627aC99DaF26962b2576D642c0D42c2',
  SACRA_BODY_1_ITEM: '0x73147b125d1f9f84a5e82EC36F96D0b7068e8D26',
  SACRA_GLOVES_1_ITEM: '0xd4e170B96dc5d02c7549BC7753DC51F6246ED96f',
  SACRA_GLOVES_6_ITEM: '0xfda07fAFA530d8414C3B279577a9472D1880B50d',
  SACRA_BELT_1_ITEM: '0xB8B222a2D5da58109beD8C3F3A57f598A33723D5',
  SACRA_AMULET_1_ITEM: '0x3e9E4EfF50aFEe19D4887B479F3422D4bA277dd8',
  SACRA_AMULET_2_ITEM: '0x5bc6710b86f555B5AbC8D04Bb7B3846F72b34878',
  SACRA_AMULET_12_ITEM: '0xd60465805fb381Ba49bfB1AF9E0EfaF8F9cB657c',
  SACRA_RING_1_ITEM: '0xdE6bB16349352c8392fa1567709E7D631A166dAf',
  SACRA_RING_2_ITEM: '0xE2e09EEbD84a4c2Df2549056C922B07eEBDacd3f',
  SACRA_RING_9_ITEM: '0xe70734342EFc960c2337431bFcD3F7f00F98D91E',
  SACRA_OFF_1_ITEM: '0x470e9dbc35f5Ad2f086964a2EA809011dC2430cf',
  SACRA_OFF_2_ITEM: '0xad7f47d5C1a3A612cbcdc157F94d6efD5BfC15c0',
  SACRA_OFF_10_ITEM: '0xE6155f5dfB245c2713cFf7b218c9F6dD41b1AB31',
  SACRA_BOOTS_1_ITEM: '0x367c206E132a01A44FA28736eDd5e16E07294C73',
  SACRA_BOOTS_5_ITEM: '0x2c7121868047fd3110d1Cc68f373f9C4015f9485',
  SACRA_OHW_1_ITEM: '0xb7d36E528e3185a516b1344972936829A708B4A0',
  SACRA_OHW_2_ITEM: '0x0C2a1A8F799B058723fd646B9A6420E578b0f68F',
  SACRA_OHW_3_ITEM: '0xCc9dd2d2e18d50E9966ddE8Dca40aDE0AC1a73C9',
  SACRA_THW_1_ITEM: '0x2dA2d9AE8D26c27523d0E03095C6C6F9Ed671fcc',
  SACRA_THW_2_ITEM: '0x7Ff7d559cada2a285F6079c0D12375b22aad1C11',
  SACRA_FEAR_ITEM: '0x919F29FC257b0041791A068e4C5ba7B4FBbDC774',
  SACRA_DEMON_SPEED_ITEM: '0x23d7bb464597403aae92bC5eB6f482921289aAe7',
  SACRA_BRUTE_FORCE_ITEM: '0x596C7FE57bEbD5c94F0c35bAc0b000b0ed7c360F',
  SACRA_SAVAGE_CHARM_ITEM: '0x97f3FE117819131d35873b7598D3BE6b8393Af7d',
  SACRA_GOLDEN_EYE_ITEM: '0x89EAeFA36962C160FAc7BEb4A1593BaE73aF42EF',
  SACRA_SABBAH_POISON_ITEM: '0x04Cb0efd2771Ca83C535F380Bd9437531F6F2851',
  SACRA_SPIT_IN_FACE_ITEM: '0x1D276c3489E9F252f3830CF4f6E03A1336842894',
  SACRA_ONSLAUGHT_ITEM: '0xCC340a219a4f037c8c0C23EaBF99aA11e524d96c',
  SACRA_STRONG_BLOCK_ITEM: '0xb3F186257E3A4b4530805Ee1EA7476A9b683FB24',
  SACRA_BEEF_PATTY_ITEM: '0x4028950a561a13D51f1F14cAD466F6a6Ac520746',
  SACRA_HEAT_STRIKE_ITEM: '0xe729a40074df39eD46B8c650382344C316b6324a',
  SACRA_STATIC_DISCHARGE_ITEM: '0x1D610e3A1C12BA4e3388D0BB58B076edd9A9DB15',
  SACRA_CONS_5_ITEM: '0xbe1346FAe2A215Db02602196dfc6e1DCF2667429',
  SACRA_CONS_6_ITEM: '0xFC65172594c1Eef76EDcf9291d978E260A3DB9c7',
  SACRA_CONS_7_ITEM: '0xD67bEb9DB86Ed4b43AcAa5E25c956D98916Ac2b7',
  SACRA_CONS_8_ITEM: '0x0181Bf7BF0A5d8E66F2b2fe298F3Cf746164F75c',
  SACRA_CONS_22_ITEM: '0x4C5D3a3EfFC1FDE38742EDD5AB1A831bd82BB4Ce',
  SACRA_CONS_30_ITEM: '0x42e73e26e0Ac2fE765fD61f5b73Ee64DC7c237Ab',
  SACRA_HELM_2_ITEM: '0xd7f4cAF5bF12e4323eD5b02AB690a2a729b4CfB4',
  SACRA_HELM_7_ITEM: '0xAf5353C9Be39232A11d91B0588864cB8291F7d7C',
  SACRA_BODY_2_ITEM: '0xc62C377364F9E850C60F7cDF6ABB05D5B4FB1042',
  SACRA_BODY_6_ITEM: '0xA77aB90f32a94Cf5A26be67937a40Bd098972d3c',
  SACRA_GLOVES_2_ITEM: '0xba0F71374dd62117Ad6754006C38BaE12E42f046',
  SACRA_GLOVES_7_ITEM: '0x46c2797cd547a065772210b9F311b3A752009C0e',
  SACRA_BELT_2_ITEM: '0x4642463A7E14A6B4B36dCEB1905D3DdCe1f57A89',
  SACRA_AMULET_3_ITEM: '0x0f9EA1FC7CE32F2937841f1eb833fa2a4912f4c1',
  SACRA_AMULET_4_ITEM: '0xB9565f57a347fBA5a8b7de7daa218f25fA325488',
  SACRA_AMULET_9_ITEM: '0x49942a66144F7eFbc3F4E46B18987Eb9ea48EaFb',
  SACRA_RING_3_ITEM: '0x4507b87812F34d13107135D06Acb7120a1D39282',
  SACRA_RING_4_ITEM: '0xebE37A03FB34Fe5101587DD86a2138ECf4E0b75D',
  SACRA_RING_10_ITEM: '0x1844EC7619cC3b62B7AE3C5154A0a5124B3609dD',
  SACRA_OFF_3_ITEM: '0x7503A7B70261417c06987eFc237a4d06eF4657a5',
  SACRA_OFF_4_ITEM: '0xCFBFBe5Db7E5AE58b8622a7dAf1cFE4b5A39851F',
  SACRA_OFF_11_ITEM: '0xf363fDe972Aa07E1F2FB03576C11d44CFc7c0ca0',
  SACRA_BOOTS_2_ITEM: '0xb5F304781866A4Ff5fd779804b415D85bcBd8877',
  SACRA_OHW_4_ITEM: '0x67E5E847039b1636229397217244423E8266bFC0',
  SACRA_OHW_5_ITEM: '0x5e78aA9021569218983CC986F4B43508CebB57ea',
  SACRA_OHW_6_ITEM: '0xc5D6BCb903788c7e3838aE186AcB6EC9a2655ad7',
  SACRA_THW_3_ITEM: '0xc33E461e132462d57c1d9Cc35B9214Bb5E414094',
  SACRA_THW_4_ITEM: '0x7687C58BAcE9394392a7Dc5369b3fd34B94b9efa',
  SACRA_THW_5_ITEM: '0x3FcF66ff7c0aA112C99aa6026275B88e761ab4a4',
  SACRA_STEEL_SKIN_ITEM: '0xFEbDBC61A766ED2207927fB6E224427319206424',
  SACRA_LIFEDRAIN_ITEM: '0xe6630910D37A7E7488F95379B63E336CDdDefde5',
  SACRA_FROSTBITE_ITEM: '0xb329123ae3D844c52250Ab16596f4A37067E3eDa',
  SACRA_LIKE_A_PUSSY_ITEM: '0x8618c545535C77810b0167b0D73c9818A7B29410',
  SACRA_RAGE_ITEM: '0x8bE1fF3964236BD1FfEa62447F03c2579a3943B9',
  SACRA_DEAD_STRIKE_ITEM: '0x766F198ea3A75529E166D48b83e7127e0B91Ad41',
  SACRA_FIRM_HAND_ITEM: '0x6a3Dffe8953ab38cF0Bfa2744D6488bfcb57388f',
  SACRA_JUSTICE_ITEM: '0x2EEcB6dFD5378b6925C758012546C9A29598Ceb6',
  SACRA_STEP_BACK_ITEM: '0xae21Aa708279c0C5F6f0f3028843D3299C46Cb73',
  SACRA_PATIENCE_ITEM: '0x6eECB02146A96AEF1E12c0352bbC7249b6b734BE',
  SACRA_TIME_AND_EXPERIENCE_ITEM: '0x3050457096Cd9e2859F0A557Cb43e5C2Ab986C9d',
  SACRA_MELT_ITEM: '0x748571344A2F1e0D38079C2813f140dC025596e6',
  SACRA_ICE_SHARD_ITEM: '0x41efB6594900699B5d7BD3D327891045F713E532',
  SACRA_CONS_9_ITEM: '0x2D53Ae5Dd77e1d9fc95359c994Ef5A5AE4d65C9d',
  SACRA_CONS_10_ITEM: '0x3266B4A66D66915e170C062E7171b9dA0E4bb530',
  SACRA_CONS_11_ITEM: '0xA413678DB8E424aa418e7b28bF4469B64333d583',
  SACRA_CONS_12_ITEM: '0xbEA571E51449F4D442ace837309dB425bf6A0D60',
  SACRA_CONS_23_ITEM: '0x594B14C6d7f79601C5Cc946FB36A572B3EE62a66',
  SACRA_CONS_24_ITEM: '0x15542A27302913Bd463e5Ec73f08F6D6C4244ed7',
  SACRA_CONS_31_ITEM: '0x82fd648aA7D34D89bDe42300eD5d9210907aD5C4',
  SACRA_HELM_3_ITEM: '0xA6e61c198235599022b3f3563f8797D49Fa57D27',
  SACRA_HELM_8_ITEM: '0xFF1b3BfAb6e0F4B47665AeaFeac9E72B061C0F9f',
  SACRA_BODY_3_ITEM: '0x9B11e24fcdd6AA67A4C62470D09B143f01541Df1',
  SACRA_BODY_7_ITEM: '0x7Da5E96Cf325BE2b46BBa060A88C584841E3e276',
  SACRA_GLOVES_3_ITEM: '0x06EA82CfEb5e199b7b440201cb4ed9e3313f7C37',
  SACRA_GLOVES_8_ITEM: '0x74baebBFF827c346501CF5C93D9C45425a6f487A',
  SACRA_BELT_3_ITEM: '0x88D42C9753d715169B3E26546356a8d446076323',
  SACRA_AMULET_5_ITEM: '0x84621e82E3523f3a07521A9315a55Db670e2777f',
  SACRA_AMULET_6_ITEM: '0x6A08E537A14D8dC238B3c105F10e3133014fd439',
  SACRA_AMULET_10_ITEM: '0x0358cb2e780dd7cb99A0c9485AF278eaC0cEC0Bd',
  SACRA_AMULET_11_ITEM: '0xE250b6b0Bc75e7E956109AF9e682e980d5Ddd671',
  SACRA_RING_5_ITEM: '0x61fD64b2e9b907165Ea3cBe09fE5b00D8680d756',
  SACRA_RING_6_ITEM: '0x6D954b85D44aA2A801eaB07a20D6014F00e1273A',
  SACRA_OFF_5_ITEM: '0x46f5188a185020213B60D5f9773c3a46dc743375',
  SACRA_OFF_6_ITEM: '0xdCcDD5E9Ef89a14BdFCe37b885d5FD180050F9A8',
  SACRA_BOOTS_3_ITEM: '0xc3D0C596f6f4F71984800007F1e230e423360EfE',
  SACRA_BOOTS_6_ITEM: '0xc724eEDdbeD2c943cDA939f8cDD83750a2eDD887',
  SACRA_OHW_7_ITEM: '0x7C6F8925103b9e0A1d79B3BB58CACabC77Db5f5e',
  SACRA_OHW_8_ITEM: '0x5755cFAA0CeD764163ebB81Ec9432593892e6Ff3',
  SACRA_OHW_9_ITEM: '0x984f6fc1e4dAfbe105FE14dCC3006D0489f09445',
  SACRA_OHW_12_ITEM: '0x6D0B6C70F16Ff5E4E9AcaF61D9605db5193Ad093',
  SACRA_OHW_13_ITEM: '0x7F785c8Ee3986aAA8Cba4aA2e5527e10D6Aeb579',
  SACRA_OHW_14_ITEM: '0x44790aFf3Ad7EC5B252f92B75b9decd1659e5321',
  SACRA_THW_6_ITEM: '0xF2134F845C83B6335667fe16588C4b9318E79D5c',
  SACRA_THW_7_ITEM: '0xc419022b9b28E6a0afd15D06D45585F6EF566a93',
  SACRA_THW_10_ITEM: '0x5E8CB6815468a8767f4172A6B8161b5D1E9898d2',
  SACRA_PARALYZE_ITEM: '0x7E3351bC22A181189B1B5ADfc3217e4fcC422e10',
  SACRA_GUIDING_LIGHT_ITEM: '0x2224C23ACcfA49E7577532c35F776C73447dD51d',
  SACRA_GUT_PUNCH_ITEM: '0x17e74150C45F228b675DB6DE6C54Fba0bC338c99',
  SACRA_LAST_DANCE_ITEM: '0xe655C88Cef42fc431884c6f27306407Bbbd96a4A',
  SACRA_I_SURVIVE_ITEM: '0x3a9FeCb5b4DD2DB58B1fdfacdE32B96900eBC492',
  SACRA_ARMOR_OF_FAITH_ITEM: '0x116Bdd1948346b70ae52df933d2298fB81b9751F',
  SACRA_THUNDERBOLT_ITEM: '0xf64cea2ca538d13539bdAAA0E6ADe10AD810Dd13',
  SACRA_CONS_13_ITEM: '0xa4518242ddA1dd344063F8061124900c8e3C56A0',
  SACRA_CONS_14_ITEM: '0x0EaA3A451a06538bA924EA046709F193c4b803c9',
  SACRA_CONS_15_ITEM: '0xf32370ea7469019cA4700d95b9E4Bc40497dBB1B',
  SACRA_CONS_16_ITEM: '0xb5b8D2753Dcc0b7CC752C0258F2698a0A3bd0634',
  SACRA_CONS_25_ITEM: '0x531a93E0dA3e53A98Cbc6276A95fBC778b60169d',
  SACRA_CONS_26_ITEM: '0xC053C74B5e1551aa6eabAf0E6DC55BB06aFa5DFe',
  SACRA_CONS_32_ITEM: '0xd6e48eEafd93661D7FAFf9ef9099a6C9c9D34538',
  SACRA_HELM_4_ITEM: '0x70b20ba156fe4BBCBCF5c50d8A4F13803107B9Ca',
  SACRA_HELM_5_ITEM: '0x5e1cB9ecA7533059a57c90Ed146c25A5851e4f22',
  SACRA_BODY_4_ITEM: '0x703Cf7fA40668086E20F7BE492D5eb81174c6d99',
  SACRA_BODY_5_ITEM: '0x0Bb292C8d84C17d921A9A5A21B6a0cC943ac4965',
  SACRA_GLOVES_4_ITEM: '0xfaB08b3DD794829133Cb7bb60826DFf5A6A99A93',
  SACRA_GLOVES_5_ITEM: '0x067E05aC1e158ab29e49F224Ce6e527222dAcDA0',
  SACRA_BELT_4_ITEM: '0x48Acc3120d69c3361C7E65C3CeCE8d87124e7D9C',
  SACRA_AMULET_7_ITEM: '0xC14b53f31612B42167092833321fDfB10773e187',
  SACRA_AMULET_8_ITEM: '0xC62e303A6069B7c518528A8C3DFF271D910987e1',
  SACRA_RING_7_ITEM: '0x998bbf29463B37AC8b5Dfa236e601270FeECd6A1',
  SACRA_RING_8_ITEM: '0xdAD2A2755E6D57A099a5832D749bD803EC97E285',
  SACRA_OFF_7_ITEM: '0xe9c52d0b0CFd0F50B0Aa66C3157Ff80904287085',
  SACRA_OFF_8_ITEM: '0x0B36b7F4FC8011381910A8231202A0bFa1b08e10',
  SACRA_OFF_9_ITEM: '0x547f30EE812007Af1A5dccc9C49ac000362863E7',
  SACRA_OFF_12_ITEM: '0x773e77536fb69ba7C2345f7b531369E612DcCCF3',
  SACRA_OFF_13_ITEM: '0x94013ba19D1c2804148eB1fCc3537a6a70db6346',
  SACRA_BOOTS_4_ITEM: '0x6EA572f0A512cF9c468AB7d930Ba8CD242267365',
  SACRA_BOOTS_7_ITEM: '0x2700413e9f8Fb57A44a0A23c669E99b6256F8321',
  SACRA_OHW_10_ITEM: '0xDe9E218bB26e0d266Aa8dE2E45f36A66709596DB',
  SACRA_OHW_11_ITEM: '0x11dFCA6D84256f22927B5610358Ed6fddd2c5002',
  SACRA_THW_8_ITEM: '0xE0C8bfB53bD9f06Aa19CF37e7c39E478d7EED0F2',
  SACRA_THW_9_ITEM: '0x4F761f6e84936E0DE547CaCBa23626062227d47D',
  SACRA_THW_11_ITEM: '0x688FCA4829f6d849e55B633ADf33115dd740667D',
  SACRA_REFLECTION_ITEM: '0x711aA51CDbacc0c51309eaEcb8df75bB95267094',
  SACRA_SCARLET_BRANCH_ADEPT_ITEM: '0x2E7aEB4cF689F846cba152b90a40ae34EA4D1f7B',
  SACRA_ANATHEMA_ITEM: '0x9feaaC6CdA860D4F1f6d4a8bf6F2535cB8841b04',
  SACRA_GIANTS_BLOOD_ITEM: '0x88d2A75E49a2031b0106FBB0e9dA813622C8DE35',
  SACRA_BACKSTAB_ITEM: '0x6B66894008ac71ed93570Ae686f9925953F0d710',
  SACRA_VETERAN_CONFIDENCE_ITEM: '0xa7c3138233B69492b00E7f87d90b541fc55105b5',
  SACRA_LIVE_FORTRESS_ITEM: '0x333D4a15173fa0EfF2abA3FE18c89E22840E0745',
  SACRA_COLD_SHOULDER_ITEM: '0x15d6242ff11E0562d9Bb6029978F0DDDB680E811',
  SACRA_ERASE_ITEM: '0x09575A9aF06bF641CFFd33414cc0435305Cd96e1',
};

export const SONIC_POOLS = new PoolAddressesModel(
  '0x000000000000000000000000000000000000000E', // ethSacraPool
);
