/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  UserController,
  UserControllerInterface,
} from "../UserController";

const _abi = [
  {
    inputs: [],
    name: "EmptyNameNotAllowed",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDungeonFactory",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotHeroController",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorOnlyEoa",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "openedNgLevel",
        type: "uint8",
      },
    ],
    name: "FameHallHeroAlreadyRegistered",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NameTaken",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "msgSender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "lootBoxKind",
        type: "uint256",
      },
    ],
    name: "NoAvailableLootBox",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "NotGovernance",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "TooBigName",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "chance",
        type: "uint32",
      },
    ],
    name: "TooHighChance",
    type: "error",
  },
  {
    inputs: [],
    name: "TooLongUrl",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongSymbolsInTheName",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "daily",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "weekly",
        type: "bool",
      },
    ],
    name: "ActivityCompleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "heroOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "openedNgLevel",
        type: "uint8",
      },
    ],
    name: "FameHallHeroRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "lootBoxKind",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "mintItems",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint32[]",
        name: "mintItemsChances",
        type: "uint32[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxDropItems",
        type: "uint256",
      },
    ],
    name: "LootBoxConfigChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "lootBoxKind",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "itemTokens",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "itemTokenIds",
        type: "uint256[]",
      },
    ],
    name: "LootBoxOpened",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "feeRenaming",
        type: "uint256",
      },
    ],
    name: "SetFeeRenaming",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "avatar",
        type: "string",
      },
    ],
    name: "SetUserAvatar",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "SetUserName",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "counterLootBoxes",
    outputs: [
      {
        internalType: "uint32",
        name: "dailyCounter",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "weeklyCounter",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "openedNgLevel",
        type: "uint8",
      },
    ],
    name: "fameHallHero",
    outputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "heroOwner",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "tsOpen",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeRenaming",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "lootBoxKind",
        type: "uint256",
      },
    ],
    name: "lootBoxConfig",
    outputs: [
      {
        internalType: "address[]",
        name: "mintItems",
        type: "address[]",
      },
      {
        internalType: "uint32[]",
        name: "mintItemsChances",
        type: "uint32[]",
      },
      {
        internalType: "uint256",
        name: "maxDropItems",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "nameToUserAccount",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "lootBoxKind",
        type: "uint256",
      },
    ],
    name: "openLootBox",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "openedNgLevel",
        type: "uint8",
      },
    ],
    name: "registerFameHallHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "registerPassedDungeon",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "bool",
        name: "isWinner",
        type: "bool",
      },
    ],
    name: "registerPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "setFeeRenaming",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "lootBoxKind",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address[]",
            name: "mintItems",
            type: "address[]",
          },
          {
            internalType: "uint32[]",
            name: "mintItemsChances",
            type: "uint32[]",
          },
          {
            internalType: "uint256",
            name: "maxDropItems",
            type: "uint256",
          },
        ],
        internalType: "struct IUserController.LootBoxConfig",
        name: "config",
        type: "tuple",
      },
    ],
    name: "setLootBoxConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "avatar",
        type: "string",
      },
    ],
    name: "setUserAvatar",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "setUserName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAccount",
        type: "address",
      },
    ],
    name: "userAccountName",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "userActivity",
    outputs: [
      {
        components: [
          {
            internalType: "uint32",
            name: "epochDay",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "epochWeek",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "counterPassedDungeons",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "counterPvp",
            type: "uint32",
          },
          {
            internalType: "uint16",
            name: "dailyActivities",
            type: "uint16",
          },
          {
            internalType: "bool",
            name: "dailyLootBoxReceived",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "weeklyLootBoxReceived",
            type: "bool",
          },
        ],
        internalType: "struct IUserController.UserActivity",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAccount",
        type: "address",
      },
    ],
    name: "userAvatar",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class UserController__factory {
  static readonly abi = _abi;
  static createInterface(): UserControllerInterface {
    return new Interface(_abi) as UserControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): UserController {
    return new Contract(address, _abi, runner) as unknown as UserController;
  }
}
