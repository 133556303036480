const BLOCK_EXPLORERS = new Map<number, string>([
  [11155111, 'https://sepolia.etherscan.io/'],
  [137, 'https://polygonscan.com/'],
  [80001, 'https://mumbai.polygonscan.com/'],
  [5, 'https://goerli.etherscan.io/'],
  [1351057110, 'https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com/'],
]);

export function getEventTopicByName(clazz: any, name: string): string {
  return clazz.createInterface().getEventTopic(clazz.createInterface().getEvent(name));
}

export function blockExplorerLink(chainId: number): string {
  return BLOCK_EXPLORERS.get(chainId) ?? '';
}
