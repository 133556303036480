<app-dialog-title
  class="g-flex-column__item-fixed"
  title="{{ 'hero-slot-dialog.slot' | translate }} {{ itemSlotName }}"
  (closeDialog)="close()">
</app-dialog-title>

<div class="dialog__description g-flex g-flex-column__item-fixed g-flex--space-between">
  <div>
    <!--{{ itemsTypeName ?? '' | translate }}-->
    {{ 'hero-slot-dialog.available' | translate }}
  </div>

  <!--  <div class="app-divider-scratches-border-v-medium-down"></div>-->

  <div>
    <span class="dialog__slot-available">{{ data.availableItems?.length }}</span>
    {{ 'hero-slot-dialog.items' | translate }}
  </div>
</div>

<div class="dialog__items g-scroll-y g-flex-column__item">
  @for (item of data.availableItems; track item) {
    <app-item-card
      [class.card--selected]="selectedItem?.id === item.id"
      [item]="item"
      (selectItem)="selectItem(item)"
      (selectItemImg)="openItemDescriptionDialog(item)">
      <div class="item__actions g-flex-column g-flex__item-fixed">
        <button
          class="buttons__item app-btn-blue-1"
          (click)="selectItem(item)">
          {{ 'item-description-dialog.equip' | translate }}
        </button>
      </div>
    </app-item-card>
  }
</div>
