/* eslint-disable */
export let local: Storage;

if (typeof window !== 'undefined' && typeof (window as any).localStorage !== 'undefined') {
  local = (window as any).localStorage;
}

export const setLocal = (key: string, data: any) => {
  const jsonData = JSON.stringify(data);
  if (local) {
    local.setItem(key, jsonData);
  }
};

export const getLocal = (key: string) => {
  let data = null;
  let raw = null;
  if (local) {
    // @ts-ignore
    raw = local.getItem(key);
  }
  if (raw && typeof raw === 'string') {
    try {
      data = JSON.parse(raw);
    } catch (error) {
      return null;
    }
  }
  return data;
};

export const removeLocal = (key: string) => {
  if (local) {
    local.removeItem(key);
  }
};

export const removeIndexedDb = (dbName: string) => {
  return new Promise((resolve, reject) => {
    const DBDeleteRequest = window.indexedDB.deleteDatabase(dbName);
    DBDeleteRequest.onerror = function (event) {
      console.log(dbName, 'Error deleting database.');
      resolve(false);
    };

    DBDeleteRequest.onsuccess = function (event) {
      console.log(dbName, 'Database deleted successfully');
      resolve(true);
    };

    DBDeleteRequest.onblocked = function (event) {
      console.log(dbName, 'Database delete blocked');
      resolve(false);
    };
  });
};

export const updateLocal = (key: string, data: any) => {
  const localData = getLocal(key) || {};
  const mergedData = { ...localData, ...data };
  setLocal(key, mergedData);
};
