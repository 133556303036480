import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AppStateService } from '@services/app-state-service/app-state.service';

const SLIDER_WIDTH: number = 937;
const SLIDER_WIDTH_SMALL: number = 333;
const SLIDER_WIDTH_MEDIUM: number = 600;
const SLIDER_WIDTH_MEDIUM_LONG: number = 800;
const POINT_WIDTH = 43;

@Component({
  selector: 'app-slider',
  standalone: true,
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle],
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() value = 0;
  @Input() maxValue = 100;
  @Input() minValue = 0;
  @Input() description = '';
  @Input() isDisabled = false;
  @Input() size: 'default' | 'small' | 'medium' | 'medium-long' = 'default';
  @Input() isShowTooltip = true;
  @Input() postfix = '%';
  @Input() labels: string[] = [];

  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() valueChanging: EventEmitter<number> = new EventEmitter<number>();

  dragX = 0;
  translate = {};
  isPercentageVisible = true;
  sliderWidth = SLIDER_WIDTH;

  constructor(
    private appStateService: AppStateService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    if (this.size === 'small') {
      this.sliderWidth = SLIDER_WIDTH_SMALL;
    }

    if (this.size === 'medium') {
      this.sliderWidth = SLIDER_WIDTH_MEDIUM;
    }

    if (this.size === 'medium-long') {
      this.sliderWidth = SLIDER_WIDTH_MEDIUM_LONG;
    }

    this.initValue();
  }

  ngOnChanges({ value }: SimpleChanges) {
    if (value) {
      this.initValue();
    }
  }

  dragStart(event) {
    if (event.dataTransfer) {
      const img = new Image();
      img.src = '/assets/images/ui/elements/slider/slider_point.png';
      event.dataTransfer.setDragImage(img, 0, 0);
    }

    this.isPercentageVisible = false;
  }

  drag(event) {
    if (event.clientX <= 0 && !event.touches) {
      return;
    }

    const elementPosition = this.elementRef.nativeElement.getBoundingClientRect().left;

    if (event.touches && event.touches[0]) {
      this.dragX =
        Math.round(event.touches[0].clientX / this.appStateService.getZoom() - POINT_WIDTH) - elementPosition;
    } else {
      this.dragX = Math.round(event.clientX / this.appStateService.getZoom() - POINT_WIDTH) - elementPosition;
    }

    this.value = Math.round(
      (this.dragX / (this.sliderWidth - POINT_WIDTH)) * (this.maxValue - this.minValue) + this.minValue,
    );

    if (this.dragX >= this.sliderWidth - POINT_WIDTH) {
      this.dragX = this.sliderWidth - POINT_WIDTH;
      this.value = this.maxValue;
    }

    if (this.dragX < this.minValue) {
      this.dragX = 0;
      this.value = this.minValue;
    }

    this.translate = {
      transform: `translate3d(${this.dragX}px, 0 ,0)`,
    };

    this.valueChanging.emit(this.value);
  }

  dragEnd() {
    this.translate = {
      transform: `translate3d(${this.dragX}px, 0 ,0)`,
    };

    this.isPercentageVisible = true;

    this.valueChanged.emit(this.value);
  }

  private initValue() {
    if (this.value < 0) {
      this.value = 0;
    }

    if (this.value > this.maxValue) {
      this.value = this.maxValue;
    }

    if (this.value < this.minValue) {
      this.value = this.minValue;
    }

    this.dragX = Math.round(
      ((this.value - this.minValue) / (this.maxValue - this.minValue)) * (this.sliderWidth - POINT_WIDTH),
    );

    this.translate = {
      transform: `translate3d(${this.dragX}px, 0 ,0)`,
    };
  }
}
