import { Dialog } from '@angular/cdk/dialog';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ProviderService } from '@services/provider.service';
import { Web3authService } from '@services/web3auth.service';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { CHAIN_IDS } from '@shared/constants/chain-ids.constant';
import { formatUnits } from 'ethers';

const DEFAULT_CONFIRMATION = 'Sure?';
const CONFIRM_TIMEOUT = 3000;

@Directive({
  selector: '[appButtonClick]',
  standalone: true,
  host: {
    role: 'button',
  },
})
export class ButtonClickDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  @HostBinding('class.disabled')
  isDisabled?: boolean = false;

  // @HostBinding('attr.disabled')
  // get disabledAttr(): boolean {
  //   return Boolean(this.isDisabled);
  // }

  @Input()
  @HostBinding('class.loading')
  isLoading?: boolean = false;

  @Input()
  tabindex: number = 0;

  @HostBinding(`attr.tabindex`)
  get tabindexAttr(): number {
    return this.isDisabled ? -1 : this.tabindex;
  }

  @Input() infoTitle: string = '';
  @Input() infoDesc: string = '';
  @Input() isNeedConfirm: boolean = false;
  @Input() actionCost = 0;
  @Input() isNeedConfirmPush: boolean = false;
  @Input() confirmLabel: string = DEFAULT_CONFIRMATION;
  @Input() isRunConfirmTimer: boolean = true;

  @Output() hostClick: EventEmitter<Event> = new EventEmitter<Event>();

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.spacebar', ['$event'])
  onHostClick($event: Event): void {
    if (this.isDisabled || this.isLoading) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();

      return;
    }

    const isNeedConfirm = this.isNeedConfirmationCalc();

    if (!this.originLabel && isNeedConfirm) {
      this.originLabel = this.elementRef.nativeElement.textContent;
    }

    clearInterval(this.progressInterval);

    if (isNeedConfirm) {
      if (this.clickCount === 0) {
        const cost = this.getCost();
        const postfix = cost !== 0 && this.confirmLabel === DEFAULT_CONFIRMATION ? ` Gas: ${cost} Gwei` : '';

        (this.elementRef.nativeElement as HTMLButtonElement).textContent = this.confirmLabel + postfix;

        (this.elementRef.nativeElement as HTMLButtonElement).style.whiteSpace = 'pre-wrap';
        (this.elementRef.nativeElement as HTMLButtonElement).style.padding = '0 30px';
        (this.elementRef.nativeElement as HTMLButtonElement).style.lineHeight = '0.9';

        this.clickCount++;

        if (this.isRunConfirmTimer) {
          this.runConfirmTimer();
        }
      } else {
        this.emitEvent($event);
      }
    } else {
      this.emitEvent($event);
    }
  }

  private confirmTimerBarName = 'confirm-timer-bar';
  private progressInterval: number;
  private clickCount = 0;
  private originLabel = '';

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public dialog: Dialog,
    public web3authService: Web3authService,
    public providerService: ProviderService,
  ) {}

  ngOnInit() {
    this.confirmTimerBarName = `confirm-timer-bar-${new Date().getTime()}`;

    this.addRemoveDisableButton();
    this.originLabel = this.elementRef.nativeElement.textContent;
  }

  ngOnChanges({ isDisabled }: SimpleChanges) {
    if (isDisabled && isDisabled.previousValue !== isDisabled.currentValue) {
      this.addRemoveDisableButton();
    }
  }

  ngOnDestroy() {
    clearInterval(this.progressInterval);
  }

  private emitEvent(event) {
    this.hostClick.emit(event);

    const isNeedConfirm = this.isNeedConfirmationCalc();
    if (isNeedConfirm) {
      (this.elementRef.nativeElement as HTMLButtonElement).textContent = this.originLabel;
    }

    this.clickCount = 0;
  }

  private addRemoveDisableButton() {
    if (this.isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement as HTMLElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement as HTMLElement, 'disabled');
    }

    if ((this.elementRef.nativeElement as HTMLElement).querySelector('.btn-disabled-info')) {
      this.renderer.removeChild(
        this.elementRef.nativeElement,
        (this.elementRef.nativeElement as HTMLElement).querySelector('.btn-disabled-info'),
      );
    }

    if (this.infoDesc) {
      const div = this.renderer.createElement('div');
      this.renderer.addClass(div, 'btn-disabled-info');
      this.renderer.addClass(div, 'pointer');

      this.renderer.listen(div, 'click', () => {
        this.dialog.open(InfoDialogComponent, {
          panelClass: 'app-overlay-pane',
          data: { infoTitle: this.infoTitle, infoDesc: this.infoDesc },
        });
      });

      if (this.elementRef.nativeElement.disabled || this.isDisabled) {
        this.renderer.appendChild(this.elementRef.nativeElement, div);
      }
    }
  }

  isNeedConfirmationCalc() {
    const cost = this.getCost();

    return (
      this.isNeedConfirmPush ||
      (this.isNeedConfirm &&
        this.providerService.isLocalPK() &&
        cost > this.minGasPerConfirmation(this.providerService.chainId))
    );
  }

  minGasPerConfirmation(network: number) {
    if (network === CHAIN_IDS.FANTOM) {
      return 30;
    }
    if (network === CHAIN_IDS.REAL) {
      return 0.3;
    }
    if (network === CHAIN_IDS.NEBULA_TESTNET) {
      return 0.001;
    }
    return 999999999999999;
  }

  getCost() {
    const value =
      this.actionCost === 0 && !!this.providerService?.feeData
        ? +formatUnits(this.providerService?.feeData?.maxFeePerGas ?? this.providerService?.feeData?.gasPrice ?? 1, 9)
        : this.actionCost;
    return Number(value.toFixed(value > 10 ? 0 : 1));
  }

  private runConfirmTimer() {
    let confirmTimerBar;
    if (document.querySelector(`#${this.confirmTimerBarName}`)) {
      confirmTimerBar = (this.elementRef.nativeElement as HTMLButtonElement).querySelector(
        `#${this.confirmTimerBarName}`,
      );
    } else {
      confirmTimerBar = document.createElement('div');
      (this.elementRef.nativeElement as HTMLButtonElement).appendChild(confirmTimerBar);
    }
    confirmTimerBar.id = 'confirm-timer-bar';
    confirmTimerBar.style.position = 'absolute';
    confirmTimerBar.style.bottom = '-8px';
    confirmTimerBar.style.left = '22px';
    confirmTimerBar.style.width = 'calc(100% - 43px)';
    confirmTimerBar.style.height = '4px';
    confirmTimerBar.style.background = '#6b6050';
    confirmTimerBar.style.boxShadow = '0 2px 4px 0 rgb(0 0 0)';
    confirmTimerBar.style.transition = 'all 0.16s ease';

    let count = 0;
    const time = CONFIRM_TIMEOUT / 100;
    this.progressInterval = window.setInterval(() => {
      count += 1;
      confirmTimerBar.style.transform = `scaleX(${(1 - count / time).toFixed(2)})`;

      if (count >= time) {
        clearInterval(this.progressInterval);
        (this.elementRef.nativeElement as HTMLButtonElement).textContent = this.originLabel;
        this.clickCount = 0;
      }
    }, 100);
  }
}
