import { IProviderInfo } from '../../helpers';

export * from '../injected';

export const WALLETCONNECT: IProviderInfo = {
  id: 'walletconnect',
  name: 'WalletConnect',
  logo: 'https://cdn-assets.minds.com/web3modal-assets/walletconnect-circle.svg',
  type: 'qrcode',
  check: 'isWalletConnect',
  package: {
    required: [['projectId']],
  },
};
