import { HeroCustomData } from '@generated/gql';
import { MONSTER_OBJECTS } from '@shared/constants/addresses/addresses.constant';
import { toByte32 } from '@shared/utils';

const KARMA = 'KARMA';
const DEATH_COUNT = 'DEATH_COUNT';
const HERO_CLASS = 'HERO_CLASS';
const B1_MAIN = 'B1_MAIN';
const HANGMANS = 'HANGMANS';
const TUUNGBAG = 'TUUNGBAG';
const BELAV = 'BELAV';
const LOFGUARD = 'LOFGUARD';
const SINGALONE = 'SINGALONE';
const POSTMAN = 'POSTMAN';
const COWMAGE = 'COWMAGE';
const KNUD = 'KNUD';
const OLDFRIEND = 'OLDFRIEND';
const FOXCATHER = 'FOXCATHER';
const DESERTER = 'DESERTER';
const HANGMANS1_SLAVESUCC = 'HANGMANS1_SLAVESUCC';
const HANGMANS2_SLAVEFAIL = 'HANGMANS2_SLAVEFAIL';
const HANGMANS3_BARBSUCC = 'HANGMANS3_BARBSUCC';
const HANGMANS4_BARBFAIL = 'HANGMANS4_BARBFAIL';
const HANGMANS5_MAGEFAIL = 'HANGMANS5_MAGEFAIL';
const HANGMANS6_MAGESUCC1 = 'HANGMANS6_MAGESUCC1';
const HANGMANS7_MAGESUCC2 = 'HANGMANS7_MAGESUCC2';
const HANGMANS8_ASSBUYSUCC = 'HANGMANS8_ASSBUYSUCC';
const HANGMANS9_ASSFAIL = 'HANGMANS9_ASSFAIL';
const HANGMANS10_ASSSUCC = 'HANGMANS10_ASSSUCC';
const HANGMANS11_RUN = 'HANGMANS11_RUN';
const HANGMANS12_DEFBUY = 'HANGMANS12_DEFBUY';
const HANGMANS13_DEFRUN = 'HANGMANS13_DEFRUN';
const HANGMANS20_HELPER = 'HANGMANS20_HELPER';
const OFF_301 = 'OFF_301';
const PEDO_301 = 'PEDO_301';
const GUTTERS_302 = 'GUTTERS_302';
const CHASER = 'CHASER';
const ENTER_306 = 'ENTER_306';
const STUCK_310 = 'STUCK_310';
const FLOWER_308 = 'FLOWER_308';
const KUDJA_321 = 'KUDJA_321';
const SOUL_410 = 'SOUL_410';
const PEDRO_411 = 'PEDRO_411';
const COUNT_412 = 'COUNT_412';
const WOODCOIN_418 = 'WOODCOIN_418';
const GUY_417 = 'GUY_417';
const TURTLING_423 = 'TURTLING_423';
const ASSAMBLAGE_424 = 'ASSAMBLGE_424';
const TALK_425 = 'TALK_425';
const VEGANS_426 = 'VEGANS_426';
const BANDITS_426 = 'BANDITS_426';
const SPEEDRUN_432 = 'SPEEDRUN_432';
const DOGWAN_428 = 'DOGWAN_428';
const PLAN_427 = 'PLAN_427';
const PEDANT_140 = 'PEDANT_140';
const BRAD_142 = 'BRAD_142';
const SQUASH_143 = 'SQUASH_143';
const LIONHEART_144 = 'LIONHEART_144';
const SHEEPS_126 = 'SHEEPS_126';
const BANDITS_108 = 'BANDITS_108';
const ZUCCI_143 = 'ZUCCI_143';
const PEDO_126 = 'PEDO_126';
const NUDE_120 = 'NUDE_120';
const JERK_120 = 'JERK_120';
const DEX_107 = 'DEX_107';
const SWORD_146 = 'SWORD_146';
const DRUNK_112 = 'DRUNK_112';
const SEX_115 = 'SEX_115';
const BRU_102 = 'BRU_102';
const REFLECT_127 = 'REFLECT_127';
const DOG_125 = 'DOG_125';
const POOP_116 = 'POOP_116';
const GROB_104 = 'GROB_104';
const DED_138 = 'DED_138';
const STUFF_127 = 'STUFF_127';
const PLAGUE_230 = 'PLAGUE_230';
const CRY_216 = 'CRY_216';
const PHIL_228 = 'PHIL_228';
const MILL_238 = 'MILL_238';
const TRAITOR_206 = 'TRAITOR_206';
const CANNI_218 = 'CANNI_218';
const FOX_209 = 'FOX_209';
const HOBO_207 = 'HOBO_207';
const TWO_229 = 'TWO_229';
const DESERTER1 = 'DESERTER1';
const BOAR_225 = 'BOAR_225';
const SHIT_215 = 'SHIT_215';
const SHIT_215_2 = 'SHIT_215_2';
const HAL_203 = 'HAL_203';
const SEX_214 = 'SEX_214';
const BOOZE_215 = 'BOOZE_215';
const GIRL_217 = 'GIRL_217';
const MASS_204 = 'MASS_204';
const MASS_204_2 = 'MASS_204_2';
const EVIL_227 = 'EVIL_227';
const PIG_HATER = 'PIG_HATER';
const KIDS_309 = 'KIDS_309';
const SOLO_322 = 'SOLO_322';
const LIVES_321 = 'LIVES_321';
const RESCUER = 'RESCUER';
const BETR_302 = 'BETR_302';
const CANIBAL = 'CANIBAL';
const PARTY_328 = 'PARTY_328';
const SPID_317 = 'SPID_317';
const GHOST_307 = 'GHOST_307';
const TRAP = 'TRAP';
const MIMIC_315 = 'MIMIC_315';
const VICTORINA = 'VICTORINA';
const CONS_24 = 'CONS_24';
const NIG_328 = 'NIG_328';
const DRUNK_326 = 'DRUNK_326';
const GUTTERS = 'GUTTERS';
const RUN_323 = 'RUN_323';
const CHAMP_306 = 'CHAMP_306';
const RUN_306 = 'RUN_306';
const THIEF_323 = 'THIEF_323';
const BROTHER_402 = 'BROTHER_402';
const RUNE_430 = 'RUNE_430';
const GIRL_421 = 'GIRL_421';
const CONS_25 = 'CONS_25';
const BARBRAPE = 'BARBRAPE';
const VASSAl_435 = 'VASSAl_435';
const MAGEDOWN = 'MAGEDOWN';
const SPEED_432 = 'SPEED_432';
const SLEEP_422 = 'SLEEP_422';
const GIRL_419 = 'GIRL_419';
const PSYMAGE = 'PSYMAGE';
const FUCK_418 = 'FUCK_418';
const BOLT_417 = 'BOLT_417';
const MAP_432 = 'MAP_432';
const PEDO_409 = 'PEDO_409';
const KEY_407 = 'KEY_407';
const TURT_423 = 'TURT_423';
const FIGHT_438 = 'FIGHT_438';
const BOX_403 = 'BOX_403';

export function getAllHeroCustomDataConstants() {
  const arr = [
    KARMA,
    DEATH_COUNT,
    HERO_CLASS,
    B1_MAIN,
    HANGMANS,
    TUUNGBAG,
    BELAV,
    LOFGUARD,
    SINGALONE,
    POSTMAN,
    COWMAGE,
    KNUD,
    OLDFRIEND,
    FOXCATHER,
    DESERTER,
    HANGMANS1_SLAVESUCC,
    HANGMANS2_SLAVEFAIL,
    HANGMANS3_BARBSUCC,
    HANGMANS4_BARBFAIL,
    HANGMANS5_MAGEFAIL,
    HANGMANS6_MAGESUCC1,
    HANGMANS7_MAGESUCC2,
    HANGMANS8_ASSBUYSUCC,
    HANGMANS9_ASSFAIL,
    HANGMANS10_ASSSUCC,
    HANGMANS11_RUN,
    HANGMANS12_DEFBUY,
    HANGMANS13_DEFRUN,
    HANGMANS20_HELPER,
    OFF_301,
    PEDO_301,
    GUTTERS_302,
    CHASER,
    ENTER_306,
    STUCK_310,
    FLOWER_308,
    KUDJA_321,
    SOUL_410,
    PEDRO_411,
    COUNT_412,
    WOODCOIN_418,
    GUY_417,
    TURTLING_423,
    ASSAMBLAGE_424,
    TALK_425,
    VEGANS_426,
    BANDITS_426,
    SPEEDRUN_432,
    DOGWAN_428,
    PLAN_427,
    PEDANT_140,
    BRAD_142,
    SQUASH_143,
    LIONHEART_144,
    SHEEPS_126,
    BANDITS_108,
    ZUCCI_143,
    PEDO_126,
    NUDE_120,
    JERK_120,
    DEX_107,
    SWORD_146,
    DRUNK_112,
    SEX_115,
    BRU_102,
    REFLECT_127,
    DOG_125,
    POOP_116,
    GROB_104,
    DED_138,
    STUFF_127,
    PLAGUE_230,
    CRY_216,
    PHIL_228,
    MILL_238,
    TRAITOR_206,
    CANNI_218,
    FOX_209,
    HOBO_207,
    TWO_229,
    DESERTER1,
    BOAR_225,
    SHIT_215,
    SHIT_215_2,
    HAL_203,
    SEX_214,
    BOOZE_215,
    GIRL_217,
    MASS_204,
    MASS_204_2,
    EVIL_227,
    PIG_HATER,
    KIDS_309,
    SOLO_322,
    LIVES_321,
    RESCUER,
    BETR_302,
    CANIBAL,
    PARTY_328,
    SPID_317,
    GHOST_307,
    TRAP,
    MIMIC_315,
    VICTORINA,
    CONS_24,
    NIG_328,
    DRUNK_326,
    GUTTERS,
    RUN_323,
    CHAMP_306,
    RUN_306,
    THIEF_323,
    BROTHER_402,
    RUNE_430,
    GIRL_421,
    CONS_25,
    BARBRAPE,
    VASSAl_435,
    MAGEDOWN,
    SPEED_432,
    SLEEP_422,
    GIRL_419,
    PSYMAGE,
    FUCK_418,
    BOLT_417,
    MAP_432,
    PEDO_409,
    KEY_407,
    TURT_423,
    FIGHT_438,
    BOX_403,
  ];

  for (let i = 101; i < 10_000; i++) {
    arr.push('STORY_' + i);
  }

  for (let i = 1; i < 20; i++) {
    arr.push('BOSS_COMPLETED_' + i);
  }

  for (const m of MONSTER_OBJECTS) {
    arr.push('MONSTER_' + m.toString());
  }

  return arr;
}

export function getCustomHeroDataByByte32() {
  const result = new Map<string, string>();
  const consts = getAllHeroCustomDataConstants();
  for (const c of consts) {
    result.set(toByte32(c), c);
  }
  return result;
}

export function getKarmaValue(heroCustomData: readonly HeroCustomData[]) {
  const heroData = getCustomHeroDataByByte32();
  for (const d of heroCustomData) {
    const name = heroData.get(d.dataIndex) ?? 'unknown';
    if (name === 'KARMA') {
      return Number(d.value);
    }
  }
  return 1000;
}

const KARMA_TO_TITLE = new Map<number, string>([
  [-10, 'High Cockalorum'],
  [-9, 'Bloodclaat'],
  [-8, 'Minger'],
  [-7, 'Prick'],
  [-6, 'Twat'],
  [-5, 'Git'],
  [-4, 'Smellfungus'],
  [-3, 'Wanker'],
  [-2, 'Knobhead'],
  [-1, 'Arse'],
  [0, ' Bum'],
  [1, 'Serf'],
  [2, 'Beggar'],
  [3, 'Plodder'],
  [4, 'Nobleman'],
  [5, 'Elite'],
  [6, 'Master'],
  [7, 'Lord'],
  [8, 'Idol'],
  [9, 'Legend'],
  [10, 'Divine'],
]);

export function getHeroTitle(heroCustomData: readonly HeroCustomData[]) {
  const karma = getKarmaValue(heroCustomData) - 1000;
  const val = Math.max(Math.min(Math.floor(karma / 10), 10), -10);
  return KARMA_TO_TITLE.get(val) ?? 'Unknown';
}
