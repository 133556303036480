import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';

@Component({
  selector: 'app-info-dialog',
  standalone: true,
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, NgClass],
})
export class InfoDialogComponent {
  infoDesc: SafeHtml;
  canClose: boolean;
  customWidth = 990;

  @HostBinding('style.width.px')
  private get modalWidth() {
    return this.customWidth;
  }

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      infoTitle: string;
      infoDesc: string;
      canClose: boolean;
      descTitle: string;
      descText: string;
      customWidth: number;
      buttons?: [{ id: string; label: string; class?: string }];
    } = {
      infoTitle: '',
      infoDesc: '',
      canClose: true,
      descTitle: '',
      descText: '',
      customWidth: 990,
    },
    private dialogRef: DialogRef<{ btnId: string } | null, InfoDialogComponent>,
    private domSanitizer: DomSanitizer,
  ) {
    this.canClose = data.canClose !== undefined && data.canClose !== null ? data.canClose : true;
    this.infoDesc = data.infoDesc ? this.domSanitizer.bypassSecurityTrustHtml(data.infoDesc) : '';
    this.customWidth = data.customWidth;
  }

  close(): void {
    this.dialogRef.close(null);
  }

  onBtnClick(btnId: string) {
    this.dialogRef.close({ btnId });
  }
}
