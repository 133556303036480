<app-dialog-title
  [title]="'repair.title' | translate"
  [hasTitleScratch]="true"
  (closeDialog)="close()">
</app-dialog-title>

@if (loading) {
  <app-loading-small class="page-loader"></app-loading-small>
} @else {
  <div class="container g-flex-column__item g-scroll-y">
    <div class="items g-flex g-flex--align-center g-flex--justify-center">
      <div class="g-flex-column">
        <div>
          {{ 'repair.target' | translate }}
        </div>

        <app-item-slot
          class="item-slot g-flex"
          [slotItem]="targetItem"
          (clickEmit)="onItemSlotClick(targetItem)"
          slotSize="huge">
        </app-item-slot>
      </div>

      <div class="item-divider app-divider-group-of-bolts"></div>

      <div class="catalisator g-flex-column">
        <div class="app-text-warning app-text-warning--shadow">
          {{ 'repair.catalyst' | translate }}
        </div>

        <app-item-slot
          class="item-slot g-flex"
          [slotItem]="selectedCatalyst"
          (clickEmit)="onItemSlotClick(selectedCatalyst)"
          slotSize="huge">
        </app-item-slot>
      </div>
    </div>

    <app-scratch
      class="scratch"
      backgroundType="huge">
    </app-scratch>

    <div class="desc2 app-btn-small_font-size color-caption">
      {{ 'repair.desc2' | translate }}
    </div>

    <app-scratch
      class="scratch"
      backgroundType="huge">
    </app-scratch>

    <div class="g-flex-column">
      @if (selectedCatalyst && selectedCatalyst.itemId > 0) {
        <div class="title g-flex g-flex--justify-center">
          <div class="title__name">
            {{ 'repair.target-durability' | translate }}
          </div>

          <div>{{ targetItem.durability }} / {{ targetItem.meta.durability }}</div>
        </div>

        <div class="buttons g-flex g-flex--align-center g-flex--justify-center gap-80">
          @if (!isEnoughAllowance) {
            @if (!isEnoughBalance) {
              <button
                [disabled]="true"
                class="btn-repair app-btn-red-3 g-flex g-flex--align-center g-flex--justify-center">
                <div>
                  {{ 'repair.buttons.not-enough' | translate }}
                </div>

                <img
                  class="balance__icon shadow__icon"
                  [src]="
                    'assets/images/ui/icons/tokens/' + targetItem.meta.feeToken.token.symbol.toLowerCase() + '.png'
                  "
                  alt="" />
              </button>
            } @else {
              <button
                class="btn-repair app-btn-red-3 g-flex g-flex--align-center g-flex--justify-center"
                [disabled]="transactionLoading"
                (click)="approveFeeToken()">
                <div>
                  {{ 'repair.buttons.approve' | translate }}
                </div>

                <img
                  class="balance__icon shadow__icon"
                  [src]="
                    'assets/images/ui/icons/tokens/' + targetItem.meta.feeToken.token.symbol.toLowerCase() + '.png'
                  "
                  alt="" />
              </button>
            }
          }

          @if (isEnoughAllowance && isEnoughBalance) {
            <button
              appButtonClick
              [isNeedConfirm]="true"
              [isDisabled]="transactionLoading"
              class="btn-repair app-btn-red-3"
              (hostClick)="repair()">
              {{ 'repair.buttons.repair-this-item' | translate }}
            </button>
          }

          <button
            class="app-btn-blue-2 g-flex g-flex--align-center g-flex--justify-center"
            (click)="resetCatalyst()">
            {{ 'repair.buttons.reset' | translate }}
          </button>
        </div>

        <div class="desc g-flex g-flex--align-center g-flex--justify-center app-btn-small_font-size color-red-fire">
          {{ 'repair.desc' | translate }}
        </div>

        <div class="cost g-flex g-flex--align-center g-flex--justify-center">
          <span>
            {{ 'repair.cost' | translate }}
          </span>

          <app-balance
            class="cost__value"
            [isReverse]="false"
            [token]="targetItem.meta.feeToken.token.id"
            [balance]="+targetItem.meta.feeToken.amount"
            [isShowBalanceUsd]="true">
          </app-balance>

          <button
            class="btn-buy app-btn-silver-2"
            [disabled]="transactionLoading"
            (click)="buyTokens()">
            {{ 'repair.buttons.buy-coins' | translate }}
          </button>
        </div>
      } @else {
        <div class="title g-flex g-flex--space-between">
          <div>
            {{ 'repair.catalysts' | translate }}
          </div>

          <div>
            <span class="app-text-warning app-text-warning--shadow">{{ catalystItems.length }}</span>
            {{ 'repair.available' | translate }}
          </div>
        </div>

        <div class="g-flex-column g-scroll-y">
          @for (item of catalystItems; track item) {
            <app-item-card
              class="item pointer"
              [item]="item"
              (selectItem)="selectCatalyst(item)"
              (selectItemImg)="selectCatalyst(item)">
            </app-item-card>
          }
        </div>
      }
    </div>
  </div>
}
