import { Router } from '@angular/router';
import { environment } from '@environments/environment.prod';
import { FighterTurnResultEntity, HeroEntity, ItemEntity, ItemMetaEntity } from '@generated/gql';
import { DamageModel } from '@models/damage.model';
import { HeroModel } from '@models/hero.model';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { TokenService } from '@services/onchain/token.service';
import { SoundService } from '@services/sound.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { CHAIN_FIELDS, CHAIN_IDS, getChainByChainId } from '@shared/constants/chain-ids.constant';
import { DAMAGE_TYPE } from '@shared/constants/damage-type.constant';
import { ACTION_ICONS, ACTION_TYPES } from '@shared/constants/damage-types.constant';
import { getExperienceRequiredForLevel } from '@shared/constants/game.constant';
import { HEROES } from '@shared/constants/heroes.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { expect } from 'chai';
import { finalize } from 'rxjs/internal/operators/finalize';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

import { actionText, getDamageData } from '../pages/dungeon/dungeon-battle-history-dialog/battle-log.utils';
import { SKILL_ITEM } from '../pages/dungeon/dungeon-fight/dungeon-fight.component';

// const IPFS_GATEWAY = 'aqua-select-wombat-259.mypinata.cloud';
const IPFS_GATEWAY = 'sacra.myfilebase.com';

export function getHeroExperiencePercent(curExp: number, prevLvlExp: number, nextLvlExp: number): number {
  // console.log('getHeroExperiencePercent', curExp, prevLvlExp, nextLvlExp)
  return +(((curExp - prevLvlExp) / (nextLvlExp - prevLvlExp)) * 100).toFixed(2);
}

export function changeIPFSDomain(url: string) {
  if (!url) {
    return url;
  }
  return url.replace('ipfs.filebase.io', IPFS_GATEWAY).replace('sacra.myfilebase.com', IPFS_GATEWAY);
}

export function getActualUri(entity: ItemEntity | HeroEntity) {
  const uri = entity.uniqUri ? entity.uniqUri : entity.meta && entity.meta.uri ? entity.meta.uri : '';
  // const uri = entity.meta?.uri || '';
  return changeIPFSDomain(uri);
}

export function getActualMetaUri(entity: ItemMetaEntity): string {
  const uri = entity?.uri ? entity.uri : '';
  return changeIPFSDomain(uri);
}

export function getMainDomain() {
  const mainDomain: string = window.location.hostname;
  const split = mainDomain.split('.');
  if (split.length > 2) {
    // return only first level domain
    return split[split.length - 2] + '.' + split[split.length - 1];
  }
  if (mainDomain === 'localhost') {
    return 'sacra.game';
  }
  return mainDomain;
}

export function continueToChamber(
  router: Router,
  isBattle: boolean,
  isEvent: boolean,
  heroToken: string,
  heroTokenId: number,
  dungeonAdr: string,
  chamberAdr: string,
  chamberIndex: number,
  iteration: number,
  isHeroDead = false,
) {
  console.log(
    'continueToChamber',
    isBattle,
    isEvent,
    heroToken,
    heroTokenId,
    dungeonAdr,
    chamberAdr,
    chamberIndex,
    isHeroDead,
  );

  // no need anymore, we restore full hp
  // if (isHeroDead) {
  //   return router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.HERO_DEAD]);
  // }

  if (!chamberAdr || chamberAdr === '' || chamberAdr === 'no_chamber') {
    return router.navigate([
      MAIN_ROUTES.MAIN,
      MAIN_ROUTES.DUNGEON,
      heroToken,
      heroTokenId,
      MAIN_ROUTES.DUNGEON_DETAILS,
      dungeonAdr,
    ]);
  }

  let cType;
  if (isBattle) {
    cType = MAIN_ROUTES.DUNGEON_FIGHT;
  } else if (isEvent) {
    cType = MAIN_ROUTES.DUNGEON_EVENT;
  } else {
    cType = MAIN_ROUTES.DUNGEON_STORY;
  }

  return router.navigate([
    MAIN_ROUTES.MAIN,
    MAIN_ROUTES.DUNGEON,
    heroToken,
    heroTokenId,
    cType,
    dungeonAdr,
    chamberAdr,
    chamberIndex,
    iteration,
  ]);
}

export function goToDungeonEnd(hero: HeroEntity, router: Router, dungeonId: number) {
  if (Number(hero.meta.feeToken.amount ?? '1') === 0) {
    router.navigate([MAIN_ROUTES.F2P_END]);
  } else {
    router.navigate([MAIN_ROUTES.DUNGEON, hero.meta.id, hero.heroId.toString(), MAIN_ROUTES.DUNGEON_END, dungeonId]);
  }
}

export function callBuyToken(
  tokenService: TokenService,
  soundService: SoundService,
  destroy$: DestroyService,
  account: string,
  tokenAdr: string,
  amount: bigint,
  chainId: number,
  finalizeCallback: () => void,
  subscribeCallback: () => void,
) {
  soundService.play({ key: 'buy_coins' });

  tokenService
    .buyTokens$(account, '', tokenAdr, amount, chainId)
    .pipe(
      finalize(() => {
        finalizeCallback();
      }),
      takeUntil(destroy$),
    )
    .subscribe(() => {
      subscribeCallback();
    });
}

export function callBuyNetworkCoin(chainId: number) {
  if (chainId === CHAIN_IDS.FANTOM) {
    window.open('https://info.sacra.game/mvp-1-on-fantom', '_blank');
  }
  if (chainId === CHAIN_IDS.REAL) {
    window.open('https://info.sacra.game/mvp-2-on-real-live-now', '_blank');
  }
}

export function getNetworkTokenName() {
  return getChainByChainId(Number(environment['CHAIN_ID']))[CHAIN_FIELDS.TOKEN_NAME];
}

export function getNetworkTokenIcon() {
  return (
    'assets/images/ui/icons/net/' + getChainByChainId(Number(environment['CHAIN_ID']))[CHAIN_FIELDS.TOKEN_NAME] + '.png'
  );
}

export function minNetworkCoinBalance(chainId: number) {
  if (chainId === CHAIN_IDS.FANTOM) {
    return 0.05;
  }
  if (chainId === CHAIN_IDS.REAL) {
    return 0.0001;
  }
  if (chainId === CHAIN_IDS.NEBULA_TESTNET) {
    return 0.01;
  }
  return 0.01;
}

export function isShowSpeedUpDialog(heroModel: HeroModel | HeroEntity | undefined): boolean {
  return heroModel?.meta?.heroClass !== HEROES.GHOST;
}

export function getHeroVirtualLevel(curExp: number, curLvl: number) {
  let level = curLvl;
  for (; level < 99; ) {
    if (getExperienceRequiredForLevel(level) >= curExp + 1) {
      break;
    }
    ++level;
  }
  level -= level > 0 ? 1 : 0;

  return level + 1;
}

export function chamberIdToStoryId(chamberId: string | undefined): number {
  return +(chamberId ?? '0') % 10_000;
}

export function chamberIdToBiome(chamberId: string | undefined): number {
  return +(chamberId ?? '0') % 10_000;
}

export function chamberIdToType(chamberId: string | undefined): number {
  return Math.floor((+(chamberId ?? '0') % 1_000_000) / 10_000);
}

export function readObjectId(objectId: number): { biome: number; subType: number; id: number } {
  const data = {
    biome: Math.floor(objectId / 1_000_000),
    subType: Math.floor(objectId / 10_000) % 100,
    id: objectId % 10_000,
  };

  expect(data.biome !== 0, 'zero biome');
  expect(data.subType !== 0, 'zero subType');
  expect(data.id !== 0, 'zero id');

  return data;
}

export function packStoryAnswerId(storyId: number, pageId: number, heroClass: number, answerId: number): string {
  let packed = BigInt(storyId);
  packed |= BigInt(pageId) << 16n;
  packed |= BigInt(heroClass) << 32n;
  packed |= BigInt(answerId) << 40n;

  return '0x' + packed.toString(16).padStart(64, '0');
}

export function unpackStoryAnswerId(packedData: string): {
  storyId: number;
  pageId: number;
  heroClass: number;
  answerId: number;
} {
  const data = BigInt(packedData);

  const storyId = Number(data & 0xffffn);
  const pageId = Number((data >> 16n) & 0xffffn);
  const heroClass = Number((data >> 32n) & 0xffn);
  const answerId = Number((data >> 40n) & 0xffffn);

  return { storyId, pageId, heroClass, answerId };
}

export function getSkillItemsFromStoredInfo(
  items: ItemEntity[],
  storageSkillsItems: (SKILL_ITEM | undefined)[],
  onlyFromHelper: boolean,
) {
  const tmpArr: ItemEntity[] = [];
  for (let i = 0; i < 10; i++) {
    const storedItem = storageSkillsItems[i];
    if (storedItem && storedItem.fromHelper === onlyFromHelper) {
      const item = (items ?? []).find(
        item => item.meta.id.toLowerCase() === storedItem.token.toLowerCase() && item.itemId === storedItem.id,
      );
      if (
        !item ||
        !item.equipped
        // || item.equippedSlot !== ITEM_SLOT.SKILL_1 + i - (onlyFromHelper ? 3 : 0)
      ) {
        storageSkillsItems[i] = undefined;
      } else {
        tmpArr.push(item);
      }
    }
  }
  return { result: tmpArr.sort((a, b) => (a.equippedSlot ?? 0) - (b.equippedSlot ?? 0)), storageSkillsItems };
}

export function createDamageAnimate(
  fighter: FighterTurnResultEntity,
  oppositeFighter: FighterTurnResultEntity,
  translateService: TranslateService,
): DamageModel[] {
  const heroDamageData = getDamageData(fighter.magicAttack.attackType, fighter.damage);

  let damagesForAnimate: DamageModel[] = [];

  if (heroDamageData.damage !== 0) {
    damagesForAnimate = [
      ...damagesForAnimate,
      {
        type: DAMAGE_TYPE.VALUE,
        value: heroDamageData.damage,
        actionIcon: heroDamageData.actionIcon,
        isCritical: fighter.statuses.gotCriticalHit,
      },
    ];
  }
  // else {
  //   const filtered = Object.keys(fighter.statuses)
  //     .filter(key => fighter.statuses[key] === true)
  //     .reduce((obj, key) => {
  //       obj[key] = fighter.statuses[key];
  //       return obj;
  //     }, {});
  //
  //   const key = Object.keys(filtered)[0];
  //   const type = key ? ACTION_STATUSES[key] : ACTION_TYPES.MISS;
  //   const text = this.translateService.instant(actionText(type));
  //
  //   damagesForAnimate = [...damagesForAnimate, { type: DAMAGE_TYPE.VALUE, actionText: text, isCritical: false }];
  // }

  if (fighter.statuses.missed) {
    const text = translateService.instant(actionText(ACTION_TYPES.MISS));
    damagesForAnimate = [
      ...damagesForAnimate,
      { type: DAMAGE_TYPE.VALUE, actionText: text, actionIcon: ACTION_ICONS.MISS },
    ];
  }

  if (fighter.statuses.hitBlocked) {
    const text = translateService.instant(actionText(ACTION_TYPES.BLOCK));
    damagesForAnimate = [...damagesForAnimate, { type: DAMAGE_TYPE.VALUE, actionText: text }];
  }

  if (fighter.damagePoison !== 0) {
    damagesForAnimate = [
      ...damagesForAnimate,
      { type: DAMAGE_TYPE.VALUE, value: fighter.damagePoison, actionIcon: ACTION_ICONS.POISON },
    ];
  }

  if (fighter.damageReflect !== 0) {
    damagesForAnimate = [
      ...damagesForAnimate,
      { type: DAMAGE_TYPE.VALUE, value: fighter.damageReflect, actionIcon: ACTION_ICONS.RETALIATE },
    ];
  }

  if (fighter.manaConsumed !== 0) {
    damagesForAnimate = [
      ...damagesForAnimate,
      { type: DAMAGE_TYPE.VALUE, value: fighter.manaConsumed, actionIcon: ACTION_ICONS.MANA_CONSUMED },
    ];
  }

  /////////// opposite logic /////////////

  const fff = oppositeFighter;

  if (fff.statuses.stun) {
    damagesForAnimate = [...damagesForAnimate, { type: DAMAGE_TYPE.STATUS, actionIcon: ACTION_ICONS.STUN }];
  }

  if (fff.statuses.burn) {
    damagesForAnimate = [...damagesForAnimate, { type: DAMAGE_TYPE.STATUS, actionIcon: ACTION_ICONS.BURN }];
  }

  if (fff.statuses.freeze) {
    damagesForAnimate = [...damagesForAnimate, { type: DAMAGE_TYPE.STATUS, actionIcon: ACTION_ICONS.FREEZE }];
  }

  if (fff.statuses.confuse) {
    damagesForAnimate = [...damagesForAnimate, { type: DAMAGE_TYPE.STATUS, actionIcon: ACTION_ICONS.CONFUSE }];
  }

  if (fff.statuses.curse) {
    damagesForAnimate = [...damagesForAnimate, { type: DAMAGE_TYPE.STATUS, actionIcon: ACTION_ICONS.CURSE }];
  }

  return damagesForAnimate;
}

export function chanceToHit(
  attackersAttackRating: number,
  defendersDefenceRating: number,
  attackersLevel: number,
  defendersLevel: number,
  arFactor: number,
  defenderBlockRating: number,
) {
  attackersAttackRating += (attackersAttackRating * arFactor) / 100;
  const x = Math.max(attackersAttackRating, 1);
  const y = Math.max(attackersAttackRating + defendersDefenceRating, 1);
  const z = attackersLevel;
  const k = defendersLevel / 2;
  const xy = x / y;
  const zk = z / (attackersLevel + k);
  const base = 2 * xy * zk;
  return Math.max(Math.min(base, 0.95), 0.2) * 100 * (1 - adjustAttrDef(defenderBlockRating) / 100);
}

export function adjustAttribute(z: number, x: number, k: number) {
  if (x < 0) {
    return 0;
  }
  const reduce = Math.pow(2, -x / k);
  return Math.floor(z * (1 - reduce));
}

export function adjustAttrDef(x: number) {
  return adjustAttribute(90, x, 100);
}

export function adjustAttrAttack(x: number) {
  return adjustAttribute(100, x, 100);
}

export function getTokenType(tokenAdr: string, chainId: number) {
  if (GET_CORE_ADDRESSES(chainId).gameToken.toLowerCase() === tokenAdr.toLowerCase()) {
    return 'sacra';
  }
  if (GET_CORE_ADDRESSES(chainId).magicToken.toLowerCase() === tokenAdr.toLowerCase()) {
    return getMagicTokenIconName(tokenAdr);
  }
  console.error('unknown token type', tokenAdr, chainId);
  return '???';
}

export function getTokenPrice() {
  return 1; // if we will use non-stable token will need to implement logic here
}

export function getMagicTokenIconName(adr: string) {
  adr = adr.toLowerCase();

  if (adr === '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'.toLowerCase()) {
    return 'ftm';
  }
  return 'usdc';
}

export function adjustMintAmount(amount: number, heroNgLevel: number, maxOpenedNgLevel: number) {
  // console.log('>>>>>>>>>>>>>> adjustMintAmount', amount, heroNgLevel, maxOpenedNgLevel);
  return (amount * (1 + heroNgLevel)) / getNgSum(maxOpenedNgLevel);
}

function getNgSum(maxNgLevel: number) {
  return ((maxNgLevel + 1) * (maxNgLevel + 2)) / 2;
}
