@if (state === 'default' || state === 'miss') {
  <img
    src="/assets/images/ui/daily-quests/day-point/default.png"
    alt="" />
}

@if (state === 'active' || state === 'pass') {
  <img
    src="/assets/images/ui/daily-quests/day-point/active.png"
    alt="" />
}

@switch (state) {
  @case ('pass') {
    <img
      class="marker"
      src="/assets/images/ui/daily-quests/day-point/pass.png"
      alt="" />
  }
  @case ('miss') {
    <img
      class="marker"
      src="/assets/images/ui/daily-quests/day-point/miss.png"
      alt="" />
  }
  @case ('active') {
    <div class="number text-shadow app-btn_font-size">
      {{ dayNumber }}
    </div>
  }
}
