import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtmService {
  private utm: string | null = null;

  setUTM(utm: string | null) {
    this.utm = utm;
  }

  getUTM(): string | null {
    return this.utm;
  }
}
