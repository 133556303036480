import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-augmentation-level',
  standalone: true,
  templateUrl: './augmentation-level.component.html',
  styleUrls: ['./augmentation-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AugmentationLevelComponent {
  @Input() level: number;
  @Input() size: 'default' | 'small' = 'default';
}
