import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { StorageService } from '@services/storage.service';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { DROPDOWN_SIZE } from '@shared/components/dropdown/constants/dropdown-sizes.constant';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { DropdownItemModel } from '@shared/components/dropdown/model/dropdown-item.model';
import { FilterCountComponent } from '@shared/components/filters-dialog/components/filter-count/filter-count.component';
import { STORAGE_KEY_FILTER_TEMPLATES } from '@shared/components/filters-dialog/constants/filters.constant';
import { ItemIdComponent } from '@shared/components/item-id/item-id.component';
import { RadioGroupComponent } from '@shared/components/radio-group/radio-group.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { ATTRIBUTE_TO_ICON, ATTRIBUTE_TO_NAME, CORE_ATTRIBUTE_TO_NAME } from '@shared/constants/attributes.constant';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { DigitOnlyDirective } from '@shared/directives/digit-only/digit-only.directive';
import { filter, takeUntil } from 'rxjs';
import * as uuid from 'uuid';

import { FilterTemplatesComponent } from '../components/filter-templates/filter-templates.component';
import {
  FILTER_ORDER_BY_TYPE,
  FILTER_TO_ICONS,
  FILTER_TYPE,
  getMagicAttackOptions,
  IAttributesChoose,
  IFilter,
  IFilterTemplate,
} from '../interfaces/filters.interface';

@Component({
  selector: 'app-items-filters-dialog',
  standalone: true,
  templateUrl: './items-filters-dialog.component.html',
  styleUrls: ['./items-filters-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column app-window-responsive-background--full',
  },
  imports: [
    DialogTitleComponent,
    ItemIdComponent,
    FilterCountComponent,
    RadioGroupComponent,
    ScratchComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    DropdownComponent,
    DigitOnlyDirective,
  ],
})
export class ItemsFiltersDialogComponent implements OnInit {
  actionTypes = [
    {
      id: 'general',
      label: 'General',
    },
    {
      id: 'stats',
      label: 'Stats',
    },
  ];
  selectedActionId: 'general' | 'stats' = 'general';

  generalControls = [
    {
      label: 'Price',
      controlName: 'price',
    },
    {
      label: 'Gear Score',
      controlName: 'score',
    },
    {
      label: 'Augmentation',
      controlName: 'augmentationLevel',
    },
    {
      label: 'Item Level',
      controlName: 'meta.level',
    },
    {
      label: 'Durability',
      controlName: 'durability',
    },
    {
      label: 'Fragility',
      controlName: 'fragility',
    },
  ];
  generalFilterCount = 0;
  generalFilter = {};

  generalFormGroup: FormGroup = new FormGroup({});
  statsFormGroup: FormGroup = new FormGroup({});
  filtersFormGroup: FormGroup = new FormGroup({});

  coreAttributes = Array.from(CORE_ATTRIBUTE_TO_NAME);
  attributes = Array.from(ATTRIBUTE_TO_NAME);

  chooseAttributes: IAttributesChoose[] = [];

  coreStatsOptions: DropdownItemModel[] = [];
  statsOptions: DropdownItemModel[] = [];
  magicAttackOptions: DropdownItemModel[] = [];
  dropdownSortItemSelected: DropdownItemModel;
  filters: IFilter[] = [];
  selectedTemplateName: string | null = null;

  DROPDOWN_SIZE = DROPDOWN_SIZE;
  CHECKBOX_STATE = CHECKBOX_STATE;
  FILTER_TYPE = FILTER_TYPE;

  private templatesDialogRef:
    | DialogRef<{ name?: string; filters?: IFilter[]; general?: {}; key?: string }, FilterTemplatesComponent>
    | undefined;

  private confirmDialogRef: DialogRef<boolean, ConfirmDialogComponent> | undefined;

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      filters: IFilter[];
      isSell: boolean;
      general: { [key: string]: { min: string; max: string } };
      isPriceDisabled: boolean;
    } = {
      filters: [],
      general: {},
      isSell: true,
      isPriceDisabled: false,
    },
    private dialogRef: DialogRef<
      {
        filters: IFilter[];
        general: { [key: string]: { min: string; max: string } };
      } | null,
      ItemsFiltersDialogComponent
    >,
    private destroy$: DestroyService,
    private translateService: TranslateService,
    private dialog: Dialog,
    private storageService: StorageService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    if (data.isSell) {
      this.generalControls = this.generalControls.filter(it => it.controlName !== 'price');
    }

    if (data.filters) {
      data.filters.forEach(it => {
        this.onAddStat(it.filterType, { and: it.and, value: it.value }, it.attribute, it.id);
      });
    }
  }

  ngOnInit() {
    this.coreStatsOptions = this.coreAttributes.map(([key, name]) => {
      return {
        id: key.toString(),
        label: this.translateService.instant(name),
        prefixIconPath: ATTRIBUTE_TO_ICON.get(key),
        iconHeight: '40px',
      };
    });

    this.statsOptions = this.attributes.map(([key, name]) => {
      return {
        id: key.toString(),
        label: this.translateService.instant(name),
        prefixIconPath: ATTRIBUTE_TO_ICON.get(key),
        iconHeight: '40px',
      };
    });

    this.magicAttackOptions = getMagicAttackOptions(this.translateService);

    this.chooseAttributes = [
      {
        formName: FILTER_TYPE.COMMON,
        attributes: this.statsOptions,
        selectedAttribute: null,
        icon: '',
        placeholder: 'Common attributes',
      },
      {
        formName: FILTER_TYPE.MAGIC_ATTACK,
        attributes: this.magicAttackOptions,
        selectedAttribute: null,
        icon: 'attack',
        placeholder: 'Magic attack',
      },
      {
        formName: FILTER_TYPE.SKILL_BUFF_CASTER,
        attributes: this.statsOptions,
        selectedAttribute: null,
        icon: 'skill',
        placeholder: 'Skill',
      },
      {
        formName: FILTER_TYPE.IMPACT,
        attributes: this.statsOptions,
        selectedAttribute: null,
        icon: 'use',
        placeholder: 'Consumable',
      },
      {
        formName: FILTER_TYPE.REQUIRED,
        attributes: this.coreStatsOptions,
        selectedAttribute: null,
        icon: '',
        placeholder: 'Required stats',
      },
    ];

    this.chooseAttributes.forEach(it => {
      this.statsFormGroup.addControl(
        it.formName,
        new FormGroup({
          and: new FormControl(CHECKBOX_STATE.NONE),
          value: new FormControl(null),
        }),
      );
    });

    this.generalControls.forEach(it => {
      this.generalFormGroup.addControl(
        it.controlName,
        this.getMinMaxGroup(it.controlName, it.controlName === 'price' && this.data.isPriceDisabled),
      );
    });

    this.generalFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getGeneralCount();
    });

    this.getGeneralCount();
  }

  getControl(controlName: string, formGroup: FormGroup = this.generalFormGroup): UntypedFormControl {
    return formGroup.get(controlName) as UntypedFormControl;
  }

  getForm(controlName: string, formGroup: FormGroup = this.generalFormGroup): UntypedFormGroup {
    return formGroup.get(controlName) as UntypedFormGroup;
  }

  close(): void {
    this.makeFilters();

    if (this.filters.length || Object.keys(this.generalFilter).length) {
      this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        panelClass: 'app-overlay-pane',
        data: {
          text: 'Do you want to apply filters?',
          confirmBtnText: 'Apply',
          cancelBtnText: 'Close',
          canClose: false,
        },
      });

      this.confirmDialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(isConfirm => {
        if (!isConfirm) {
          this.dialogRef.close(null);

          return;
        }

        this.onApply();
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  onSelectAction({ id }) {
    this.selectedActionId = id;
  }

  onAddStat(filterType: FILTER_TYPE, { and, value }, attribute: DropdownItemModel | null, id = uuid.v4()) {
    // const { and, value } = this.statsFormGroup.get(item.formName)?.getRawValue();
    let filterTypeIcon: string | undefined;
    if (FILTER_TO_ICONS.has(filterType)) {
      filterTypeIcon = '/assets/images/ui/icons/item-props/' + (FILTER_TO_ICONS.get(filterType) ?? '') + '.png';
    }

    this.filters.push({
      id,
      filterType,
      attribute,
      and,
      value,
      filterTypeIcon,
      order: FILTER_ORDER_BY_TYPE[filterType],
    });

    this.chooseAttributes.forEach(it => {
      it.selectedAttribute = null;
    });

    this.statsFormGroup.reset();

    this.filtersFormGroup.addControl(
      id,
      new FormGroup({
        and: new FormControl(and),
        value: new FormControl(value),
        enabled: new FormControl(CHECKBOX_STATE.CHECKED),
      }),
    );

    this.filters.sort((a, b) => a.order - b.order);
  }

  onRemoveStat({ id }: IFilter) {
    this.filters = this.filters.filter(it => it.id !== id);

    this.filtersFormGroup.removeControl(id);
  }

  onTemplate() {
    this.templatesDialogRef = this.dialog.open(FilterTemplatesComponent, {
      panelClass: 'app-overlay-pane',
      data: {},
    });

    this.templatesDialogRef.closed
      .pipe(
        filter(data => !!data),
        takeUntil(this.destroy$),
      )
      .subscribe(data => {
        this.selectedTemplateName = data?.key ? data.key : '';

        if (data?.name) {
          this.makeFilters();

          const templates = this.storageService.get(STORAGE_KEY_FILTER_TEMPLATES)
            ? (JSON.parse(this.storageService.get(STORAGE_KEY_FILTER_TEMPLATES)) as IFilterTemplate)
            : {};

          this.storageService.set(
            STORAGE_KEY_FILTER_TEMPLATES,
            JSON.stringify({
              ...templates,
              [data.name]: {
                filters: this.filters,
                general: this.generalFilter,
              },
            }),
          );
        }

        if (data?.filters) {
          this.filters = [];

          data.filters.forEach(it => {
            this.onAddStat(it.filterType, { and: it.and, value: it.value }, it.attribute, it.id);
          });
        }

        if (data?.general) {
          this.generalFormGroup.reset();

          Object.keys(data?.general).forEach(key => {
            const formGroup = this.generalFormGroup.get(key);

            formGroup?.get('min')?.setValue(data?.general ? data?.general[key]?.min : null);
            formGroup?.get('max')?.setValue(data?.general ? data?.general[key]?.max : null);
          });

          this.getGeneralCount();
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  onReset() {
    this.generalFormGroup.reset();
    this.statsFormGroup.reset();
    this.filtersFormGroup.reset();
    this.filters = [];
  }

  onApply() {
    this.makeFilters();

    this.dialogRef.close({
      filters: this.filters,
      general: this.generalFilter,
    });
  }

  private makeFilters() {
    Object.keys(this.filtersFormGroup.controls).forEach(key => {
      if ((this.filtersFormGroup.controls[key] as FormGroup).get('enabled')?.value === CHECKBOX_STATE.NONE) {
        this.filters = this.filters.filter(it => it.id !== key);
      }

      this.filters = this.filters.map(it => {
        const group = this.filtersFormGroup.get(key) as FormGroup;
        return {
          ...it,
          value: it.id === key ? group.get('value')?.value : it.value,
          and: it.id === key ? group.get('and')?.value : it.and,
        };
      });
    });

    const value = this.generalFormGroup.getRawValue();
    const generalFilterKeys = Object.keys(value).filter(key => value[key].min || value[key].max);
    generalFilterKeys.forEach(key => {
      this.generalFilter[key] = value[key];
    });
  }

  private getMinMaxGroup(controlName: string, isDisabled: boolean): FormGroup {
    return new FormGroup({
      min: new FormControl({
        value: this.data.general ? this.data.general[controlName]?.min : null,
        disabled: isDisabled,
      }),
      max: new FormControl({
        value: this.data.general ? this.data.general[controlName]?.max : null,
        disabled: isDisabled,
      }),
    });
  }

  private getGeneralCount() {
    const value = this.generalFormGroup.getRawValue();

    this.generalFilterCount = Object.keys(value).filter(key => value[key].min || value[key].max).length;
  }
}
