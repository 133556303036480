import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { ProviderService } from '@services/provider.service';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { DropdownItemModel } from '@shared/components/dropdown/model/dropdown-item.model';
import { getChainOptions } from '@shared/constants/chain-ids.constant';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-network-dialog',
  standalone: true,
  templateUrl: './network-dialog.component.html',
  styleUrls: ['./network-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent],
})
export class NetworkDialogComponent {
  canClose: boolean;
  networksOptions: DropdownItemModel[] = getChainOptions();
  switchNetworkCode: number = -10;

  constructor(
    @Inject(DIALOG_DATA) public data: { canClose: boolean },
    private dialogRef: DialogRef<null, NetworkDialogComponent>,
    private destroy$: DestroyService,
    private providerService: ProviderService,
    private logger: NGXLogger,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.canClose = data.canClose !== undefined && data.canClose !== null ? data.canClose : true;
  }

  close(): void {
    this.dialogRef.close(null);
  }

  switchNetwork(chainId: number) {
    this.providerService.changeNetworkAndRedirect(chainId, this.destroy$, code => {
      this.logger.info('change network code', code);
      this.switchNetworkCode = code;
      this.changeDetectorRef.detectChanges();
    });
  }

  mapNetworkNameToDescription(name: string): string {
    if (name === 'Sonic') {
      return 'Free to Play on Sonic (Beta-3)';
    }
    if (name === 'Fantom') {
      return 'Fantom Opera (MVP-1)';
    }
    return name;
  }
}
