import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { ItemSharedComponent } from '@shared/components/item-shared/item-shared.component';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { NetworkGuard } from '@shared/guards/network.guard';

export const APP_ROUTES: Routes = [
  {
    path: MAIN_ROUTES.AUTH,
    loadChildren: () => import('./pages/auth/auth-routes').then(m => m.AUTH_ROUTES),
  },
  {
    path: MAIN_ROUTES.DELEGATE,
    loadChildren: () => import('./pages/delegate/delegate-routes').then(m => m.DELEGATE_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.DAILY_QUESTS,
    loadChildren: () => import('./pages/daily-quests/daily-quests-routes').then(m => m.DAILY_QUESTS_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.GUILDS,
    loadChildren: () => import('./pages/guilds/guilds-routes').then(m => m.GUILDS_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.FAQ,
    loadChildren: () => import('./pages/faq/faq-routes').then(m => m.FAQ_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.HEROES_LIST,
    loadChildren: () => import('./pages/heroes-list/heroes-list-routes').then(m => m.HEROES_LIST_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.MAP_TOKEN_ID,
    loadChildren: () => import('./pages/map/map-routes').then(m => m.MAP_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.MAINTENANCE,
    loadChildren: () => import('./pages/maintenance/maintenance-routes').then(m => m.MAINTENANCE_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.HERO_DEAD,
    loadChildren: () => import('./pages/hero-dead/hero-dead-routes').then(m => m.HERO_DEAD_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.PAWNSHOP,
    loadChildren: () => import('./pages/pawnshop/pawnshop-routes').then(m => m.PAWNSHOP_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.REF_TOP_LIST,
    loadChildren: () => import('./pages/ref-top-list/ref-top-list-route').then(m => m.REF_TOP_LIST_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.REF_TOP_LIST_ACCOUNT,
    loadChildren: () =>
      import('./pages/ref-top-list-account/ref-top-list-account-route').then(m => m.REF_TOP_LIST_ACCOUNT_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.TOP_LIST,
    loadChildren: () => import('./pages/top-list/top-list-routes').then(m => m.TOP_LIST_ROUTES),
  },
  {
    path: MAIN_ROUTES.TRANSFORM_ID,
    loadChildren: () => import('./pages/transform/transform-routes').then(m => m.TRANSFORM_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.WEB3AUTH_SETTINGS,
    loadChildren: () => import('./pages/web3auth-settings/web3auth-settings-routes').then(m => m.WEB3_AUTH_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.F2P_END,
    loadChildren: () => import('./pages/f2pend/f2pend-routes').then(m => m.F2PEND_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.CREATE_SUCCESS_TOKEN_ID,
    loadChildren: () =>
      import('./pages/hero-create-success/hero-create-success-routes').then(m => m.HERO_CREATE_SUCCESS_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.CREATE,
    loadChildren: () => import('./pages/hero-create/hero-create-routes').then(m => m.HERO_CREATE_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.HERO_ID,
    loadChildren: () => import('./pages/hero/hero-routes').then(m => m.HERO_ROUTES),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.DUNGEON_TOKEN_ID,
    loadChildren: () => import('./pages/dungeon/dungeon-state.module').then(m => m.DungeonStateModule),
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: MAIN_ROUTES.ITEM,
    component: ItemSharedComponent,
    canMatch: [() => inject(NetworkGuard).canMatch()],
  },
  {
    path: 'index.html',
    redirectTo: MAIN_ROUTES.MAIN,
  },
  {
    path: MAIN_ROUTES.EMPTY,
    pathMatch: 'full',
    loadComponent: () => import('./pages/main/main.component').then(m => m.MainComponent),
  },
];
