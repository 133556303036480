import { Dialog } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';

@Component({
  selector: 'app-bar-stat',
  standalone: true,
  templateUrl: './bar-stat.component.html',
  styleUrls: ['./bar-stat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center g-flex--justify-center',
  },
  imports: [NgClass],
})
export class BarStatComponent {
  @Input() percentageValue: number;
  @Input() barType: 'hp' | 'mp' | 'exp' | 'spec' | 'enemy' = 'hp';
  @Input() size: 'huge' | 'large' | 'medium' | 'small' | 'menu' | 'fight' | 'spec' = 'huge';
  @Input() isShowPoint = true;
  @Input() isAllowClick = true;
  @Input() label: string = '';
  @Input() topLabel: string = '';
  @Input() topLabelClasses: string[] = [];

  @Input()
  @HostBinding('class.rotated')
  isRotated = false;

  @HostListener('click')
  onClick() {
    if (!this.isAllowClick) {
      return;
    }

    if (this.barType !== 'spec') {
      this.dialog.open(InfoDialogComponent, {
        panelClass: 'app-overlay-pane',
        data: {
          infoTitle: this.translateService.instant(`bars-desc.name.${this.barType}`),
          infoDesc: this.translateService.instant(`bars-desc.desc.${this.barType}`),
          customWidth: 800,
        },
      });
    }
  }

  constructor(
    private translateService: TranslateService,
    private dialog: Dialog,
  ) {}
}
