<app-dialog-title
  [title]="data.title || 'Confirm'"
  [canClose]="canClose"
  (closeDialog)="close()">
</app-dialog-title>

<div class="content">
  <div class="text">
    {{ data.text || 'Confirm?' }}
  </div>

  <div class="btns g-flex g-flex--align-center g-flex--space-between">
    <button
      class="btn-reset app-btn-blue-2"
      (click)="close()">
      {{ data.cancelBtnText }}
    </button>

    <button
      class="btn-reset app-btn-blue-2"
      (click)="onConfirm()">
      {{ data.confirmBtnText }}
    </button>
  </div>
</div>
