import { ACTION_ICONS, ACTION_TYPES, MAGIC_ATTACK_TYPES } from '@shared/constants/damage-types.constant';

export const getDamageData = (attackType, damage) => {
  // not magic attack, return physical damage
  if (attackType === MAGIC_ATTACK_TYPES.UNKNOWN) {
    return {
      actionIcon: ACTION_ICONS.PHYS,
      damage: damage,
    };
  } else if (attackType === MAGIC_ATTACK_TYPES.FIRE) {
    return {
      actionIcon: ACTION_ICONS.FIRE,
      damage: damage,
    };
  } else if (attackType === MAGIC_ATTACK_TYPES.COLD) {
    return {
      actionIcon: ACTION_ICONS.COLD,
      damage: damage,
    };
  } else if (attackType === MAGIC_ATTACK_TYPES.LIGHTNING) {
    return {
      actionIcon: ACTION_ICONS.LIGHTING,
      damage: damage,
    };
  } else if (attackType === MAGIC_ATTACK_TYPES.CHAOS) {
    return {
      actionIcon: ACTION_ICONS.CHAOS,
      damage: damage,
    };
  } else {
    throw new Error('Unknown attack type');
  }
};

export const getHitZeroType = statuses => {
  if (statuses.missed) {
    return ACTION_TYPES.MISS;
  } else if (statuses.hitBlocked) {
    return ACTION_TYPES.BLOCK;
  } else if (statuses.gotCriticalHit) {
    return ACTION_TYPES.CRITICAL;
  } else {
    // we should not have this case but let's show at least miss info
    return ACTION_TYPES.MISS;
  }
};

export const getHitNotZeroType = statuses => {
  if (statuses.missed) {
    return ACTION_TYPES.MISS;
  } else if (statuses.hitBlocked) {
    return ACTION_TYPES.BLOCK;
  } else if (statuses.gotCriticalHit) {
    return ACTION_TYPES.CRITICAL;
  } else {
    return ACTION_TYPES.DAMAGE;
  }
};

export const actionText = (actionType): string => {
  if (actionType === ACTION_TYPES.DAMAGE) {
    return 'action-names.dealt';
  }
  if (actionType === ACTION_TYPES.POISON_DAMAGE) {
    return 'action-names.dealt';
  }
  if (actionType === ACTION_TYPES.RETALIATE) {
    return 'action-names.reflected';
  }
  if (actionType === ACTION_TYPES.MANA_CONSUMED) {
    return 'action-names.consumed';
  }
  if (actionType === ACTION_TYPES.STUN) {
    return 'action-names.was-stunned';
  }
  if (actionType === ACTION_TYPES.BURN) {
    return 'action-names.was-set-on-fire';
  }
  if (actionType === ACTION_TYPES.FREEZE) {
    return 'action-names.was-frozen';
  }
  if (actionType === ACTION_TYPES.CONFUSE) {
    return 'action-names.was-confused';
  }
  if (actionType === ACTION_TYPES.CURSE) {
    return 'action-names.was-cursed';
  }
  if (actionType === ACTION_TYPES.POISON) {
    return 'action-names.was-poisoned';
  }
  if (actionType === ACTION_TYPES.MISS) {
    return 'action-names.missed';
  }
  if (actionType === ACTION_TYPES.BLOCK) {
    return 'action-names.blocked';
  }
  if (actionType === ACTION_TYPES.CRITICAL) {
    return 'action-names.dealt';
  }
  if (actionType === ACTION_TYPES.POITION) {
    return 'action-names.used';
  }
  return '';
};
