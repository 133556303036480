<div
  class="tutorial-backdrop"
  (click)="onNext()"></div>

<div
  #tutorialPointer
  id="tutorial-pointer"
  class="tutorial-pointer"
  [class.tutorial-pointer--show]="isShowPointer">
  <img
    src="assets/images/ui/tutorial/pointer.png"
    alt="" />
</div>

<div
  class="content"
  [ngStyle]="tutorialPosition">
  <img
    class="icon"
    src="assets/images/ui/tutorial/icons/2.png"
    alt="" />

  <button
    class="btn-close app-btn-cross-light"
    (click)="close()"></button>

  <div
    class="bg"
    [style.height.px]="
      textDiv.offsetHeight + 220 < modalMinHeight
        ? modalMinHeight + nextOffset
        : textDiv.offsetHeight + nextOffset + 220
    ">
    <div class="top"></div>
    <div class="bottom"></div>

    <div
      class="middle"
      [style.height.px]="
        (textDiv.offsetHeight + 220 < modalMinHeight
          ? modalMinHeight + nextOffset
          : textDiv.offsetHeight + nextOffset + 220) - 90
      "></div>

    <div
      class="left"
      [style.height.px]="
        (textDiv.offsetHeight + 220 < modalMinHeight
          ? modalMinHeight + nextOffset
          : textDiv.offsetHeight + nextOffset + 220) - 90
      "></div>

    <div
      class="right"
      [style.height.px]="
        (textDiv.offsetHeight + 220 < modalMinHeight
          ? modalMinHeight + nextOffset
          : textDiv.offsetHeight + nextOffset + 220) - 90
      "></div>
  </div>

  <div
    #textDiv
    class="text text-shadow app-btn_font-size"
    [innerHTML]="text"></div>

  <app-checkbox
    class="skip-tutorial"
    [formControl]="skipTutorialsControl"
    label="Skip all tutorials">
  </app-checkbox>

  @if (isHasNext) {
    <button
      class="btn-next app-btn-square-plus app-btn-square-plus--arrows pointer text-shadow app-btn_font-size pointer"
      (click)="onNext()">
      Next
    </button>
  }
</div>
