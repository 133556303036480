import { FeeTokenDataModel } from '@models/fee-token-data.model';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';
import { HEROES } from '@shared/constants/heroes.constant';

export class HeroMetadataModel {
  readonly heroAdr: string;
  readonly heroName: string;
  readonly uri: string;
  readonly heroClass: HEROES;
  readonly feeTokenData: FeeTokenDataModel | null;

  initialAttributes: HeroStatItemInputInterface[] = [];

  constructor(heroAdr: string, name: string, uri: string, heroClass: HEROES, feeTokenData: FeeTokenDataModel | null) {
    this.heroAdr = heroAdr;
    this.heroName = name;
    this.uri = uri;
    this.heroClass = heroClass;
    this.feeTokenData = feeTokenData;
  }
}
