<app-dialog-title
  class="g-flex-column__item-fixed"
  [title]="'transfer-dialog.title' | translate"
  (closeDialog)="close()">
</app-dialog-title>

<div class="content g-flex-column__item g-flex-column g-flex--align-center">
  <input
    class="input app-input app-input--nickname app-btn_font-size"
    [class.app-text-error]="!isValidAddress(addressControl.value || '')"
    [placeholder]="'transfer-dialog.address' | translate"
    [formControl]="addressControl" />

  <button
    class="btn-transfer buttons__item app-btn-red-1"
    appButtonClick
    [isDisabled]="!isValidAddress(addressControl.value || '') || processing"
    (hostClick)="onTransfer()">
    {{ 'transfer-dialog.buttons.send' | translate }}
  </button>
</div>
