<div
  class="text"
  [class.has-description]="!!description"
  [class.text--long]="backgroundType === 'long'"
  [ngClass]="fontType">
  <div class="g-flex-column g-flex--align-center">
    <ng-content></ng-content>

    @if (isShowDivider) {
      <img
        class="icon"
        [class.icon--no-margin]="isNoMargin"
        src="assets/images/ui/dividers/borders_v/border_v_red_down.png"
        alt="" />
    }
  </div>

  @if (description) {
    <div class="desc g-flex g-flex--align-center">
      {{ description }}
    </div>
  }
</div>
