<app-dialog-title
  [title]="'swap-coins-dialog.title' | translate"
  (closeDialog)="close()">
</app-dialog-title>

@if (isLoading && !(sellSelected && buySelected)) {
  <app-loading-small class="page-loader"></app-loading-small>
} @else {
  <div class="content g-flex-column g-flex--align-center g-flex--justify-center">
    @if (!hideSwap()) {
      <div class="sell-info g-flex g-flex--align-center g-flex--space-between app-btn-small_font-size text-shadow">
        <div>
          {{ 'swap-coins-dialog.sell' | translate }}
        </div>

        <div class="g-flex g-flex--align-center">
          <img
            src="assets/images/ui/icons/other/wallet.png"
            alt="" />

          <div>
            {{ sellSelected.valueN?.toFixed(4) }}
          </div>

          <button
            class="app-btn-max"
            (click)="setMax()"></button>
        </div>
      </div>

      <div class="item item__sell g-flex g-flex--align-center g-flex--space-between">
        <app-dropdown
          id="id1"
          [size]="DROPDOWN_SIZE.MEDIUM"
          [items]="sellOptions"
          [selectedItem]="sellSelected"
          (selectItem)="updateSellSelected($event)">
        </app-dropdown>

        <input
          class="item__price-input app-input app-input--large app-btn_font-size color-caption"
          [formControl]="sellPriceControl"
          tetuDigitOnly
          [hasDecimal]="true" />
      </div>

      <button
        class="app-btn-swap"
        (click)="onSwapFields()"></button>

      <div class="buy-info g-flex g-flex--align-center g-flex--space-between app-btn-small_font-size text-shadow">
        <div>
          {{ 'swap-coins-dialog.buy' | translate }}
        </div>

        <div class="g-flex g-flex--align-center">
          <img
            src="assets/images/ui/icons/other/wallet.png"
            alt="" />

          <div>
            {{ buySelected.valueN?.toFixed(4) }}
          </div>
        </div>
      </div>

      <div class="item g-flex g-flex--align-center g-flex--space-between">
        <app-dropdown
          id="id2"
          [size]="DROPDOWN_SIZE.MEDIUM"
          [items]="buyOptions"
          [selectedItem]="buySelected"
          (selectItem)="updateBuySelected($event)">
        </app-dropdown>

        <input
          class="item__price-input app-input app-input--large app-btn_font-size color-caption"
          [formControl]="buyPriceControl"
          tetuDigitOnly
          [hasDecimal]="true" />
      </div>

      @if (!!sellPriceControl.value && sellPriceControl.value !== '0') {
        <div class="info app-btn-small_font-size text-shadow g-flex g-flex--align-center color-caption">
          1 {{ sellSelected.label }} ~ {{ resultPrice() }} {{ buySelected.label }}
        </div>
      }

      @if (!sellPriceControl.value || sellPriceControl.value === '0') {
        <div class="info app-btn-small_font-size text-shadow g-flex g-flex--align-center color-caption">
          1 {{ sellSelected.label }} ~ ? {{ buySelected.label }}
        </div>
      }

      <div
        class="info app-btn-small_font-size text-shadow g-flex g-flex--align-center"
        [ngClass]="priceImpact < 5 ? 'color-caption' : 'color-negative-value'">
        Price impact: {{ priceImpactFormatted() }}%
      </div>

      @if (isEnoughAllowance) {
        <button
          class="btn-swap app-btn-red-3 pointer"
          appButtonClick
          [isNeedConfirm]="true"
          [infoDesc]="getSwapButtonDisabledDesc()"
          [isDisabled]="
            !isEnoughSellAmount() ||
            txProcessing ||
            sellPriceControl.invalid ||
            sellPriceControl.value === '0' ||
            !sellPriceControl.value ||
            buyPriceControl.invalid ||
            buyPriceControl.value === '0' ||
            !buyPriceControl.value
          "
          (hostClick)="onSwap()">
          {{ txProcessing ? '' : 'Swap' }}
        </button>
      }

      @if (txProcessing) {
        <app-loading-small class="swap-loader"></app-loading-small>
      }

      @if (!isEnoughAllowance) {
        <button
          class="btn-swap app-btn-red-3 pointer"
          appButtonClick
          [isDisabled]="
            !isEnoughSellAmount() ||
            txProcessing ||
            sellPriceControl.invalid ||
            sellPriceControl.value === '0' ||
            !sellPriceControl.value ||
            buyPriceControl.invalid ||
            buyPriceControl.value === '0' ||
            !buyPriceControl.value
          "
          (hostClick)="onApprove()">
          Approve
        </button>
      }
    } @else {
      <div>In-game purchase is not available</div>
    }

    <button
      class="btn-swap app-btn-silver-3"
      appButtonClick
      (hostClick)="swapOnDex()">
      Swap on DEX
    </button>
  </div>
}
