/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type { DataStorage, DataStorageInterface } from "../DataStorage";

const _abi = [
  {
    inputs: [],
    name: "clearData",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "data",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "store",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class DataStorage__factory {
  static readonly abi = _abi;
  static createInterface(): DataStorageInterface {
    return new Interface(_abi) as DataStorageInterface;
  }
  static connect(address: string, runner?: ContractRunner | null): DataStorage {
    return new Contract(address, _abi, runner) as unknown as DataStorage;
  }
}
