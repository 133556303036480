import { MAGIC_ATTACK_TYPES } from '@shared/constants/damage-types.constant';

export enum ATTRIBUTES {
  // core
  STRENGTH, // 0
  DEXTERITY, // 1
  VITALITY, // 2
  ENERGY, // 3
  // attributes
  DAMAGE_MIN, // 4
  DAMAGE_MAX, // 5
  ATTACK_RATING, // 6
  DEFENSE, // 7
  BLOCK_RATING, // 8
  LIFE, // 9
  MANA, // 10
  // resistance
  FIRE_RESISTANCE, // 11
  COLD_RESISTANCE, // 12
  LIGHTNING_RESISTANCE, // 13
  // dmg against
  DMG_AGAINST_HUMAN, // 14
  DMG_AGAINST_UNDEAD, // 15
  DMG_AGAINST_DAEMON, // 16
  DMG_AGAINST_BEAST, // 17

  // defense against
  DEF_AGAINST_HUMAN, // 18
  DEF_AGAINST_UNDEAD, // 19
  DEF_AGAINST_DAEMON, // 20
  DEF_AGAINST_BEAST, // 21

  // --- unique, not augmentable
  // hero will not die until have positive chances
  LIFE_CHANCES, // 22
  // increase chance to get an item
  MAGIC_FIND, // 23
  // decrease chance to get an item
  DESTROY_ITEMS, // 24
  // percent of chance x2 dmg
  CRITICAL_HIT, // 25
  // dmg factors
  MELEE_DMG_FACTOR, // 26
  FIRE_DMG_FACTOR, // 27
  COLD_DMG_FACTOR, // 28
  LIGHTNING_DMG_FACTOR, // 29
  // increase attack rating on given percent
  AR_FACTOR, // 30
  // percent of MELEE damage will be converted to HP
  LIFE_STOLEN_PER_HIT, // 31
  // amount of mana restored after each battle
  MANA_AFTER_KILL, // 32
  // reduce all damage on percent after all other reductions
  DAMAGE_REDUCTION, // 33

  // -- statuses
  // chance to stun an enemy, stunned enemy skip next hit
  STUN, // 34
  // chance burn an enemy, burned enemy will loss 10% of defense and resists each turn
  BURN, // 35
  // chance freeze an enemy, frozen enemy will skip a half of turns
  FREEZE, // 36
  // chance to reduce enemy's attack rating on 50% until the end of the battle
  CONFUSE, // 37
  // chance to reduce core stats of enemy on 30% until the end of the battle
  CURSE, // 38
  // percent of dmg return to attacker
  REFLECT_DAMAGE_MELEE, // 39
  REFLECT_DAMAGE_MAGIC, // 40
  // chance to poison enemy, poisoned enemy will loss 5% of the current health each turn
  POISON, // 41
  // reduce chance get any of uniq statuses
  RESIST_TO_STATUSES, // 42

  END_SLOT, // 46
  EXTRA_ENERGY,
  DAMAGE,
}

export const CORE_ATTRIBUTE_TO_NAME = new Map<ATTRIBUTES, string>([
  [ATTRIBUTES.STRENGTH, 'attributes-names.strength'],
  [ATTRIBUTES.DEXTERITY, 'attributes-names.dexterity'],
  [ATTRIBUTES.VITALITY, 'attributes-names.vitality'],
  [ATTRIBUTES.ENERGY, 'attributes-names.energy'],
]);

export const OTHER_ATTRIBUTE_TO_NAME = new Map<ATTRIBUTES, string>([
  [ATTRIBUTES.DAMAGE_MIN, 'attributes-names.damage-min'],
  [ATTRIBUTES.DAMAGE_MAX, 'attributes-names.damage-max'],
  [ATTRIBUTES.DAMAGE, 'attributes-names.damage'],
  [ATTRIBUTES.ATTACK_RATING, 'attributes-names.attack-rating'],
  [ATTRIBUTES.DEFENSE, 'attributes-names.defense'],
  [ATTRIBUTES.BLOCK_RATING, 'attributes-names.block-rating'],
  [ATTRIBUTES.LIFE, 'attributes-names.life'],
  [ATTRIBUTES.MANA, 'attributes-names.mana'],
  [ATTRIBUTES.FIRE_RESISTANCE, 'attributes-names.fire-res'],
  [ATTRIBUTES.COLD_RESISTANCE, 'attributes-names.cold-res'],
  [ATTRIBUTES.LIGHTNING_RESISTANCE, 'attributes-names.lightning-res'],
  [ATTRIBUTES.DMG_AGAINST_HUMAN, 'attributes-names.dmg-against-human'],
  [ATTRIBUTES.DMG_AGAINST_UNDEAD, 'attributes-names.dmg-against-undead'],
  [ATTRIBUTES.DMG_AGAINST_DAEMON, 'attributes-names.dmg-against-daemon'],
  [ATTRIBUTES.DMG_AGAINST_BEAST, 'attributes-names.dmg-against-beast'],
  [ATTRIBUTES.DEF_AGAINST_HUMAN, 'attributes-names.def-against-human'],
  [ATTRIBUTES.DEF_AGAINST_UNDEAD, 'attributes-names.def-against-undead'],
  [ATTRIBUTES.DEF_AGAINST_DAEMON, 'attributes-names.def-against-daemon'],
  [ATTRIBUTES.DEF_AGAINST_BEAST, 'attributes-names.def-against-beast'],
  [ATTRIBUTES.LIFE_CHANCES, 'attributes-names.life-chances'],
  [ATTRIBUTES.MAGIC_FIND, 'attributes-names.magic-find'],
  [ATTRIBUTES.DESTROY_ITEMS, 'attributes-names.destroy-items'],
  [ATTRIBUTES.CRITICAL_HIT, 'attributes-names.critical-hit-chance'],
  [ATTRIBUTES.MELEE_DMG_FACTOR, 'attributes-names.melee-dmg'],
  [ATTRIBUTES.FIRE_DMG_FACTOR, 'attributes-names.fire-dmg'],
  [ATTRIBUTES.COLD_DMG_FACTOR, 'attributes-names.cold-dmg'],
  [ATTRIBUTES.LIGHTNING_DMG_FACTOR, 'attributes-names.lightning-dmg'],
  [ATTRIBUTES.AR_FACTOR, 'attributes-names.ar-factor'],
  [ATTRIBUTES.LIFE_STOLEN_PER_HIT, 'attributes-names.life-stolen-per-hit'],
  [ATTRIBUTES.MANA_AFTER_KILL, 'attributes-names.mana-after-kill'],
  [ATTRIBUTES.DAMAGE_REDUCTION, 'attributes-names.damage-reduction'],
  [ATTRIBUTES.STUN, 'attributes-names.stun'],
  [ATTRIBUTES.BURN, 'attributes-names.burn'],
  [ATTRIBUTES.FREEZE, 'attributes-names.freeze'],
  [ATTRIBUTES.CONFUSE, 'attributes-names.confuse'],
  [ATTRIBUTES.CURSE, 'attributes-names.curse'],
  [ATTRIBUTES.REFLECT_DAMAGE_MELEE, 'attributes-names.reflect-melee-damage'],
  [ATTRIBUTES.REFLECT_DAMAGE_MAGIC, 'attributes-names.reflect-magic-damage'],
  [ATTRIBUTES.POISON, 'attributes-names.poison'],
  [ATTRIBUTES.RESIST_TO_STATUSES, 'attributes-names.resist-to-statuses'],
]);

export const ATTRIBUTE_TO_NAME = new Map<ATTRIBUTES, string>([...CORE_ATTRIBUTE_TO_NAME, ...OTHER_ATTRIBUTE_TO_NAME]);

export const ATTRIBUTE_TO_ICON = new Map<ATTRIBUTES, string>([
  [ATTRIBUTES.LIFE, 'assets/images/ui/icons/attributes/life.png'],
  [ATTRIBUTES.MANA, 'assets/images/ui/icons/attributes/mana.png'],
  [ATTRIBUTES.STUN, 'assets/images/ui/icons/action-types/stun.png'],
  [ATTRIBUTES.BURN, 'assets/images/ui/icons/action-types/burn.png'],
  [ATTRIBUTES.FREEZE, 'assets/images/ui/icons/action-types/freeze.png'],
  [ATTRIBUTES.CONFUSE, 'assets/images/ui/icons/action-types/confuse.png'],
  [ATTRIBUTES.CURSE, 'assets/images/ui/icons/action-types/curse.png'],
  [ATTRIBUTES.POISON, 'assets/images/ui/icons/action-types/poison2.png'],
  [ATTRIBUTES.STRENGTH, 'assets/images/ui/icons/stats/str.png'],
  [ATTRIBUTES.DEXTERITY, 'assets/images/ui/icons/stats/dex.png'],
  [ATTRIBUTES.VITALITY, 'assets/images/ui/icons/stats/vit.png'],
  [ATTRIBUTES.EXTRA_ENERGY, 'assets/images/ui/icons/attributes/EXTRA_ENERGY.png'],
  [ATTRIBUTES.ENERGY, 'assets/images/ui/icons/stats/enrg.png'],
  [ATTRIBUTES.DEFENSE, 'assets/images/ui/icons/stats/defense.png'],
  [ATTRIBUTES.LIFE_CHANCES, 'assets/images/ui/icons/life-chances/5.png'],
  [ATTRIBUTES.DAMAGE_MIN, 'assets/images/ui/icons/attributes/damage.png'],
  [ATTRIBUTES.DAMAGE_MAX, 'assets/images/ui/icons/attributes/damage.png'],
  [ATTRIBUTES.DAMAGE, 'assets/images/ui/icons/attributes/damage.png'],
  [ATTRIBUTES.DMG_AGAINST_HUMAN, 'assets/images/ui/icons/attributes/DMG_AGAINST_HUMAN.png'],
  [ATTRIBUTES.DMG_AGAINST_UNDEAD, 'assets/images/ui/icons/attributes/DMG_AGAINST_UNDEAD.png'],
  [ATTRIBUTES.DMG_AGAINST_DAEMON, 'assets/images/ui/icons/attributes/DMG_AGAINST_DAEMON.png'],
  [ATTRIBUTES.DMG_AGAINST_BEAST, 'assets/images/ui/icons/attributes/DMG_AGAINST_BEAST.png'],
  [ATTRIBUTES.FIRE_DMG_FACTOR, 'assets/images/ui/icons/attributes/FIRE_DMG_FACTOR.png'],
  [ATTRIBUTES.COLD_DMG_FACTOR, 'assets/images/ui/icons/attributes/COLD_DMG_FACTOR.png'],
  [ATTRIBUTES.LIGHTNING_DMG_FACTOR, 'assets/images/ui/icons/attributes/LIGHTNING_DMG_FACTOR.png'],
  [ATTRIBUTES.MELEE_DMG_FACTOR, 'assets/images/ui/icons/attributes/MELEE_DMG_FACTOR.png'],
  [ATTRIBUTES.AR_FACTOR, 'assets/images/ui/icons/attributes/AR_FACTOR.png'],
  [ATTRIBUTES.CRITICAL_HIT, 'assets/images/ui/icons/attributes/CRITICAL_HIT.png'],
  [ATTRIBUTES.DAMAGE_REDUCTION, 'assets/images/ui/icons/attributes/DAMAGE_REDUCTION.png'],
  [ATTRIBUTES.LIFE_STOLEN_PER_HIT, 'assets/images/ui/icons/attributes/LIFE_STOLEN_PER_HIT.png'],
  [ATTRIBUTES.MAGIC_FIND, 'assets/images/ui/icons/attributes/MAGIC_FIND.png'],
  [ATTRIBUTES.MANA_AFTER_KILL, 'assets/images/ui/icons/attributes/MANA_AFTER_KILL.png'],
  [ATTRIBUTES.ATTACK_RATING, 'assets/images/ui/icons/attributes/ATTACK_RATING.png'],
  [ATTRIBUTES.BLOCK_RATING, 'assets/images/ui/icons/attributes/BLOCK_RATING.png'],
  [ATTRIBUTES.DEF_AGAINST_HUMAN, 'assets/images/ui/icons/attributes/DEF_AGAINST_HUMAN.png'],
  [ATTRIBUTES.DEF_AGAINST_UNDEAD, 'assets/images/ui/icons/attributes/DEF_AGAINST_UNDEAD.png'],
  [ATTRIBUTES.DEF_AGAINST_DAEMON, 'assets/images/ui/icons/attributes/DEF_AGAINST_DAEMON.png'],
  [ATTRIBUTES.DEF_AGAINST_BEAST, 'assets/images/ui/icons/attributes/DEF_AGAINST_BEAST.png'],
  [ATTRIBUTES.LIGHTNING_RESISTANCE, 'assets/images/ui/icons/attributes/LIGHTNING_RESISTANCE.png'],
  [ATTRIBUTES.FIRE_RESISTANCE, 'assets/images/ui/icons/attributes/FIRE_RESISTANCE.png'],
  [ATTRIBUTES.COLD_RESISTANCE, 'assets/images/ui/icons/attributes/COLD_RESISTANCE.png'],
  [ATTRIBUTES.REFLECT_DAMAGE_MELEE, 'assets/images/ui/icons/attributes/REFLECT_DAMAGE_MELEE.png'],
  [ATTRIBUTES.REFLECT_DAMAGE_MAGIC, 'assets/images/ui/icons/attributes/REFLECT_DAMAGE_MAGIC.png'],
  [ATTRIBUTES.RESIST_TO_STATUSES, 'assets/images/ui/icons/attributes/RESIST_TO_STATUSES.png'],
  [ATTRIBUTES.DESTROY_ITEMS, 'assets/images/ui/icons/attributes/DESTROY_ITEMS.png'],
]);

export const MAGIC_ATTACK_NAME = new Map<MAGIC_ATTACK_TYPES, string>([
  [MAGIC_ATTACK_TYPES.UNKNOWN, 'attributes-names.unknown'],
  [MAGIC_ATTACK_TYPES.FIRE, 'attributes-names.fire'],
  [MAGIC_ATTACK_TYPES.COLD, 'attributes-names.cold'],
  [MAGIC_ATTACK_TYPES.LIGHTNING, 'attributes-names.lightning'],
  [MAGIC_ATTACK_TYPES.CHAOS, 'attributes-names.chaos'],
]);

export const MAGIC_ATTACK_ICON = new Map<MAGIC_ATTACK_TYPES, string>([
  [MAGIC_ATTACK_TYPES.UNKNOWN, 'assets/images/ui/icons/action-types/phys.png'],
  [MAGIC_ATTACK_TYPES.FIRE, 'assets/images/ui/icons/action-types/fire.png'],
  [MAGIC_ATTACK_TYPES.COLD, 'assets/images/ui/icons/action-types/cold.png'],
  [MAGIC_ATTACK_TYPES.LIGHTNING, 'assets/images/ui/icons/action-types/lighting.png'],
  [MAGIC_ATTACK_TYPES.CHAOS, 'assets/images/ui/icons/action-types/chaos.png'],
]);

export const ATTRIBUTE_TO_DESCRIPTION = new Map<ATTRIBUTES, string>([
  [ATTRIBUTES.STRENGTH, 'attributes-descriptions.strength'],
  [ATTRIBUTES.DEXTERITY, 'attributes-descriptions.dexterity'],
  [ATTRIBUTES.VITALITY, 'attributes-descriptions.vitality'],
  [ATTRIBUTES.ENERGY, 'attributes-descriptions.energy'],
  [ATTRIBUTES.DAMAGE_MIN, 'attributes-descriptions.damage-min'],
  [ATTRIBUTES.DAMAGE_MAX, 'attributes-descriptions.damage-max'],
  [ATTRIBUTES.DAMAGE, 'attributes-descriptions.damage'],
  [ATTRIBUTES.ATTACK_RATING, 'attributes-descriptions.attack-rating'],
  [ATTRIBUTES.DEFENSE, 'attributes-descriptions.defense'],
  [ATTRIBUTES.BLOCK_RATING, 'attributes-descriptions.block-rating'],
  [ATTRIBUTES.LIFE, 'attributes-descriptions.life'],
  [ATTRIBUTES.MANA, 'attributes-descriptions.mana'],
  [ATTRIBUTES.FIRE_RESISTANCE, 'attributes-descriptions.fire-resistance'],
  [ATTRIBUTES.COLD_RESISTANCE, 'attributes-descriptions.cold-resistance'],
  [ATTRIBUTES.LIGHTNING_RESISTANCE, 'attributes-descriptions.lightning-resistance'],
  [ATTRIBUTES.DMG_AGAINST_HUMAN, 'attributes-descriptions.dmg-against-human'],
  [ATTRIBUTES.DMG_AGAINST_UNDEAD, 'attributes-descriptions.dmg-against-undead'],
  [ATTRIBUTES.DMG_AGAINST_DAEMON, 'attributes-descriptions.dmg-against-daemon'],
  [ATTRIBUTES.DMG_AGAINST_BEAST, 'attributes-descriptions.dmg-against-beast'],
  [ATTRIBUTES.DEF_AGAINST_HUMAN, 'attributes-descriptions.def-against-human'],
  [ATTRIBUTES.DEF_AGAINST_UNDEAD, 'attributes-descriptions.def-against-undead'],
  [ATTRIBUTES.DEF_AGAINST_DAEMON, 'attributes-descriptions.def-against-daemon'],
  [ATTRIBUTES.DEF_AGAINST_BEAST, 'attributes-descriptions.def-against-beast'],
  [ATTRIBUTES.LIFE_CHANCES, 'attributes-descriptions.life-chances'],
  [ATTRIBUTES.MAGIC_FIND, 'attributes-descriptions.magic-find'],
  [ATTRIBUTES.DESTROY_ITEMS, 'attributes-descriptions.destroy-items'],
  [ATTRIBUTES.CRITICAL_HIT, 'attributes-descriptions.critical-hit'],
  [ATTRIBUTES.MELEE_DMG_FACTOR, 'attributes-descriptions.melee-dmg-factor'],
  [ATTRIBUTES.FIRE_DMG_FACTOR, 'attributes-descriptions.fire-dmg-factor'],
  [ATTRIBUTES.COLD_DMG_FACTOR, 'attributes-descriptions.cold-dmg-factor'],
  [ATTRIBUTES.LIGHTNING_DMG_FACTOR, 'attributes-descriptions.lightning-dmg-factor'],
  [ATTRIBUTES.AR_FACTOR, 'attributes-descriptions.ar-factor'],
  [ATTRIBUTES.LIFE_STOLEN_PER_HIT, 'attributes-descriptions.life-stolen-per-hit'],
  [ATTRIBUTES.MANA_AFTER_KILL, 'attributes-descriptions.mana-after-kill'],
  [ATTRIBUTES.DAMAGE_REDUCTION, 'attributes-descriptions.damage-reduction'],
  [ATTRIBUTES.STUN, 'attributes-descriptions.stun'],
  [ATTRIBUTES.BURN, 'attributes-descriptions.burn'],
  [ATTRIBUTES.FREEZE, 'attributes-descriptions.freeze'],
  [ATTRIBUTES.CONFUSE, 'attributes-descriptions.confuse'],
  [ATTRIBUTES.CURSE, 'attributes-descriptions.curse'],
  [ATTRIBUTES.REFLECT_DAMAGE_MELEE, 'attributes-descriptions.reflect-damage-melee'],
  [ATTRIBUTES.REFLECT_DAMAGE_MAGIC, 'attributes-descriptions.reflect-damage-magic'],
  [ATTRIBUTES.POISON, 'attributes-descriptions.poison'],
  [ATTRIBUTES.RESIST_TO_STATUSES, 'attributes-descriptions.resist-to-statuses'],
]);
