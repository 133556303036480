import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

import { ExecCommandEventsEnum } from '../../constants/browser-exec-comand.constant';
import { getOnlyEnglishLettersString, getOnlyNumbersString, onlyASCIIRegExp } from '../../utils';

import { isSpecialKeyboardCombination, keyboardEventsEnum, navigationKeyboardKeys } from './constants';

@Directive({
  selector: '[tetuDigitOnly]',
  standalone: true,
})
export class DigitOnlyDirective {
  @Input() isDigitsOnlyEnabled = true;
  @Input() hasDecimal = false;
  @Input() isOnlyEnglishLetters = false;
  @Input() minValue?: number;
  @Input() maxValue?: number;

  private decimalCounter = 0;
  inputElement: HTMLElement;

  constructor(
    @Optional() private ngControl: NgControl,
    private el: ElementRef,
  ) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (!this.isDigitsOnlyEnabled) {
      return;
    }

    if (
      navigationKeyboardKeys.includes(e.key as keyboardEventsEnum) || // Allow: navigation keys: backspace, delete, arrows etc.
      isSpecialKeyboardCombination(e) ||
      (this.hasDecimal && e.key === '.' && this.decimalCounter < 1) // Allow: only one decimal point
    ) {
      // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress
    if (
      ((e.key === ' ' || isNaN(Number(e.key))) && !this.isOnlyEnglishLetters) ||
      (this.isOnlyEnglishLetters && !onlyASCIIRegExp.test(e.key))
    ) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp() {
    if (!this.isDigitsOnlyEnabled) {
      return;
    }

    if (this.minValue && this.ngControl.value && +this.ngControl.value < this.minValue) {
      this.ngControl.control?.setValue(this.minValue);
    }

    if (this.maxValue && this.ngControl.value && +this.ngControl.value > this.maxValue) {
      this.ngControl.control?.setValue(this.maxValue);
    }

    if (!this.hasDecimal || !this.ngControl.value) {
      return;
    } else {
      this.decimalCounter = this.ngControl.value?.split('.').length - 1;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (!this.isDigitsOnlyEnabled || !event) {
      return;
    }
    event.preventDefault();
    const pastedInput: string = getOnlyNumbersString(event.clipboardData!.getData('text/plain'), true);
    this.execCommand(ExecCommandEventsEnum.insertText, Number.isNaN(Number(pastedInput)) ? '0' : pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (!this.isDigitsOnlyEnabled) {
      return;
    }
    const textData = event.dataTransfer!.getData('text');
    this.inputElement.focus();

    this.handleEvents(event, textData);
  }

  private handleEvents(event: ClipboardEvent | DragEvent, value: string) {
    const pasted = this.insertText(value, this.hasDecimal);

    if (pasted) {
      event.preventDefault();
    } else {
      if (navigator.clipboard) {
        navigator!.clipboard.writeText(value);
        this.execCommand(ExecCommandEventsEnum.paste);
      }
    }
  }

  private insertText(value: string, hasDecimal = false): boolean {
    return this.isOnlyEnglishLetters
      ? this.execCommand(ExecCommandEventsEnum.insertText, getOnlyEnglishLettersString(value))
      : this.execCommand(ExecCommandEventsEnum.insertText, getOnlyNumbersString(value, hasDecimal));
  }

  private execCommand(command: ExecCommandEventsEnum, value?: string, showUi = false): boolean {
    return document!.execCommand(command, showUi, value);
  }
}
