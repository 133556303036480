import { Injectable } from '@angular/core';
import { TransactionDataModel, TransactionStatus } from '@models/transaction-data.model';
import { DestroyService } from '@services/destroy.service';
import { ProviderService } from '@services/provider.service';
import { StorageService } from '@services/storage.service';
import { NGXLogger } from 'ngx-logger';
import { mergeMap, takeUntil } from 'rxjs';

export const STORAGE_KEY_ACTIONS = 'TETU_GAME_ACTIONS';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  transactions: { [key: string]: TransactionDataModel } = {};

  constructor(
    private storageService: StorageService,
    private destroy$: DestroyService,
    private logger: NGXLogger,
  ) {}

  getTransactions() {
    return this.transactions;
  }

  setTransaction(transaction: TransactionDataModel) {
    if (transaction.status === TransactionStatus.REJECTED) {
      delete this.transactions[transaction.id];
    } else {
      this.transactions[transaction.id] = transaction;
    }

    this.storageService.set(
      STORAGE_KEY_ACTIONS,
      JSON.stringify(
        this.transactions,
        (key, value) => {
          if (typeof value === 'bigint') {
            return value.toString();
          }
          if (key === 'relayService') {
            return undefined;
          }
          return value;
        }, // return everything else unchanged
      ),
    );
  }

  removeTransaction(transaction: TransactionDataModel) {
    delete this.transactions[transaction.id];

    this.storageService.set(
      STORAGE_KEY_ACTIONS,
      JSON.stringify(
        this.transactions,
        (key, value) => (typeof value === 'bigint' ? value.toString() : value), // return everything else unchanged
      ),
    );
  }

  removeAllTransactions() {
    this.storageService.remove(STORAGE_KEY_ACTIONS);
    this.storageService.set(STORAGE_KEY_ACTIONS, JSON.stringify({}));
    this.transactions = {};
  }

  checkLastTx(providerService: ProviderService) {
    const txs = Object.values(this.transactions)
      .map(it => new TransactionDataModel(it))
      .sort((a, b) => (b?.date ?? 0) - (a?.date ?? 0));
    for (const tx of txs) {
      if (tx.tx && tx.status === TransactionStatus.PENDING) {
        providerService
          .getWeb3$()
          .pipe(
            mergeMap(p => {
              // @ts-ignore
              return p.eth.getTransactionReceipt(tx.tx);
            }),
            takeUntil(this.destroy$),
          )
          .subscribe(receipt => {
            this.logger.trace('LAST TX receipt', receipt);
            if (receipt) {
              tx.status = receipt.status ? TransactionStatus.COMPLETED : TransactionStatus.FAILED;
              this.setTransaction(tx);
            }
          });
      }
    }
  }
}
