import { RelayService } from '@services/onchain/relay.service';
import { format } from 'date-fns';
import { ContractTransaction } from 'ethers';
import * as uuid from 'uuid';

export enum TransactionStatus {
  PENDING = 0,
  COMPLETED = 1,
  FAILED = 2,
  REJECTED = 3,
}

export const TransactionStatusName = {
  [TransactionStatus.PENDING]: 'Pending',
  [TransactionStatus.COMPLETED]: 'Completed',
  [TransactionStatus.FAILED]: 'Failed',
};

export class TransactionDataModel {
  readonly id: string = '';
  readonly name: string = '';
  readonly date: number = 0;
  readonly dateFormatted: string = '';

  // ---- TX DATA ---
  chainId?: number;
  readonly txPopulated: Promise<ContractTransaction>;
  value?: bigint;
  gasLimit?: bigint;
  maxFeePerGas?: bigint;
  maxPriorityFeePerGas?: bigint;
  gasPrice?: bigint;
  to?: string;
  from?: string;

  // ----------------

  status: TransactionStatus = TransactionStatus.PENDING;
  error?: string;
  symbol?: string;
  balance?: string;
  balanceUsd?: string;
  operation?: string;
  tx?: string;
  txLink?: string;
  isNeedUpdateHero?: boolean;
  isNeedUpdateBalances?: boolean;
  isItemUsed?: boolean;

  waitSubgraphSync = true;
  waitViaWs = false;
  subgraphWaitUserData = false;
  isSponsoredRelayPossible = false;
  isDelegatedRelayPossible = true;
  useDelegatedPK = false;
  showLoadingScreen = true;
  showSpeedUpDialog?: boolean;
  readonly relayService?: RelayService;

  constructor(data: Partial<TransactionDataModel> = {}) {
    Object.assign(this, data, {
      id: data.id ? data.id !== '' : uuid.v4(),
      date: !!data.date ? data.date !== 0 : Date.now(),
      dateFormatted: data.dateFormatted !== '' ? data.dateFormatted : format(new Date(), 'dd MMMM yyyy'),
    });
  }
}
