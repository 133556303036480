import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-count',
  standalone: true,
  templateUrl: './filter-count.component.html',
  styleUrls: ['./filter-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterCountComponent {
  @Input() count: string = '';
}
