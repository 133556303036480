import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';

@Component({
  selector: 'app-hero-stat-item-dialog',
  standalone: true,
  templateUrl: './hero-stat-item-dialog.component.html',
  styleUrls: ['./hero-stat-item-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, TranslateModule],
})
export class HeroStatItemDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: HeroStatItemInputInterface,
    private dialogRef: DialogRef<HeroStatItemInputInterface | null, HeroStatItemDialogComponent>,
  ) {}

  close(): void {
    this.dialogRef.close(null);
  }

  enterBiome(): void {
    this.dialogRef.close(this.data);
  }
}
